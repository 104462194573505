<script>
import './SummaryTableCSS.svelte';
import SummaryTableHeader from'./SummaryTableHeader.svelte';
import SummaryTableRow from'./SummaryTableRow.svelte';
import { get_rating } from './../evaluation_data.js';
export let cmp31;
export let cmp32;
export let cmp33;
</script>

<table class="summary_table">
    <SummaryTableHeader text_column1={"PTSD diagnosis"} text_column2={"Past Month"}/>
    <SummaryTableRow text1={"PTSD PRESENT – ALL CRITERIA (A-G) MET?"} cmp={cmp31} col3={false}/>
    <SummaryTableRow text1={"With dissociative symptoms"} cmp={cmp32} col3={false}/>
    <SummaryTableRow text1={"(21) With delayed onset (> 6 months)"} rating={get_rating(21)} cmp={cmp33} col3={false}/>
</table>
