<script>
  import pageFile from "./pages.json";
  import Intensity from './ContentIntensity.svelte';

  export let num = 0;
  let container = "container-1";
  let question_style = "font-weight: bold;"
  if(num>20 && num <29)
  {
    container = "container-2";
    question_style = "font-weight: normal;"
  }
  let inline_text_classes =["text-inline1","text-inline2","text-inline3"];

</script>

<style lang="less">
  [class*="container-"]
  {
    border: 1px solid #000000;
    top: 15%;
    padding-bottom: 1%;
  }
  .container-1{ width: 62%; }
  .container-2{ flex: 1; padding: 1vw; margin-right: .5vw; }

  .question{ font-size: 1.15vw; }
  
  [class*="text-"]
  {
    line-height: 1.5;
    font-size: 1vw;
    margin-inline-start: 1em;
    margin-inline-end: 1em;
    margin-block-start: 0em;
    margin-block-end: 1em;
  }
  .text-paragraph{ font-size: 1vw; }
  .text-inline1{ margin-inline-start: 2em; }
  .text-inline2{ margin-inline-start: 3em; }
  .text-inline3{ margin-inline-start: 4em; }

  @media (max-width:1000px)
  {
      .text-paragraph, .text-inline1, .text-inline2, .text-inline3 { font-size: 10px; }
      .question{ font-size: 11px; }
  }

  @media (max-width:700px){ .container-1{ width: 59%; }}
</style>

<div class={container}>
  {#each pageFile as item}
    {#if item.id === num}
      {#each item.questions as content}
        <p class="text-paragraph"><span class="question" style={question_style}>{@html content.question}</span>{@html content.subtext}</p>
        {#if content.followup}        
          {#each content.followup as f,i}
            <p class={inline_text_classes[i]}>{f.pretext}<span class="question" style={question_style}>{@html f.maintext}</span>{@html f.ratings}</p>
          {/each}
        {/if}
      {/each}
    {/if}
  {/each}
  {#if num < 21 || num > 28}
    <Intensity num={num}/>
  {/if}
</div>