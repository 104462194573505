<script>
import {close_transcript} from './TranscriptReview.svelte';
import ContextController from './ContextController.svelte';
</script>
<style lang="less">
	#titleBar
	{
		background-color: #023b5a;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 7%;
		width: 100%;
	}
	#titleParagraph
	{
		font-size: 1vw;
		color: white;
		margin-block-end: 0;
		margin-block-start: 0;
	}
	.reviewCloseBtn:focus > .btn_content
	{
		box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
		outline: none;
	}
	.reviewCloseBtn:focus,
	.btn_content:focus
	{
		outline: none;
	}
	.reviewCloseBtn:hover { box-shadow: 0 0 0 2px white; }
	.btn_content
	{
		display: flex;
		width: 100%;
		height: 100%;
		pointer-events: all;
		cursor: pointer;
		border-radius: 2px;
	}
	#reviewCloseBtnDiv
	{
		height: 5%;
		width: 5%;
		position: absolute;
		right: 1%;
		top: 1%;
		pointer-events: all;
		cursor: pointer;
		z-index: 40;
	}
	.reviewCloseBtn
	{
		border: none;
		border-radius: 2px;
		display: block;
		height: 100%;
		width: 100%;
		background-color: transparent;
		padding: 0;
		pointer-events: none;
	}
	#closeSvg
	{
		height: 3%;
		width: 3%;
		position: absolute;
		right: 2%;
		top: 2%;
		fill: white;
	}
</style>
  
<div id="titleBar">
	<p id="titleParagraph">Review Responses</p>
	<div id="reviewCloseBtnDiv">		
		<ContextController divID={"ctx_review_close"}/>
		<button class="reviewCloseBtn" on:click|stopPropagation={close_transcript}>				
			<span class="btn_content" tabindex="-1" aria-label="Review Close">
			</span>
		</button>
	</div>
	<svg id="closeSvg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
</div>
