<script>
  import Content from './Content-A.svelte';
  import Question from './ContentQuestion-A.svelte';
  import Subtext from './ContentSubtext-A.svelte';
  import Exposure from './ContentExposure-A.svelte';

  export let num = 0;
  export let initCoach;
  </script>
  <style>
  #critAFlexContainer
  {
    display: block;
    height: 90%;
    overflow-y:auto;
    overflow-x: hidden;
    margin-bottom: 1%;
  }
  #questionExposureTable
  {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  </style>
  <div id="critAFlexContainer">
    <Content bind:value={num} bind:coachStart={initCoach}/>
    <div id="questionExposureTable">
      <Question/>
      <Exposure/>
    </div>
    <Subtext/>
  </div>



  