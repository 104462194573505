<script>
    import pageFile from "./pages.json";

    export let num = 0;
</script>

<style lang="less">
.titleBar
  {
    width: 100%;
    z-index: 0;
    position: relative;
    display: block;
    padding-bottom: 1%;
  }
  p
  {
        //font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    line-height: 1.5;
    font-size: 1vw;
    margin-inline-start: 0em;
    margin-inline-end: 1em;
    margin-block-start: .5em;
    margin-block-end: 0em;
  }
.span_title{
  font-size: 1.15vw;
  font-weight: bold;
}

@media (max-width:1000px){
    p
  {
      
      font-size: 14px;
  }
  .span_title
  {
    font-size: 14px;    
  }
}
</style>


<div class="titleBar" id="title_bar">
    {#each pageFile as jsonfile}     
        {#if jsonfile.id === num}            
            <p class="titleParagraph"><span class="span_title" data-cy="itemTitle">{@html jsonfile.titleName}</span>{@html jsonfile.titleText}</p>
        {/if}
    {/each}
</div>