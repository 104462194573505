<script>
import './SummaryTableCSS.svelte';
export let text_column1;
export let text_column2;
</script>

<tr class="summary_table">
    <th scope="col" bgcolor="#C0C0C0" align="left"><b>{text_column1}</b></th>
    <th scope="col" colspan="2" bgcolor="#C0C0C0" align="center"><b>{text_column2}</b></th>
</tr>
