<script>
import { onMount, onDestroy } from 'svelte';
import evaluationFile from "./evaluation.json";
export let transcriptMethod;

onMount(async function()
{
	document.getElementById("transcriptContainer").addEventListener('scroll', timeOutScroll);
});
	
onDestroy(function() {
	document.getElementById("transcriptContainer").removeEventListener("scroll", timeOutScroll);
})
</script>
<script context="module">
let topDivItem;
let topDivItemText = '';
let bottomDivItem;
let bottomDivItemText = '';

export function get_top_div_txt()
{
	return topDivItemText;
}

export function get_bottom_div_txt()
{
	return bottomDivItemText;
}

export function torcLinkToggle(trigger)
{
	var torc = document.getElementsByClassName("torc_link_div_transcript");
	var i;
	for (i = 0; i < torc.length; i++) {
		if(trigger)
			torc[i].style.display = "block";
		else
			torc[i].style.display = "none";
	}
}

export function scrollCriterion()
{
	var x = Array.from(document.getElementsByClassName("transcriptCriterionItemContainer"));
	var e = document.getElementById("transcriptContainer");
	var container = e.getBoundingClientRect();
	var topBanner = document.getElementById("topBannerContainer");
	var bottomBanner = document.getElementById("bottomBannerContainer");
	var critLabel = document.getElementsByClassName("criterionLabel");
	let critLabelHeight = 0;

	if(critLabel.length > 0)
		critLabelHeight = critLabel[0].getBoundingClientRect().height;
		
	if(x.length > 0)
	{
		bottomBanner.style.display = "block";
		topBanner.style.display = "block";
	}

	var breakNext = false;

	if(e.offsetHeight + e.scrollTop >= e.scrollHeight)
		bottomBanner.style.display = "none";
	if(e.scrollTop == 0)
		topBanner.style.display = "none";
	if(x.length <= 1)
		bottomBanner.style.display = "none";

	x.forEach(function(element, index){
		var rect = element.getBoundingClientRect();
		
		if(breakNext)
		{							
			bottomDivItem = element;
			bottomDivItemText = setNavBannerItemText(index);
			breakNext = false;					
		}			
		if(rect.top >= container.top - 1 && rect.bottom <= container.bottom)
			return;
		if(rect.top >= container.top - 1 && rect.top <= container.bottom && rect.bottom >= container.bottom)
		{				
			bottomDivItem = element;	
			if(index < x.length - 1)	
			{
				bottomDivItem = x[index+1];
				bottomDivItemText = setNavBannerItemText(index + 1);
				if(Math.abs(container.bottom - rect.top) < critLabelHeight * 2)
					bottomBanner.style.display = "none";
				else
					bottomBanner.style.display = "block";
			}				
			else	
				bottomBanner.style.display = "none";			
		}
		if(rect.top <= container.top && rect.bottom >= container.top && rect.bottom <= container.bottom)
		{	
			if(Math.abs(container.top - rect.top) < critLabelHeight || Math.abs(container.top - rect.bottom) < critLabelHeight)
				topBanner.style.display = "none";
			else
				topBanner.style.display = "block";
			topDivItem = element;
			topDivItemText = setNavBannerItemText(index);	
			if(index >= x.length - 1)
				bottomBanner.style.display = "none";	
		}
		if(rect.top <= container.top && rect.bottom >= container.bottom)
		{
			if(Math.abs(container.top - rect.top) < critLabelHeight)
				topBanner.style.display = "none";
			else
				topBanner.style.display = "block";	
			topDivItem = element;
			topDivItemText = setNavBannerItemText(index);
			breakNext = true;	
			if(index >= x.length - 1)
				bottomBanner.style.display = "none";	
		}
	});
}
function setNavBannerItemText(index)
{	
	for(var i=0; i<evaluationFile.criterions.length; i++)
	{
		if(index == 0)
		{
			return bottomDivItemText = evaluationFile.criterions[i].criterion_title;
		}
		for(var k in evaluationFile.criterions[i].criterions)
			if(evaluationFile.criterions[i].criterions[k].itemNum == index)
				{
					return bottomDivItemText = evaluationFile.criterions[i].criterions[k].title;
				}					
	}
}
let isScrolling = false;
function timeOutScroll()
{
	isScrolling = true;
}
let transcriptInterval;
export function start_transcript_interval()
{
	transcriptInterval = setInterval(() => {
		if(isScrolling)
		{
			isScrolling = false
			scrollCriterion();
		}			
	}, 25)
}

export function stop_transcript_interval()
{
	clearInterval(transcriptInterval);
}

export function scroll_transcript_to_top()
{
	var container = document.getElementById("transcriptContainer");
	if(topDivItem)
	{
		if(topDivItem.offsetTop - 5 <= 0)
			container.scrollTop = 0;
		else
			container.scrollTop = topDivItem.offsetTop - 5;
	}
	scrollCriterion();
		
}
export function scroll_transcript_to_bottom()
{
	var container = document.getElementById("transcriptContainer");
	if(bottomDivItem)
	{
		if(bottomDivItem.offsetTop + 5 >= container.scrollHeight - container.offsetHeight)
			container.scrollTop = container.scrollHeight - container.offsetHeight;
		else
			container.scrollTop = bottomDivItem.offsetTop + 5;
	}		
	scrollCriterion();
}
export function add_criterion_div(page)
{
	var transcriptContainer = document.getElementById("transcriptContainer");
	transcriptContainer.innerHTML += 
		'<div class="transcriptCriterionItemContainer" id="'+ page + 'TranscriptDiv"><div class="criterionLabel"><span class="critBannerSpan"><p class="bannerParagraph">'+ setNavBannerItemText(page) +'</p></span><div></div>'; 
}
export function scroll_transcript()
{
	var container = document.getElementById("transcriptContainer");
	container.scrollTop = container.scrollHeight - container.clientHeight;
}

</script>
<style lang="less">
	#transcriptContainer, #transcriptContainerPrint
	{
		position: relative;
		display: flex;
		flex-direction: column;		
		overflow-y: auto;
		border-bottom: 1px solid black;
	}
	#transcriptContainer
	{
		height: 90%;
	}
	#transcriptContainerPrint
	{
		height: auto;
	}
</style>

<div id={transcriptMethod}>		</div>
