<script>
    import { onMount } from "svelte"
    import { get_saved_state } from "./saved_states.js"
    import { post_item_completed, get_session, ServerError, get_user_item_progress} from "./server_api.js"
    import pageFile from "./pages.json";
    import ContextController from "./ContextController.svelte";
    import { context_toggle, game_mode } from "./stores";
    export let num = 0;
    let symptom_select;
    let value;
    let percentageInput;
    let loading_symptom_select = false;

    let noBtn;
    let yesBtn;

  $: {
    if(symptom_select !== undefined && !loading_symptom_select)
    {
      if(num ===21)
        post_item_completed({over_delay:symptom_select}).catch((err)=>{
          if(err instanceof ServerError)
            refresh_data();
        });
      else
        post_item_completed({over_duration:symptom_select}).catch((err)=>{
          if(err instanceof ServerError)
            refresh_data();
        });
    }
    if(loading_symptom_select)
    {
      ToggleInput();
      loading_symptom_select = false;
    }
      
  }

  function symptomSelectBtnPressed(target)
  {
    if(target)
      target.click();
  }

  function symptomSelectPressed(target)
  {
    if(target === null)
      return;
    if(target.value == symptom_select)
    {
      symptom_select = null;
      target.checked = false;
    }
    else
      target.checked = true;
  }

    function limitInput(e)
    {
      value = e.target.value

      if(num === 21)
        post_item_completed({delay:value}).catch((err)=>{
          if(err instanceof ServerError)
            refresh_data();
        });
      else
        post_item_completed({duration:value}).catch((err)=>{
          if(err instanceof ServerError)
            refresh_data();
        });
    }

    function autoSelect()
    {
      const tempInput = document.getElementById('percentageInput');
      tempInput.select();
    }

    function clear()
    {
      percentageInput.value = "";
      symptom_select = null;
    }
  async function ToggleInput()
  {
    let disabled = true;
    let progress = await get_user_item_progress(get_session());

    disabled = (progress[num].passed != null && progress[num].passed != 0) && $game_mode == "learn" ? true : false;

    var btns = document.getElementsByClassName("btn");
    var i;

    for (i = 0; i < btns.length; i++) {
      if(disabled)
        btns[i].disabled = true;
      else
        btns[i].disabled = false;

    }

    var btnsRadio = document.getElementsByClassName("btnRadio");
    for (i = 0; i < btnsRadio.length; i++) {
      if(disabled)
        btnsRadio[i].disabled = true;  
      else
        btnsRadio[i].disabled = false;    
    }

    if(disabled)
      percentageInput.disabled = true;
    else
      percentageInput.disabled = false;
  }
    function refresh_data()
    {
      clear();
      let saved_state = get_saved_state(num,get_session());
      if(saved_state)
      {
        loading_symptom_select = true;
        if(saved_state.frequency != null && saved_state.frequency != undefined)
          percentageInput.value = saved_state.frequency;
        else if(saved_state.duration != null && saved_state.duration != undefined)
          percentageInput.value = saved_state.duration;
        else if(saved_state.delay != null && saved_state.delay != undefined)
          percentageInput.value = saved_state.delay;
        if(saved_state.over_duration != null && saved_state.over_duration != undefined)
          symptom_select = saved_state.over_duration;
        else if(saved_state.over_delay != null && saved_state.over_delay != undefined)
          symptom_select = saved_state.over_delay;
        else
          ToggleInput();
      }
    }

    onMount( async function(){ refresh_data(); });
</script>

<style lang="less">

  .btn:focus > .btn_content  {
      box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
      outline: none;
  }
  .btn:focus,
  .btn_content:focus {
      outline: none;
  }
  .btn_content
  { 
    display: inline-block;
    width: 100%;
    height: 100%;
    pointer-events: all;
  }
  .btn
  {
    background: transparent;
    border: none !important;
    font-size: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 1px 0px;
    cursor: pointer;
    pointer-events: none;
  }
  p
  {
    line-height: 1.5;
    font-size: 1vw;
    margin-inline-start: 0em;
    margin-inline-end: 0em;
    margin-block-start: 0em;
    margin-block-end: 1em;
  }
  .container
  {
    flex: 1;
    padding: 1vw;
    border: 1px solid #000000;
  }
  .context_ctnr
  {
    position: relative;
    width:auto;
    height:auto;
  }
  span
  {
    font-size: 1.15vw;
    font-weight: bold;
  }

  input
  {
    border: 0;
    outline: 0;
    border-bottom: 1px solid black;
    font-family: 'Lato', sans-serif;
    width: 13vw;
  }
  .percentageInput
  {
      font-family: 'Kalam', cursive;
      text-align: left;
      font-size: 1.25vw;
  }
  .btn_label
  {
    font-size: 1vw;
    display: inline-block;
    cursor: pointer;
    position: relative;
    pointer-events: none;
  }

  .yesno {
    stroke-dasharray: 61.551002502441406;
    stroke-dashoffset: 61.551002502441406;
    animation: draw .1s ease-out forwards;
  }
  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }
  .context_enter_answer_ctnr
  {
    position: relative;
  }

  svg
  {
    position: relative;
    width: 2vw;
    height: 1.5vw;
    transition: stroke-dashoffset 0.2s ease-in-out 0s;
  }
  label
  {
    cursor: pointer;
    display: inline-block;
  }
  p
  {
    font-size: 0.95vw;
    margin-inline-start: 0.5vw;
    margin-inline-end: 0.5vw;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  .svg-container
  {
    position: absolute;
    display: none;
    width: 5%;
    height: 5%;
  }
  [type=radio] { 
    position: absolute;
    cursor: pointer;
    opacity: 0;
    width: 0;
    height: 0;
  }
  [type=radio] + .svg-container { cursor: pointer; }
  [type=radio]:checked + .svg-container{ display: inline-block; }
  path
  {
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
    animation: draw .1s ease-out forwards;
    fill:none;
    stroke:#000;
    stroke-miterlimit:10;
    stroke-width:0.75px;
  }
  #noSvg
  {
    left: -.25vw;
    top: -.15vw;
  }

  @media (max-width:1000px){
      p{ font-size: 10px; }
  }
</style>

<div class="container">
  {#each pageFile as item}
    {#if item.id === num}
      {#each item.intensity as content}
        <p><span>{content.question}</span> {@html content.subtext}
          <label class="context_enter_answer_ctnr">
            <ContextController divID={"ctx_enter_answer"} />
            <input type="text" bind:this={percentageInput} class="percentageInput" id="percentageInput" maxlength="25" on:input={limitInput} on:click={autoSelect} tabindex={$context_toggle?"-1":"0"} data-cy={'fPercentageInput'}>
          </label>
        </p>
        <p><i>{content.symptomQuestion}</i></p>
        <div class="context_ctnr">
          <ContextController divID={"ctx_select"} ctx_help_div_style={"width:4vw;left:1vw;top:-.25vw;"}/>
          <p>
            <label class="btn_label">
              <button class="btn" on:click={(event)=>symptomSelectBtnPressed(noBtn)} tabindex={$context_toggle?"-1":"0"} data-cy={'btnNO'}><span class="btn_content" tabindex="-1"></span></button>
              <input class="btnRadio" type="radio" bind:group={symptom_select}  value={0} bind:this={noBtn} on:click={(event)=>symptomSelectPressed(noBtn)} tabindex="-1">
              <div class="svg-container">
                <svg id="noSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.01 11.97"><defs></defs><path class="yesno" d="M13.35,12.15a18.45,18.45,0,0,1-4.91.48c-2.35-.1-5.47-.21-7.15-2.41A3.79,3.79,0,0,1,.38,8C.3,4.52,7.4.22,14.11.38c4.49.11,11.1,2.3,11.51,6.09C26,9.8,21.54,14.14,14.38,15.6"/></svg>
              </div>
              <i>NO</i>
            </label>
            <label class="btn_label">
              <button class="btn" on:click={(event)=>symptomSelectBtnPressed(yesBtn)} tabindex={$context_toggle?"-1":"0"} data-cy={'btnYES'}><span class="btn_content" tabindex="-1"></span></button>
              <input class="btnRadio" type="radio" bind:group={symptom_select} value={1} bind:this={yesBtn} on:click={(event)=>symptomSelectPressed(yesBtn)} tabindex="-1">
              <div class="svg-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.01 15.97"><defs></defs><path class="yesno" d="M13.35,12.15a18.45,18.45,0,0,1-4.91.48c-2.35-.1-5.47-.21-7.15-2.41A3.79,3.79,0,0,1,.38,8C.3,4.52,7.4.22,14.11.38c4.49.11,11.1,2.3,11.51,6.09C26,9.8,21.54,14.14,14.38,15.6"/></svg>
              </div>
              <i>YES</i>
            </label>
          </p>
        </div>
      {/each}                 
    {/if}
  {/each}
</div>