export default class ConditionalActivation
{

  constructor(cardsArray,validateOnly)
  {
    this.conditionalCards;
    this.allCards;
    this.groups;
    this.executedCards = new Map();
    this.validateOnly = validateOnly; // returns true for all cards and groups; validation fails if there is an exception thrown
    if(!this.validateOnly)
      this.digestCardsArray(cardsArray)
  }


  digestCardsArray(cardsArray)
  {
    this.conditionalCards = [];
    this.allCards = new Map();
    this.groups = new Map();

    for(let card of cardsArray)
    {
      this.allCards.set(card.name,card);
      if(card.groups)
        for(let group of card.groups)
        {
          if(group.length == 0)
            continue;
          let mappedGroup = this.groups.get(group);
          if(!mappedGroup)
          {
            mappedGroup = [];
            this.groups.set(group,mappedGroup);
          }
          mappedGroup.push(card);
        }
      if(card.conditionalActivation)
        this.conditionalCards.push(card);
    }
  }

  cardExecuted(card)
  {
    this.executedCards.set(card.name,card);
    let cardsToActivate = [];
    for(let i=0; i<this.conditionalCards.length;++i)
    {
      let conditionalCard = this.conditionalCards[i];
      let ret = this.parse(conditionalCard.conditionalActivation,null,null);
      if(ret.conditionMet)
      {
        cardsToActivate.push(conditionalCard);
        this.conditionalCards.splice(i,1);
      }
    }
    return cardsToActivate;
  }

  checkGroup(groupName,symbol)
  {
    if(this.validateOnly)
      return true;
    let validGroup = null;
    let groupCards = this.groups.get(groupName);
    if(!groupCards)
    {
      throw {statusMessage:"No group with the name " + groupName + " was found"}
    }
    if(symbol=="|")
    {
      for(let card of groupCards)
      {
        if(validGroup == null)
          validGroup = this.checkCard(card.name);
        else
          validGroup |= this.checkCard(card.name);
      }
    }
    else if(symbol="&")
    {
      for(let card of groupCards)
      {
        if(validGroup == null)
          validGroup = this.checkCard(card.name);
        else
          validGroup &= this.checkCard(card.name);
      }
    }
    else
    {
      throw {statusMessage:"Invalid group modifier: " + symbol}
    }
    return validGroup;
  }

  checkCard(cardName)
  {
    if(this.validateOnly)
      return true;
    let card = this.allCards.get(cardName)
    if(card)
    {
      return this.executedCards.get(card.name)?true:false;
    }
    else
    {
      throw {statusMessage:"No card with the name " + cardName + " was found"}
    }
  }


  parse(condition,met,prevSymbol)
  {
    
    let i = 0;
    let conditionMet=met;
    let advanceIndexAmnt; 
    try {
      while(i<condition.length)
      {
        let char = condition[i];
        advanceIndexAmnt = 1;
        switch(char){
          case '!': 
          {
            let ret = this.parse(condition.substring(i+advanceIndexAmnt),conditionMet,"!");
            // to the right of the condtion no valid value is returned 
            if(ret.conditionMet == null || ret.conditionMet == undefined)
              throw {statusMessage:"No value found to the right of !"};
            conditionMet = !ret.conditionMet;
            i += ret.index;
            if(prevSymbol == "!" || prevSymbol == "&" || prevSymbol == "|")
            {
              return {index:i+advanceIndexAmnt,conditionMet:conditionMet}
            }
            break;
          }
          case '(': 
          {
            let closingFound = false;
            let ret = {};

            while(i+advanceIndexAmnt<condition.length)
            {
              ret = this.parse(condition.substring(i+advanceIndexAmnt),ret.conditionMet,"(");
              advanceIndexAmnt += ret.index;
              if(condition[i+advanceIndexAmnt]==')')
              {
                advanceIndexAmnt += 1 ;
                closingFound = true;
                break;
              }
            }
            if(!closingFound)
              throw {statusMessage:"Did not find closing ("}
            ret.index = advanceIndexAmnt + i;
            conditionMet = ret.conditionMet;
            if(prevSymbol)
              return {index:ret.index,conditionMet:ret.conditionMet};
            break;
          }
          case ')':
          {	
            if(prevSymbol == "|" || prevSymbol == "&" || prevSymbol == "!")
              return {index:i};
            if(prevSymbol  != "(")
              throw {statusMessage:"Missing an opening ("};
            
            return {index:i,conditionMet:conditionMet};
          }
          case '$':
          {
            let symb;
            if(i+advanceIndexAmnt>condition.length)
              throw {statusMessage:"Nothing found after $"}
            else
            {
              symb = condition[i+advanceIndexAmnt];
              advanceIndexAmnt++;
            }
            if(i+advanceIndexAmnt>condition.length)
              throw {statusMessage:"Nothing found after the $'s " + symb};
            let groupName = condition.substring(i+advanceIndexAmnt).match(/^[\w-]+/);
            if(!groupName || groupName.length == 0)
              throw {statusMessage:"Could not parse group name after the $"};
            advanceIndexAmnt += groupName[0].length; 
            conditionMet = this.checkGroup(groupName[0],symb);
            return {index:i+advanceIndexAmnt,conditionMet:conditionMet};
          }
          case '&':
          {
            // b/c we evaluate left to right by the time we reach this symbol the left side MUST have already returned a value
            if(conditionMet == null || conditionMet == undefined)
              throw {statusMessage:"No value found to the left of &"}; 
            let ret = this.parse(condition.substring(i+advanceIndexAmnt),undefined,"&");
            // to the right of the condtion no valid value is returned 
            if(ret.conditionMet == null || ret.conditionMet == undefined)
              throw {statusMessage:"No value found to the right of &"};
            conditionMet = (conditionMet && ret.conditionMet)
            advanceIndexAmnt += ret.index;
            if(prevSymbol)
              return {index:i+advanceIndexAmnt,conditionMet:conditionMet};
            break;
          }
          case '|':
          {
            // b/c we evaluate left to right by the time we reach this symbol the left side MUST have already returned a value
            if(conditionMet == null || conditionMet == undefined)
              throw {statusMessage:"No value found to the left of |"}; 
            let ret = this.parse(condition.substring(i+advanceIndexAmnt),undefined,"|");
            // if to the right of the condtion no valid value is returned 
            if(ret.conditionMet == null || ret.conditionMet == undefined)
              throw {statusMessage:"No value found to the right of |"};
            conditionMet = (conditionMet || ret.conditionMet)
            advanceIndexAmnt += ret.index;
            if(prevSymbol)
              return {index:i+advanceIndexAmnt,conditionMet:conditionMet};
            break;
          }
          default:
          {
            let clearedWhiteSpace = false;
            while(char.match(/\s/))
            {
              if(i+advanceIndexAmnt >= condition.length)
              {
                break;
              }
              clearedWhiteSpace = true;
              char = condition[i+advanceIndexAmnt];
              advanceIndexAmnt++;
            }
            if(clearedWhiteSpace)
            {
              if(i+advanceIndexAmnt <= condition.length)
              {
                advanceIndexAmnt--; //go back to eval a character as long as we are not at the last elements
              }
              break;
            }
            if(char.match(/\w/))
            {
              let card = 	condition.substring(i+advanceIndexAmnt-1).match(/[\w]+/);
              advanceIndexAmnt += card[0].length-1; // -1 for the char that was already advanced
              conditionMet = this.checkCard(card[0]);	
              if(prevSymbol == "!" || prevSymbol == "&" || prevSymbol == "|")
              {
                return {index:i+advanceIndexAmnt,conditionMet:conditionMet}
              }
            }
            else
            {
              if(char.length && !char.match(/\s/))
                throw {statusMessage:"Unrecognized symbol " + char};
            }
            if(!(condition.substring(i+advanceIndexAmnt).match(/^[\s]*[&|\||)|(]/) || condition.substring(i+advanceIndexAmnt).match(/^[\s]*$/)))
            {
              throw {statusMessage:"Cards must be followed by a comparison operator, a parentheses or nothing at all"};
            }
          }
        }
        i += advanceIndexAmnt;
      }
    }
    catch(err)
    {
      if(!err.position)
      {
        err.position = 0;
      }
      err.position += i+advanceIndexAmnt;
      throw err;
    }
    return {index:i,conditionMet:conditionMet};
  }	

}
export function validateCondition(condition)
{
  let conditionalActivation = new ConditionalActivation([],true);
  let ret = {valid:false};
  try
  { 
    conditionalActivation.parse(condition);
  }
  catch(err)
  {
    ret.statusMessage = err.statusMessage + " at position " + err.position;
    return ret;
  }
  ret.valid = true;
  return ret;
}