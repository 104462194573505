<script>
  import { onMount } from "svelte"
  import { get_saved_state } from "./saved_states.js"
  import { post_item_completed, get_session, ServerError, get_user_item_progress} from "./server_api.js"
  import pageFile from "./pages.json";
  import ContextController from "./ContextController.svelte";
  import { game_mode, context_toggle } from "./stores";
//  import RadioBtn from "./RadioBtn.svelte"
  export let freq;
  export let num = 0;
  export let intensity;
  export let trauma;
  let percentageInput;
  let loading_intensity = false;
  let loading_trauma = false;
  let trauma_btns=[];
  let trauma_btn_info=[{"name":"<i>Definite</i>","id":"btnDefinite","value":3,"svg":"svgTrauma"},{"name":"<i>Probable</i>","id":"btnProbable","value":2,"svg":"svgTrauma"},{"name":"<i>Unlikely</i>","id":"btnUnlikely","value":1,"svg":"svgTrauma"}];
  let intensity_btns=[];
  let intensity_btn_info=[
    {"name":"<i>Minimal</i>","btn_style":"width: 4vw;","btn":intensity_btns[0],"value":1,"svg_style":"width: 4vw","ariaLabel":"Select Minimal"},
    {"name":"<i>Clearly Present</i>","btn_style":"width: 7vw;","btn":intensity_btns[1],"value":2,"svg_style":"width: 7.1vw","ariaLabel":"Select Clearly Present"},
    {"name":"<i>Pronounced</i>","btn_style":"width: 5.5vw;","btn":intensity_btns[2],"value":3,"svg_style":"left: -0.15vw;width: 5.8vw;","ariaLabel":"Select Pronounced"},
    {"name":"<i>Extreme</i>","btn_style":"width: 4vw;","btn":intensity_btns[3],"value":4,"svg_style":"width: 4vw","ariaLabel":"Select Extreme"}];


  $: {
    if(intensity !== undefined && !loading_intensity)
    {
      post_item_completed({intensity:intensity}).catch((err)=>{
        if(err instanceof ServerError)
          refresh_data();
      });
    }
    if(loading_intensity)
    {
      loading_intensity = false;
      ToggleInput();
    }
      
  }

  $: {
    if(trauma !== undefined && !loading_trauma && trauma_btns[0] && trauma_btns[1] && trauma_btns[2])
    {
      post_item_completed({trauma:trauma}).catch((err)=>{
        if(err instanceof ServerError)
          refresh_data();
      });
    }
    if(loading_trauma)
    {
      ToggleInput(true);
      loading_trauma = false;
    }
      
  }

  function limitInput(e)
  {
      const tempInput = document.getElementById('percentageInput');

      freq = tempInput.value;
      post_item_completed({frequency:freq}).catch((err)=>{
        if(err instanceof ServerError)
          refresh_data();
      });
  }

  function autoSelect()
  {
    const tempInput = document.getElementById('percentageInput');
    tempInput.select();
  }
    
  function btnPressed(target)
  {
    if(target)
      target.click();
  }

  function intensityPressed(target)
  {
    if(target === null)
      return;
    if(event.target.value == intensity)
    {
      intensity = null;
      event.target.checked = false;
    }
    else
      target.checked = true;
  }

  function traumaPressed(target)
  {
    if(target === null)
      return;
    if(target.value == trauma)
    {
      trauma = null;
      target.checked = false;
    }
    else
      target.checked = true;
  }

  function clear()
  {
    percentageInput.value = "";
    intensity = null;
    trauma = null;
  }

  async function ToggleInput()
  {
    let disabled = true;
    let progress = await get_user_item_progress(get_session());
    disabled = (progress[num].passed != null && progress[num].passed != 0) && $game_mode == "learn" ? true : false;

    var btns = document.getElementsByClassName("btn");
    var i;

    for (i = 0; i < btns.length; i++) {
      if(disabled)
        btns[i].disabled = true;
      else
        btns[i].disabled = false;

    }

    var btnsRadio = document.getElementsByClassName("btnRadio");
    for (i = 0; i < btnsRadio.length; i++) {
      if(disabled)
        btnsRadio[i].disabled = true;  
      else
        btnsRadio[i].disabled = false;    
    }

    if(disabled)
      percentageInput.disabled = true;
    else
      percentageInput.disabled = false;
  }
  function refresh_data()
  {
    clear();
    let saved_state = get_saved_state(num,get_session());
    if(saved_state)
    {

      if(saved_state.frequency != null && saved_state.frequency != undefined)
        percentageInput.value = saved_state.frequency;
      if(saved_state.intensity)
      {
        loading_intensity = true;
        intensity = saved_state.intensity;
      }
      if(saved_state.trauma)
      {
        loading_trauma = true;
        trauma = saved_state.trauma;
      }
      if(loading_intensity == false && loading_trauma == false)
        ToggleInput();
    }     
  }

  onMount( async function(){ refresh_data(); });
</script>

<style lang="less">

  .btn:focus > .btn_content  {
      box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
      outline: none;
  }

  /* Removing default outline only after we've added our custom one */
  .btn:focus,
  .btn_content:focus {
      outline: none;
  }

  .btn
  {
    background: transparent;
    border: none !important;
    font-size: 0;  
    height: 1.25vw;
    width: 100%;
    position: absolute;
    padding: 1px 1px;
    top: .15vw;
    cursor: pointer;
    pointer-events: none;
  }/* from severity ratin gs, can't add  left: -0.15vw;*/
  .btn_content
  { 
    display: inline-block;
    width: 100%;
    height: 100%;
    pointer-events: all;
  }
  #btnMinimal{ width: 4vw; }
  #btnClearly{ width: 7vw; }
  #btnPronounced{ width: 5.5vw; }
  #btnExtreme{ width: 4vw; }
  #btnDefinite{ width: 3.75vw; }
  #btnProbable{ width: 4.15vw; }
  #btnUnlikely{ width: 3.75vw; }
  p
  {
    line-height: 1.5;
    font-size: 1vw;
    margin-inline-start: 0em;
    margin-inline-end: 0em;
    margin-block-start: 0em;
    margin-block-end: 1em;
  }
  .container
  {
    border-top: 1px solid black;
    margin-inline-start: 1em;
    margin-inline-end: 1em;
    padding-block-start: 1em;
  }
  .context_ctnr
  {
    position: relative;
    width:100%;
    height:auto;
  }
  .btn_label
  {
    padding-inline-start: 1em;
    font-size: 1vw;
    position: relative;
    cursor: pointer;
    pointer-events: none;
    width: fit-content;
  }
  span
  {
    font-size: 1.15vw;
    font-weight: bold;
  }
  input
  {
    border: 0;
    outline: 0;
    border-bottom: 1px solid black;
    font-family: 'Lato', sans-serif;
    width: 4vw;
  }
  .percentageInput
  {
    font-family: 'Kalam', cursive;
    text-align: left;
    font-size: 1.25vw;
    margin-left: 0.5vw;
    width: 15vw;
  }
  .context_enter_answer_ctnr
  {
    position: relative;
  }
  label
  {
    cursor: pointer;
    display: inline-block;
  }
  [type=radio] {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    width: 0;
    height: 0;
  }
  [type=radio] + .svg-container {
  cursor: pointer;
  }

  [type=radio]:checked + .svg-container{
    display: inline-block;
  }
  .cls-1
  {
    fill:none;
    stroke:#000;
    stroke-miterlimit:10;
    stroke-width:0.75px;
  }
  path
  {
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
    animation: draw .1s ease-out forwards;
  }
  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }
  svg
  {
    position: relative;
    height: 2vw;
    left: 0vw;
    bottom: 0.2vw;
    transition: stroke-dashoffset 0.2s ease-in-out 0s;
     width: 90%;
  }
  .svg-container
  {
    position: absolute;
    display: none;
    width: 100%;
  }
  #svgMinimal,#svgExtreme,#svgTrauma
  {
    width: 4vw;
  }
  #svgPresent
  {
    width: 7.1vw;
  }
  #svgPronounced
  {
    left: -0.15vw;
    width: 5.8vw;
  }
  @media (max-width:1000px){
    p{ font-size: 10px; }
    span{ font-size: 11px; }
  }
</style>

<div class="container">
  {#each pageFile as item}
    {#if item.id === num}
      {#each item.intensity as content}
        <div class="context_ctnr">
          <ContextController divID={"ctx_select"}/>
          <p>
            <u>Circle:</u> {content.type} =
              {#each intensity_btn_info as btn,i}
                <label class="btn_label">
                  <button class="btn" id={btn.id} on:click={(event)=>btnPressed(intensity_btns[i])} tabindex={$context_toggle?"-1":"0"} data-cy={'distressBtn' + i} aria-label="{btn.ariaLabel}">
                    <span class="btn_content" tabindex="-1"></span>
                  </button>
                  <input class="btnRadio" type="radio" bind:group={intensity} bind:this={intensity_btns[i]} value={btn.value} on:click={(event)=>intensityPressed(intensity_btns[i])} tabindex="-1" data-cy={'distressBtnInput' + i}>
                  <div class="svg-container">
                    <svg id={btn.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.85 21.57" preserveAspectRatio="none"><defs></defs><path class="cls-1" d="M22,16.48a37.27,37.27,0,0,1-8.18.61c-3.92-.15-9.11-.32-11.91-3.34a4.83,4.83,0,0,1-1.5-3C.27,6,12.12.11,23.3.38c7.49.19,18.49,3.23,19.14,8.41C43,13.35,35.61,19.25,23.66,21.2"/></svg>
                  </div>
                  {@html btn.name}
                </label>
              {/each}
          </p>
        </div>            
        {#if content.typeFollowup}
          {#each content.typeFollowup as f}
            <p class="text-inline2">{f.pretext}<span class="question">{@html f.maintext}</span>{@html f.closetext}</p>
          {/each}
        {/if}
        <p>
          <span>{content.question}</span>
          {#if content.pretext}{content.pretext}{/if}
          {@html content.subtext}
          <label class="context_enter_answer_ctnr">
            <ContextController divID={"ctx_enter_answer"}/>
            <input type="text" class="percentageInput" id="percentageInput" maxlength="25" bind:this={percentageInput} on:input={limitInput} on:click={autoSelect} tabindex={$context_toggle?"-1":"0"} data-cy='percentageInput'>
          </label>
        </p>
        {#if content.followup}        
          {#each content.followup as f,i}
            <p class="text-inline1"><span class="question">{@html f.question}</span>{@html f.subtext}</p>
          {/each}
        {/if}
        <!-- circle trauma-relatedness (only items 9, 11-20) -->
        {#if item.id === 9 || (item.id >= 11 && item.id <= 20) || item.id === 29 || item.id === 30}
          <div class="context_ctnr">
            <ContextController divID={"ctx_select"}/>
            <p>
              <u>Circle:</u> Trauma-relatedness =
              {#each trauma_btn_info as btn,i}
                <label class="btn_label">
                  <button class="btn" id={btn.id} on:click={(event)=>btnPressed(trauma_btns[i])} tabindex={$context_toggle?"-1":"0"} data-cy={'traumaBtn' + (trauma_btn_info.length - (i + 1))}>
                    <span class="btn_content" tabindex="-1"></span>
                  </button>
                  <input class="btnRadio" type="radio" bind:group={trauma} bind:this={trauma_btns[i]} value={btn.value} on:click={(event)=>traumaPressed(trauma_btns[i])} tabindex="-1">
                  <div class="svg-container">
                    <svg id={btn.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.85 21.57" preserveAspectRatio="none"><defs></defs><path class="cls-1" d="M22,16.48a37.27,37.27,0,0,1-8.18.61c-3.92-.15-9.11-.32-11.91-3.34a4.83,4.83,0,0,1-1.5-3C.27,6,12.12.11,23.3.38c7.49.19,18.49,3.23,19.14,8.41C43,13.35,35.61,19.25,23.66,21.2"/></svg>
                  </div>
                  {@html btn.name}
                </label>
              {/each}
            </p>
          </div>                      
        {/if}
      {/each}
    {/if}
  {/each}
</div>