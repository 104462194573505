<script>
    import { onMount } from "svelte";
    import { coach_asset_path, patient_asset_path, input_disabled_dialogue, media_asset_path} from './stores.js';
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    var audio_context;
    export var audio_element;
    export let animationFilePath;
    let loadedPatientAnimation={};
    var current_track;
    var animQueue = [];
    var c_characters = ["patient"];
    var js_characters = ["coach"];
    var currentAnim = { started:true };
    var animPoll = false;

    async function pollQueue()
    {
        animPoll = true;
        if(animQueue.length == 0 && (currentAnim.started || currentAnim.errored))
        {
            animPoll = false;
            return;
        }
        if(audio_element.paused && !Module['is_talking']())
        {
            if(!currentAnim.started && audio_element.src.split('/').pop() === currentAnim.sound)
            {
                if(!currentAnim.delayed)
                {
                    await start();
                }
            }
            else if(animQueue.length != 0 && (currentAnim.started || currentAnim.errored))
            {
                currentAnim = animQueue.shift();
                audio_element.src = currentAnim.sound;
            }
        }
        setTimeout(pollQueue,100); //.1 sec
    }

    export function add(person,animationName,sound,executeOnAnimationStart)
    {
        var soundFile = (person == "coach" ? $coach_asset_path : $patient_asset_path) + $media_asset_path + (sound ? sound + ".mp3": null);
        var animObj = {
            started:false,
            person:person,
            anim:animationName, //same as card name
            sound:soundFile,
            visimeFile:sound,
            animStartFunction:executeOnAnimationStart
        };
        animQueue.push(animObj);
        if(!animPoll)
            pollQueue();
    }

    function soundStarted()
    {
        if(currentAnim.sound!="rechatmedia/silent.mp3")
            $input_disabled_dialogue = true;
    }

    function soundEnded()
    {
        $input_disabled_dialogue = false;
        audio_element.onended = null;
    }

    export function skipTrack()
    {
        audio_element.removeAttribute('src');
        audio_element.load();
        soundEnded();
    }
    
    export function cancelAnim(name)
    {
        if(currentAnim.anim == name)
        {
            skipTrack();
        }
        else
        {
            for(let i =0; i!=animQueue.length;++i)
            {
                if(animQueue[i].anim == name)
                {
                    animQueue.splice(i,1);
                    i--; //account for the item just removed
                }
            }
        }
    }

    function errorHandling(event)
    {
        console.log(event.srcElement.error.message);
        currentAnim.errored = true;
        soundEnded();
    }

    async function start()
    {
        var delay = 0;      
        if(c_characters.find((element)=>{return element === currentAnim.person;}))
        {
            var visime = String(await readVisimeAsString((currentAnim.person == "coach" ? $coach_asset_path : $patient_asset_path) + $media_asset_path +  currentAnim.visimeFile + "_lipsync.txt"));
            let anim = loadedPatientAnimation[currentAnim.anim]
            if(!anim)
            {
                let blankAnim = {gesture:{name:"",delay:0},fidgets:[],eyes:""};
                anim = blankAnim;
            }
            var delay = Module['talk'](visime,JSON.stringify(anim));
       }
        else if(js_characters.find((element)=>{return element === currentAnim.person;}))
        {
            //js animations
        }
        if(currentAnim.person == "coach" && audio_element.currentTime > 0)
            return;                     
        if (audio_context.state === 'suspended')
        {
            await audio_context.resume();
        }   
        if(delay == Module.Error_Codes.EXCEPTION_ERROR.value )
        {
            console.log("EXCEPTION_ERROR");
        }
        else if(delay == Module.Error_Codes.STILL_TALKING.value )
        {
            console.log("STILL_TALKING");
        }
        else if(delay != 0)
        {
            if(!currentAnim.delayed)
            {
                console.log("sound delay: " + delay);
                currentAnim.delayed = true;
                setTimeout(function(){
                    currentAnim.started = true;
                    currentAnim.animStartFunction()
                    audio_element.play()
                }, delay);
            }
        }
        else
        {
            currentAnim.started = true;
            currentAnim.animStartFunction();
            audio_element.play();
        }
        if(animQueue.length === 0)
            audio_element.onended = soundEnded;       
    }

    async function readVisimeAsString(visimeFile)
    {
        var response = await fetch(visimeFile);
        var data = await response.text();
        return data;
            
    }

    async function loadAnimationFiles()
    {
        try
        {
            const response = await fetch(animationFilePath);
    let json = await response.json();
            for(let animation of json)
            {
                loadedPatientAnimation[animation.name] = animation.animation;
            }
        }
        catch(err)
        {
            console.log(err);
        }
    }
    onMount( async function() {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        audio_context = new AudioContext();
        current_track = audio_context.createMediaElementSource(audio_element);
        current_track.connect(audio_context.destination);
        audio_element.onplay = soundStarted;
        loadAnimationFiles();
    });
</script>

<audio data-cy={"audio_element"} bind:this={audio_element} on:canplaythrough={start} on:error={errorHandling}></audio>