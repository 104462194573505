<script context="module">
let savedElementStates = [];

export function toggleTutorialAnimation(card,page)
{
    if(card && card.data)
    {
        let classElement;

        if(card.data.length >= 1)
        {
            var dataArray = card.data.split(" ");   
            for(let className of dataArray)
            {
                
                if(className == "exposureContext" && page >= 1)
                    continue;                                                        
                classElement = document.getElementById(className);
                if(!classElement)
                    continue;
                var found = false;
                if(savedElementStates.length > 0)
                {
                    for(let savedElement of savedElementStates)
                    {                    
                        if(savedElement.savedElement == classElement)
                        {
                            found = true;
                            break;
                        }
                    }
                }
                if(!found)
                    addSavedState(classElement);
                if(classElement.nodeName != "P")
                {
                    classElement.style.display === "block" ? classElement.style.display = "none" : classElement.style.display = "block";
                    classElement.style.pointerEvents === "none" ? classElement.style.pointerEvents = "all" : classElement.style.pointerEvents = "none";
                }
                classElement.classList.contains("tutorialAnim") ? classElement.classList.remove("tutorialAnim") : classElement.classList.add("tutorialAnim");
            }
        }
    }
}

export function resetTutorialAnimation(card)
{
    for(let e of savedElementStates)
    { 
        e.savedElement.classList.remove("tutorialAnim");
        e.savedElement.style.display = e.displayStatus;
        e.savedElement.style.pointerEvents = e.pointerStatus;
    }
}

export function addSavedState(classElement)
{
    var tempElement = {};
    tempElement.savedElement = classElement;
    tempElement.displayStatus = classElement.style.display;
    tempElement.pointerStatus = classElement.style.pointerEvents;
    savedElementStates.push(tempElement);
}

</script>
<style lang="less">
:global(.tutorialAnim)
{
    animation: pulse 2s infinite ease-in-out;
}	

@keyframes -global-pulse {
    0%
    {
        opacity: 0.75;
    }
    50%
    {
        opacity: 1;
        filter: brightness(2) drop-shadow(0px 0px 8px cyan);

    }
    100%
    {
        opacity: 0.75;
    }
}
</style>