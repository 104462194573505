<script>
import './SummaryTableCSS.svelte';
import SummaryTableHeader from'./SummaryTableHeader.svelte';
import SummaryTableRow from'./SummaryTableRow.svelte';
import evaluation from "./../evaluation.json";
import { get_rating } from './../evaluation_data.js';

export let cmp;
</script>

<table class="summary_table">
    <SummaryTableHeader text_column1={evaluation.criterions[6].criterion_title} text_column2={"Current"}/>
    <SummaryTableRow text1={evaluation.criterions[6].criterions[0].title} rating={get_rating(22)} cmp={cmp} col3={false}/>
</table>
