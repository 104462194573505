<script>
import './SummaryTableCSS.svelte';
import SummaryTableFormNY from './SummaryTableFormNY.svelte';
export let text1;
export let text2="";
export let text3="";
export let col3=true;
export let rating;
export let cmp;
export let isHeader = false;
</script>

{#if !isHeader}
<tr class="summary_table">
    <th width="60%" align="left" scope="row" style="font-weight: normal;">{text1}</th>
    {#if col3}
        <td width="15%" align="center">{text2}</td>
    {/if}
    {#if text3!=""}
        <td width="25%" align="center">{text3}</td>
    {:else}
        <td><SummaryTableFormNY on:allow_page_change rating={rating} cmp={cmp}/></td>
    {/if}                            
</tr>        
{:else}
<tr class="summary_table">
    <th scope="col" width="60%" align="left">{text1}</th>
    {#if col3}
        <th scope="col" width="15%" align="center">{text2}</th>
    {/if}
    {#if text3!=""}
        <th scope="col" width="25%" align="center">{text3}</th>
    {:else}
        <th scope="col"><SummaryTableFormNY on:allow_page_change rating={rating} cmp={cmp}/></th>
    {/if}                            
</tr>        
{/if}

