<script>
import {get_top_div_txt,get_bottom_div_txt,scroll_transcript_to_bottom,scroll_transcript_to_top, torcLinkToggle } from './Transcript.svelte';
import { show_TORC_links } from './stores.js';
import ContextController from './ContextController.svelte';
export let nav_id; 
export let nav_class;
export let nav_dir_top;
export let span_label;
export let arrow_class;
export let context_divID;

let divTxt='';
let bannerDiv;

$: {
	torcLinkToggle($show_TORC_links);
}


function set_nav_txt(top)
{
	divTxt = top? get_top_div_txt():get_bottom_div_txt();
}
function scroll_transcript_to(top)
{
	if(top)
		scroll_transcript_to_top();
	else
		scroll_transcript_to_bottom();
	set_nav_txt(nav_dir_top);
}

function onFocus(element)
{
	set_nav_txt(nav_dir_top);
	element.parentElement.style.boxShadow = "0 0 0 2px #023b5a";
	element.parentElement.style.width = "auto";
}

function onBlur(element)
{
	element.parentElement.style.boxShadow = "none";
	element.parentElement.style.width = null;
}
</script>
<style lang="less">
	[class*="navBanner-"]
	{
		transition: width 0.2s ease;
		position: absolute;
		display: flex;
		left: -1%;
		width: 6%;
		height: 5.5%;
		z-index: 40;
		overflow: hidden;
		cursor: pointer;
		border-radius: 0 25px 25px 0;
	}
	.navBanner-top{top: 7%}
	.navBanner-bottom{bottom: 3%}
	.navBanner-top:hover,.navBanner-bottom:hover
	{
		width: 99%;		
	}
	.bannerParagraph, :global(.bannerParagraph)
	{
		font-size: .9vw;
		color: #023b5a;
		position: relative;
		left: 2.5vw;
		overflow: hidden;
		white-space: nowrap;
		margin: auto;
		display: inline-block;
		text-align: center;
	}
	.bannerBtn
	{
		border: none;
		display: flex;
		height: 100%;
		background-color: #ffffff;
		border-radius: 0 30px 30px 0;
		padding: 1%;
		padding-right: 3vw;
		z-index: 40;
	}
	.bannerBtn:focus,
	.btn_content:focus
	{
		outline: none;
	}
	.btn_content
	{
		display: flex;
		width: 100%;
		height: 100%;
		pointer-events: all;
		cursor: pointer;
	}
	[class*="arrow-"]
	{
		position: absolute;
		left: 0.5%;
		width: 3.5%;
		height: 3.5%;
		z-index: 40;
		overflow: hidden;
		pointer-events: none;
	}
	.arrow-up{top: 8%;}
	.arrow-down{bottom: 3.75%; transform: rotate(180deg)}
</style>

<div id={nav_id}>
	<ContextController divID={context_divID}/>
	<div class={nav_class} on:mouseenter={set_nav_txt(nav_dir_top)}>		
		<button class="bannerBtn" bind:this={bannerDiv} on:click={scroll_transcript_to(nav_dir_top)} on:focus={(event)=>onFocus(bannerDiv)} on:blur={(event)=>onBlur(bannerDiv)}>
			<span class="btn_content" tabindex="-1" aria-label={span_label}>
				<p class="bannerParagraph">{divTxt}</p>
			</span>
		</button>
	</div>	
	<svg class={arrow_class} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.71 13.77"><g id="Layer_2" data-name="Layer 2"><g id="twirlarrow_copy" data-name="twirlarrow copy"><polyline points="24.01 13.06 12.36 1.41 0.71 13.06" style="fill:none;stroke:#023b5a;stroke-miterlimit:10;stroke-width:3px"/></g></g></svg>
</div>