<script>
import { onMount } from "svelte"
import { get_saved_state } from "../saved_states.js"
import { post_item_completed, get_session, ServerError, get_user_item_progress} from "../server_api.js";
import ContextController from "../ContextController.svelte";
import { context_toggle, game_mode } from "../stores"; 
const exposureSelection = { type: 'text', maxlength: '1'};
const exposureInput = { type: 'text', maxlength: '1'};
const marker_char = 'X';
let loading_met = false;
let loading_threat = false;
let loading_injury = false;
let loading_sexv = false;
let exposure_experience;
let exposure_witnessed;
let exposure_learned;
let exposure_aversive;
let life = {
  no: null,
  yes: null
};
let injury = {
  no:null,
  yes:null
};
let sexv = {
  no:null,
  yes:null
};
let criteria = {
  no:null,
  probable:null,
  yes:null
};
let life_select = "";
let life_self;
let life_other;
let injury_select = "";
let injury_self;
let injury_other;
let sexv_select = "";
let sexv_self;
let sexv_other;
let criteria_met;
export let num = 0;


function post_selection_changed(obj)
{
  post_item_completed(obj).catch((err)=>{
    if(err instanceof ServerError)
      refresh_data();
  }); 
}

 $: { 
      if(criteria_met !== undefined && !loading_met)
      {
        let obj = {};
        obj.met = criteria_met;
        post_item_completed(obj).catch((err)=>{
          if(err instanceof ServerError)
            refresh_data();
        });
      }
      if(loading_met)
      {
        ToggleInput();
        loading_met = false;
      }
        
  }
  $: {
      if(life_select === "N" && !loading_threat)
      {
        let obj = {threat:{self:false,other:false}};
        post_selection_changed(obj);
      }
      if(loading_threat)
      {
        ToggleInput();
        loading_threat = false;
      }
        
  }
  $: {
      if(injury_select === "N" && !loading_injury)
      {
        let obj = {injury:{self:false,other:false}};
        post_selection_changed(obj);
      }
      if(loading_injury)
      {
        ToggleInput();
        loading_injury = false;
      }
        
  }
  $: {
      if(sexv_select === "N" && !loading_sexv)
      {
        let obj = {sexv:{self:false,other:false}};
        post_selection_changed(obj);
      }
      if(loading_sexv)
      {
        ToggleInput();
        loading_sexv = false;
      }
        
  }
function lifePressed(target)
{
  if(target.value == life_select)
  {
    target.checked = false;
    clear_reaction(target,"life_threat");
    let obj = {threat:{self:false,other:false}};
    post_selection_changed(obj);
  }
  else
  {
    target.checked = true;
    post_selection_changed({threat:{yes_no:true}});
    
  }
}

function injuryPressed(target)
{
  if(target.value == injury_select)
  {
    target.checked = false;
    clear_reaction(target,"injury");
    let obj = {injury:{self:false,other:false}};
    post_selection_changed(obj);
  }
  else
  {
    target.checked = true;
    post_selection_changed({injury:{yes_no:true}});
  }
}

function sexvPressed(target)
{
  if(target.value == sexv_select)
  {
    target.checked = false;
    clear_reaction(target,"sexual_violence");
    let obj = {sexv:{self:false,other:false}};
    post_selection_changed(obj);
  }
  else
  {
    target.checked = true;
    post_selection_changed({sexv:{yes_no:true}});
  }
}

function wrapperClick(target)
{
  if(target)
  {
    target.click();
  }
}

function criteriaMetRadioPressed(target)
{
  if(target === null)
    return;
  if(target.value == criteria_met)
  {
    criteria_met = null;
    target.checked = false;
  }
  else
  {
    target.checked = true;
  }
}

//Old working version of the mark_exposure funnction
// function mark_exposure(event)
// {
//   console.log(event);
//   const target = event.target;
//   if(target.value==marker_char)
//   {
//     target.value = "";
//   }
//   else
//   {
//     target.value = marker_char;
//   }

//   let obj = {};
//   obj.exp = {};
//   obj.exp[target.dataset.id] = target.value === marker_char;
//   post_item_completed(obj);
  
// }

function mark_exposure(target)
{
  if(target.value==marker_char)
  {
    target.value = "";
  }
  else
  {
    target.value = marker_char;
  }

  let obj = {};
  obj.exp = {};
  obj.exp[target.dataset.id] = target.value === marker_char;
  post_item_completed(obj).catch((err)=>{
    if(err instanceof ServerError)
    {  
      refresh_data();
    }
  });
}

function mark_reaction(event,other,select)
{
  var ThreatNo;
  var ThreatYes;
  var group;
  if(select === "life")
  {    
    ThreatNo = document.getElementById("lifeThreatNo");
    ThreatYes = document.getElementById("lifeThreatYes");
  }
  else if(select === "injury")
  {
    ThreatNo = document.getElementById("injuryThreatNo");
    ThreatYes = document.getElementById("injuryThreatYes");
  }
  else if(select === "sexual_violence")
  {
    ThreatNo = document.getElementById("sexThreatNo");
    ThreatYes = document.getElementById("sexThreatYes");
  }
  

  const target = event;
  if(target.value==marker_char)
  {
    target.value = "";
    if(event.value === "" && other.value === "")
    {
      ThreatNo.checked = true;
      ThreatYes.checked = false;
      group = "N"
    }
    else
    {
      group = "Y"
    }
  }
  else
  {
    target.value = marker_char;
    ThreatYes.checked = true;
    ThreatNo.checked = false;
    group = "Y";
  }

  if(select === "life")
  {    
    life_select = group;
    post_selection_changed({threat:{yes_no:group=="Y"}});
  }
  else if(select === "injury")
  {
    injury_select = group;
    post_selection_changed({injury:{yes_no:group=="Y"}});
  }
  else if(select === "sexual_violence")
  {
    sexv_select = group;
    post_selection_changed({sexv:{yes_no:group=="Y"}});
  }


  let obj = {};
  let person = target.dataset.self != null ? "self" : "other";
  obj[target.dataset.id] = {};
  obj[target.dataset.id][person] = target.value === marker_char ? true : false;
  post_item_completed(obj).catch((err)=>{
    if(err instanceof ServerError)
    {  
      refresh_data();
    }
  });
}

async function clear_reaction(target,id)
{
  if(id != null)
  {
    if(id === "life_threat")
    {
      if(target.value == life_select)
      {
        life_select = "";
        target.checked = false;
        post_selection_changed({threat:{yes_no:null}});
      }
      else
      {
        target.checked = true;
        post_selection_changed({threat:{yes_no:false}});
      }
      life_other.value = "";
      life_self.value = "";
    }
    else if(id === "injury")
    {
      if(target.value == injury_select)
      {
        injury_select = "";
        target.checked = false;
        post_selection_changed({injury:{yes_no:null}});
      }
      else
      {
        target.checked = true;
        post_selection_changed({injury:{yes_no:false}});
      }
      injury_other.value = "";
      injury_self.value = "";

    }
    else if(id === "sexual_violence")
    {
      if(target.value == sexv_select)
      {
        sexv_select = "";
        target.checked = false;
        post_selection_changed({sexv:{yes_no:null}});
      }
      else
      {
        target.checked = true;
        post_selection_changed({sexv:{yes_no:false}});
      }
      sexv_other.value = "";
      sexv_self.value = "";
    }
  }
}

function clear()
{
  exposure_experience.value = null;
  exposure_witnessed.value = null;
  exposure_learned.value = null;
  exposure_aversive.value = null;
  life_self.value = null;
  life_other.value = null;
  life_select = "";
  injury_self.value = null;
  injury_other.value = null;
  injury_select = "";
  sexv_self.value = null;
  sexv_other.value = null;
  sexv_select = "";
  criteria_met = null;
}

async function ToggleInput()
{
  let disabled = true;
  let progress = await get_user_item_progress(get_session());

  disabled = (progress[num].passed != null && progress[num].passed != 0) && $game_mode == "learn" ? true : false;

  var radio = document.getElementsByClassName("radioBtn");
  var i;
  for (i = 0; i < radio.length; i++) {
    if(disabled)
      radio[i].disabled = true;
    else
      radio[i].disabled = false;
      
  }

  var yesBtns = document.getElementsByClassName("YesBtn");
  for (i = 0; i < yesBtns.length; i++) {
      if(disabled)
        yesBtns[i].disabled = true;
      else
        yesBtns[i].disabled = false;
      
  }

  var noBtns = document.getElementsByClassName("NoBtn");
  for (i = 0; i < noBtns.length; i++) {
      if(disabled)
        noBtns[i].disabled = true;
      else
        noBtns[i].disabled = false;
      
  }
  
  var inputBtns = document.getElementsByClassName("InputBtn");
  for (i = 0; i < inputBtns.length; i++) {
      if(disabled)
        inputBtns[i].disabled = true;
      else
        inputBtns[i].disabled = false;
      
  }

  var selectBtns = document.getElementsByClassName("SelectBtn");  
  for (i = 0; i < selectBtns.length; i++) {
      if(disabled)
        selectBtns[i].disabled = true;
      else
        selectBtns[i].disabled = false;
      
  }
}

function refresh_data()
{
  clear();
  let saved_state = get_saved_state(num,get_session());
  if(saved_state)
  { 
    if(saved_state.exp)
    {
      if(saved_state.exp.experienced)
        exposure_experience.value = marker_char;
      if(saved_state.exp.witnessed)
        exposure_witnessed.value = marker_char;
      if(saved_state.exp.learned)
        exposure_learned.value = marker_char;
      if(saved_state.exp.aversive)
        exposure_aversive.value = marker_char;
    }
    if(saved_state.threat)
    {
      loading_threat = true;
      let threat = saved_state.threat;
      if(threat.yes_no)
        life_select = "Y";
      else if(threat.yes_no != null)
        life_select = "N";
      if(threat.self)
        life_self.value = marker_char;
      if(threat.other)
        life_other.value = marker_char;
    }
    if(saved_state.injury)
    {
      loading_injury = true;
      let injury = saved_state.injury;
      if(injury.yes_no)
        injury_select = "Y";
      else if(injury.yes_no != null)
        injury_select = "N";
      if(injury.self)
        injury_self.value = marker_char;
      if(injury.other)
        injury_other.value = marker_char;
    }
    if(saved_state.sexv)
    {
      loading_sexv = true;
      let sexv = saved_state.sexv;
      if(sexv.yes_no)
        sexv_select = "Y";
      else if(sexv.yes_no != null)
        sexv_select = "N";
      if(sexv.self)
        sexv_self.value = marker_char;
      if(sexv.other)
        sexv_other.value = marker_char;
    }
    if(saved_state.met != null && saved_state.met != undefined)
    {
      criteria_met = saved_state.met;
      loading_met = true;
    }
    if(loading_injury == false && loading_met == false && loading_sexv == false && loading_threat == false)
      ToggleInput();
  }
}

onMount( async function() {
  refresh_data();
});

</script>

<style lang="less">
.InputBtn:focus > .btn_content  {
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none; // removes default focus outline
}
.NoBtn:focus > .btn_content  {
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none; // removes default focus outline
}
.ProbableBtn:focus > .btn_content  {
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none; // removes default focus outline
}
.YesBtn:focus > .btn_content  {
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none; // removes default focus outline
}
.SelectBtn:focus > .btn_content  {
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none; // removes default focus outline
}

/* Removing default outline only after we've added our custom one */
.InputBtn:focus,
.YesBtn:focus,
.NoBtn:focus,
.ProbableBtn:focus,
.SelectBtn:focus,
.btn_content:focus {
    outline: none;
}
.btn_content
{
  display: inline-block;
  width: 100%;
  height: 100%;
  pointer-events: all;
}
input {
  background-color: transparent;
}
.exposureDiv
	{
    height: auto;
    width: 36%;
    display: block;
    flex-direction: column;
    border: 1px solid #000000;
    padding-bottom: 1%;
    position: relative;
  }
  .context_multi1_ctnr
  {
    position: relative;
    width: 4vw;
  }
  .context_select1_ctnr
  {
    position: relative;
    width: 4.75vw;
  }
  .context_select2_ctnr
  {
    position: relative;
    width: 10vw;
  }
  .context_multi2_ctnr
  {
    position: relative;
    width: 2vw;
  }
  .exposure_line
  {
    display:flex;
    align-items:center;
    font-size: .95vw;
    margin-inline-start: 0.5vw;
    margin-inline-end: 0.5vw;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  .txt
  {
    margin-left: 0.15vw;
    margin-right: 0.15vw;
    font-size: .95vw;
  }
  p
  {
    //font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    font-size: .95vw;
    margin-inline-start: 0.5vw;
    margin-inline-end: 0.5vw;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  .exposureForm
  {
      margin-inline-start: 1em;
  }
  .exposureInput
  {
    font-family: 'Kalam', cursive;
    border: 0;
    outline: 0; 
    border-bottom: 1px solid black;
    width: 4vw;
    cursor: pointer;
  }
  #noSvg
  {
    left: -.25vw;
    top: -.15vw;
  }
  input
  {
    text-align: center;
    font-size: 1.15vw;
    font-family: 'Lato', sans-serif;
  }
  .exposureSelection
  {
    font-family: 'Kalam', cursive;
    border: 0;
    outline: 0; 
    border-bottom: 1px solid black;
    width: 2vw;
  }
    label
  {
    //font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    font-size: 1vw;
    display: inline-block;
    cursor: pointer;
    position: relative;
    pointer-events: none;
  }
  [type=radio] { 
  position: absolute;  
  opacity: 0;
  width: 0;
  height: 0;
}
  [type=radio] + .svg-container {
  cursor: pointer;
}

[type=radio]:checked + .svg-container{
  display: inline-block;
}

path {
  stroke-dasharray: 150;
  stroke-dashoffset: 150;
  animation: draw .1s ease-out forwards;
  fill:none;
  stroke:#000;
  stroke-miterlimit:10;
  stroke-width:0.75px;
}
.yesno {
  stroke-dasharray: 61.551002502441406;
  stroke-dashoffset: 61.551002502441406;
  animation: draw .1s ease-out forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
 svg
{
  position: absolute;
  width: 2vw;
  height: 1.5vw;
  transition: stroke-dashoffset 0.2s ease-in-out 0s;
}
  #svgBottom
  {
    height:2.5vw;
  }
  #svgProbably
  {
    width: 5.5vw;
    height: 3.5vw;
    top: -0.6vw;
  }
.svg-container
{
  position: absolute;
  display: none;
  width: 2vw;
  height: 1.5vw;
}

.InputBtn
{
  background: transparent;
  border: none !important;
  font-size: 0;
  width: 4vw;
  height: 2vw;
  position: absolute;
  padding: 1px 0px;
  cursor: pointer;
  pointer-events: none;
}

.NoBtn
{
  background: transparent;
  border: none !important;
  font-size: 0;
  width: 1.75vw;
  height: 1vw;
  position: absolute;
  padding: 1px 0px;
  cursor: pointer;
  pointer-events: none;
}

.YesBtn
{
  background: transparent;
  border: none !important;
  font-size: 0;
  width: 1.75vw;
  height: 1vw;
  position: absolute;
  padding: 1px 0px;
  cursor: pointer;
}

.ProbableBtn
{
  background: transparent;
  border: none !important;
  font-size: 0;
  width: 5vw;
  height: 1vw;
  position: absolute;
  padding: 1px 0px;
  cursor: pointer;
}

.SelectBtn
{
  background: transparent;
  border: none !important;
  font-size: 0;
  width: 2vw;
  height: 2vw;
  position: absolute;
  padding: 1px 0px;
  cursor: pointer;
  pointer-events: none;
}

#exposureContext
{
  border: .3vw solid #597f96;
  position: absolute;
  display: none;
  width: 97.5%;
  height: 97.5%;
  top: 0px;
  bottom: 0px;
  margin-right: 0%;
  z-index: 200;
}
    
@media (max-width:1000px){
  p,txt
  {
      font-size: 8.5px;
  }

  label
  {    
    font-size: 9px;
  }
  input
  {
    font-size: 10px;
  }
  svg
  {
    width: 18px;
    height: 12px;
  }
  #svgProbably
  {
    width: 46px;
    height: 25px;
    top: -.25vw;
  }
  #svgBottom
  {
    width: 20px;
    height: 20px;
    top: .1vw;
  }
}

@media (max-width: 600px)
{
  .exposureInput
  {
    width: 3.5vw;
    font-size: 1vw;
    cursor: pointer;
  }
}
</style>


<div class="exposureDiv" id="exposureDiv">
    <div id="exposureContext"></div>
    <p><strong>Exposure type:</strong></p>
    <form class="exposureForm" aria-label="Exposure type group">
      <div class="exposure_line">
        <div class="context_multi1_ctnr">
          <ContextController divID={"ctx_check_multiple"} />
          <button type="button" aria-label="Experienced" class="InputBtn" on:click={(event)=>mark_exposure(exposure_experience)} tabindex={$context_toggle?"-1":"0"} data-cy='exposureExperienced'><span class="btn_content" tabindex="-1"></span></button>
          <input {...exposureInput} id="inputExperienced" class="exposureInput" bind:this={exposure_experience} data-cy='exposureExperiencedInput' data-id="experienced" tabindex="-1" readonly>
        </div>
        <label for="inputExperienced">Experienced</label><br>
      </div>
      <div class="exposure_line">
        <div class="context_multi1_ctnr">
          <ContextController divID={"ctx_check_multiple"} />
          <button type="button" aria-label="Witnessed" class="InputBtn" on:click={(event)=>mark_exposure(exposure_witnessed)} tabindex={$context_toggle?"-1":"0"} data-cy='exposureWitnessed'><span class="btn_content" tabindex="-1"></span></button>
          <input {...exposureInput} id="inputWitnessed" class="exposureInput" bind:this={exposure_witnessed}  data-cy='exposureWitnessedInput' data-id="witnessed" tabindex="-1" readonly>
        </div>
        <label for="inputWitnessed">Witnessed</label><br>
      </div>
      <div class="exposure_line">
        <div class="context_multi1_ctnr">
          <ContextController divID={"ctx_check_multiple"} />
          <button type="button" aria-label="Learned about" class="InputBtn" on:click={(event)=>mark_exposure(exposure_learned)} tabindex={$context_toggle?"-1":"0"} data-cy='exposureLearned'><span class="btn_content" tabindex="-1"></span></button>
          <input {...exposureInput} id="inputLearned" class="exposureInput" bind:this={exposure_learned} data-cy='exposureLearnedInput' data-id="learned" tabindex="-1" readonly>
        </div>
        <label for="inputLearned">Learned about</label><br>
      </div>
      <div class="exposure_line">
        <div class="context_multi1_ctnr">
          <ContextController divID={"ctx_check_multiple"} />
          <button type="button" aria-label="Exposed to aversive details" class="InputBtn" on:click={(event)=>mark_exposure(exposure_aversive)} tabindex={$context_toggle?"-1":"0"} data-cy='exposureExposed'><span class="btn_content" tabindex="-1"></span></button>
          <input {...exposureInput} id="inputExposed" class="exposureInput" bind:this={exposure_aversive} data-cy='exposureExposedInput' data-id="aversive" tabindex="-1" readonly>
        </div>
        <label for="inputExposed">Exposed to aversive details</label><br>
      </div>
    </form>

    <p><strong>Life threat?</strong></p>
 
    <div class="exposure_line">
      <div class="context_select1_ctnr">
        <ContextController divID={"ctx_select"} ctx_help_div_style={"left:.5vw;top:-.3vw"}/>
        <label class="reactionLabel" aria-label="Life threat group">
          <button class="NoBtn" on:click={(event)=>wrapperClick(life.no)} aria-label="No" tabindex={$context_toggle?"-1":"0"} data-cy='lifeThreatNo'><span class="btn_content" tabindex="-1"></span></button>
          <input type="radio" class="radioBtn" id="lifeThreatNo" name="lifeThreatReaction" data-cy='lifeThreatNoInput' bind:group={life_select} bind:this={life.no} value={"N"} on:click={(event)=>clear_reaction(life.no,"life_threat")} tabindex="-1" aria-hidden="true">
          <div class="svg-container">
            <svg id="noSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.01 15.97"><defs></defs><title>yes-no</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_2-2" data-name="Layer 2-2"><path class="yesno" d="M13.35,12.15a18.45,18.45,0,0,1-4.91.48c-2.35-.1-5.47-.21-7.15-2.41A3.79,3.79,0,0,1,.38,8C.3,4.52,7.4.22,14.11.38c4.49.11,11.1,2.3,11.51,6.09C26,9.8,21.54,14.14,14.38,15.6"/></g></g></g></svg>
          </div>
          <div class="txt"><i>NO</i></div>
        </label>
        <label class="reactionLabel">
          <button class="YesBtn" on:click={(event)=>wrapperClick(life.yes)} aria-label="Life threat Yes" tabindex={$context_toggle?"-1":"0"} data-cy='lifeThreatYes'><span class="btn_content" tabindex="-1"></span></button>
          <input type="radio" class="radioBtn" id="lifeThreatYes" name="lifeThreatReaction" data-cy='lifeThreatYesInput' bind:group={life_select} bind:this={life.yes} value={"Y"} on:click={(event)=>lifePressed(life.yes)}  tabindex="-1" aria-hidden="true">
          <div class="svg-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.01 15.97"><defs></defs><title>yes-no</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_2-2" data-name="Layer 2-2"><path class="yesno" d="M13.35,12.15a18.45,18.45,0,0,1-4.91.48c-2.35-.1-5.47-.21-7.15-2.41A3.79,3.79,0,0,1,.38,8C.3,4.52,7.4.22,14.11.38c4.49.11,11.1,2.3,11.51,6.09C26,9.8,21.54,14.14,14.38,15.6"/></g></g></g></svg>
          </div>
          <div class="txt"><i>YES</i></div>
        </label>
      </div>
      <div class="txt">(self</div>
      <div class="context_multi2_ctnr">
        <ContextController divID={"ctx_check_multiple"} />
        <button type="button" class="SelectBtn" on:click={()=>mark_reaction(life_self,life_other,"life")} tabindex={$context_toggle?"-1":"0"} data-cy='lifeThreatSelf'><span class="btn_content" tabindex="-1" aria-label="Life threat to self"></span></button>
        <input {...exposureSelection} class="exposureSelection" bind:this={life_self} data-id="threat" data-cy='lifeThreatSelfInput' data-self data-selection={life_select} tabindex="-1" aria-hidden="true" readonly>
      </div>
      <div class="txt">other</div>
      <div class="context_multi2_ctnr">
        <ContextController divID={"ctx_check_multiple"} />
        <button type="button" class="SelectBtn" on:click={()=>mark_reaction(life_other,life_self,"life")} tabindex={$context_toggle?"-1":"0"} data-cy='lifeThreatOther'><span class="btn_content" tabindex="-1" aria-label="Life threat to other"></span></button> 
        <input {...exposureSelection} class="exposureSelection" data-id="threat" bind:this={life_other} data-cy='lifeThreatOtherInput' data-selection={life_select} tabindex="-1" aria-hidden="true" readonly>
      </div>
      <div class="txt">)</div>
    </div>


    <p><strong>Serious injury?</strong></p>
    <div class="exposure_line">
      <div class="context_select1_ctnr">
        <ContextController divID={"ctx_select"} ctx_help_div_style={"left:.5vw;top:-.3vw"}/>
        <label class="reactionLabel" aria-label="Serious injury group">
          <button class="NoBtn" on:click={(event)=>wrapperClick(injury.no)} aria-label="No" tabindex={$context_toggle?"-1":"0"} data-cy='seriousInjuryNo'><span class="btn_content" tabindex="-1"></span></button>
          <input type="radio" class="radioBtn" id="injuryThreatNo" name="injuryReaction" data-cy='seriousInjuryNoInput' bind:group={injury_select} bind:this={injury.no} value={"N"} on:click={(event)=>clear_reaction(injury.no,"injury")} tabindex="-1" aria-hidden="true">
          <div class="svg-container">
            <svg id="noSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.01 15.97"><defs></defs><title>yes-no</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_2-2" data-name="Layer 2-2"><path class="yesno" d="M13.35,12.15a18.45,18.45,0,0,1-4.91.48c-2.35-.1-5.47-.21-7.15-2.41A3.79,3.79,0,0,1,.38,8C.3,4.52,7.4.22,14.11.38c4.49.11,11.1,2.3,11.51,6.09C26,9.8,21.54,14.14,14.38,15.6"/></g></g></g></svg>
          </div>
          <div class="txt"><i>NO</i></div>
        </label>
        <label class="reactionLabel">
          <button class="YesBtn" on:click={(event)=>wrapperClick(injury.yes)} aria-label="Serious injury Yes" tabindex={$context_toggle?"-1":"0"} data-cy='seriousInjuryYes'><span class="btn_content" tabindex="-1"></span></button>
          <input type="radio" class="radioBtn" id="injuryThreatYes" name="injuryReaction" data-cy='seriousInjuryYesInput' bind:group={injury_select} bind:this={injury.yes} value={"Y"} on:click={(event)=>injuryPressed(injury.yes)} tabindex="-1" aria-hidden="true">
          <div class="svg-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.01 15.97"><defs></defs><title>yes-no</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_2-2" data-name="Layer 2-2"><path class="yesno" d="M13.35,12.15a18.45,18.45,0,0,1-4.91.48c-2.35-.1-5.47-.21-7.15-2.41A3.79,3.79,0,0,1,.38,8C.3,4.52,7.4.22,14.11.38c4.49.11,11.1,2.3,11.51,6.09C26,9.8,21.54,14.14,14.38,15.6"/></g></g></g></svg>
          </div>
          <div class="txt"><i>YES</i></div>
        </label>
      </div>
      <div class="txt">(self </div>
      <div class="context_multi2_ctnr">
        <ContextController divID={"ctx_check_multiple"} />
        <button type="button" class="SelectBtn" on:click={()=>mark_reaction(injury_self,injury_other,"injury")} tabindex={$context_toggle?"-1":"0"} data-cy='seriousInjurySelf'><span class="btn_content" tabindex="-1" aria-label="Serious injury to self"></span></button>
        <input {...exposureSelection} class="exposureSelection" bind:this={injury_self} data-id="injury" data-cy='seriousInjurySelfInput' data-self data-selection={injury_select} tabindex="-1" aria-hidden="true" readonly> 
      </div>
      <div class="txt">other </div>
      <div class="context_multi2_ctnr">
        <ContextController divID={"ctx_check_multiple"} />
        <button type="button" class="SelectBtn" on:click={()=>mark_reaction(injury_other,injury_self,"injury")} tabindex={$context_toggle?"-1":"0"} data-cy='seriousInjuryOther'><span class="btn_content" tabindex="-1" aria-label="Serious injury to other"></span></button> 
        <input {...exposureSelection} class="exposureSelection" bind:this={injury_other} data-id="injury" data-cy='seriousInjuryOtherInput' data-selection={injury_select} tabindex="-1" aria-hidden="true" readonly>
      </div>
      <div class="txt"> )</div>
    </div>

    <p><strong>Sexual violence?</strong></p>
    <div class="exposure_line">
      <div class="context_select1_ctnr">
        <ContextController divID={"ctx_select"} ctx_help_div_style={"left:.5vw;top:-.3vw"}/>
        <label class="reactionLabel" aria-label="Sexual violence group">
          <button class="NoBtn" on:click={(event)=>wrapperClick(sexv.no)} aria-label="No" tabindex={$context_toggle?"-1":"0"} data-cy='sexualViolenceNo'><span class="btn_content" tabindex="-1"></span></button>
          <input type="radio" class="radioBtn" id="sexThreatNo" name="sexViolanceReaction" data-cy='sexualViolenceNoInput' bind:group={sexv_select} value={"N"}  bind:this={sexv.no} on:click={(event)=>clear_reaction(sexv.no,"sexual_violence")} tabindex="-1" aria-hidden="true">
          <div class="svg-container">
            <svg id="noSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.01 15.97"><defs></defs><title>yes-no</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_2-2" data-name="Layer 2-2"><path class="yesno" d="M13.35,12.15a18.45,18.45,0,0,1-4.91.48c-2.35-.1-5.47-.21-7.15-2.41A3.79,3.79,0,0,1,.38,8C.3,4.52,7.4.22,14.11.38c4.49.11,11.1,2.3,11.51,6.09C26,9.8,21.54,14.14,14.38,15.6"/></g></g></g></svg>
          </div>
          <div class="txt"><i>NO</i></div>
        </label>
        <label class="reactionLabel">
          <button class="YesBtn" on:click={(event)=>wrapperClick(sexv.yes)} aria-label="Sexual violence Yes" tabindex={$context_toggle?"-1":"0"} data-cy='sexualViolenceYes'><span class="btn_content" tabindex="-1"></span></button>
          <input type="radio" class="radioBtn" id="sexThreatYes" name="sexViolanceReaction" data-cy='sexualViolenceYesInput' bind:group={sexv_select} value={"Y"} bind:this={sexv.yes} on:click={(event)=>sexvPressed(sexv.yes)} tabindex="-1" aria-hidden="true">
          <div class="svg-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.01 15.97"><defs></defs><title>yes-no</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_2-2" data-name="Layer 2-2"><path class="yesno" d="M13.35,12.15a18.45,18.45,0,0,1-4.91.48c-2.35-.1-5.47-.21-7.15-2.41A3.79,3.79,0,0,1,.38,8C.3,4.52,7.4.22,14.11.38c4.49.11,11.1,2.3,11.51,6.09C26,9.8,21.54,14.14,14.38,15.6"/></g></g></g></svg>
          </div>
          <div class="txt"><i>YES</i></div>
        </label>
      </div>
      <div class="txt">(self </div>
      <div class="context_multi2_ctnr">
        <ContextController divID={"ctx_check_multiple"} />
        <button type="button" class="SelectBtn" on:click={()=>mark_reaction(sexv_self,sexv_other,"sexual_violence")} tabindex={$context_toggle?"-1":"0"} data-cy='sexualViolenceSelf'><span class="btn_content" tabindex="-1" aria-label="Sexual violence to self"></span></button>
        <input {...exposureSelection} class="exposureSelection" bind:this={sexv_self} data-id="sexv" data-cy='sexualViolenceSelfInput' data-self data-selection={sexv_select} tabindex="-1" aria-hidden="true" readonly> 
      </div>
      <div class="txt">other </div>
      <div class="context_multi2_ctnr">
        <ContextController divID={"ctx_check_multiple"} />
        <button type="button" class="SelectBtn" on:click={()=>mark_reaction(sexv_other,sexv_self,"sexual_violence")} tabindex={$context_toggle?"-1":"0"} data-cy='sexualViolenceOther'><span class="btn_content" tabindex="-1" aria-label="Sexual violence to other"></span></button>
        <input {...exposureSelection} class="exposureSelection" bind:this={sexv_other} data-id="sexv" data-cy='sexualViolenceOtherInput' data-selection={sexv_select} tabindex="-1" aria-hidden="true" readonly>
      </div>
      <div class="txt"> )</div>
    </div>

    <p><strong>Criterion A met?</strong></p>
    <div class="exposure_line" style="margin-top:.5vw;">
      <div class="context_select2_ctnr">
        <ContextController divID={"ctx_select"} ctx_help_div_style={"left:.5vw;top:-.3vw"}/>
        <label class="reactionLabel" aria-label="Criterion A met group">
          <button class="NoBtn" on:click={(event)=>wrapperClick(criteria.no)} aria-label="No" tabindex={$context_toggle?"-1":"0"} data-cy='critANo'><span class="btn_content" tabindex="-1"></span></button>
          <input type="radio" class="radioBtn" data-cy='critANoInput' name="critMetReaction" bind:group={criteria_met} value={-1} bind:this={criteria.no} on:click={(event)=>criteriaMetRadioPressed(event.target)} tabindex="-1" aria-hidden="true">
          <div class="svg-container">
            <svg id="noSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.01 15.97"><defs></defs><title>yes-no</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_2-2" data-name="Layer 2-2"><path class="yesno" d="M13.35,12.15a18.45,18.45,0,0,1-4.91.48c-2.35-.1-5.47-.21-7.15-2.41A3.79,3.79,0,0,1,.38,8C.3,4.52,7.4.22,14.11.38c4.49.11,11.1,2.3,11.51,6.09C26,9.8,21.54,14.14,14.38,15.6"/></g></g></g></svg>
          </div>
          <div class="txt"><i>NO</i></div>
        </label>
        <label class="reactionLabel">
          <button class="ProbableBtn" on:click={(event)=>wrapperClick(criteria.probable)} aria-label="Criterion A met Probable" tabindex={$context_toggle?"-1":"0"} data-cy='critAProbable'><span class="btn_content" tabindex="-1"></span></button>
          <input type="radio" class="radioBtn" data-cy='critAProbableInput' name="critMetReaction" bind:group={criteria_met} value={0} bind:this={criteria.probable} on:click={(event)=>criteriaMetRadioPressed(event.target)} tabindex="-1" aria-hidden="true">
          <div class="svg-container">
            <svg id="svgProbably" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65.9 30.57"><defs></defs><title>probable</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_2-2" data-name="Layer 2-2"><path d="M33.33,16.48a87.33,87.33,0,0,1-12.46.65c-6-.14-13.9-.28-18.18-3.3a4.56,4.56,0,0,1-2.31-3C.18,6,18.21.16,35.25.38c11.42.15,28.2,3.15,29.23,8.33.91,4.55-10.35,10.49-28.54,12.49"/></g></g></g></svg>
          </div>
          <div class="txt"><i>PROBABLE</i></div>
        </label>
        <label class="reactionLabel">
          <button class="YesBtn" on:click={(event)=>wrapperClick(criteria.yes)} aria-label="Criterion A met Yes" tabindex={$context_toggle?"-1":"0"} data-cy='critAYes'><span class="btn_content" tabindex="-1"></span></button>
          <input type="radio" class="radioBtn" data-cy='critAYesInput' name="critMetReaction" bind:group={criteria_met} value={1} bind:this={criteria.yes} on:click={(event)=>criteriaMetRadioPressed(event.target)} tabindex="-1" aria-hidden="true">
          <div class="svg-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.01 15.97"><defs></defs><title>yes-no</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_2-2" data-name="Layer 2-2"><path class="yesno" d="M13.35,12.15a18.45,18.45,0,0,1-4.91.48c-2.35-.1-5.47-.21-7.15-2.41A3.79,3.79,0,0,1,.38,8C.3,4.52,7.4.22,14.11.38c4.49.11,11.1,2.3,11.51,6.09C26,9.8,21.54,14.14,14.38,15.6"/></g></g></g></svg>
          </div>
          <div class="txt"><i>YES</i></div>
        </label>
      </div>
    </div>
</div>
