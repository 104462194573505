<script>
import './SummaryTableCSS.svelte';
import SummaryTableHeader from'./SummaryTableHeader.svelte';
import SummaryTableRow from'./SummaryTableRow.svelte';
import SummaryTableLastRow from './SummaryTableLastRow.svelte';
import evaluation from "./../evaluation.json";
import { get_rating } from './../evaluation_data.js';
export let id;
export let ind;
export let total_severity;
export let total_sx;
export let criterion_severity;
export let criterion_sx;
export let criterion_sx_title;
export let cmp;
</script>

<table class="summary_table">
    <SummaryTableHeader text_column1={evaluation.criterions[ind].criterion_title} text_column2={"Past Month"}/>
    <SummaryTableRow text1={"Symptom"} text2={"Sev"} text3={criterion_sx_title} isHeader={true}/>
    {#each evaluation.criterions[ind].criterions as item}
        <SummaryTableRow on:allow_page_change text1={item.title} text2={get_rating(item.itemNum)} rating={get_rating(item.itemNum)} cmp={cmp}/>
    {/each}
    <SummaryTableLastRow bgcolor={"#C0C0C0"} alignment={"left"} text1={id} text2={"subtotals"} text3={total_severity} text4={criterion_severity} text5={total_sx} text6={criterion_sx}/>
</table>
