<script>
    import { context_toggle, input_disabled_all, input_disabled_tutorial } from './stores.js';
    let contextBtn;
    var inverted=false; 
    $: {
        if(contextBtn)
            contextBtn.disabled = $input_disabled_tutorial;
    }
	function toggleContext()
	{
        if(!$context_toggle)
            {
                $input_disabled_all = true;
                window.addEventListener("click",toggleOutsideContext);
                window.addEventListener("keypress",toggleOutsideContext);
            }            
        else 
        {
            window.removeEventListener("click",toggleOutsideContext);
            window.removeEventListener("keypress",toggleOutsideContext)
            $input_disabled_all = false;
        }
            
        $context_toggle = !$context_toggle;        
        var e = document.getElementById("svg_questionmark");
        var p = document.getElementById("contextBtn");
        if(inverted)
        {
            e.classList.replace("questionmark_inverted","questionmark");
            p.style.backgroundColor="white";
        }
        else
        {
            e.classList.replace("questionmark","questionmark_inverted");
            p.style.backgroundColor="black";
        }
        inverted = !inverted;
    }
    function toggleOutsideContext(event)
    {
        var contextButton = document.getElementById("contextBtn");
        if(!contextButton.contains(event.target))
        {
            window.removeEventListener("click",toggleOutsideContext);
            window.removeEventListener("keypress",toggleOutsideContext);
            toggleContext();
        }
    }
</script>
<script context="module">
	
</script>
<style lang="less">
#contextBtn:focus > .btn_content  {
    box-shadow: inset 0 0 0 0px #fff, 0 0 0 2px #023b5a;
    border-radius: 2px;
    outline: none; // removes default focus outline
}
#contextBtn:focus, .btn_content:focus
{
	outline:none;
}
.btn_content:hover { box-shadow: 0 0 0 2px #023b5a; border-radius: 2px;}
.btn_content
{
	display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    pointer-events: all;
}
#contextBtnDiv
{
	width: 2vw;
    position: relative;
}
#contextBtn
{
    border: none !important;
    border-radius: 50%;
    width: 80%;
    height: 80%;
    display: block;
    position: relative;
    /* left: 1%; */
    top: 10%;
    cursor: pointer;
    pointer-events: none;
    padding: 0;
    background-color: white;   
}
#contextBtn:disabled
{
    opacity: .5;    
}
#contextBtn:disabled > .btn_content
{
    pointer-events: none;
}
#contextBtnTutorial
{
    display: none;
    border: .3vw solid #597f96;
    border-radius: 10px;
    position: absolute;
    width: 85%;
    height: 75%;
    top: 0px;
    bottom: 0px;
    margin-left: -20%;
    z-index: 200;
}
:global(.questionmark)
{
    filter: invert(0%);
}
:global(.questionmark_inverted)
{
    filter: invert(100%);    
}
</style>
<div id="contextBtnDiv">	
    <div id="contextBtnTutorial"></div>
	<button id="contextBtn" on:click|stopPropagation={toggleContext} bind:this={contextBtn}>
		<span class="btn_content" aria-label="Context Button"></span>
		<svg class="questionmark" id="svg_questionmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.5 27.5"><g id="Layer_2" data-name="Layer 2"><g id="ContextHelpButton"><g id="GRP_ContextHelpButton"><path id="QuestionCurve" d="M13.84,5.61a4.86,4.86,0,0,0-3.35,1.12A4.91,4.91,0,0,0,9,10l1.9.22a3.89,3.89,0,0,1,1-2.3,2.74,2.74,0,0,1,2-.74,2.86,2.86,0,0,1,2.06.81,2.58,2.58,0,0,1,.84,1.89,2.17,2.17,0,0,1-.28,1.1,6.31,6.31,0,0,1-1.25,1.35,13,13,0,0,0-1.33,1.29,4.29,4.29,0,0,0-.73,1.2,5.24,5.24,0,0,0-.3,1.87c0,.13,0,.31,0,.56h1.77a7.64,7.64,0,0,1,.15-1.6,2.69,2.69,0,0,1,.41-.87,9,9,0,0,1,1.18-1.18,8.82,8.82,0,0,0,1.81-2,3.67,3.67,0,0,0,.46-1.82,3.81,3.81,0,0,0-1.31-2.9A5,5,0,0,0,13.84,5.61Z"/><rect id="Dot" x="12.77" y="18.8" width="2.1" height="2.1"/><path id="Circle" d="M13.75,0A13.75,13.75,0,1,0,27.5,13.75,13.77,13.77,0,0,0,13.75,0Zm0,26A12.25,12.25,0,1,1,26,13.75,12.27,12.27,0,0,1,13.75,26Z"/></g></g></g></svg>
	</button>
</div>
