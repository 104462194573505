<script>
	import { open_coach_menu,is_coach_submenu_open,is_coach_menu_open,hide_coach_menu,close_coach_sub_menu} from './CoachMenu.svelte';
	import { hide_coach_text } from './CoachText.svelte';
	import { input_disabled_dialogue, input_disabled_all, input_disabled_tutorial, page, coach_asset_path, base_asset_path, game_mode, context_toggle } from './stores.js';
	import { get } from 'svelte/store';
	import { save_transcript } from './TranscriptStates.svelte';
	import CoachButtonAnim, { alert_in, alert_loop, alert_out } from './CoachButtonAnim.svelte';
	import ContextController from './ContextController.svelte';
	import { close_notepad } from './InputFeedNotepad.svelte';
	export let incoming_match_input;
	export let skipTrack;
	export let dialogue;
	export let coach_card_name;
	export let coach_dir;
	let opened = false;
	let coachBtn;
	let bubbleActive = "all";
	let bubbleGrayscale = "0%";
	let pointerSelect = $game_mode == "learn" ? "cursor" : "unset";
	let coachOpen = $game_mode == "learn" ? "ctx_coach_open" : "ctx_coach_open_validate";
	let coachClose = $game_mode == "learn" ? "ctx_coach_close" : "ctx_coach_close_validate";
	
	$:{
		if(coach_active)
		{
			if($input_disabled_dialogue)
			{
				disable_enable_coach_button(true);
				bubbleActive = "none";
				bubbleGrayscale = "100%";
				coach_active=true;
			}
			else
			{
				disable_enable_coach_button(false);
				bubbleActive = "all";
				bubbleGrayscale = "0%";
			}				
		}
		if(coachBtn)
		{
			if($input_disabled_tutorial || $page.itemNum < 0)
			{
				coachBtn.style.cursor = "unset";
			}				
			else
			{
				if($game_mode == "learn")
					coachBtn.style.cursor = "pointer";
			}				
		}	
	}
    function is_sound_playing()
	{
		return $input_disabled_dialogue == true;
	}
	async function play_alert_card()
	{
		if(alert_card && alert_card!=null)
		{
			await incoming_match_input(alert_card.data,false);
			hide_coach_alert();
			save_transcript($page.itemNum, "coach", dialogue);
		}
	}
	function play_help_card()
	{
		incoming_match_input("how_can_I_help",false);
	}

	export function click_coach()
	{
		if(!$input_disabled_tutorial)
		{
			opened = is_coach_menu_open();
			if(!opened)
			{
				if(alert_card && alert_card!=null&&$game_mode == 'learn')
					play_alert_card();
				else
				{
					close_notepad("mouse");
					open_coach_menu();
					if($game_mode == 'learn')
					{					
						play_help_card();
						$input_disabled_all = true;
					}
					else
						document.getElementById("coach_image_container").style.zIndex = "40";
				}
			}
			else
			{
				if($game_mode != "learn")
					document.getElementById("coach_image_container").style.zIndex = "3";
				if(is_coach_submenu_open())
					close_coach_sub_menu();
				hide_coach_menu();
				hide_coach_text();
				if(is_sound_playing())
					skipTrack();							
				$input_disabled_all = false;
			}
		}
		opened = is_coach_menu_open();
	}
</script>
<script context="module">
	var coach_active = false;
	let alert_card = null;
	export function show_coach_alert(card)
	{		
		alert_card = card;
		var coach_alert_ctnr = document.getElementById("alert_svg_container");
		if(coach_alert_ctnr)
			coach_alert_ctnr.style.display = "block";
		alert_in();
		setTimeout(function(){
			alert_loop();
  		}, 1000); 
	}
	export function hide_coach_alert()
	{
		alert_card = null;
		alert_out();
		setTimeout(function(){
			var coach_alert_ctnr = document.getElementById("alert_svg_container");
			if(coach_alert_ctnr)
				coach_alert_ctnr.style.display = "none";
  		}, 1400); 
		
	}
	export function disable_enable_coach_button(disable)
	{
		coach_active = !disable;
		var element = document.getElementById("coach_btn");
		if(element!=undefined)
			element.disabled = disable;
		element = document.getElementById("coach_btn_content");
		if(element!=undefined)
		{
			element.disabled = disable;
			let b = document.getElementById("coach_menu_btn_content_border");
			if(b)
				b.style.border = disable?"none":"solid 3px white";
		}

		element = document.getElementById("coach_img");
		if(element!=undefined)
			element.src = get(coach_asset_path) + (disable?"CoachNonClickable.jpg":"CoachClickable.jpg");
		
	}
</script>
<style lang="less">
	#coach_image_container
	{
		position: absolute;
		display: block;
		right: .75vw;
		top: .75vw;
		width: 7vw;
		height: 7vw;
		margin: 0;
		z-index: 3;
	}
	.coach_menu_btn
	{
		border: none;
		outline: none;
		display: block;
		background-color: transparent;
		border-radius: 5px;
		transition: .2s ease;
		cursor: pointer;
		padding: 0;
		pointer-events: none;
		width: 100%;
		height: 100%;
		box-shadow: black 0px 0px 11px 1px;
	}
	.coach_menu_btn_content
	{
		display: block;
		width: 100%;
		height: 100%;
		pointer-events: all;
		border-radius: 5px;
//		box-sizing: border-box;
		z-index: 4;
	}
	#coach_menu_btn_content_border
	{
		position: absolute;
		width: 100%;
		height: 100%;
		pointer-events: none;
		border-radius: 5px;
		box-sizing: border-box;
		z-index: 4;
	}
	.coach_menu_btn:focus > .coach_menu_btn_content 
	{
		outline: none;
	}
	.coach_menu_btn:focus,.coach_menu_btn_content:focus
	{
		outline: none; 
	}
	.coach_menu_btn:hover:enabled,
	.coach_menu_btn:focus
	{
		box-shadow: white 0px 0px 15px 3px; 
		outline: none; 
	}
	.coach_menu_btn:disabled{ pointer-events: none;box-shadow: rgba(1, 1, 1, 0.65) 0px 0px 15px 4px}
	.coach_menu_btn_content:disabled{ pointer-events: none;	}
	#coach_img
	{
		width: 100%;
		height: 100%;
		border-radius: 5px;
		padding: 0vw;
		z-index: 3;
	}
	#coach_alert
	{
		display: none;
		width: 2.5vw;
		height: 2.5vw;
		position: absolute;
		left: 4.8vw;
		top: 5.7vw;
		z-index: 5;
	}
	.coach_menu_btn_bubble
	{
		position: absolute;
		border: none;
		border-radius: 2px;
		outline: none;
		display: block;
		background-color: transparent;
		transition: .2s ease;
		cursor: pointer;
		padding: 0;
		pointer-events: all;
		width: 40%;
		height: 40%;
		right: 0.5vw;
		top: 0.5vw;
		filter: drop-shadow(0 0 5px black);
	}
	.coach_menu_btn_bubble:hover { box-shadow: 0 0 0 2px #023b5a; }

	.coach_menu_btn_bubble:active {
	transform: translate(2px, 2px);
	}
	.coach_menu_btn_bubble_content
	{ 
		display: inline-block;
		width: 100%;
		height: 100%;
		pointer-events: all;
	}
	.coach_menu_btn_bubble:focus,
	.coach_menu_btn_bubble_content:focus {
		outline: none;
	}
	.coach_menu_btn_bubble:focus > .coach_menu_btn_bubble_content 
	{
		box-shadow: 0 0 0 2px #023b5a;
		outline: none;
	}

</style>
<div id="coach_image_container" style="cursor: {pointerSelect};">		
		{#if opened}
			<ContextController divID={coachClose}/>
		{:else}
			<ContextController divID={coachOpen}/>
		{/if}
		{#if ($game_mode == 'learn' || $input_disabled_tutorial )}

			<button bind:this={coachBtn} class="coach_menu_btn" id="coach_btn" on:click|stopPropagation={(event)=>click_coach()} aria-label="Coach Menu" tabindex={$context_toggle?"-1":"0"}>
				<span class="coach_menu_btn_content" id="coach_btn_content" tabindex="-1" aria-hidden="true">
					<div id="coach_menu_btn_content_border"/>       
					<img id="coach_img" src={$coach_asset_path+"CoachNonClickable.jpg"} alt="Coach">    
					<CoachButtonAnim/>
				</span>
			</button>
		{:else}
			<button bind:this={coachBtn} class="coach_menu_btn_bubble" id="coach_btn" on:click|stopPropagation={(event)=>click_coach()} aria-label="Coach Menu" style="pointer-events: {bubbleActive}">
				<span class="coach_menu_btn_bubble_content" tabindex="-1" style="filter: grayscale({bubbleGrayscale}); pointer-events: {bubbleActive}" >
					<img id="bubble_img" src={$base_asset_path+"MenuBubble.svg"} alt="More...">
				</span>  
			</button>
		{/if}  
</div>
