
<script context="module">
let currElement;
let currText;


export function show_tooltip(e,text)
{
    currElement = e;
    currText = text;
    window.addEventListener("resize",resetPosition);
    var tooltip_e = document.getElementById("context_help");

    var tmp_tooltip_e = document.getElementById("context_help_hidden");
    tmp_tooltip_e.style.display="flex";
    var tmp_tooltip_text = document.getElementById("help_text_hidden");
    tmp_tooltip_text.innerHTML=text;
    tooltip_e.style.width=tmp_tooltip_e.offsetWidth+"px";
    tooltip_e.style.height=tmp_tooltip_e.offsetHeight+"px";
    tmp_tooltip_e.style.display="none";

    var text_e = document.getElementById("help_text");
    text_e.innerHTML=text;
    tooltip_e.style.display="flex";
    var tt_offset = 3;

    var win_height = window.innerHeight;
    var win_width = window.innerWidth;

    var viewportOffset = e.getBoundingClientRect();
    var e_top = viewportOffset.top;
    var e_left = viewportOffset.left;
    var e_height = e.offsetHeight;
    var e_width = e.offsetWidth;
    var e_x_middle = e_width/2;
    var e_y_middle = e_height/2;

    var tt_height = tooltip_e.offsetHeight;
    var tt_width = tooltip_e.offsetWidth;

    var place_left = (e_left+e_x_middle)>win_width/2?true:false;
    var place_on_top = (e_top+e_y_middle)>win_height/2?true:false;

    var tt_x_margin = place_left?e_left-tt_width-tt_offset:win_width-(e_left+e_width+tt_width+tt_offset);
    var tt_y_margin = place_on_top?e_top-tt_height-tt_offset:win_height-(e_top+e_height+tt_height+tt_offset);

    var s = 'border: solid transparent;content: " ";height: 0;width: 0;position: absolute;pointer-events: none;border-color: transparent;';
    var style_body,style_border;

    if(tt_x_margin>tt_y_margin)
    {
        if(place_left)
        {
            tooltip_e.style.left = (tt_x_margin<0?0:e_left-tt_width-tt_offset)+"px";
            style_body=s+'left: 100%;border-left-color: white;border-width: 12px;margin-top: -12px;margin-left: -2px;';
            style_border=s+'left: 100%;border-left-color: #597f96; border-width: 13px;margin-top: -13px;';

        }
        else
        {
            tooltip_e.style.left = (tt_x_margin>0?e_left+e_width+tt_offset:e_left+e_width+tt_offset-tt_x_margin)+"px";
            style_body=s+'right: 100%;border-right-color: white;border-width: 12px;margin-top: -12px;margin-right: -2px;';
            style_border=s+'right: 100%;border-right-color:#597f96;border-width: 13px;margin-top: -13px;';

        }
        let y_pos = e_top+e_y_middle-tt_height/2;
        if( y_pos+tt_height > win_height ) 
            y_pos = win_height-tt_height;

        let tt_top = y_pos<0?0:y_pos;
        let arrow_top=e_top+e_y_middle-tt_top;
        arrow_top=arrow_top<13?13:arrow_top;
        arrow_top=arrow_top>tt_height-13?tt_height-13:arrow_top;

        tooltip_e.style.top = tt_top+"px";
        style_body+='top:'+arrow_top+"px";
        style_border+='top:'+arrow_top+"px";
    }
    else
    {
        if(place_on_top)
        {
            tooltip_e.style.top = (tt_y_margin<0?0:e_top-tt_height-tt_offset)+"px";
            style_body=s+'top: 100%;border-top-color: white;border-width: 12px;margin-left: -12px;margin-top: -2px;';
            style_border=s+'top: 100%;border-top-color: #597f96;border-width: 13px;margin-left: -13px;';
        }
        else
        {
            tooltip_e.style.top = (tt_y_margin>0?e_top+e_height+tt_offset:e_top+e_height+tt_offset-tt_y_margin)+"px";
            style_body=s+'bottom: 100%;border-bottom-color: white;border-width: 12px;margin-left: -12px;margin-bottom: -2px;';
            style_border=s+'bottom: 100%;border-bottom-color: #597f96;border-width: 13px;margin-left: -13px;';
        }
        let x_pos = e_left+e_x_middle-tt_width/2;
        if( x_pos+tt_width > win_width ) 
            x_pos = win_width-tt_width;
        let tt_left = x_pos<0?0:x_pos;
        let arrow_left=e_left+e_x_middle-tt_left;
        arrow_left=arrow_left<13?13:arrow_left;
        arrow_left=arrow_left>tt_width-13?tt_width-13:arrow_left;

        tooltip_e.style.left = tt_left+"px";
        style_body+='left:'+arrow_left+"px";
        style_border+='left:'+arrow_left+"px";
    }
    document.getElementById("context_help_arrow_border").style.cssText = style_border;
    document.getElementById("context_help_arrow_body").style.cssText = style_body;
}

export function hide_tooltip()
{
    var tooltip_e = document.getElementById("context_help");
    tooltip_e.style.display="none";
    window.removeEventListener("resize",resetPosition);
}

function resetPosition()
{
    show_tooltip(currElement, currText);
}
</script>
<style lang="less">

[class*="context_tooltip-"]
{
    font-size:1.15vw;
    position:absolute;
    padding: 0.7vw;
    border: 2px solid #597f96;
    max-width: 70%;
    display:none;
    flex-direction: row;
    align-items:center;
    justify-content: flex-start;
}
.context_tooltip-hidden{width:auto;}
.context_tooltip-visible
{
    color:#597f96;
    background:white;
    border-radius: 5px;
    z-index:201;
    box-sizing: border-box;
    width:20px;
    height:20px;
}
.context_help_questionmark
{
    background-color: #ececec;
    max-width: 4vw;
    max-height: 4vw;
    padding-left:1.2vw;
    padding-right:1.2vw;
    margin-right: 0.7vw;
    font-size:2.5vw;
    align-content: center;
}
#splittingLine
{
    border-left: 1px solid lightgrey;
    height: 100%;
    margin-right: 0.7vw;
}
</style>

<div id="context_help_hidden" class="context_tooltip-hidden">
    <label class="context_help_questionmark">?</label>
    <div id="splittingLine"></div>
    <label id="help_text_hidden"></label>
</div>


<div id="context_help" class="context_tooltip-visible">
    <div id="context_help_arrow_border"/>
    <div id="context_help_arrow_body"/>
    <label class="context_help_questionmark">?</label>
    <div id="splittingLine"></div>
    <label id="help_text"></label>
</div>


