<script context="module">
  import './css/eval.css';
  import './css/per_item.css';
</script>

<script>
  import { get_source } from './server_api';
  import { patient_asset_path, common_asset_path } from './stores.js';	
  import { onMount, onDestroy } from 'svelte'
  import { createEventDispatcher } from 'svelte';
  import { game_mode } from './stores.js';
  import { set_transcript } from './TranscriptStates.svelte';
  import evaluation_avm from './evaluation_avm_v3.json'
  import evaluation_validate from './evaluation_validate_v3.json';
  import evaluation_learn from './evaluation_v3.json';
  import eval_captions_v3 from './eval_captions_v3.json';
  import Transcript from './Transcript.svelte';
  import EvaluationPerItem from './EvaluationPerItem.svelte';
  import EvaluationModal from './EvaluationModal.svelte';
  import IconCaretToggle from './IconCaretToggle.svelte';

  const dispatch = createEventDispatcher();

  export let user;
  export let certDate;
  export let certID;
  export let certSession;
  export let e;

  let eval_title="";
  let eval_date="";
  let eval_patient="";
  let eval_back = "";
  let eval_print = "";
  let eval_print1 = "";
  let eval_print2 = "";
  let eval_title_bar = "";
  let eval_toggle = "Expand All";
  let caretHidden = true;
  let hoverToggle = false;

  let eval_ratings=[];
  let other_considerations=[];
  let itemExplanation=[];

  let player_severity_score=0;
  let true_severity_score=0;
  let player_severity_score_evaluation="";
  let severity_score_additional_info="";
  let required_prompts_percentage=0;
  let required_prompts_evaluation="";
  let required_prompts_in_order_evaluation="";
  let required_prompts_in_order_array=[{item: 'A'}, {item: 'B1'}, {item: 'B2'}, {item: 'B3'}, {item: 'B4'}, {item: 'B5'}, {item: 'C1'}, {item: 'C2'}, {item: 'D1'}, {item: 'D2'}, {item: 'D3'}, {item: 'D4'}, {item: 'D5'}, {item: 'D6'}, {item: 'D7'}, {item: 'E1'}, {item: 'E2'}, {item: 'E3'}, {item: 'E4'}, {item: 'E5'}, {item: 'E6'}, {item: '21'}, {item: '22'}, {item: '23'}, {item: '24'}, {item: '25'}, {item: '29'}, {item: '30'}];
  let player_score_reveal=0;
  let score_reveal_addition_info="";
  let shortItemNames=['Criterion A', 'Item B1', 'Item B2', 'Item B3', 'Item B4', 'Item B5', 'Item C1', 'Item C2', 'Item D1', 'Item D2', 'Item D3', 'Item D4', 'Item D5', 'Item D6', 'Item D7', 'Item E1', 'Item E2', 'Item E3', 'Item E4', 'Item E5', 'Item E6', 'Delayed onset ≥ 6 months', 'Duration ≥ 1 month', 'Subjective distress', 'Impairment in social functioning', 'Impairment in occupational functioning', 'Global validity', 'Global severity', 'Global improvement', 'Depersonalization', 'Derealization'];

  let topbar;
  let evalMenu;
  let printEvalBtn;
  let printTranscriptBtn;
  let downloadAdminBtn;
  let topbarText;
  let doneBtn;
  let backLabel;
  let hideAllItems = true;
  let source;

  function done()
  {
    dispatch('close');
  }

  var evaluationFile;
  if($game_mode=="learn")
  {
    evaluationFile = evaluation_learn;
    eval_patient = "Anthony";
  }
  else if($game_mode=="validate")
  {
    evaluationFile = evaluation_validate;
    eval_patient = "Kathy";
  }
  else
  {
    evaluationFile = evaluation_avm;
    eval_patient = "Robert";
  }

  function createTagsArray(itemNum)
  {
    let tagsArray = [];
    if (e.tags != null) {
      if (e.tags[itemNum]) {
        for (let i in e.tags[itemNum]) {
          for (let tag in e.tags[itemNum][i]) {
            if (e.tags[itemNum][i][tag] === true && !tagsArray.includes(tag)) {
              tagsArray.push(tag);
            }
          }
        }
      }
    }
    return tagsArray;
  }

  function checkTags(tagsArray, tagsToCheck)
  {
    let containsAllTags = false;
    for (let i in tagsToCheck) {
      if (tagsArray.includes(tagsToCheck[i])) {
        containsAllTags = true;
      } else {
        containsAllTags = false;
        break;
      }
    }
    return containsAllTags;
  }

  function get_eval_summary_text_v3()
  {
    let part = $game_mode=="learn" ? 2 : ($game_mode=="validate" ? 3 : 4);
    eval_title = eval_title.concat(eval_captions_v3.title, part, "<br>", eval_captions_v3.evaluationFor, user);
    eval_date = eval_date.concat(certDate);
    eval_back = eval_captions_v3.Back;
    eval_print = eval_captions_v3.PrintEval;
    eval_print1 = eval_captions_v3.PrintTranscript;
    eval_print2 = eval_captions_v3.DownloadAdmin;
    eval_title_bar = eval_captions_v3.Evaluation;

    player_severity_score = $game_mode=="learn" ? e.initial_severity_score : e.final_severity_score;

    // CAPS-5 SEVERITY SCORE
    true_severity_score = e.correct_total_severity;
    let correct_score_min = e.correct_total_severity-e.severity_range;
    let correct_score_max = e.correct_total_severity+e.severity_range;
    if (true_severity_score == player_severity_score) 
    { 
      // Should output "Perfect!"
      player_severity_score_evaluation = eval_captions_v3.Perfect;
    }
    else if (correct_score_min <= player_severity_score && correct_score_max >= player_severity_score) 
    {
      // Should output "Well Done."
      player_severity_score_evaluation = eval_captions_v3.Valid;
    }
    else 
    {
      // Should output "Your severity is outside the desired range. etc"
      player_severity_score_evaluation = eval_captions_v3.Invalid;
    }
    severity_score_additional_info = severity_score_additional_info.concat(eval_captions_v3.Accuracy1,e.severity_range,eval_captions_v3.Accuracy2,e.double_severity_range,eval_captions_v3.Accuracy3);

    // REQUIRED PROMPTS ADMINISTERED
    let prompts_percent = e.required_prompts_used / e.required_prompts_total;
    required_prompts_percentage = Math.round((e.required_prompts_used/e.required_prompts_total)*100);
    for(let i in eval_captions_v3.promptsRequired) {
      if(prompts_percent < eval_captions_v3.promptsRequired[i].range)
      {
        required_prompts_evaluation = eval_captions_v3.promptsRequired[i].text;
        break;
      }
    }
    
    // REQUIRED PROMPTS IN ORDER 
    for (let i = 0; i < 26; i++) {
      required_prompts_in_order_array[i] = {...required_prompts_in_order_array[i], required: e.admin_details[i].required_prompts, in_order: e.admin_details[i].in_order}
    }
    for (let i = 26; i < 28; i++) {
      required_prompts_in_order_array[i] = {...required_prompts_in_order_array[i], required: e.admin_details[i+3].required_prompts, in_order: e.admin_details[i+3].in_order}
    }
    for(let i in eval_captions_v3.promptsInOrder) {
      if(e.total_items_in_order < eval_captions_v3.promptsInOrder[i].range) {
        required_prompts_in_order_evaluation = eval_captions_v3.promptsInOrder[i].text;
        break;
      }
    }

    // SCORES REVEALED
    player_score_reveal = e.score_reveal;
    score_reveal_addition_info = eval_captions_v3.ScoreRevealAdditionalInfo;
  }

  function get_eval_details_text_v3()
  {
    for (let i in evaluationFile.criterions) {
      for (let j in evaluationFile.criterions[i].criterions) {
        let itemNum = evaluationFile.criterions[i].criterions[j].itemNum;
        let currentItem = [];
        let tagsArray = [];
        
        other_considerations[itemNum] = [];

        // FILLS IN ITEM SCORE EXPLANATION
        for (let k in evaluationFile.criterions[i].criterions[j].items) {
          let itemExplanationDetails = {
            ...evaluationFile.criterions[i].criterions[j].items[k]
          }
          currentItem.push(itemExplanationDetails)
        }
        itemExplanation.push(currentItem)

        // GET ALL TAGS FROM ITEM
        tagsArray = createTagsArray(itemNum);
        
        // CONSIDERATIONS I.E. COMMENTS WITHIN ADMIN FEEDBACK (PER ITEM CONSIDERATIONS)
        if (evaluationFile.criterions[i].criterions[j].considerations) {
          for (let k in evaluationFile.criterions[i].criterions[j].considerations) {
            if (checkTags(tagsArray, evaluationFile.criterions[i].criterions[j].considerations[k].tag)) {
              other_considerations[itemNum].push(evaluationFile.criterions[i].criterions[j].considerations[k].text);
            }
          }
        }

        // MORE ADMIN FEEDBACK (CC_TAGS FOR ALL ITEMS)
        for (let cct in evaluationFile.cc_tags) {
          if(checkTags(tagsArray, evaluationFile.cc_tags[cct].tag)) {
            other_considerations[itemNum].push(evaluationFile.cc_tags[cct].text);
          }
        }

        // MORE ADMIN FEEDBACK (REQUIRED PROMPTS ON CERTAIN ITEMS)
        if (evaluationFile.criterions[i].criterions[j].requiredPrompts) {
          for (let k in evaluationFile.criterions[i].criterions[j].requiredPrompts) {
            if(checkTags(tagsArray, evaluationFile.criterions[i].criterions[j].requiredPrompts[k].tag)) {
              other_considerations[itemNum].push(evaluationFile.criterions[i].criterions[j].requiredPrompts[k].text);
            }
          }
        }
      }  
    }
  }

  function get_eval_ratings_text_v3()
  {
    let score_id;
    let correct_id;
    if ($game_mode == "learn") {
      score_id = "initial";
      correct_id = "scored_correct";
    } else {
      score_id = "final";
      correct_id = "final_scored_correct";
    }
    for (let i in evaluationFile.criterions) {
      for (let j in evaluationFile.criterions[i].criterions) {
        let ind = evaluationFile.criterions[i].criterions[j].itemNum;
        let mark = e.ratings[ind][correct_id] ? true : false
        // item header
        let itemData = {};
        if (ind === 0) {
          itemData = {
            "title": evaluationFile.criterions[i].criterions[j].title,
            "score": e.ratings[ind][score_id],
            "mark": mark,
            "correct": e.ratings[ind].correct,
            "header": "Met?"
          }
        } else if (ind === 21 || ind === 22) {
          itemData = {
            "title": evaluationFile.criterions[i].criterions[j].title,
            "score": e.ratings[ind][score_id],
            "mark": mark,
            "correct": e.ratings[ind].correct,
            "header": "Present"
          }
        } else {
          itemData = {
            "title": evaluationFile.criterions[i].criterions[j].title,
            "score": e.ratings[ind][score_id],
            "mark": mark,
            "correct": e.ratings[ind].correct,
            "header": "Severity"
          }
        }

        // other items such as Exposure, Life Threat, Serious Injury, Intensity, etc..
        let itemDetails = [];
        for (let k in e.ratings[ind].other_scoring_items) {
          mark = e.ratings[ind].other_scoring_items[k][correct_id] === 1 ? true : false;
          if (e.ratings[ind].other_scoring_items[k].caption === "Exposure Type") {
            itemDetails.push({
              "title": e.ratings[ind].other_scoring_items[k].caption = "Exposure",
              "score": e.ratings[ind].other_scoring_items[k][score_id],
              "correct": e.ratings[ind].other_scoring_items[k].correct,
              "mark": mark
            })
          } else if (e.ratings[ind].other_scoring_items[k].caption === "Trauma-Relatedness") {
            itemDetails.push({
              "title": e.ratings[ind].other_scoring_items[k].caption = "Trauma Relatedness",
              "score": e.ratings[ind].other_scoring_items[k][score_id],
              "correct": e.ratings[ind].other_scoring_items[k].correct,
              "mark": mark
            })
          } else if (e.ratings[ind].other_scoring_items[k].caption === "Frequency") {
            if (e.ratings[ind].other_scoring_items[k][score_id] === 'n/a' && e.ratings[ind].other_scoring_items[k].correct !== 'n/a') {
              other_considerations[ind].push(evaluationFile.generic.abc_frequency);
            }
            itemDetails.push({
              "title": e.ratings[ind].other_scoring_items[k].caption,
              "score": e.ratings[ind].other_scoring_items[k][score_id],
              "correct": e.ratings[ind].other_scoring_items[k].correct,
              "mark": mark
            })
          } else {
            itemDetails.push({
              "title": e.ratings[ind].other_scoring_items[k].caption,
              "score": e.ratings[ind].other_scoring_items[k][score_id],
              "correct": e.ratings[ind].other_scoring_items[k].correct,
              "mark": mark
            })
          }
        }

        // required prompts (always last)
        mark = e.admin_details[ind].required_prompts_asked === e.admin_details[ind].num_required_prompts ? true : false;
        itemDetails.push({
          "title": eval_captions_v3.RequiredPrompts,
          "score": e.admin_details[ind].required_prompts_asked,
          "mark": mark,
          "correct": e.admin_details[ind].num_required_prompts
        });

        // administration feedback
        let administration_feedback = {"title": eval_captions_v3.OtherConsiderations};
        if (other_considerations[ind].length > 0) {
          let o_c_array = [];
          for (let l in other_considerations[ind]) {
            o_c_array.push(other_considerations[ind][l]);
          }
          administration_feedback = {
            ...administration_feedback,
            "feedback": o_c_array
          }
        }

        eval_ratings[ind] = {
          "item": itemData,
          "details": itemDetails,
          "administration_feedback": administration_feedback,
          "short_name": shortItemNames[ind],
          "item_explanation": itemExplanation[ind],
          "severity_generic": evaluationFile.generic.severity_explain
        }
      }
    }
  }

  function scrollCollapse()
  {
    if(window.scrollY != 0)
    {
      topbar.style.height = "5vw";
      doneBtn.style.width = "3%";
      doneBtn.style.left = "8vw";
      doneBtn.style.top = "1vw";
      backLabel.style.marginTop = "-.5vw";
      backLabel.style.fontSize = ".6vw";
      topbarText.style.right = "45%";
      topbarText.style.fontSize = "2vw";
      printTranscriptBtn.style.top = "0.35vw";
      printEvalBtn.style.top = "0.35vw";
      downloadAdminBtn.style.top = "2.35vw";
    }
    else
    {
      topbar.style.height = "10vw";
      doneBtn.style.width = "6%";
      doneBtn.style.left = "4vw";
      doneBtn.style.top = "1.5vw";
      backLabel.style.marginTop = "-.3vw";
      backLabel.style.fontSize = "1.2vw";
      topbarText.style.right = "40%";
      topbarText.style.fontSize = "3vw";  
      printTranscriptBtn.style.top = "2.35vw";
      printEvalBtn.style.top = "2.35vw";
      downloadAdminBtn.style.top = "5.35vw";
    }
  }
  
  function fill_in_evaluation_text_v3()
  {
    get_eval_details_text_v3();
    get_eval_summary_text_v3();
    get_eval_ratings_text_v3();
  }

  function printEval()
  {
    setTimeout(() => {
      hideAllItems = true;
    }, 25)
    setTimeout(() => {
      hideAllItems = false;
    }, 50)
    setTimeout(() => {
      window.print()
    }, 100)
    setTimeout(() => {
      hideAllItems = true;
    }, 200)
  }

  function toggleAllItems()
  {
    if (hideAllItems) {
      hideAllItems = false;
      eval_toggle = "Collapse All";
      caretHidden = false;
    } else {
      hideAllItems = true;
      eval_toggle = "Expand All";
      caretHidden = true;
    }
  }

  function handleMouseOver() {
    hoverToggle = true;
  }

  function handleMouseOut() {
    hoverToggle = false;
  }

  async function printTranscript()
  {  
    await setupTranscript();
    setTimeout(() => {
      window.print();
    }, 100);
    setTimeout(() => {
      closeTranscript();
    }, 200);
  }

  async function setupTranscript() {
    let transcriptContainerPrint = document.getElementById("transcriptContainerPrint");
    let transcriptContainer = document.getElementById("transcriptContainer");
    let evalTranscript = document.getElementById("evalTranscript");
    let evalBody = document.getElementById("pagediv");
    transcriptContainer.innerHTML = "";
    
    await set_transcript(30, certSession);
    
    topbar.style.display = "none";
    evalBody.style.display = "none";
    evalTranscript.style.display = "block";
    transcriptContainerPrint.innerHTML = transcriptContainer.innerHTML;
    evalMenu.style.height = "auto";
    evalMenu.style.maxHeight = "none";
  }

  function closeTranscript() {
    let transcriptContainerPrint = document.getElementById("transcriptContainerPrint");
    let evalTranscript = document.getElementById("evalTranscript");
    let evalBody = document.getElementById("pagediv");

    topbar.style.display = "block";
    evalMenu.style.height = "56.25vw";
    evalMenu.style.maxHeight = "100vh";
    transcriptContainerPrint.innerHTML = "";
    evalTranscript.style.display = "none";
    evalBody.style.display = "block";
  }

  function fill_true_severity_range_and_score() {
    let severityScoreBar = document.getElementsByClassName("indicatordiv");
    let severityScorePercent = player_severity_score/(true_severity_score * 2)
    let severityScoreBarLeftMargin = 200 + (Math.round(800 * severityScorePercent) - 5);
    severityScoreBar[0].style.marginLeft = `${severityScoreBarLeftMargin}px`;
  }

  function set_required_prompts_bar() {
    let requiredPromptsScoreBar = document.getElementsByClassName("indicatordiv");
    let requiredPromptsScoreBarLeftMargin = 200 + (Math.round(800 * (required_prompts_percentage/100)) - 5);
    if (requiredPromptsScoreBarLeftMargin > 990) {
      requiredPromptsScoreBarLeftMargin = 990;
    } else if (requiredPromptsScoreBarLeftMargin < 200) {
      requiredPromptsScoreBarLeftMargin = 200;
    }
    requiredPromptsScoreBar[1].style.marginLeft = `${requiredPromptsScoreBarLeftMargin}px`;
  }

  function set_score_reveal_bar() {
    let scoreRevealBar = document.getElementsByClassName("indicatordiv");
    let scoreRevealBarLeftMargin = 200 + (Math.round(800 * (player_score_reveal/94)) - 5);
    if (scoreRevealBarLeftMargin > 990) {
      scoreRevealBarLeftMargin = 990;
    } else if (scoreRevealBarLeftMargin < 200) {
      scoreRevealBarLeftMargin = 200;
    }
    scoreRevealBar[2].style.marginLeft = `${scoreRevealBarLeftMargin}px`;
  }

  function addSemicolonsToExposure() {
    let correctExposure = eval_ratings[0].details[0].correct.split(" ");
    let scoredExposure = eval_ratings[0].details[0].score.split(" ");
    let correctExposureText = "";
    let scoredExposureText = "";
    if (correctExposure.length > 2) {
      for (let i = 1; i < correctExposure.length; i++) {
        correctExposureText += correctExposure[i];
        if (i !== correctExposure.length - 1) {
          correctExposureText += '; ';
        }
      }
      eval_ratings[0].details[0].correct = correctExposureText;
    }
    if (scoredExposure.length > 2) {
      for (let i = 1; i < scoredExposure.length; i++) {
        scoredExposureText += scoredExposure[i];
        if (i !== scoredExposure.length - 1) {
          scoredExposureText += '; ';
        }
      }
      eval_ratings[0].details[0].score = scoredExposureText;
    }
  }

  onMount( async function()
  {
    window.addEventListener("scroll", scrollCollapse);
    source = await get_source();
    fill_in_evaluation_text_v3();
    fill_true_severity_range_and_score();
    set_required_prompts_bar();
    if ($game_mode === 'learn') {
      set_score_reveal_bar();
    }
    addSemicolonsToExposure();
  })

  onDestroy( () => {
    window.removeEventListener("scroll", scrollCollapse);
  })
</script>

<style lang="less">
  body
  {
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
  }
	.pagediv 
  {
		margin: auto;
    position: relative;
    display: block;
    top: 10vw;
	}
  .done-button 
  {
    width: 6%;
    left: 4vw;
    position: relative;
    top: 1.5vw;
  }
  .doneIcon 
  {
    position: absolute;
    left: 10%;
    width: 80%;
    height: 80%;
    fill: #e2eaf3;
  }
  .topBar 
  {
    height: 10vw;
    width: 100%;
    position: fixed;
    z-index: 1000;
    background: #ededed;    
  }
  .topBarText 
  {
    position: absolute;
    top: 31%;
    margin-block-end: 0;
    margin-block-start: 0;
    right: 40%;
    color:  #023b5a;
    font-size: 3vw;
  }
  .done-button-circle 
  {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 100%;
    border-radius: 50%;
    overflow: hidden;
    background: #023b5a;
    box-shadow: 0 0 3px grey;
  }
  .done-button-circle:hover 
  {
    background:#30588e;
    cursor:pointer;
  }
  .done-button label 
  {
    display: block;
    float: left;
    width: 100%;
    padding-top: 65%;
    padding-bottom: 50%;
    line-height: 1.2vw;
    margin-top: -0.3vw;
    text-align: center;
    color: #e2eaf3;
    font-family: Verdana;
    font-size: 1.2vw;
    font-weight: bold;
    text-decoration: none;
  }
  .backIcon
  {
    position: absolute;
    left: 10%;
    width: 80%;
    height: 80%;
    fill: #e2eaf3;
  }
  .backBtn
  {
    background: transparent;
    border: none !important;
    font-size: 0;
    width: 7vw;
    height: 7vw;
    position: absolute;
    padding: 1px 0px;
    border-radius: 50%;
    cursor: pointer;
    right: 0vw;
    bottom: 0vw;
  }
  .done-button-circle:focus-within 
  {
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none; // removes default focus outline
  }
  .btn_content
  {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .printBtn:hover > .printSVG
  {
    fill: #7092a6;
  }
  .downloadBtn:hover > .printSVG
  {
    fill: #7092a6;
  }
  .printBtnDiv
  {
    position: absolute;
    right: 17vw;
    top: 2.35vw;
  }
  .printBtnDiv2
  {
    position: absolute;
    right: 0vw;
    top: 2.35vw;
  }
  .printBtnDiv3
  {
    position: absolute;
    right: 2.75vw;
    top: 5.35vw;
  }
  .toggleBtnDiv {
    position: absolute;
    right: 0px;
    top: 7px;
  }
  .toggleIconDiv {
    left: 0.5vw;
    position: absolute;
    top: 1px;
  }
  .printBtn:hover {
    background: white;
    border: 2px solid #7092a6;
    color: #7092a6;
  }
  .printBtn
  {
    cursor: pointer;
    width: 16vw;
    height: 2vw;
    border-radius: 8px;
    bottom: 0vw;
    background: #023b5a;
    color: white;
    font-family: 'Lato', sans-serif;
    text-align: center;
    margin: 0;
    right: 2vw;
    text-indent: 15%;
    font-size: 1vw;
    padding: .35vw;
  }
  .downloadBtn
  {
    cursor: pointer;
    width: 27vw;
    height: 2vw;
    border-radius: 8px;
    bottom: 0vw;
    background: #023b5a;
    color: white;
    font-family: 'Lato', sans-serif;
    text-align: center;
    margin: 0;
    right: 2vw;
    text-indent: 15%;
    font-size: 1vw;
    padding: .35vw;
  }
  .downloadBtn:hover {
    background: white;
    border: 2px solid #7092a6;
    color: #7092a6;
  }
  .toggleBtn {
    cursor: pointer;
    border-radius: 8px;
    background: #023b5a;
    color: white;
    font-family: 'Lato', sans-serif;
    text-align: center;
    text-indent: 15%;
    font-size: 18px;
    width: 180px;
    height: 30px;
  }
  .toggleBtn:hover {
    background: white;
    border: 2px solid #7092a6;
    color: #7092a6;
  }
  .printSVG
  {
    position: absolute;
    width: 1.5vw;
    height: 1.5vw;
    top: 0vw;
    left: .5vw;
    fill: white;
    padding: .25vw;
  }
  .printBtn:focus
  {    
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none; // removes default focus outline
  }
  .downloadBtn:focus
  {    
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none; // removes default focus outline
  }
  .bodytext.additional-text {
    font-style: italic;
    color: grey;
  }

  #evalTranscript
  {
    display: none;
    flex-direction: column;
    position: relative;
    height: auto;
    width: 45%;
    top: 20%;
    z-index: 40;
    margin: 0 auto;
    max-width: 100%;
    overflow: hidden;
  }
  @media print 
  {
    .topBar {
      display: none !important;
    }
    .pagediv {
      position: static !important;
    }
  }
</style>

<div class="evaluationMenu" bind:this={evalMenu}>
  <div class="topBar" bind:this={topbar}>
    <div class="done-button" bind:this={doneBtn}>
      <div class="done-button-circle">
        <svg class="backIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="transform: scale(-1)">
          <path fill="none" d="M0 0h24v24H0z"/>
          <path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"/>
        </svg>
        <button class="backBtn" on:click={done} aria-label="Back" data-cy={'backBtn'}></button>
        <label tabindex="-1" bind:this={backLabel}>{eval_back}</label>
      </div>
    </div>
    <p class="topBarText" bind:this={topbarText}>{eval_title_bar}</p>
    <div class="printBtnDiv" bind:this={printEvalBtn}>    
      <button class="printBtn" on:click={printEval} aria-label="Print Evaluation">{eval_print}
        <svg class="printSVG" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <path d="M8 2h16v4h-16v-4z"></path>
          <path d="M30 8h-28c-1.1 0-2 0.9-2 2v10c0 1.1 0.9 2 2 2h6v8h16v-8h6c1.1 0 2-0.9 2-2v-10c0-1.1-0.9-2-2-2zM4 14c-1.105 0-2-0.895-2-2s0.895-2 2-2 2 0.895 2 2-0.895 2-2 2zM22 28h-12v-10h12v10z"></path>
        </svg>
      </button>
    </div>
    <div class="printBtnDiv2" bind:this={printTranscriptBtn}>    
      <button class="printBtn" on:click={printTranscript} aria-label="Print Transcript">{eval_print1}
        <svg class="printSVG" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <path d="M8 2h16v4h-16v-4z"></path>
          <path d="M30 8h-28c-1.1 0-2 0.9-2 2v10c0 1.1 0.9 2 2 2h6v8h16v-8h6c1.1 0 2-0.9 2-2v-10c0-1.1-0.9-2-2-2zM4 14c-1.105 0-2-0.895-2-2s0.895-2 2-2 2 0.895 2 2-0.895 2-2 2zM22 28h-12v-10h12v10z"></path>
        </svg>
      </button>
    </div>
    <div class="printBtnDiv3" bind:this={downloadAdminBtn}>
      <a href="{$common_asset_path + "CAPS-5_Administration_and_Scoring_Guidance.pdf"}" download="CAPS-5_Administration_and_Scoring_Guidance.pdf">
        <button class="downloadBtn" aria-label="Download Admin & Scoring Guidance">{eval_print2}
          <svg class="printSVG" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.75 69.33">
            <g id="a6c85ed6-bb3b-4ef5-a7bb-0f68d3d90891" data-name="Layer 2">
              <g id="b5d23604-5e67-4f11-8bc2-53eeff81f3d4" data-name="Icon_Document">
                <path class="bc2a8cec-8e9c-4555-a407-32b13e7c9cdb" d="M48.57,0H8.17A8.17,8.17,0,0,0,0,8.17v53a8.17,8.17,0,0,0,8.17,8.17h40.4a8.17,8.17,0,0,0,8.18-8.17v-53A8.17,8.17,0,0,0,48.57,0ZM8.12,10.45h18.4v6.36H8.12Zm40.74,49.4H7.58v-7H48.86Zm0-13.81H7.58V39H48.86Zm0-13.32H7.58v-7H48.86Z"/>
              </g>
            </g>
          </svg>
        </button>
      </a>
    </div>
  </div>

  <!-- Hidden Transcript, only shows when you print Transcript -->
  <div id="evalTranscript">
    <Transcript transcriptMethod={"transcriptContainerPrint"} />
  </div>

  <!-- Popup Modal to remind users to print/save evaluation, only shows when users come from TRAIN -->
  {#if source === 'development' || source === 'TRAIN'}
    <EvaluationModal />
  {/if}

  <div id="pagediv" class="pagediv">
    <div class="headerdiv">
      <div class="patientdiv">
        <img src="{$patient_asset_path+"Transcript_Portrait.png"}" loading="lazy" alt="" class="patientimage">
        <div class="patienttext">Patient: {eval_patient}</div>
      </div>
      <div class="titlediv">
        <h1 class="headertitletext">{@html eval_title}</h1>
        <div class="datetext">{@html eval_date}</div>
      </div>
      <img src="{$patient_asset_path+"ptsd-logo.jpg"}" loading="lazy" sizes="300px" alt="" class="logoimage">
    </div>

    <!-- CAPS SEVERITY SCORE SECTION -->
    <div class="sectiondiv">
      <div class="titlebar">
        <div class="titlebanner">
          <h1 class="titletext">CAPS-5 Severity Score</h1>
          <div class="scorebox">
            <h1 class="yourscoring">Your Result</h1>
            <h1 class="score">{player_severity_score}</h1>
          </div>
        </div>
      </div>
      <div class="meterdiv">
        <!-- Grey 10% area of acceptability -->
        <div class="approxdiv">
          <!-- Blue true severity bar -->
          <div class="targetdiv"></div>
        </div>
        <div class="metertext">
          <div class="starttext">0</div>
          <div class="midtext">Patient's true severity: {true_severity_score}</div>
          <div class="endtext">{true_severity_score * 2}</div>
        </div>
      </div>
      <div class="indicatordiv">
        <div class="indicatorfront"></div>
        <div class="indicatorback"></div>
      </div>
      <div class="textdiv">
        <div class="margindiv"></div>
        <div class="coachdiv">
          <img src="{$patient_asset_path+"coach_1coach.png"}" loading="lazy" alt="" class="coachimg">
        </div>
        <div class="dividerdiv"></div>
        <div class="bodytextcontainer">
          <div class="bodytext">
            {@html player_severity_score_evaluation}
          </div>
          <div class="bodytext additional-text">
            {severity_score_additional_info}
          </div>
        </div>
      </div>
    </div>

    <!-- REQUIRED PROMPTS ADMINISTERED -->
    <div class="sectiondiv item2">
      <div class="titlebar">
        <div class="titlebanner">
          <h1 class="titletext">Required Prompts Administered</h1>
          <div class="scorebox">
            <h1 class="yourscoring">Your Result</h1>
            <h1 class="score">{required_prompts_percentage}%</h1>
          </div>
        </div>
      </div>
      <div class="meterdiv">
        <div class="metertext barlabels2">
          <div class="starttext">0%</div>
          <div class="endtext _100">100%</div>
        </div>
      </div>
      <div class="indicatordiv bar2">
        <div class="indicatorfront"></div>
        <div class="indicatorback"></div>
      </div>
      <div class="textdiv">
        <div class="margindiv"></div>
        <div class="coachdiv">
          <img src="{$patient_asset_path+"coach_1coach.png"}" loading="lazy" alt="" class="coachimg">
        </div>
        <div class="dividerdiv"></div>
        <div class="bodytextcontainer">
          <div class="bodytext italics">
            {required_prompts_evaluation}   
          </div>
        </div>
      </div>
    </div>

    <!-- REQUIRED PROMPTS IN ORDER -->
    <div class="sectiondiv item3">
      <div class="titlebar">
        <div class="titlebanner">
          <h1 class="titletext">Required Prompts In Order</h1>
          <div class="scorebox">
            <h1 class="yourscoring">Your Result</h1>
            <div class="scorediv">
              <h1 class="score scoredividend">{e.total_items_in_order}</h1>
              <h1 class="scoreof">of</h1>
              <h1 class="scoredivisor">{e.items_with_prompts}</h1>
            </div>
          </div>
        </div>
      </div>

      <div class="gridborderdiv">
        <div class="griddiv">
          {#each required_prompts_in_order_array as item}
            {#if (item.required === 'Yes' && item.in_order === 'Yes')}
              <div class="tablecell cellchecked">
                <div class="celltext celltextchecked">{item.item}</div><img src="{$patient_asset_path+"Icon_Check-3.svg"}" loading="lazy" alt="" class="checkmark cellcheckmark">
              </div>
            {:else}
              <div class="tablecell">
                <div class="celltext">{item.item}</div>
                <img src="{$patient_asset_path+"Icon_Check-3.svg"}" loading="lazy" alt="" class="checkmark">
              </div>
            {/if}
          {/each}
        </div>
      </div>
      <div class="textdiv">
        <div class="margindiv"></div>
        <div class="coachdiv">
          <img src="{$patient_asset_path+"coach_1coach.png"}" loading="lazy" alt="" class="coachimg">
        </div>
        <div class="dividerdiv"></div>
        <div class="bodytextcontainer">
          <div class="bodytext italics">
            {required_prompts_in_order_evaluation} 
          </div>
        </div>
      </div>
    </div>

    <!-- SCORES REVEALED -->
    {#if $game_mode === "learn"}
    <div class="sectiondiv item2">
      <div class="titlebar">
        <div class="titlebanner">
          <h1 class="titletext">Scores Revealed</h1>
          <div class="scorebox">
            <h1 class="yourscoring">Your Result</h1>
            <div class="scorediv">
              <h1 class="score scoredividend">{player_score_reveal}</h1>
              <h1 class="scoreof">of</h1>
              <h1 class="scoredivisor">94</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="meterdiv">
        <div class="metertext barlabels2">
          <div class="starttext">0</div>
          <div class="endtext _100">94</div>
        </div>
      </div>
      <div class="indicatordiv bar3">
        <div class="indicatorfront"></div>
        <div class="indicatorback"></div>
      </div>
      <div class="textdiv">
        <div class="margindiv"></div>
        <div class="coachdiv">
          <img src="{$patient_asset_path+"coach_1coach.png"}" loading="lazy" alt="" class="coachimg"></div>
        <div class="dividerdiv"></div>
        <div class="bodytextcontainer">
          <div class="bodytext">
            {@html score_reveal_addition_info}
          </div>
        </div>
      </div>
    </div>
    {/if}

    <!-- PER ITEM EVALUATIONS -->
    <div class="sectiondiv peritem">
      <div class="titlebar">
        <div class="toggleBtnDiv">    
          <button class="toggleBtn" on:click={toggleAllItems} on:mouseover={handleMouseOver} on:mouseout={handleMouseOut} aria-label="Toggle All Per-Item Feedback">
            {eval_toggle}
            <div class="toggleIconDiv">
              <IconCaretToggle fillColor="white" hidden={caretHidden} {hoverToggle} />
            </div>
          </button>
        </div>
        <div class="titlebanner">
          <h1 class="titletext">Per-Item Feedback</h1>
        </div>
      </div>

      {#each eval_ratings as rating }
        <EvaluationPerItem {rating} bind:hideAllItems />
      {/each}
    </div>

  </div>
</div>