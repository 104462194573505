<script>
export let focusTrap;
function close_messagebox()
{
  focusTrap.deactivate();
  let e = document.getElementById("modal-wrapper");
  e.style.display = "none";
}
</script>


<style>
  #modal-wrapper
  {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    z-index: 250;
  }
  #recordDiv
  {
    display: block;
    background: white;
    z-index: 1;
    border: 3px solid #7092a6;
    border-radius: 8px;
    position: fixed;
    width: 25vw;
    height: 10vw;
    top: 16vw;
    left: 40vw;
  }
  #recordParagraph
  {
    position: relative;
    font-size: 1vw;
    color: #7092a6;
    font-weight: bold;
    text-align: center;
  }
  #btnDiv
  {
    position: relative;
    padding: 0.15vw 1.5vw 1.5vw 1.5vw;
  }
  .recordConfirm
  {
    width: 8vw;
    margin-right: auto;
    margin-left: auto;
    height: 2vw;
    display: block;
    background: #7092a6;
    background-image: linear-gradient(0deg, #557C93 0%, #7092a6 30%);
    color: white;
    font-family: Lato;
    font-size: 1vw;
    font-weight: 700;
    letter-spacing: 1px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: .5vw;
    text-align: center;
  }
  .recordConfirm:focus,
  .recordConfirm:hover{
    background: white;
    border: 2px solid #7092a6;
    color: #7092a6;
  }
  .recordConfirm:focus
  {
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none;
  }
</style>
<div id="modal-wrapper">
  <div id="recordDiv">
    <p id="recordParagraph">WARNING: Google speech recognition services have failed, probably due to a network issue.  Voice input will be disabled for the rest of this session.  Please use your keyboard to communicate with the patient.</p>
    <div id="btnDiv">
      <button id="MessageBoxOK" class="recordConfirm" on:click={close_messagebox} aria-label="Ok" data-cy="OkButton">OK</button>
    </div>
  </div>
 </div>