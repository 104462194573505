<script>
import './SummaryTableCSS.svelte';
import SummaryTableHeader from'./SummaryTableHeader.svelte';
import SummaryTableRow from'./SummaryTableRow.svelte';
import SummaryTableLastRow from './SummaryTableLastRow.svelte';
export let criterion_severity;
export let criterion_sx;
</script>

<table class="summary_table">
    <SummaryTableHeader text_column1={"PTSD Totals"} text_column2={"Past Month"}/>
    <SummaryTableRow text1={"Totals"} text2={"Total Sev"} text3={"Total # Sx"} isHeader={true}/>
    <SummaryTableLastRow bgcolor={"#C0C0C0"} text1={"Sum of subtotals (B+C+D+E)"} text4={criterion_severity} text6={criterion_sx}/>             
</table>
