import {logReChat,serializeAllCards,setCurrentUICard,setTutorialCard} from "../ReChat.svelte"
import { show_coach_alert, hide_coach_alert } from '../CoachButton.svelte';
import {post_event_log} from "../server_api.js";
import { get } from 'svelte/store';
import { environment } from "../stores.js";

export default class Conversation
{
  constructor()
  {
    this.conversants;
    this.stashedPartialResults = [];
    this.previousFollowupGroups = [];
  }
  
  static matchReturnValues = {
    moreInputNeeded: -1,
    noMatchFound: 0,
    matchFound: 1
  }
  
  startConversation(conversant)
  {
    this.conversants = conversant;
  }


  runCard(card,conversantIndex,input,matchDistance)
  {
    if(!card.isUnparseable)
    {
      for(let i = 0; i!=this.conversants.length; ++i)
      {
        this.conversants[i].rechat.followupGroupsToggle(this.previousFollowupGroups,false); 
      }
    }

    let event_info = this.conversants[conversantIndex].rechat.runNextCard(card);

    if(!card.isUnparseable)
    {
      for(let i = 0; i!=this.conversants.length; ++i)
      {
        this.conversants[i].rechat.followupGroupsToggle(card.followupGroups,true); 
        
      }
      this.previousFollowupGroups = card.followupGroups;
    }
    for(let i = 0; i!=this.conversants.length; ++i)
    {
      if(i!==conversantIndex)
      {
        this.conversants[i].rechat.externalGroups(card.activateGroups,card.deactivateGroups)
      }
    }
    if(get(environment) != "production")
      event_info.user_input = input;
    else
      event_info.user_input = "USER OPTED OUT OF COLLECTION"
    event_info.matching_distance = matchDistance;
    event_info.active_cards = serializeAllCards(); // needs to be done after all external groups get updated
    post_event_log("CARD_EXECUTE",event_info);
  }


  checkForSpecialTags(cardToRun)
  {
    for(let i=0; i!=cardToRun.tags.length; ++i)
    {        
      if(cardToRun.tags[i] === "alert")
        show_coach_alert(cardToRun);
      else if(cardToRun.tags[i] === "cancel_alert")
        hide_coach_alert();
      else if(cardToRun.tags[i] === "context_sensitive")
        setCurrentUICard(cardToRun);
      else if(cardToRun.tags[i] === "tutorial")
        setTutorialCard(cardToRun);
    }
  }

  // return correct enum if we need more input for partial match
  matchInput(input,allowPartialMatch)
  {
    let results = [];
    if(this.stashedPartialResults.length && allowPartialMatch)
    {
      for(let i=0; i!=this.stashedPartialResults.length; ++i)
      {
        let res = this.conversants[this.stashedPartialResults[i].conversantIndex].rechat.matchInput(input,this.stashedPartialResults[i].partialMatch);
        if(res.card)
        {
          results.push({conversantIndex:this.stashedPartialResults[i].conversantIndex,result:res});
        }  
      }
    }
    else
    {
      for(let i=0; i!=this.conversants.length; ++i)
      {      
        let res;
        if(allowPartialMatch)
          res = this.conversants[i].rechat.matchInput(input,{startIndex:0});
        else
          res = this.conversants[i].rechat.matchInput(input);
        if(res.card)
        {
          results.push({conversantIndex:i,result:res});
        } 
      }
    }
    console.log("results");
    console.log(results);
    this.stashedPartialResults.length = 0;
    if(results.length == 0)
    {
      return { matchValue: Conversation.matchReturnValues.noMatchFound };
    }
    let needMoreInput = false;
    let cardToRun;
    let bestMatchDistance = 100000;
    let conversantIndex;
    for(let i=0; i!=results.length; ++i)
    {
      if(results[i].result.partialMatch && allowPartialMatch)
        {
          needMoreInput = true;
          let stashPartialMatch = {
                conversantIndex: results[i].conversantIndex,
                partialMatch: {
                startIndex: results[i].result.partialStartIndex,
                card: results[i].result.card,
                trigger: results[i].result.trigger,
                combinedInput:results[i].result.combinedInput
            }
          };
          this.stashedPartialResults.push(stashPartialMatch);
        }
      else
      {
        needMoreInput = false;
        if(results[i].result.distance < bestMatchDistance)
        {
          bestMatchDistance = results[i].result.distance;
          conversantIndex = results[i].conversantIndex;
          cardToRun = results[i].result.card;
        }
      }
    }
    if(needMoreInput)
    {
      return {matchValue: Conversation.matchReturnValues.moreInputNeeded };
    }
    this.stashedPartialResults.length = 0;
    if( cardToRun && cardToRun.tags)
      this.checkForSpecialTags(cardToRun,conversantIndex);
    this.runCard(cardToRun,conversantIndex,input,bestMatchDistance);
    logReChat("\n### INPUT RECEIVED ###");
    logReChat("**INPUT:** ("+this.conversants[conversantIndex].rechat.getPerson()+") " + cardToRun.dialogue);
    return { matchValue: Conversation.matchReturnValues.matchFound, card:cardToRun, conversantIndex:conversantIndex };
  }

  matchOutputDialogue(card,lastConversantIndex,input)
  {
    let results = [];
    for(let i=0; i!=this.conversants.length; ++i)
    {
      let res = this.conversants[i].rechat.matchInput(card.dialogue);
      if(res.card && !res.card.isUnparseable && !res.partialMatch)
      {
        if(lastConversantIndex != i || (lastConversantIndex === i && res.distance == 0 && !this.conversants[lastConversantIndex].noSelfMatch))
          results.push({conversantIndex:i,result:res});
      }    
    }
    let conversantIndex;
    let cardToRun;
    let matchDistance;
    for(let i=0; i!=results.length; ++i)
    {
      if(!results[i].result.partialMatch)
      {
          conversantIndex = results[i].conversantIndex;
          cardToRun = results[i].result.card;
          matchDistance = results[i].result.distance;
          break;
      }
    }
    console.log(cardToRun);
    if(cardToRun)
    {
      if( cardToRun && cardToRun.tags)
        this.checkForSpecialTags(cardToRun,conversantIndex);
      this.runCard(cardToRun,conversantIndex,input,matchDistance);

      logReChat("\n### INPUT RECEIVED ###"); 
      logReChat("**INPUT:** ("+this.conversants[conversantIndex].rechat.getPerson()+") " + cardToRun.dialogue);
      return {matchValue: Conversation.matchReturnValues.matchFound, card:cardToRun, conversantIndex:conversantIndex };
    }
    else 
      return {matchValue: Conversation.matchReturnValues.noMatchFound};
  }
}