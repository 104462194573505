async function getBrowser(){
  var ua= navigator.userAgent, tem, 
  M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+.+?(\s|$))/i) || [];
  if(/trident/i.test(M[1])){
      tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE '+(tem[1] || '');
  }
  if(M[1]=== 'Chrome'){
      tem= ua.match(/\b(OPR|Edg)\/(\d+)/);
      if(tem!= null)
        return tem.slice(1).join(' ').replace('OPR', 'Opera');
      else if(navigator.brave && await navigator.brave.isBrave())
        return 'Brave ' + M[0];
  }
  M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
  if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
  return M.join(' ');
};

function getOS()
{
  var match = navigator.userAgent.match(/(Windows|MacOS|Macintosh|UNIX|Linux|X11)(.+?(?=\)))/i) || [navigator.userAgent];
  return match[0];
}

module.exports = {
  getBrowser:getBrowser,
  getOS:getOS
}