<script context="module">
export function fwd_arrow_animation(on)
{
    let element = document.getElementById("ctx_advance");
    if(element==null)
        return;
    if(on)
    {
        element.style.display = "block";
        element.style.pointerEvents = "none";
        element.classList.add("tutorialAnim")
    }
    else
    {
        element.style.display = "none";
        element.style.pointerEvents = "all";
        element.classList.remove("tutorialAnim");
    }
}
</script>
<script>
    
    import { createEventDispatcher, onMount, onDestroy } from 'svelte';
    import pageFile from "./pages.json";
    import evaluationFile from "./evaluation.json";
    import { get_session, get_user_item_progress } from "./server_api.js"
    import { debug_mode, page, dirtyServerConnection, input_disabled_dialogue, input_disabled_all, context_toggle, item_page_finished_mount, incorrectScoreValues, errorConnectionMessage } from './stores.js';
    import { get_criteria_for_item } from './item_translation_util.js'
    import ContextController from './ContextController.svelte';
    import {scoring_page} from "./utils.svelte"
    import {progress_level} from "./stores.js"

    const dispatch = createEventDispatcher();
    let numPages = Object.keys(pageFile).length;
    let buttonNodeList;
    let backBtn;
    let fwBtn;
    let evalBtn;
    let navigating = false;
    let navigateAnimBackward = false;
    let navigateAnimForward = false;
    let serverErrorElement;
    let scoringErrorElement
    let navigationButtons;
    let instructionsBtn;
    let critABtn;
    let critBBtn;
    let critCBtn;
    let critDBtn;
    let critEBtn;
    let critFBtn;
    let critGBtn;
    let globalBtn;
    let dissBtn;
    let summaryBtn;
    let instructionDiv;
    let critADiv;
    let critBDiv;
    let critCDiv;
    let critDDiv;
    let critEDiv;
    let critFDiv;
    let critGDiv;
    let globalDiv;
    let dissDiv;
    let summaryDiv;
    let summaryDivContainer;
    let currentCritDiv;
    let currentFocusItem;
    let nextFocusItem;
    let focusBtn;

    export let prePageChangedFunc;
    export let postPageChangeFunc;
    export let courseCompleted;
    function backToInstructions()
    {
        $page.itemNum = -3;
    }

    $: {
        if(fwBtn)
        {    
            fwBtn.disabled = $input_disabled_dialogue && !$debug_mode && scoring_page() || $input_disabled_all && $page.itemNum >= -3 || $context_toggle;
            fwBtn.style.cursor = $input_disabled_dialogue && !$debug_mode && scoring_page() || $input_disabled_all && $page.itemNum >= -3 || $context_toggle ? "unset" : "pointer";
        }
        if(backBtn)
        {
            backBtn.disabled = $input_disabled_dialogue && !$debug_mode && scoring_page() || $input_disabled_all && $page.itemNum >= -2 || $context_toggle;
            backBtn.style.cursor = $input_disabled_dialogue && !$debug_mode && scoring_page() || $input_disabled_all && $page.itemNum >= -2 || $context_toggle ? "unset" : "pointer";
        }   
        if(instructionsBtn)
        {
            instructionsBtn.disabled = $input_disabled_dialogue && !$debug_mode && scoring_page() || $input_disabled_all && $page.itemNum >= -3 || $context_toggle;      
            instructionsBtn.style.cursor = $input_disabled_dialogue && !$debug_mode && scoring_page() || $input_disabled_all && $page.itemNum >= -3 || $context_toggle ? "unset" : "pointer";    
        }            
        if(critABtn)
        {
            critABtn.disabled = $input_disabled_dialogue && !$debug_mode && scoring_page() || ($progress_level <0 || $input_disabled_all) || $context_toggle;
            critABtn.style.cursor = $input_disabled_dialogue && !$debug_mode && scoring_page() || ($progress_level <0 || $input_disabled_all) || $context_toggle ? "unset" : "pointer";
        }            
        if(critBBtn)
        {
            critBBtn.disabled = $input_disabled_dialogue && !$debug_mode && scoring_page() || $progress_level <1 || $input_disabled_all || $context_toggle;  
            critBBtn.style.cursor = $input_disabled_dialogue && !$debug_mode && scoring_page() || $progress_level <1 || $input_disabled_all || $context_toggle ? "unset" : "pointer";
        }            
        if(critCBtn)
        {
            critCBtn.disabled = $input_disabled_dialogue && !$debug_mode && scoring_page() || $progress_level <6 || $input_disabled_all || $context_toggle;   
            critCBtn.style.cursor = $input_disabled_dialogue && !$debug_mode && scoring_page() || $progress_level <6 || $input_disabled_all || $context_toggle ? "unset" : "pointer";
        }            
        if(critDBtn)
        {
            critDBtn.disabled = $input_disabled_dialogue && !$debug_mode && scoring_page() || $progress_level <8 || $input_disabled_all || $context_toggle;
            critDBtn.style.cursor = $input_disabled_dialogue && !$debug_mode && scoring_page() || $progress_level <8 || $input_disabled_all || $context_toggle ? "unset" : "pointer";
        }
            
        if(critEBtn)
        {
            critEBtn.disabled = $input_disabled_dialogue && !$debug_mode && scoring_page() || $progress_level <15 || $input_disabled_all || $context_toggle;
            critEBtn.style.cursor = $input_disabled_dialogue && !$debug_mode && scoring_page() || $progress_level <15 || $input_disabled_all || $context_toggle ? "unset" : "pointer";
        }            
        if(critFBtn)
        {
            critFBtn.disabled = $input_disabled_dialogue && !$debug_mode && scoring_page() || $progress_level <21 || $input_disabled_all || $context_toggle;
            critFBtn.style.cursor = $input_disabled_dialogue && !$debug_mode && scoring_page() || $progress_level <21 || $input_disabled_all || $context_toggle ? "unset" : "pointer";
        }            
        if(critGBtn)
        {
            critGBtn.disabled = $input_disabled_dialogue && !$debug_mode && scoring_page() || $progress_level <23 || $input_disabled_all || $context_toggle;
            critGBtn.style.cursor = $input_disabled_dialogue && !$debug_mode && scoring_page() || $progress_level <23 || $input_disabled_all || $context_toggle ? "unset" : "pointer";
        }            
        if(globalBtn)
        {
            globalBtn.disabled = $input_disabled_dialogue && !$debug_mode && scoring_page() || $progress_level <26 || $input_disabled_all || $context_toggle;
            globalBtn.style.cursor = $input_disabled_dialogue && !$debug_mode && scoring_page() || $progress_level <26 || $input_disabled_all || $context_toggle ? "unset" : "pointer";
        }         
        if(dissBtn)
        {
            dissBtn.disabled = $input_disabled_dialogue && !$debug_mode && scoring_page() || $progress_level <29 || $input_disabled_all || $context_toggle;
            dissBtn.style.cursor = $input_disabled_dialogue && !$debug_mode && scoring_page() || $progress_level <29 || $input_disabled_all || $context_toggle? "unset" : "pointer";
        }            
        if(summaryBtn)
        {
            summaryBtn.disabled = $input_disabled_dialogue && !$debug_mode && $page.itemNum >= 31 || $context_toggle ;
            summaryBtn.style.cursor = $input_disabled_dialogue && !$debug_mode && $page.itemNum >= 31 || $context_toggle ? "unset" : "pointer";
        }                        
    }
    
    $: {
        if(serverErrorElement)
        {
            if($dirtyServerConnection == true)
                serverErrorElement.innerHTML = $errorConnectionMessage.length ? $errorConnectionMessage : "SERVER NOT RESPONDING - TRY RELOADING PAGE";
            else
                serverErrorElement.innerHTML = "";
        }
    }

    $: {
        if(scoringErrorElement)
        {
            if($incorrectScoreValues && $incorrectScoreValues.length)
                scoringErrorElement.innerHTML = "Wrong Scores: " + $incorrectScoreValues;
            else
                scoringErrorElement.innerHTML = "";
        }
    }
    async function waitForPageUpdates(postUpdateFunctions)
    {
        let failSafe = true;
        setTimeout(()=>{failSafe = false;},3000)// 3 seconds
        while(!$item_page_finished_mount && failSafe && scoring_page())
        {
            await new Promise(r => setTimeout(r, 100));
        }
        for(let i of postUpdateFunctions)
        {
            i();
        }
    }
    async function updateProgressLevel()
    {
        let progress = await get_user_item_progress(get_session());  
        if(progress != null && progress != undefined && $page.itemNum >= 0)
        {
            $progress_level = Object.keys(progress).length-1;                
        }    
    }
    async function navigate(inItemNum, navForward)
    {
        navigating = true;
        let pageChanged = await prePageChangedFunc(inItemNum,false);  // false since we are navigating via a fwd or back btn
        if(pageChanged)
        {
            if(navForward)
                navigateAnimForward = true;
            else
                navigateAnimBackward = true;
            $item_page_finished_mount = false;
            $page.itemNum = inItemNum;
            $page.criterion = get_criteria_for_item($page.itemNum).criteria;
            waitForPageUpdates([updateProgressLevel,postPageChangeFunc.bind(null,navForward)])
            setTimeout(function(){
                if(navForward)
                    navigateAnimForward = false;
                else
                    navigateAnimBackward = false;
            }, 250);
            if($page.itemNum == 31)
            {
                summaryDiv.style.flex = 1.25;
                summaryDiv.style.overflow = "unset";
                summaryDiv.style.visibility = "visible";
            }
        }
        navigating = false;
        return pageChanged;
    }

    function openNavMenu(event, item, type)
    {
        disableButtons();
        if($page.itemNum == -3)
            fwd_arrow_animation(false);
        let focusItem = document.querySelector("#" + item.id + " > div:nth-child(1)").firstChild;

        let navClosing = false;
        let openAnother = false;
        if(currentCritDiv != null)
        {
            navClosing = true;
            openAnother = currentCritDiv != item;
            closeNavMenu();
        }            
        if(navClosing)
        {
            if(openAnother)
                setTimeout(function(){
                    item.style.visibility = "visible";
                    item.style.maxHeight = "17.5vw";
                    currentCritDiv = item;
                    nextFocusItem = focusItem;
                    if(type == "keyboard")
                    {                                
                        document.activeElement.blur();
                        focusItem.focus();                             
                    }      
                }, 250);
        }   
        else    
        {
            item.style.visibility = "visible";
            item.style.maxHeight = "17.5vw";
            currentCritDiv = item;         
            nextFocusItem = focusItem;
            if(type == "keyboard")
            {                                
                document.activeElement.blur();
                focusItem.focus();                             
            }            
        }
    }

    function closeNavMenu()
    {
        if(currentCritDiv != null)
        {
            currentCritDiv.style.maxHeight = "0vw";
            currentCritDiv.style.visibility = "hidden";
            currentCritDiv = null;   
        }        
    }

    async function disableButtons()
    {
        let progress = await get_user_item_progress(get_session());
        let length;
        if(progress == null || progress == undefined)
            length = 1;
        else
            length = Object.keys(progress).length;
        let i = 3;
        for(i; i<length+3; i++)
        {
            buttonNodeList[i].disabled = false;
            buttonNodeList[i].style.cursor = "pointer";            
        }
        for(i; i<buttonNodeList.length; i++)
        {
            buttonNodeList[i].disabled = true;
            buttonNodeList[i].style.cursor = "unset";            
        }
    }

    async function navigateTo(inItemNum)
    {        
        closeNavMenu();
        if(navigating)
            return;

        navigating = true;
        let pageChanged = await prePageChangedFunc(inItemNum,true); // true since we are navigating directly to an item
        if(pageChanged)
        {
            $page.itemNum = inItemNum;
            $page.criterion = get_criteria_for_item($page.itemNum).criteria;     
            let progress = await get_user_item_progress(get_session());
            if(progress)
            {
                length = Object.keys(progress).length;
                if(length != inItemNum+1)
                    postPageChangeFunc(false);
                else
                    postPageChangeFunc(true);
            }       
            else
            {
                if($page.itemNum < inItemNum)
                    postPageChangeFunc(true);                 
                else
                    postPageChangeFunc(false);                 
            }
        }
        if(inItemNum+1 >= numPages)
        {
            toggleWidth(true);
        }
        navigating = false;
        return pageChanged;
    }
    async function navBackward()
    {              
        if(navigating)
            return;
        if($page.itemNum != -3)
        {            
            await navigate($page.itemNum-1, false);   
        }
        if($page.itemNum <= numPages)
        {
            toggleWidth(false);
        }
    }
    
    async function navForward()
    {           
        if(navigating)
            return;
        if($page.itemNum == -3)
            fwd_arrow_animation(false);
        if($page.itemNum != numPages)
        {
            await navigate($page.itemNum+1, true);
        }
        if($page.itemNum+1 >= numPages)
        {
            toggleWidth(true);
        }
    }

    async function goToEval()
    {
        await courseCompleted();     
        dispatch("showEval");
    }

    function closeNavEvent(event)
    {
        if (event.target.closest(".criterionContainer")) return;
        if (event.target.closest(".largeCriterionContainer")) return;
        closeNavMenu();
    }

    // function focusExtraElements(e,targetBtn)
    // {
    //     if(e.target == backBtn)
    //     {
    //         if(e.relatedTarget == nextFocusItem)
    //             targetBtn.focus();
    //     }
    //     else
    //     {
    //         if(e.relatedTarget && currentFocusItem)
    //             currentFocusItem.focus();
    //     }
    // }

    function focusNextElement(e, prevElement, currElement, nextElement)
    {        
        if(e.relatedTarget == prevElement)
            currElement.focus();
        else if(e.relatedTarget == currElement || e.relatedTarget == fwBtn)
        {
            currentFocusItem = e.srcElement;

            if(nextElement && !nextElement.disabled)
            {
                if(nextElement == summaryBtn && summaryDiv.style.visibility != "visible")
                    fwBtn.focus();
                else
                    nextElement.focus();
            }                
            else
            {
                if(fwBtn)
                    fwBtn.focus();
                else
                    evalBtn.focus();
            }                
        }             
    }

    function focusNavItem(e, nextElement, prevElement, targetDiv, prevDiv)
    {        
        if(!e.relatedTarget)
            return;
        if(e.relatedTarget == nextElement || e.relatedTarget == fwBtn)
        {
            if(targetDiv.style.visibility == "visible")
                nextFocusItem.focus();
            else
            {
                if(nextElement && !nextElement.disabled)
                    nextElement.focus();
                else
                    return;
            }                
        }            
        else if(prevDiv && prevDiv.style.visibility == "visible")
            currentFocusItem.focus();      
        else if(e.relatedTarget == nextFocusItem)            
        {                               
            if(!nextElement.disabled)
            {
                if(e.target == critABtn && (targetDiv.style.visibility == "visible" || prevDiv.style.visibility == "visible"))
                    prevElement.focus();
                else
                    nextElement.focus();
            }                
            else
                prevElement.focus();
        }            
        else if(e.relatedTarget != nextElement && e.relatedTarget != fwBtn)
        {
            prevElement.focus();
        }
            
    }
    
    function toggleWidth(widthChange)
    {
        if(widthChange)
        {
            navigationButtons.style.width = "68%";
            document.getElementById("ctx_navigate").style.width = "68%";
            document.getElementById("criterionEDiv").style.marginLeft ="-11vw";
            document.getElementById("criterionEDiv").style.marginRight ="-11vw";
            document.getElementById("criterionGDiv").style.marginLeft ="-10vw";
            document.getElementById("criterionGDiv").style.marginRight ="-10vw";
        }
        else
        {
            navigationButtons.style.width = "80%";
            document.getElementById("ctx_navigate").style.width = "80%";
        }
    }

    function handleBlur(event)
    {
        var prevTarget = document.getElementById("sevInfoDiv");
        var sevTarget = document.getElementsByClassName("severityBtn");

        if(event.relatedTarget == prevTarget)
            sevTarget[4].focus();
    }

    onMount( async function() { 
        $progress_level = -1;
        await updateProgressLevel();
        document.addEventListener("click", closeNavEvent);
        buttonNodeList = document.querySelectorAll(".navBtn");
        })

    onDestroy(function() {
        document.removeEventListener("click", closeNavEvent);
    })

</script>

<style lang="less">
button:focus > .btn_content, button:focus > .btn_contentNav  {
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none; // removes default focus outline
}

/* Removing default outline only after we've added our custom one */
button:focus,
.btn_content:focus,
.btn_contentNav:focus {
    outline: none;
}

.btn_content, .btn_contentNav
{
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  pointer-events: all;
  font-size: 1vw;
}

button:focus > .btn_contentNav  {
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none; // removes default focus outline
}
.btn_content
{
  height: 100%;
}
#btn_instructions
{
    display: inline-block;  
    height: 60%;
    vertical-align: middle;
    line-height: normal;
    position: relative;
}
#btn_contentEval
{
    display: inline-block;  
    height: 40%;
    vertical-align: middle;
    line-height: normal;
    position: relative;
}

#forwardButton,
#backwardButton
{
    background-color: rgba(0, 0, 0, 0);
    background-size: 1vw;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 2vw;
    width: 2vw;
    border: none;
    position: absolute;
    bottom: 0vw;
    pointer-events: none;
    padding: 0;
}
#forwardButton {background-image: url(/assets/ArrowRight.png);right: 0%;}

#backwardButton {background-image: url(/assets/ArrowLeft.png);left: 0%;}

#forwardButton:disabled,#backwardButton:disabled {filter: opacity(.5);}

#forwardButton:disabled > .btn_content,#backwardButton:disabled > .btn_content {pointer-events: none;}

#forwardButton:active {background-image: url(/assets/ArrowRight_glow.png);}

#backwardButton:active {background-image: url(/assets/ArrowLeft_glow.png);}

#forwardButton:focus > .btn_content,
#backwardButton:focus > .btn_content
  {
    box-shadow: inset 0 0 0 0px #fff, 0 0 0 2px #023b5a;
    border-radius: 2px;
    outline: none; // removes default focus outline
}

#forwardButton:focus, .btn_content:focus,#backwardButton:focus, .btn_content:focus {outline:none;}

#forwardButton:hover > .btn_content,#backwardButton:hover > .btn_content { box-shadow: 0 0 0 2px #023b5a; border-radius: 2px;}

.navigationBar
{
    width: 100%;
    height: 2vw;
    position: relative;
    margin-bottom: 1%;
    display: flex;
}
#instructionsBtn
{
    width: 10vw;
    height: 2vw;
    border: none;
    position: absolute;
    left: 0%;
    bottom: 0vw;
    /* margin-block-end: 2vw; */
    background: #023b5a;
    color: white;
    letter-spacing: 0.5px;
    font-family: Lato;
    font-size: .8vw;
    font-weight: 900;
    border-radius: 8px;
    cursor: pointer;
}
#criterionBDiv, #criterionCDiv, #criterionDDiv, #criterionEDiv, #criterionFDiv, #criterionGDiv, 
#criterionADiv, #instructionBtnDiv, #globalDivContainer, #dissDivContainer, #summaryDivContainer
{
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    height: auto;
    max-height: 0vw;
    display: block;
    width: auto;
    visibility: hidden;
}
#criterionADiv
{
    margin-left: -3vw;
    margin-right: -3vw;
}
#criterionBDiv
{
    margin-left: -8vw;
    margin-right: -8vw;
}
#criterionCDiv
{
    margin-left: -12vw;
    margin-right: -12vw;
}
#criterionDDiv
{
    margin-left: -14vw;
    margin-right: -14vw;
}
#criterionEDiv, #criterionFDiv
{
    margin-left: -11vw;
    margin-right: -11vw;
}
#criterionGDiv
{
    margin-left: -10vw;
    margin-right: -10vw;
}
#instructionBtnDiv
{
    margin-left: -4vw;
    margin-right: -4vw;
}
#globalDivContainer, #dissDivContainer
{
    margin-left: -4vw;
    margin-right: -4vw;
}
#summaryDivContainer
{
    margin-left: -1vw;
    margin-right: -1vw;
}

.btnDiv
{
    display: flex;
    width: 75%;
    margin: 0 auto;
    height: 2vw;
}

.largeBtnDiv
{
    display: flex;
    width: 100%;
    height: 2vw;
}
#evalBtn
{
    width: 10vw;
    height: 3vw;
    border: none;
    position: absolute;
    right: 0%;
    bottom: 0vw;
    /* margin-block-end: 2vw; */
    background: #023b5a;
    color: white;
    letter-spacing: 0.5px;
    font-family: Lato;
    font-size: .75vw;
    font-weight: 900;
    border-radius: 8px;
    cursor: pointer;
}
#evalBtn:hover,
#evalBtn:focus, 
#instructionsBtn:focus,
#instructionsBtn:hover,
.menuOptionBtns:hover,
.menuOptionBtns:focus {
    background: white;
    border: 2px solid #7092a6;
    color: #7092a6;
}

#evalBtn:hover, #instructionsBtn:hover > .menuIcons
{
    fill: #7092a6;
}
#evalBtn:focus, #instructionsBtn:focus > .menuIcons
{
    fill: #7092a6;
}
.menuIcons
{
    fill: white;
    display: inline-block;
    height: 40%;
    width: 8%;
    margin-left: .25vw;
    top: 0.3vw;
    position: relative;
}

#navBtns
{
    position: absolute;
    width: 80%;
    bottom: 0vw;
    left: 6vw;
    display: flex;
    justify-content: space-evenly;
}

.criterionContainer
{
    display: inline-block;
    position: relative;
    width: 75%;
    margin: 0 auto;
}

.largeCriterionContainer
{
    display: inline-block;
    position: relative;
    margin: 0 auto;
}

.criterionContainer:disabled, .largeCriterionContainer:disabled
{
    opacity: .5;
}

.critDiv
{
    display: inline-block;
    width: 4vw;
    align-self: flex-end;
    flex: .5;
}

.globalDiv, .instructionDiv, .dissDiv
{
    display: inline-block;
    width: 7vw;
    align-self: flex-end;
    flex: 1;
}
.summaryDiv
{
    display: inline-block;
    width: 4vw;
    align-self: flex-end;
    transition: all 100ms linear;
    flex: 0.0001;
    overflow: hidden;
    visibility: hidden;
}

.navBtn, .navBtnSummary
{
    font-size: 0;
    width: 100%;
    height: 100%;
    position: relative;
    border-width: 0;
    background: #023b5a;
    cursor: pointer;    
    pointer-events: all;
    white-space: nowrap;
}

.navBtn:focus, .navBtnSummary:focus
{
    padding: 0;
    border: 3px solid white;
    outline: 1px solid black;
}
.navBtn:disabled
{
    background: gray;
    pointer-events: none;
    cursor: auto;
}
.btnLabel:hover
{
  text-decoration: underline;
  cursor: pointer;
}

.critItem
{
    display: flex;
    height: 2vw;
    border-bottom: 1px solid #E2E2E2;
    position: relative;
}

.navigationItem
{
    display: block;
    border-bottom: 1px solid #E2E2E2;
    position: relative;
    padding-top: 0.75vw;
    padding-bottom: 0.75vw;
}
.btnLabel
{
    font-size: 1vw;
    display: block;
    cursor: unset;
    text-align: left;
    margin-left: 10%;
    color: #E2E2E2;
}
.btnLabelSummary
{
    font-size: 1vw;
    display: block;
    cursor: pointer;
    color: #E2E2E2;
    text-align: center;
    margin: 0 auto;
}

.animForward
{
    animation: scaleUpFade 250ms linear;  
    -webkit-animation: scaleUpFade 250ms linear;  
}
.navBtnContainer
{
    position: relative;
    display: inline-block;
    width: 90%;
}
[class*="navBtn-"]
{
    display: inline-block;
    width: 5%;
    height: 100%;
}

@keyframes scaleUpFade
{
    0% {transform: scale(1); filter: blur(0px); -webkit-transform: scale(1)}
    35% {transform: scale(2); filter: blur(0px); -webkit-transform: scale(2)}
    75% {transform: scale(1.5); filter: blur(2px); -webkit-transform: scale(1.5)}
    100%{transform: scale(1); filter: blur(5px); -webkit-transform: scale(1)}
}
@-webkit-keyframes scaleUpFade {
    0% {transform: scale(1); filter: blur(0px); -webkit-transform: scale(1)}
    35% {transform: scale(2); filter: blur(0px); -webkit-transform: scale(2)}
    75% {transform: scale(1.5); filter: blur(2px); -webkit-transform: scale(1.5)}
    100%{transform: scale(1); filter: blur(5px); -webkit-transform: scale(1)}
}
/* @-webkit-keyframes scaleUpFade {
    0% {-webkit-transform: scale(1)}
    25% {-webkit-transform: scale(1.25)}
    50% {filter: blur(5px); -webkit-transform: scale(2)}
    100%{filter: blur(0px); -webkit-transform: scale(1)}

} */

#serverErrorParagraph, #scoringErrorParagraph
{

    position: absolute;
    right: 4%;
    bottom: -.5vw;
    font-size: 1vw;
    font-weight: 1000;
    letter-spacing: 1px;
    color: red;
    margin-block-start: 0;
}


</style>

<div class="navigationBar">
<div class="navBtn-Back">
{#if ($debug_mode || $page.itemNum > -3) && $page.itemNum != -3}
    <ContextController divID={"ctx_return"} data-cy="btnBack"/>
    {#if navigateAnimBackward}
        <button class="animForward" bind:this={backBtn} on:blur={(event)=>handleBlur(event)} on:click|stopPropagation={navBackward} id="backwardButton" data-cy="btnBack" aria-label="Backwards"><span class="btn_content" tabindex="-1"></span></button>
    {:else}
        <button bind:this={backBtn} on:blur={(event)=>handleBlur(event)} on:click|stopPropagation={navBackward} id="backwardButton" data-cy="btnBack" aria-label="Backwards"><span class="btn_content" tabindex="-1"></span></button>
    {/if}    
{/if}
</div>
<div id="navBtnContainer">
    <ContextController divID={"ctx_navigate"}/>
    <div bind:this={navigationButtons} class="navigationButtons" id="navBtns">
        <div class="instructionDiv">        
            <div id="instructionBtnDiv" bind:this={instructionDiv}>
                <div class="critItem">
                    <button onmouseup="this.blur();" class="navBtn" on:blur={(event)=>focusNextElement(event, backBtn, instructionsBtn, critABtn)} on:click={(event)=>navigateTo(-3)} aria-label="Administration navigation"><label 
                                class="btnLabel" aria-hidden="true">Instructions - Administration</label></button>
                </div>
                <div class="critItem">
                    <button onmouseup="this.blur();" class="navBtn" on:blur={(event)=>focusNextElement(event,backBtn, instructionsBtn, critABtn)} on:click={(event)=>navigateTo(-2)} aria-label="Scoring part 1 navigation"><label 
                                class="btnLabel" aria-hidden="true">Instructions - Scoring pt. 1</label></button>
                </div>
                <div class="critItem">
                    <button onmouseup="this.blur();" class="navBtn" on:blur={(event)=>focusNextElement(event,backBtn, instructionsBtn, critABtn)} on:click={(event)=>navigateTo(-1)} aria-label="Scoring part 2 navigation"><label 
                                class="btnLabel" aria-hidden="true">Instructions - Scoring pt. 2</label></button>
                </div>                        
            </div>
            <div class="largeBtnDiv">
                <button bind:this={instructionsBtn} onmouseup="this.blur();" class="largeCriterionContainer" on:blur={(event)=>focusNavItem(event, critABtn, backBtn, instructionDiv, null)} on:mousedown={(event)=>openNavMenu(event,instructionDiv, "mouse")} on:keypress={(event)=>openNavMenu(event,instructionDiv, "keyboard")}><span class="btn_contentNav" tabindex="-1" aria-label="Instructions Navigation Menu">Instructions</span></button>
            </div>
        </div>
        <div class="critDiv">        
            <div id="criterionADiv" bind:this={critADiv}>
                {#each evaluationFile.criterions as criterion}
                    {#if criterion.id =="A"}                    
                        {#each criterion.criterions as criterions, i}
                            <div class="critItem">                               
                            <button onmouseup="this.blur();" on:blur={(event)=>focusNextElement(event, instructionsBtn, critABtn, critBBtn)} class="navBtn" on:click={(event)=>navigateTo(criterions.itemNum)} aria-label="Starting page {criterions.title} navigation"><label 
                                    class="btnLabel" aria-hidden="true">{criterions.title}</label></button>
                            </div>                       
                        {/each}
                    {/if}
                {/each}
            </div>
            <div class="btnDiv">
                <button bind:this={critABtn} disabled="true" onmouseup="this.blur();" class="criterionContainer" on:blur={(event)=>focusNavItem(event, critBBtn, instructionsBtn, critADiv, instructionDiv)} on:mousedown={(event)=>openNavMenu(event,critADiv, "mouse")} on:keypress={(event)=>openNavMenu(event,critADiv, "keyboard")} ><span class="btn_contentNav" tabindex="-1" aria-label="Criterion A Navigation Menu">A</span></button>
            </div>
        </div>
        <div class="critDiv">        
            <div id="criterionBDiv" bind:this={critBDiv}>
                {#each evaluationFile.criterions as criterion}                
                    {#if criterion.id =="B"}                    
                        {#each criterion.criterions as criterions, i}
                            <div class="critItem">
                                <button onmouseup="this.blur();" on:blur={(event)=>focusNextElement(event,critABtn, critBBtn, critCBtn)} class="navBtn" on:click={(event)=>navigateTo(criterions.itemNum)} aria-label="Page {criterions.title} navigation"><label 
                                    class="btnLabel" aria-hidden="true">{criterions.title}</label></button>                             
                            </div>                       
                        {/each}
                    {/if}
                {/each}
            </div>
            <div class="btnDiv">
                <button bind:this={critBBtn} disabled="true" onmouseup="this.blur();" class="criterionContainer" on:blur={(event)=>focusNavItem(event, critCBtn, critABtn, critBDiv, critADiv)} on:mousedown={(event)=>openNavMenu(event,critBDiv, "mouse")} on:keypress={(event)=>openNavMenu(event,critBDiv, "keyboard")} ><span class="btn_contentNav" tabindex="-1" aria-label="Criterion B Navigation Menu">B</span></button>
            </div>
        </div>
        <div class="critDiv">        
            <div id="criterionCDiv" bind:this={critCDiv}>
                {#each evaluationFile.criterions as criterion}
                    {#if criterion.id =="C"}
                        {#each criterion.criterions as criterions}
                            <div class="critItem">
                                <button onmouseup="this.blur();" on:blur={(event)=>focusNextElement(event,critBBtn, critCBtn, critDBtn)} class="navBtn" on:click={(event)=>navigateTo(criterions.itemNum)} aria-label="Page {criterions.title} navigation"><label 
                                    class="btnLabel" aria-hidden="true">{criterions.title}</label></button>  
                            </div>                       
                        {/each}
                    {/if}
                {/each}
            </div>
            <div class="btnDiv">
                <button bind:this={critCBtn} disabled="true" onmouseup="this.blur();" class="criterionContainer" on:blur={(event)=>focusNavItem(event, critDBtn, critBBtn, critCDiv, critBDiv)} on:mousedown={(event)=>openNavMenu(event,critCDiv, "mouse")} on:keypress={(event)=>openNavMenu(event,critCDiv, "keyboard")} ><span class="btn_contentNav" tabindex="-1" aria-label="Criterion C Navigation Menu">C</span></button>
            </div>
        </div>
        <div class="critDiv">        
            <div id="criterionDDiv" bind:this={critDDiv}>
                {#each evaluationFile.criterions as criterion}
                    {#if criterion.id =="D"}
                        {#each criterion.criterions as criterions}
                            <div class="critItem">
                                <button onmouseup="this.blur();" on:blur={(event)=>focusNextElement(event,critCBtn, critDBtn, critEBtn)} class="navBtn" on:click={(event)=>navigateTo(criterions.itemNum)} aria-label="Page {criterions.title} navigation"><label 
                                    class="btnLabel" aria-hidden="true">{criterions.title}</label></button>
                            </div>                       
                        {/each}
                    {/if}
                {/each}
            </div>
            <div class="btnDiv">
                <button bind:this={critDBtn} disabled="true" onmouseup="this.blur();" class="criterionContainer" on:blur={(event)=>focusNavItem(event, critEBtn, critCBtn, critDDiv, critCDiv)} on:mousedown={(event)=>openNavMenu(event,critDDiv, "mouse")} on:keypress={(event)=>openNavMenu(event,critDDiv, "keyboard")} ><span class="btn_contentNav" tabindex="-1" aria-label="Criterion D Navigation Menu">D</span></button>
            </div>
        </div>
        <div class="critDiv">        
            <div id="criterionEDiv" bind:this={critEDiv}>
                {#each evaluationFile.criterions as criterion}
                    {#if criterion.id =="E"}
                        {#each criterion.criterions as criterions}
                            <div class="critItem">
                                <button onmouseup="this.blur();" on:blur={(event)=>focusNextElement(event,critDBtn, critEBtn, critFBtn)} class="navBtn" on:click={(event)=>navigateTo(criterions.itemNum)} aria-label="Page {criterions.title} navigation"><label 
                                    class="btnLabel" aria-hidden="true">{criterions.title}</label></button> 
                            </div>                       
                        {/each}
                    {/if}
                {/each}
            </div>
            <div class="btnDiv">
                <button bind:this={critEBtn} disabled="true" onmouseup="this.blur();" class="criterionContainer" on:blur={(event)=>focusNavItem(event, critFBtn, critDBtn, critEDiv, critDDiv)} on:mousedown={(event)=>openNavMenu(event,critEDiv, "mouse")} on:keypress={(event)=>openNavMenu(event,critEDiv, "keyboard")} ><span class="btn_contentNav" tabindex="-1" aria-label="Criterion E Navigation Menu">E</span></button>
            </div>
        </div>
        <div class="critDiv">        
            <div id="criterionFDiv" bind:this={critFDiv}>
                {#each evaluationFile.criterions as criterion}
                    {#if criterion.id =="F" || criterion.id =="PTSD"}
                        {#each criterion.criterions as criterions}
                            <div class="critItem">
                                <button onmouseup="this.blur();" on:blur={(event)=>focusNextElement(event,critEBtn, critFBtn, critGBtn)} class="navBtn" on:click={(event)=>navigateTo(criterions.itemNum)} aria-label="Page {criterions.title} navigation"><label 
                                    class="btnLabel" aria-hidden="true">{criterions.title}</label></button> 
                            </div>                       
                        {/each}
                    {/if}
                {/each}
            </div>
            <div class="btnDiv">
            <button bind:this={critFBtn} disabled="true" onmouseup="this.blur();" class="criterionContainer" on:blur={(event)=>focusNavItem(event, critGBtn, critEBtn, critFDiv, critEDiv)} on:mousedown={(event)=>openNavMenu(event,critFDiv, "mouse")} on:keypress={(event)=>openNavMenu(event,critFDiv, "keyboard")} ><span class="btn_contentNav" tabindex="-1" aria-label="Criterion F Navigation Menu">F</span></button>
            </div>
        </div>
        <div class="critDiv">       
            <div id="criterionGDiv" bind:this={critGDiv}>
                {#each evaluationFile.criterions as criterion}
                    {#if criterion.id =="G"}
                        {#each criterion.criterions as criterions}
                            <div class="critItem">
                                <button onmouseup="this.blur();" on:blur={(event)=>focusNextElement(event, critFBtn, critGBtn, globalBtn)} class="navBtn" on:click={(event)=>navigateTo(criterions.itemNum)} aria-label="Page {criterions.title} navigation"><label 
                                    class="btnLabel" aria-hidden="true">{criterions.title}</label></button>
                            </div>                       
                        {/each}
                    {/if}
                {/each}
            </div>
            <div class="btnDiv">
            <button bind:this={critGBtn} disabled="true" onmouseup="this.blur();" class="criterionContainer" on:blur={(event)=>focusNavItem(event, globalBtn, critFBtn, critGDiv, critFDiv)} on:mousedown={(event)=>openNavMenu(event,critGDiv, "mouse")} on:keypress={(event)=>openNavMenu(event,critGDiv, "keyboard")} ><span class="btn_contentNav" tabindex="-1" aria-label="Criterion G Navigation Menu">G</span></button>
            </div>
        </div>
        <div class="globalDiv">        
            <div id="globalDivContainer" bind:this={globalDiv}>
                {#each evaluationFile.criterions as criterion}
                    {#if criterion.id =="GL"}
                        {#each criterion.criterions as criterions}
                            <div class="critItem">
                                <button onmouseup="this.blur();" on:blur={(event)=>focusNextElement(event, critGBtn, globalBtn, dissBtn)} class="navBtn" on:click={(event)=>navigateTo(criterions.itemNum)} aria-label="Page {criterions.title} navigation"><label 
                                    class="btnLabel" aria-hidden="true">{criterions.title}</label></button>
                            </div>                       
                        {/each}
                    {/if}
                {/each}
            </div>
            <div class="largeBtnDiv">
            <button bind:this={globalBtn} disabled="true" onmouseup="this.blur();" class="largeCriterionContainer" on:blur={(event)=>focusNavItem(event, dissBtn, critGBtn, globalDiv, critGDiv)} on:mousedown={(event)=>openNavMenu(event,globalDiv, "mouse")} on:keypress={(event)=>openNavMenu(event,globalDiv, "keyboard")} ><span class="btn_contentNav" tabindex="-1" aria-label="Criterion Global Navigation Menu">Global</span></button>
            </div>
        </div>
        <div class="dissDiv">        
            <div id="dissDivContainer" bind:this={dissDiv}>
                {#each evaluationFile.criterions as criterion}
                    {#if criterion.id =="Diss"}
                        {#each criterion.criterions as criterions}
                            <div class="critItem">
                                <button onmouseup="this.blur();" on:blur={(event)=>focusNextElement(event, globalBtn, dissBtn, summaryBtn)} class="navBtn" on:click={(event)=>navigateTo(criterions.itemNum)} aria-label="Page {criterions.title} navigation"><label 
                                    class="btnLabel" aria-hidden="true">{criterions.title}</label></button>
                            </div>                       
                        {/each}
                    {/if}
                {/each}
            </div>
            <div class="largeBtnDiv">
                <button bind:this={dissBtn} disabled="true" onmouseup="this.blur();" class="largeCriterionContainer" on:blur={(event)=>focusNavItem(event, summaryBtn, globalBtn, dissDiv, globalDiv)} on:mousedown={(event)=>openNavMenu(event,dissDiv, "mouse")} on:keypress={(event)=>openNavMenu(event,dissDiv, "keyboard")} ><span class="btn_contentNav" tabindex="-1" aria-label="Criterion Dissociative Navigation Menu">Dissociative</span></button>
            </div>
        </div>
        <div class="summaryDiv" bind:this={summaryDiv}>        
            <div id="summaryDivContainer" bind:this={summaryDivContainer}>
                <div class="critItem">
                    <button onmouseup="this.blur();" class="navBtnSummary" on:blur={(event)=>focusNextElement(event, dissBtn, summaryBtn, fwBtn)} on:click={(event)=>navigateTo(31)} aria-label="Summary Page 1 navigation"><label 
                    class="btnLabelSummary" aria-hidden="true">Summary Page 1</label></button>
                </div>
                <div class="critItem">
                    <button onmouseup="this.blur();" class="navBtnSummary" on:blur={(event)=>focusNextElement(event, dissBtn, summaryBtn, fwBtn)} on:click={(event)=>navigateTo(32)} aria-label="Summary Page 2 navigation"><label 
                    class="btnLabelSummary" aria-hidden="true">Summary Page 2</label></button>
                </div>
                <div class="critItem">
                    <button onmouseup="this.blur();" class="navBtnSummary" on:blur={(event)=>focusNextElement(event, dissBtn, summaryBtn, fwBtn)} on:click={(event)=>navigateTo(33)} aria-label="Summary Page 3 navigation"><label 
                    class="btnLabelSummary" aria-hidden="true">Summary Page 3</label></button>
                </div>                     
            </div>
            <div class="largeBtnDiv">
                <button bind:this={summaryBtn} disabled="true" onmouseup="this.blur();" class="largeCriterionContainer" on:blur={(event)=>focusNavItem(event, evalBtn, dissBtn, summaryDivContainer, dissDiv)} on:mousedown={(event)=>openNavMenu(event,summaryDivContainer, "mouse")} on:keypress={(event)=>openNavMenu(event,summaryDivContainer, "keyboard")} ><span class="btn_contentNav" tabindex="-1" aria-label="Criterion Summary Navigation Menu">Summary</span></button>
            </div>
        </div>
        

    </div>
</div>
{#if $page.itemNum >= numPages-1}
<ContextController divID={"ctx_complete"} />
<button bind:this={evalBtn} on:click|stopPropagation={goToEval} id="evalBtn" aria-label="Course complete" tabindex={$context_toggle?"-1":"0"} data-cy={'evalBtn'}><span id="btn_contentEval" tabindex="-1">Course Complete</span><svg class="menuIcons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg></button>
{:else}
<div class="navBtn-Fwd">
    <ContextController divID={"ctx_advance"} data-cy='btnForward'/>
    {#if navigateAnimForward}
        <button class="animForward" bind:this={fwBtn} on:click|stopPropagation={navForward} id="forwardButton" aria-label="Forwards" data-cy='btnForward'><span class="btn_content" tabindex="-1" ></span></button>
    {:else}
        <button bind:this={fwBtn} on:click|stopPropagation={navForward} id="forwardButton" aria-label="Forwards" data-cy='btnForward' ><span class="btn_content" tabindex="-1"></span></button>
    {/if}
</div>
{/if}
<p align="right" bind:this={serverErrorElement} id="serverErrorParagraph"></p>
<p align="right" bind:this={scoringErrorElement} id="scoringErrorParagraph"></p>
</div>