<script>
import { base_asset_path } from './stores.js'
import eval_captions from "./eval_captions_v2.json";
export let eval_ratings;
export let tbl_your_scored_header;

function toggle_subitems(index)
{
  var element = document.getElementById("tbody"+ index);
  var arrow = document.getElementById("evalRatingArrow"+ index);
  if (element.style.display == "none")
  {
    element.removeAttribute("aria-hidden");
    arrow.style.transform = "rotate(180deg)";
    element.style.display = "table-row-group";
  }    
  else
  {
    arrow.style.transform = "rotate(90deg)";
    element.style.display = "none";
    setTimeout(function(){
      element.setAttribute("aria-hidden","true");
            }, 75);
  }      
  console.log(element.style.display);
}

</script>
<style lang="less">
.btn_content
{
  display: inline-block;
  width: 100%;
  height: 100%;
  pointer-events: all;
  border-radius: 2px; 
}
.evalRatingArrow
{
  margin-left: 1%;
  fill: #E2E2E2;
  width: 2vw;
  height: 2vw;
  position: relative;
  transition: all 0.075s linear;
  transform: rotate(90deg);
}
.eval_rating_btn:focus > .btn_content{
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none;
}
.eval_rating_btn:focus,
.btn_content:focus{
    outline: none;
}
.btn_content:focus
{
  outline: none;
}
.btn_content:hover { box-shadow: 0 0 0 2px #023b5a; }
.eval_rating_btn
{
  position: absolute;
  height: 2vw;
  width: 2vw;
  left: 10.5vw;
  bottom: unset;
  background: transparent;
  cursor: pointer;
  text-align: left;
  color: black;
  outline: none;
  border: none !important;
  pointer-events: none;
}
table
{
  display: block;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  background-color: white;
  width: 80%;
  margin-bottom: 1vw;
  margin-left:10%;
  margin-right:10%;
  table-layout: fixed;
}

td, th
{
  border: 1px solid black;
  padding: .5vw;
  width: 20%;
}

tr
{
  font-size: 1.5vw;
  text-align: center;
  color: black;
}

tbody, thead
{
  font-size: 1.25vw;
  text-align: center;
  background-color: white;
}

thead:nth-of-type(odd) {
  background-color: #dedede;
  -webkit-print-color-adjust: exact; 
}

tbody:nth-of-type(even){
  background-color: #dedede;
  -webkit-print-color-adjust: exact; 
}
@media (max-width: 550px)
{
  .eval_rating_btn
  {
    height: 2.5vw;
    width: 2.5vw;
    left: 5.5vw;
  }  
}
</style>

<div>
  <table>
    <thead>
      <tr>
        <th style="width: 60%;" align="left" bgcolor="#C0C0C0">{eval_captions.ItemName}</th><th bgcolor="#C0C0C0" >{tbl_your_scored_header}</th><th bgcolor="#C0C0C0">{eval_captions.CorrectScore}</th>
      </tr>
    </thead>
    {#each eval_ratings as rating, i}
      <thead>
        <tr>
          <th style="width: 60%;" align="left" scope="col">
            <img class="evalRatingArrow" style="--rotate:0deg" src={$base_asset_path + "blackDropArrow.png"} alt="Dropdown arrow for evaluation rating table {i}" id="evalRatingArrow{i}" height="15em">
            <button class="eval_rating_btn" on:click={(event)=>toggle_subitems(i)} aria-label="Expand button for table {i}" ><span class="btn_content" tabindex="-1"></span></button>
            {rating.item.title}</th>
          <th scope="col">{@html rating.item.score}</th>
          <th scope="col">{rating.item.correct}</th>
        </tr>  
      </thead>         
      <tbody id="tbody{i}" class="tbodyTables" style="display: none;">
        {#each rating.details as subItem}                  
          <tr>                    
            {#if subItem.title.includes(eval_captions.OtherConsiderations)}
              <th colspan="3" style="text-align: left; width: 60%; font-weight: normal;" scope="row"><span style="margin-right: 3.3%; margin-left: 3.3%;">&#8226;</span>{@html subItem.title}</th>
            {:else}
              <th style="text-align: left; font-weight: normal;" scope="row"><span style="margin-right: 5%; margin-left: 5%">&#8226;</span>{@html subItem.title}</th>
              <td>{@html subItem.score}</td>
              <td>{subItem.correct}</td>
            {/if}                    
          </tr>                  
        {/each}                                    
      </tbody>
    {/each}
  <table>
</div>
