<script>
import RadioSwirl from './../RadioSwirl.svelte';
import {onMount,createEventDispatcher} from 'svelte';
export let rating;
export let cmp;
let f;

const dispatch = createEventDispatcher();

onMount( async function() {
    let docYes = f.severity[1];
    let docNo = f.severity[0];
    if(cmp(rating)) 
        docYes.checked = true;
    else
        docNo.checked = true;
    docYes.disabled = true;
    docNo.disabled = true;
    dispatch('allow_page_change');
})
</script>
    
<style lang="less">
form
{
    text-align: center;
}
</style>

<form bind:this={f}>
    <RadioSwirl class_span={"leftLabel"} value={0} text={"0 = NO"}/>
    <RadioSwirl class_span={"rightLabel"} value={1} text={"1 = YES"}/>
</form>


