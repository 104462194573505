<script>
  import { onMount } from 'svelte'
  import { activate_tabbing,deactivate_tabbing } from '../utils.svelte';
  export let course_complete;
  export let confirm_restart;
  export let cancel_restart;
  export let main_menu_btn_ids;
  export let addtnl_class_to_handle;
  export let btn_to_blur_id;
  let btn_to_focus_id = "restartNo";
  let addtnl_class_elements=[];
  let captions = {"true":{"text":"This will start a new training interview. Your previous Evaluation will be saved.","btn1_txt":"OK","area_lbl1":"Restart Ok","btn2_txt":"Cancel","area_lbl2":"Restart Cancel"},
                  "false":{"text":"Your progress in the current training interview will be abandoned. Are you sure?","btn1_txt":"Yes","area_lbl1":"Restart Yes","btn2_txt":"No","area_lbl2":"Restart No"},
                  "force_restart":{"text":"This course has been updated. Unfortunately, for evaluation purposes we must now restart you from the beginning of the course.","btn1_txt":"OK","area_lbl1":"Restart Yes"}};

function activate_tabs()
{
  activate_tabbing(main_menu_btn_ids);
  for (var i=0, len=addtnl_class_elements.length; i<len; ++i)
    if(addtnl_class_elements[i]["element"]!=null)
      addtnl_class_elements[i]["element"].setAttribute("href",addtnl_class_elements[i]["href"]);
}
function confirm(score)
{
  activate_tabs();
  confirm_restart();
}
function cancel(score)
{
  activate_tabs();
  cancel_restart();
  let element = document.getElementById(btn_to_blur_id);
  if(element!=null)
    element.focus();
}

  onMount( async function()
  {
    let element = document.getElementById(btn_to_blur_id);
    if(element!=null)
      element.blur();
    element = document.getElementById(btn_to_focus_id);
    if(element!=null)
      element.focus();
    deactivate_tabbing(main_menu_btn_ids);
    let elements = document.getElementsByClassName(addtnl_class_to_handle);
    for (var i=0, len=elements.length; i<len; ++i)
    {
      elements[i].style.cursor = "unset";
      addtnl_class_elements.push({"element":elements[i],"href":elements[i].getAttribute("href")});
      elements[i].removeAttribute("href");
    }
  });
</script>


<style>
#restartDiv
{
  display: block;
  background: white;
  z-index: 1;
  border: 3px solid #7092a6;
  border-radius: 8px;
  position: fixed;
  width: 25vw;
  height: 11vw;
  top: 16vw;
  left: 40vw;
}
#restartParagraph
{
  position: relative;
  font-size: 1vw;
  color: #7092a6;
  font-weight: bold;
  text-align: center;
}
#confirmBtnDiv
{
    position: relative;
    padding: 0.15vw 1.5vw .5vw 1.5vw;
}
.restartConfirm
{
  width: 8vw;
  height: 2vw;
  display: inline-block;
  background: #7092a6;
  background-image: linear-gradient(0deg, #557C93 0%, #7092a6 30%);
  color: white;
  font-family: Lato;
  font-size: 1vw;
  font-weight: 700;
  letter-spacing: 1px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
.restartConfirm:focus,
.restartConfirm:hover{
  background: white;
  border: 2px solid #7092a6;
  color: #7092a6;
}
.restartConfirm:focus
{
  box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
  outline: none;
}
#restartYes
{
  margin-left: 2vw;
  margin-right: 1vw;
}
#confirmText
{
  font-size: 1vw;
  text-align: center;
}
.centerDiv{
  text-align: center;
}
</style>

<div id="restartDiv">
  <p id="restartParagraph">Restart Training</p>
  <div id="confirmBtnDiv">
      <p id="confirmText">{captions[course_complete].text}</p>
      {#if captions[course_complete].btn2_txt}
        <button id="restartYes" class="restartConfirm" on:click={confirm} aria-label={captions[course_complete].area_lbl1}>{captions[course_complete].btn1_txt}</button>
        <button id={btn_to_focus_id} class="restartConfirm" on:click|stopPropagation={cancel} aria-label={captions[course_complete].area_lbl2}>{captions[course_complete].btn2_txt}</button>
      {:else}
      <div class="centerDiv">
        <button class="restartConfirm" on:click={confirm} aria-label={captions[course_complete].area_lbl1}>{captions[course_complete].btn1_txt}</button>
      </div>
      {/if}
  </div>
</div>  
