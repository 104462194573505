<script>
  export let hidden = true;
  export let fillColor = '#231f20';
  export let hoverToggle = false;
</script>

{#if hidden}
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.64 35.18" loading="lazy" width="19" style="-webkit-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(270deg) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(270deg) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(270deg) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(270deg) skew(0, 0); fill: {fillColor}" data-w-id="b350d18a-8174-dc22-6329-d934fcaf8601" alt="" class="dropdowntoggle" class:toggleFill={hoverToggle} on:click>
  <g id="e110ef37-c60c-44f3-9640-83b611ff4a57" data-name="Layer 2">
    <g id="efb99fb6-a7d4-4c4b-8f11-688df1f493cc" data-name="Icon_Caret">
      <path class="f2f61329-1b26-4d47-8943-8d2cb6f939d9" d="M40.13,34.52,75.61,5a2.82,2.82,0,0,0-1.8-5h-71A2.83,2.83,0,0,0,1,5L36.51,34.52A2.84,2.84,0,0,0,40.13,34.52Z"/>
    </g>
  </g>
</svg> 
{:else}
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.64 35.18" loading="lazy" width="19" style="-webkit-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0); fill: {fillColor}" data-w-id="b350d18a-8174-dc22-6329-d934fcaf8601" alt="" class="dropdowntoggle" class:toggleFill={hoverToggle} on:click>
  <g id="e110ef37-c60c-44f3-9640-83b611ff4a57" data-name="Layer 2">
    <g id="efb99fb6-a7d4-4c4b-8f11-688df1f493cc" data-name="Icon_Caret">
      <path class="f2f61329-1b26-4d47-8943-8d2cb6f939d9" d="M40.13,34.52,75.61,5a2.82,2.82,0,0,0-1.8-5h-71A2.83,2.83,0,0,0,1,5L36.51,34.52A2.84,2.84,0,0,0,40.13,34.52Z"/>
    </g>
  </g>
</svg> 
{/if}

<style>
  .dropdowntoggle {
    cursor: pointer;
  }
  .toggleFill {
    fill: #7092a6!important;
  }
</style>