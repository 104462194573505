<script>
  import pageFile from "./../pages.json";
</script>

<style lang="less">
.contentSubtext{
    height: 10%;
    width: 100%;
    position: initial; 
    left: 0;
    float: left;
    margin-top: 1%;
  }
.contentParagraph{
  //font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  line-height: 1.5;
  font-size: 1vw;
  margin-inline-end: 1em;
  margin-block-start: 0em;
  margin-block-end: 0em;
}
.question{
  font-size: 1vw;
  font-weight: bold;
}

@media (max-width:1000px){
    .contentParagraph
    {
      font-size: 10px;
    }
    .question
    {
      font-size: 11px;
    }
}

@media (max-width:700px)
{
    #contentBox
    {
        width: 59%;
    }
}
</style>

<div class="contentSubtext">
      {#each pageFile as jsonfile, index}        
        {#if jsonfile.id === 0}
            <p class="contentParagraph" id="contentSubtext{index}"><span class="question">{@html jsonfile.subtext}</span></p>
        {/if}
      {/each}
</div>