<script>
	import { input_disabled_dialogue,base_asset_path, context_toggle } from './stores.js';	
	import ContextController from "./ContextController.svelte";
	import './btn_class.svelte';
	export let divID;
	export let btn_id;
	export let area_lbl;
	export let btn_text;
	export let btn_class;
	export let btn_ctnr_class;
	export let on_click;
	export let dropdown=false;
	export let component="";
</script>
<style lang="less">
	.menu_btn_ctnr
	{
		flex: 1 1 auto;
		position: relative;
		width: 19.5vw;
		height: 25%;
		max-width: 19.5vw;
		max-height: 3.4vw;
		margin-top: 3%;
		font-size: 1.3vw;
		font-weight: 300;
	}
	.sub_menu_btn_ctnr
	{
		flex: 1 1 auto;
		position: relative;
		width: 19.5vw;
		/*height: 2.8vw;*/
		height: 25%;
		max-width: 19.5vw;
		max-height: 2.8vw;
		font-size: 1.1vw;
		font-weight: 300;
	}
	.sub_menu_btn
	{
		display:inline-block;
		height:100%;
		width:100%;
		border: 2px solid #b3c5cf;
		border-top: none;
		display: block;
		background: #3A7BA1;
		color: white;
		letter-spacing: 0.5px;
		font-family: Lato;
		font-size: inherit;
		font-weight: inherit;
		cursor: pointer;
		position: relative;
	}
	.sub_menu_btn:hover{background: #73a8c8;}
	.sub_menu_btn:disabled,
	.sub_menu_btn[disabled]
	{
		border: 2px solid #565656;
		background: #2e2e2e;
		color: #565656;
	}

	.sub_menu_btn:focus
	{
    	box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
		outline: none;
		z-index: 5;
	}	
	.btn_drop_content
	{
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		pointer-events: all;
	}
	.btn_drop_content:focus
	{
		outline: none;
	}
	.btn_drop_content:disabled,
	.btn_drop_content[disabled]{ pointer-events: none; }
	
	.btn_drop_txt_ctnr
	{
		position: absolute;
		height: 100%;
		width: 100%;
		left:0;
		top:0;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
	}
	.btn_menu_txt_ctnr
	{
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.btn_drop_ctnr
	{
		height: 100%;
		width: 100%;
		pointer-events: none;
	}
	.arrow_ctnr
	{
		height: 100%;
		width: 20%;
		max-width: 3.4vw;
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: none;
	}
	.arrow_img
	{
		height: 2vw;
		width: 2vw;
		align-self: center;
		transition: all .25s linear;
 		transform: rotate(90deg);
		pointer-events: none;
	}
	.arrow_img_gray
	{
		height: 1vw;
		width: 1vw;
		pointer-events: none;
		display: none;
	}
	.split_line
	{
		height: 70%;
		margin-right: 2px;
		margin-left: 2px;
		pointer-events: none;
	}
	.split_line {border-left: 1px solid white;}

</style>
<div class={btn_ctnr_class}>
	<ContextController divID={divID}/>
	<button class={btn_class} onmouseup="this.blur();" id={btn_id} on:click|stopPropagation={on_click(component)} aria-label={area_lbl} tabindex={$context_toggle?"-1":"0"}>
	{#if dropdown===true}
		<span class="btn_drop_content" id="btn_drop_content" tabindex="-1" aria-hidden="true">
			<span class="arrow_ctnr" id="arrow_ctnr">
				<img class="arrow_img" id="arrow_img" src={$base_asset_path+"whiteDropArrow.png"} alt="Dropdown arrow for scoring help">
				<img class="arrow_img_gray" id="arrow_img_gray" src={$base_asset_path+"twirlarrow.svg"} alt="Dropdown arrow for scoring help">
			</span>
			<div id="split_line" class="split_line"></div>
			<span class="btn_drop_ctnr" id="btn_drop_ctnr" ></span>
			<span class="btn_drop_txt_ctnr" >{ btn_text }</span>
		</span>
	{:else}
		{#if btn_class==="btn"}
			<div class="btn_menu_txt_ctnr" id="btn_menu_txt_ctnr" ><span>{ btn_text }</span></div>
		{:else}
			<span>{ btn_text }</span>
		{/if}
	{/if}
	</button>
</div>
