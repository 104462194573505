<script>
    import Conversation from "./ConversationManager/conversation";
    import SpeechRecognition from "./SpeechRecognition.svelte";
    import { input_disabled_dialogue, input_disabled_all } from './stores.js';
    export let lastInput = '';
    export let currentTextInput = '';
    export let matchInput;
    export let abortFurtherRecognition;
    let stopFurtherRecognition;
    let partialTextMatch = '';
    let continuous;
    let transcript;
    export let focusTrap;
    export let listening;
    export let micBtn;

    $:  {
        if(transcript && transcript.length)
        {
            if(partialTextMatch && partialTextMatch.length)
            {
                currentTextInput = partialTextMatch.trim() + " " + transcript.trim();
            }
            else
            {
                currentTextInput = transcript;
            }
            if(!currentTextInput.endsWith('...'))
            {
                currentTextInput = currentTextInput.trim();
                console.log("Auto submitting Final Transcript: "+ currentTextInput);
                submitInput(true);
                transcript = '';
            }
        }
    }

    export function submitInput(speech)
    {
        let input = currentTextInput;
        if(speech)
            input= !continuous ? currentTextInput : transcript;
        if($input_disabled_dialogue || $input_disabled_all)
        {
            console.log("Input is disabled");
            return;
        }
        if(input.endsWith('...'))
        {
            // remove ... from the interim speech rec
            input = input.slice(0, input.length-3);
            console.log("Abort current recognition, since current input is being submitted");
            abortFurtherRecognition();
        }
        let matchValue;
        if(speech)
            matchValue = matchInput(input,true);
        else
            matchValue = matchInput(input,false);
        if(matchValue == Conversation.matchReturnValues.moreInputNeeded)
        {
            continuous = true;
            partialTextMatch = currentTextInput;
            currentTextInput += "...";
        }
        else
        {
            stopFurtherRecognition();
            continuous = false;
            partialTextMatch = '';
            lastInput = currentTextInput;
            currentTextInput = '';
        }
    }

</script>

{#if $$slots.context ||  $$slots.textInput}
    {#if $$slots.context}
        <slot name="context"/>
    {/if}
    {#if $$slots.textInput}
        <slot name="textInput"/>
    {/if}
{/if}
<SpeechRecognition bind:transcript bind:abortFurtherRecognition bind:stopFurtherRecognition bind:continuous bind:micBtn={micBtn} bind:focusTrap={focusTrap} bind:listening let:micBtnClick={micBtnClick}>
    <slot slot="micBtn" name="micBtn" micBtnClick={micBtnClick}/>
</SpeechRecognition>

    

