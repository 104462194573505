<script>
  import InputFeedComponent from "../../InputFeedComponent.svelte";
  import InputFeedNotepad from "../../InputFeedNotepad.svelte";
  import ContextController from "../../ContextController.svelte";
  import { input_disabled_all } from '../../stores.js';
  import { createAudioMeter } from '../../volume-meter.js';
  import {post_event_log} from "../../server_api.js";
  import Conversation from "../../ConversationManager/conversation";
  export let matchInput;
  export let lastInput;
  export let currentTextInput;
  export let focusTrap;
  export let abort_speech_recognition;
  let submitInput;
  let lastInputTextArea;
  let inputTextArea;
  let micBtn;
  let listening;
  let stream;
  let mediaStreamSource;
  let meter;
  let maxVolume;
  let audioContext;
  let rafID;
  let volumeTimeout;
  let lowMicVolumeElement;
  let lowMicText = "";
  let warnLowMicVolume;
  let lowMicVolume;
  let numLowVolumeDetections = 0;
  let numExtremeLowVolumeDetections = 0;
  let cancelVolumeTrackerStart = false;
  let hasPartialMatch = false;
  const lowVolumeText = "Your microphone level is too low for good speech recognition. Adjust your microphone settings if you can, or try moving closer to the mic, or speaking more loudly.";
  const warnVolumeText = "Warning: speech recognition is having trouble hearing you.";
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  const warnVolume = .055;
  const lowVolume =.03;
  const volumeWarnTimeInitialMS = 1500;
  const volumeWarnTimeSubsequentMS = 3500;
  let volumeWarnTimeMS = volumeWarnTimeInitialMS;

  let matchInputSpeechArea = (input,allowPartialMatch)=>{
    let volumeWarningMessage = "\n**" + numLowVolumeDetections + " Low Volume Warnings, " + numExtremeLowVolumeDetections + " Extreme Low Volume Warnings**  \n";
    numLowVolumeDetections = 0;
    numExtremeLowVolumeDetections = 0;
    let ret = matchInput(input,allowPartialMatch,volumeWarningMessage)
    hasPartialMatch = ret == Conversation.matchReturnValues.moreInputNeeded
    return ret;    
  }

  $:{
    if(inputTextArea)
      inputTextArea.disabled = $input_disabled_all;
    }

  function setMicVolumeDetected(volume)
  {
    if(volume < lowVolume)
    {
      lowMicVolume = true;
      warnLowMicVolume = false;
      lowMicText = lowVolumeText;
      numExtremeLowVolumeDetections++;
      post_event_log("MIC_VOLUME_WARNING",{warning_level:"extremely low",value:volume});      
    }
    else if(volume < warnVolume)
    {
      lowMicVolume = false;
      warnLowMicVolume = true;
      lowMicText = warnVolumeText;
      numLowVolumeDetections++;
      post_event_log("MIC_VOLUME_WARNING",{warning_level:"low",value:volume});      
    }
    else
    {
      clearMicWarnings();
    }
  }

  function clearMicWarnings()
  {
    lowMicVolume = false;
    warnLowMicVolume = false;
    lowMicText = "";
  }
  
  async function startVolumeTracker()
  {
    cancelVolumeTrackerStart = false;
    if(volumeTimeout)
      return;
    stream =  await navigator.mediaDevices.getUserMedia({ audio: {autoGainControl: false} })
    audioContext = new AudioContext();
    mediaStreamSource = audioContext.createMediaStreamSource(stream);
    meter = createAudioMeter(audioContext);
    mediaStreamSource.connect(meter);
    if(cancelVolumeTrackerStart)
    {
      clearResources();
      return;
    }
    volumeLoop();
    volumeTimeout = setTimeout(()=>{
      endVolumeTracker();
    },volumeWarnTimeMS)
    volumeWarnTimeMS=volumeWarnTimeSubsequentMS;
  }

  function endVolumeTracker(){
    clearVolumeTimeout();
    if(maxVolume != null)
    {
      setMicVolumeDetected(maxVolume);
    }
    clearResources();
    if(listening==true)
      startVolumeTracker();
  }

  function clearResources()
  {
    cancelVolumeTrackerStart = true;
    window.cancelAnimationFrame(rafID)
    if(mediaStreamSource)
    {
      mediaStreamSource.disconnect(meter);
    }
    if(meter)
    {
      meter.shutdown();
    }
    if(audioContext)
    {
      audioContext.close();
    }
    if(stream)
    {
      stream.getTracks().forEach(function(track) {
        track.stop();
      });
    }

    maxVolume = null;
    mediaStreamSource = null;
    meter = null;
    audioContext = null;
    stream = null;
  }

  function clearVolumeTimeout()
  {
    clearTimeout(volumeTimeout);
    volumeTimeout = null;
  }

  $:{
    if(listening==true)
    {
      clearMicWarnings();
      volumeWarnTimeMS=volumeWarnTimeInitialMS;
      startVolumeTracker();
    }
    else
    {
      clearVolumeTimeout();
      clearResources();
      clearMicWarnings();
    }
  }

  function volumeLoop()
  { 
    if(meter==null)
      return;
    if(maxVolume!=null)
      maxVolume = maxVolume>meter.volume?maxVolume:meter.volume;
    else
      maxVolume = meter.volume;
    // set up the next visual callback
    rafID = window.requestAnimationFrame( volumeLoop );
  }

  $:{
    if(currentTextInput && inputTextArea)
    {
      inputTextArea.value = currentTextInput;
      inputTextArea.scrollTop = inputTextArea.scrollHeight;
    }
  }

  $:{
      if(lastInput && lastInputTextArea)
      {
        lastInputTextArea.value = lastInput;
        lastInputTextArea.scrollTop = lastInputTextArea.scrollHeight;
      }
    }

  function handleOnKeydown(e)
  {
    if(e.key == "Enter")
    {
      clearMicWarnings();
      e.preventDefault();
      submitInput();
    }
  }

  function micBtnClicked(micBtnClick)
  {
    if(hasPartialMatch)
    {
      clearMicWarnings();
      submitInput();
    }
    else
    {
      micBtnClick();
    }
  }
</script>

<style lang="less">
  .currentInputContainer:focus-within {
    box-shadow: inset 0 0 0 5px #fff, 0 0 3px 4px #023b5a;
    outline: none; // removes default focus outline
  }
  #lastInputDiv
  {
      border-bottom: 1px solid black;
      height: 40%;
      width: 100%;
      display: flex;
      flex-direction: row;
      position: relative;
  }   

  textarea
  {         
      font-size: 0.9vw;
      width: 100%;
      height: 99%;
      border: none;
      background-color: #f8f8f800;
      box-sizing: border-box;
      border-radius: 8px;
      resize: none;
      outline: none !important;
  }
  textarea.currentInputTextArea
  {         
      font-family: 'Lato', sans-serif;
      height: 99%;
      padding-left: 0.5vw;
      padding-right: 0.5vw;
      overflow-y: auto;
      position: relative;
  }
  textarea.lastInputTextArea
  {   
      font-family: Helvetica, sans-serif;
      font-style: italic;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      text-align: right;
      margin-top: 0%;
      padding: 1% 2%;
  }

.lowMicVolumeElement
{
  position: absolute;
  font-weight: 1000;
  color: #AD0000;
  margin:0;
  overflow-y: scroll;
  height: 100%;
  padding: 0% 2%;
  width: -webkit-fill-available;
}
.warnLowMicVolumeElement
{
  position: absolute;
  font-weight: 1000;
  color: #0644B5;
  margin:0;
  overflow-y: scroll;
  height: 100%;
  padding: 0% 2%;
  width: -webkit-fill-available;
}
.lastInputContainer
{
  position: relative;
  display: inline-block;
  font-size: 0.9vw;
  background-color: #f8f8f800;
  height: 100%;
  width: 95%;
}
.currentInputContainer
{
  font-size: 0.9vw;
  height: 60%;
  width: 85%;
  background-color: #f8f8f8;
  border: 1px solid #000000;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  left: 4%;
  top: 20%;
  overflow: hidden;
}
#inputTutorial
{
  border: 0.3vw solid #597f96;
  border-radius: 10px;
  position: absolute;
  display: none;
  height: 53%;
  width: 84%;
  left: 3.75%;
  top: 19%;
  bottom: 0px;
  margin-right: 0%;
  z-index: 200;
}

#micDiv 
    {
      display: inline-block;
      position: relative;
      width: 10%;
      height: 55%;
      left: 4.5%;
      top: 23%;
    }
    #micTutorial
    {
      border: 0.3vw solid #597f96;
      border-radius: 10px;
      position: absolute;
      display: none;
      width: 65%;
      height: 100%;
      top: 0px;
      bottom: 0px;
      margin-left: 8%;
      margin-top: -10%;
      z-index: 200;
    }

    #micSpeechBtn
    {
      background-image: url(/assets/c5t_mic.svg);
      display: block;
      transition: filter 0.2s;
      background-color: rgba(0, 0, 0, 0);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      border: none;
      position: relative;
      pointer-events: none;
      padding: 0;
      margin: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
    }

    #micSpeechBtn:disabled,
    #micSpeechBtn[disabled]
    {
        background-image: url(/assets/micmute.png);
        filter: opacity(.5);
    }
    #micSpeechBtn:disabled > .btn_content
    {
      pointer-events: none;
    }
    .micSpeechWarnBtn
    {
      filter: invert(15%) sepia(99%) saturate(2443%) hue-rotate(215deg) brightness(103%) contrast(101%);
    }
    .micSpeechLowBtn
    {
      filter: invert(23%) sepia(98%) saturate(7500%) hue-rotate(33deg) brightness(90%) contrast(118%);
    }
    .micSpeechLowBtn:focus > .btn_content {
    box-shadow: 0 0 0 5px #AD0000, 0 0 3px 10px #AD0000;
    outline: none;
    }
    .micSpeechNormalBtn:focus > .btn_content {
    box-shadow: 0 0 0 5px #fff, 0 0 3px 10px #023b5a;
    outline: none;
    }
    .micSpeechBtnNormal:focus,
    .btn_content:focus
    {
        outline: none;
        animation: none;
    }
    .btn_content
    {
      display: block;
      width: 75%;
      height: 100%;
      position: absolute;
      left: 15%;
      top: 0vw;
      pointer-events: all;
      border-radius: 2px;
    }
    .btn_content:hover
    {
      box-shadow: inset 0 0 0 1px #fff, 0 0 1px 2px #023b5a;
      outline: none;
    }
    .btn_content_low:hover
    {
      box-shadow: inset 0 0 0 1px #AD0000, 0 0 1px 2px #AD0000;
      outline: none;
    }
.ripple {
  width: 1vw;
  height: 1vw;
  border-radius: 100%;
  position: relative;
  margin: auto;
  top: .25vw;
  z-index: 1;
  display: block;
  -webkit-animation: woong 1.5s infinite;
  -moz-animation: woong 1.5s infinite;
  -o-animation: woong 1.5s infinite;
  animation: woong 1.5s infinite;
}
.rippleWarn
{
  background-color: #0644B5;
}
.rippleLow
{
  background-color: #AD0000;
}
.rippleNormal
{
  background-color: #7092a6;
}
@-o-keyframes woong {
  0% {
    -webkit-trasform: scale(1.2);
  }
  50% {
    -webkit-transform: scale(1.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(2.4);
    opacity: 0;
  }
}
@-webkit-keyframes woong {
  0% {
    -webkit-trasform: scale(1.2);
  }
  50% {
    -webkit-transform: scale(1.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(2.4);
    opacity: 0;
  }
}
@-moz-keyframes woong {
  0% {
    -webkit-trasform: scale(1.2);
  }
  50% {
    -webkit-transform: scale(1.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(2.4);
    opacity: 0;
  }
}
@keyframes woong {
  0% {
    -webkit-trasform: scale(1.2);
  }
  50% {
    -webkit-transform: scale(1.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(2.4);
    opacity: 0;
  }
}
  #InputFeed
  {
    background-color: lightgray;
    display: flex;
    flex-direction: column;
    height: 17%;
    width: 100%;
    padding-top: 0.1%;
    border-top: 1px solid black;
  }
  .InputFeedOutline
  {
    position:absolute;
    height: 16.2%;
    width: 99%;
    z-index: 50;
    border-radius: 5px;
    pointer-events: none;
  }
  .InputFeedWarningOutline
  {
    border: 3px solid #0644B5;
  }
  .InputFeedLowOutline
  {
    border: 3px solid #AD0000;
  }
  #currentInputDiv
  {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 60%;
    position: relative;
  }
</style>

<div id="InputFeed" style="z-index: 40;">
  <div class="InputFeedOutline" class:InputFeedWarningOutline={warnLowMicVolume} class:InputFeedLowOutline={lowMicVolume}/>
  <!-- notepad slot -->
  <div id="lastInputDiv">	
    <InputFeedNotepad/>    
    <div class="lastInputContainer" aria-label="Input History Textbox">
        {#if lowMicText && lowMicText.length}
          <p bind:this={lowMicVolumeElement} class:warnLowMicVolumeElement={warnLowMicVolume} class:lowMicVolumeElement={lowMicVolume} aria-live="assertive" aria-atomic="true" role="alert">{lowMicText}</p>
        {:else}
          <textarea disabled bind:this={lastInputTextArea} bind:value={lastInput} class="lastInputTextArea"/>
        {/if}

    </div>
  </div>
  <div id="currentInputDiv">
    <InputFeedComponent bind:matchInput={matchInputSpeechArea} bind:lastInput bind:currentTextInput bind:focusTrap={focusTrap} bind:submitInput bind:micBtn={micBtn} bind:listening let:micBtnClick={micBtnClick} bind:abortFurtherRecognition={abort_speech_recognition}>
      <!-- notepad sloat end -->
      <!-- context slot -->
      <div slot="context">
        <ContextController divID={"ctx_speak"}/>
        <div id="inputTutorial"></div>
      </div>
      <!-- context slot end -->

      <!-- textInput slot -->
      <div class="currentInputContainer" slot="textInput" tabindex="-1">            
          <textarea bind:this={inputTextArea} on:keypress={handleOnKeydown} bind:value={currentTextInput} data-cy={"triggerInput"} class="currentInputTextArea" aria-label="Input Question Text Box"></textarea>
      </div>
      <!-- textInput slot end -->

      <div id="micDiv" slot="micBtn" >
        <div id="micTutorial"></div>
        <button id="micSpeechBtn" data-cy="micSpeechBtn" class:micSpeechNormalBtn={!lowMicVolume} class:micSpeechWarnBtn={warnLowMicVolume} class:micSpeechLowBtn={lowMicVolume} bind:this={micBtn} on:click={()=>{micBtnClicked(micBtnClick)}}  onmouseup="this.blur();" aria-label="Start voice command">
          <span class="btn_content" class:btn_content_low={lowMicVolume} aria-hidden="true">
            {#if listening}
              <div class="ripple" id="ripple" class:rippleNormal={!warnLowMicVolume&&!lowMicVolume} class:rippleWarn={warnLowMicVolume} class:rippleLow={lowMicVolume}></div>
            {/if}
          </span>
        </button>
      </div>
    </InputFeedComponent>
  </div>
</div>