<script context="module">
	export function toggle_metrics()
	{
		var metrics = document.getElementById("performance_metrics").style.display;
		document.getElementById("performance_metrics").style.display = (document.getElementById("performance_metrics").style.display != "block")?"block":"none";
	}
</script>
<style lang="less">
	#performance_metrics
	{
		display: none;
		z-index: 2;
	}
	[class*="text-"]
	{
		color: #8AFFB3;
		position: absolute;
		right: 1vw;
		font-size: 1vw;
	}
	.text-fps_counter{bottom: 7vw;font-size: 1.5vw}
	.text-mount_data_dir_time{bottom: 9vw;}
	.text-read_scene_time{ bottom: 10vw; }
	.text-load_character_time{ bottom: 11vw }
	.text-preload_visemes_time{ bottom: 12vw }

</style>
<div id="performance_metrics">
	<p class="text-fps_counter" id="fpsCounter"></p>
	<p class="text-mount_data_dir_time" id="mount_data_dir_time"></p>
	<p class="text-read_scene_time" id="read_scene_time"></p>
	<p class="text-load_character_time" id="load_character_time"></p>
	<p class="text-preload_visemes_time" id="preload_visemes_time"></p>
</div>
