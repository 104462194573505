<script>
  import { onMount } from 'svelte'
  import { createEventDispatcher } from 'svelte';
  import { get_certificates } from './server_api.js';
  import { convertFromUnixTimeStamp } from "./date_util.js";
  import { environment,sample_cert_id } from './stores.js';
  import EvaluationVersion from './EvaluationVersion.svelte';
  
  const dispatch = createEventDispatcher();
  export let user;
  export let showCertificate = false;
  var certificates = [];
  var currentCertificate;
  var viewBtn;
  var onMountFinished = false;
  
  function goBack()
  {
      dispatch('certificate');
  }
  
  function viewCertificate()
  {
      showCertificate = true;
  }
  
  function closeCertificate()
  {
      showCertificate = false;
  }
  
  function setCurrentCertificate(event)
  {
      var id = event.target.value;
      for(var i=0; i!=certificates.length; ++i)
      {
          if(certificates[i].id == id)
          {
              currentCertificate = certificates[i];
              break;
          }
      }
      if(viewBtn)
          viewBtn.disabled = false;
  }
  
  
  onMount( async function() {
      var obj = await get_certificates();
      
      if(obj)
      {
          certificates = obj.certificates;
      }
      for(var i = 0; i != certificates.length; ++i)
      {
          if(!currentCertificate)
          {
              currentCertificate = certificates[i];
          }
          if(showCertificate && currentCertificate.session < certificates[i].session)
              currentCertificate = certificates[i];
      }
      if($environment === "development")
      {
          let date = Math.floor(Date.now() / 1000);
          var sample={"id":$sample_cert_id,"name":"Sample Evaluation Format",date:date};
          certificates.unshift(sample);
          if(!showCertificate)
              currentCertificate=certificates[0];
      }
  
      onMountFinished = true;
  
  });
  </script>
  
  <style lang="less">
  .viewportRatio(@x, @y)
  {
    width: 100vw;
    height: @y * 100vw / @x;
    //max-width: @x / @y * 100vh;
    max-height: 100vh;
  }
  .certificateTopBar
  {
      height: 10vw;
      width: 100%;
      position: fixed;
      background: #ededed;
  }
  .back-button {
      width: 6%;
      left: 4vw;
      position: relative;
      top: 1.5vw;
  }
  .back-button-circle {
      width: 100%;
      height: 0;
      position: relative;
      padding-bottom: 100%;
      border-radius: 50%;
      overflow: hidden;
      background: #023b5a;
      box-shadow: 0 0 3px grey;
  }
  .back-button-circle:hover {
    background:#30588e;
    cursor:pointer;
  }
  .back-button label {
      display: block;
      float: left;
      width: 100%;
      padding-top: 65%;
      padding-bottom: 50%;
      line-height: 1.2vw;
      margin-top: -0.3vw;
      text-align: center;
      color: #e2eaf3;
      font-family: Verdana;
      font-size: 1.2vw;
      font-weight: bold;
      text-decoration: none;
  }
  .backIcon
  {
      position: absolute;
      left: 10%;
      width: 80%;
      height: 80%;
      fill: #e2eaf3;
  }
  .viewIcon
  {
          position: absolute;
      width: 50%;
      height: 80%;
      top: 0.25vw;
      left: 2.5vw;
      fill: white;
  }
  .topBarParagraph
  {
      position: absolute;
      top: 31%;
      margin-block-end: 0;
      margin-block-start: 0;
      right: 40%;
      color: #023b5a;
      font-size: 3vw;
  }
  .certificateCenter
  {
      position: relative;
      display: block;
      top: 15vw;
      margin: 0 auto;
      text-align: center;
      border-radius: 2%;
      background: #ededed;
      width: 46vw;
      height: 23vw;
  }
  #certificateText
  {
      position: absolute;
      font-size: 1vw;
      left: 3vw;
      top: 1vw;
      color: #023b5a;
  }
  #certificateUsername
  {
      position: absolute;
      color: #023b5a;
      font-size: 2vw;
      left: 3vw;
      top: 3.5vw;
      margin-block-start: 0;
      margin-inline-start: .25vw;
      margin-block-end: 0;
      font-weight: bold;    
  }
  .certificateList
  {
      position: relative;
      margin: auto;
      border: 1px solid #9a9a9a;
      top: 8vw;
      border-radius: 1px;
      width: 80%;
      height: 35%;
      overflow-y: auto;
  }
  .certificateItem
  {
      display: block;
      background: none;
      font-size: 1.5vw;
      padding: 1vw 1.5vw;
      text-align: left;
      color: #023b5a;
  }
  .certificateItem:hover
  {
      background: #83aae5;
      cursor: pointer;
      color:white;
  }
  .certificateList input[type="radio"] {
      position: absolute;
      cursor: pointer;
      opacity: 0;
      width: 95%;
      height: 50%;
      left: 2.5%;
  }
  .certificateList input[type="radio"]:focus + .certificateItem {
      border: 2px solid black;
  }
  
  .certificateList input[type="radio"]:checked+label {
      background: #3A7BA1;
      color: white;
  }
  
  .certificateList input[type="radio"]:unchecked+label {
      background: #3A7BA1;
      color:white;
  }
  button
  {
      position: absolute;
      width: 5vw;
      border-radius: 3px;
      height: 2vw;
      border: none;
      bottom: 3vw;
      right: 3vw;
      background: #3A7BA1;
      color: white;
      font-family: 'Lato', sans-serif;
      text-align: left;
      text-indent: 10%;
      font-size: 0.75vw;
      cursor: pointer;
  }
  
  .backBtn
  {
      background: transparent;
      border: none !important;
      font-size: 0;
      width: 7.1vw;
      height: 7.1vw;
      position: absolute;
      padding: 1px 0px;
      border-radius: 50%;
      cursor: pointer;
      right: 0vw;
      bottom: 0vw;
  }
  .back-button-circle:focus-within {
      box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
      outline: none; // removes default focus outline
    }
  
  button:disabled
  {
      background: rgba(190, 190, 190, .5);
      color: white;
  }
  
  .viewBtn:focus
  {    
      box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
      outline: none; // removes default focus outline
  }
  
  .viewBtn:hover
  {
      background: white;
      border: 2px solid #7092a6;
      color: #7092a6;
  }
  
  .viewBtn:hover > .viewIcon
  {
      fill: #023b5a;
  }
  
    @media all and (max-aspect-ratio: ~"3/2")
    {
      .certificateMenu {
        .viewportRatio(4,3);
        top:0;bottom:0; /* vertical center */
        left:0;right:0; /* horizontal center */
        display: flex;
      }
    }
  
    @media all and (min-aspect-ratio: ~"3/2")
    {
      .certificateMenu {
        .viewportRatio(16,9);
        top:0;bottom:0; /* vertical center */
        left:0;right:0; /* horizontal center */
        display: flex;
      }
    }
  </style>
  {#if showCertificate && onMountFinished}
      <EvaluationVersion user={user} certDate={convertFromUnixTimeStamp(currentCertificate.date)} certID={currentCertificate.id} certSession={currentCertificate.session} on:close={closeCertificate}/>
  {:else}
      <div class="certificateMenu">        
          <div class="certificateTopBar">
              <div class="back-button">
                  <div class="back-button-circle">
                      <svg class="backIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="transform: scale(-1)"><path fill="none" d="M0 0h24v24H0z"/><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"/></svg>
                      <button class="backBtn" on:click={goBack} data-cy={'backBtnFinal'} aria-label="back"></button>
                      <label>BACK</label>
                  </div>
              </div>
              <p class="topBarParagraph" data-cy="evaluationsTitleText">EVALUATIONS</p>
          </div>
          <div class="certificateCenter">
              <p id="certificateText">Evaluations gained by</p>
              <p id="certificateUsername">{user}</p>
              <div class="certificateList" tabindex="0">
                  {#if certificates.length === 0}
                      <input type="radio" name="empty" id="empty" value="empty">
                      <label for=empty class="certificateItem">No evaluations recieved</label>
                  {/if}
                  {#each certificates as certificate,i}
                      <input type="radio" name="certificates" id={certificate.id} value={certificate.id} data-cy={"cert_"+i} on:click={setCurrentCertificate} checked={currentCertificate&&certificate.id==currentCertificate.id}>
                      {#if certificate.date!="" }
                          <label for={certificate.id} class="certificateItem">{certificate.name + " - " + convertFromUnixTimeStamp(certificate.date)}</label>
                      {:else}
                          <label for={certificate.id} class="certificateItem">{certificate.name}</label>
                      {/if}
                  {/each}
              </div>
              <button class="viewBtn" data-cy={"viewBtn"} bind:this={viewBtn} on:click={viewCertificate} disabled={currentCertificate==null||currentCertificate==undefined}>VIEW<svg class="viewIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"/></svg></button>    
          </div>    
      </div>
  {/if}
  