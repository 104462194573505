<script>
    import Ratings from './SeverityRatings.svelte';
    import Info from './SeverityInfo.svelte';
    export let num = 0;
    let container = "container-1";
    let info = true;
    if(num>20 && num <29)
    {
        container = "container-2";
        info = false;
    }

</script>

<style lang="less">
    .container-1
	{
        width: 36%;
        border: 1px solid #000000;
        display: flex;
        flex-direction: column;
    }
    .container-2
	{
        flex: 1;
        border: 1px solid #000000;
    }
</style>


<div class={container}>
    <Ratings num={num}/>
    <Info num={num}/>
</div>
  