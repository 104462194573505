<script>
  import pageFile from "./../pages.json";
  
</script>

<style lang="less">
  .criterionAQuestion{
    height: auto;
    display: block;
    width: 62%;
    border: 1px solid #000000;
  }
.contentParagraph{
  //font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  line-height: 1.5;
  font-size: 1vw;
  margin-inline-start: 1em;
  margin-inline-end: 1em;
  margin-block-start: 0em;
  margin-block-end: 0em;
}
.question{
  font-size: 1.15vw;
  font-weight: bold;
}

@media (max-width:1000px){
    .contentParagraph
    {
      font-size: 10px;
    }
    .question
    {
      font-size: 11px;
    }
}
</style>

<div class="criterionAQuestion">
      {#each pageFile as jsonfile}        
        {#if jsonfile.id === 0}
          {#each jsonfile.questions as content, index}
            <p class="contentParagraph" id="contentParagraph{index}"><span class="question">{@html content.question}</span>{@html content.subtext}</p>
          {/each}
        {/if}
      {/each}
</div>