import {writable, readable} from 'svelte/store';

export const page = writable({ criterion: "", itemNum: -3});

export const CAPS5_cert_id = readable(1);

export const input_disabled_dialogue = writable(true);

export const input_disabled_all = writable(false);

export const input_disabled_tutorial = writable(false);

export const userID = writable("");

export const context_toggle = writable(false);

export const debug_mode = writable(false);

export const sample_cert_id = readable(-1);

export const desiredServerVersion = writable("3.3.0");

export const dirtyServerConnection = writable(false);

export const errorConnectionMessage = writable("");

export const positive_feedback_pool = writable([]);

export const notes_text = writable("");

export const notes_timer = writable(0);

export const show_TORC_links = writable(false);

export const base_asset_path = readable(window.base_path);

export const patient_asset_path = readable(window.patient_path);

export const coach_asset_path = readable(window.coach_path);

export const media_asset_path = readable("rechat_media/");

export const common_asset_path = readable(window.common_path);

export const item_page_finished_mount = writable(false);

export const  game_mode = writable(window.c5t_game_mode);

export const  mac_intel = readable((navigator.userAgent.match("Mac") && navigator.userAgent.match("Intel"))?true:false);

export const incorrectScoreValues = writable([]);

export const strict_scoring = writable(false);

export const progress_level = writable(-1);

//Environment Vars
export const environment = readable("development");

export const local_server = readable(false);
