<script>
  import { onMount } from "svelte" ;
  import { get_saved_state, } from "./saved_states.js"
  import { post_item_completed, get_session, ServerError, get_user_item_progress } from "./server_api.js"
  import { context_toggle, game_mode } from "./stores";
  import pageFile from "./pages.json";
  import ContextController from "./ContextController.svelte";
  export let severity;
  export let num = 0;
  let loading_severity = false;
  let severityLoaded = false;
  let btns=[];
  let ariaUpdateParapraph;

  let isFocused = false;

  $: {
    if(severity !== undefined && !loading_severity )
    {
      let obj = {};
      if(num === 23)
        obj = {distress:severity};
      else if(num === 24)
        obj = {social_functioning:severity};
      else if(num === 25)
        obj = {occupational_functioning:severity};
      else if(num === 26)
        obj = {global_validity:severity};
      else if(num === 27)
        obj = {global_severity:severity};
      else if(num === 28)
        obj = {global_improvement:severity};
      else
        obj = {severity:severity};
      post_item_completed(obj).catch((err)=>{
        if(err instanceof ServerError)
        {  
          refresh_data();
        }
      });
    }
    if(loading_severity)
    {
      ToggleInput();
      loading_severity = false;
    }
      
  }

  function severityBtnPressed(target, index)
  {
    if(target)
    {
      target.click();
      if(target.checked)
        ariaUpdateParapraph.innerHTML = "severity " + index + "checked";
      else
        ariaUpdateParapraph.innerHTML = "severity " + index + "unchecked";
    }
  }
  function severityPressed(target)
  {
    if(target === null)
      return;
    if(target.value == severity)
    {
      severity = null;
      target.checked = false;
    }
    else
    {
      target.checked = true;
    }
  }

  function clear()
  {
    severity = null;
  }


  async function ToggleInput()
  {
    let disabled = true;
    let progress = await get_user_item_progress(get_session());

    disabled = (progress[num].passed != null && progress[num].passed != 0) && $game_mode == "learn" ? true : false;

    var severityBtns = document.getElementsByClassName("severityBtn");
    var i;

    for (i = 0; i < severityBtns.length; i++) {
      if(disabled)
        severityBtns[i].disabled = true;
      else
        severityBtns[i].disabled = false;
      
    }

    var btnsRadio = document.getElementsByClassName("severityRadio");
    for (i = 0; i < btnsRadio.length; i++) {
      if(disabled)
        btnsRadio[i].disabled = true;
      else
        btnsRadio[i].disabled = false;
    }
  }
  
  function refresh_data()
  {
    clear();
    //loading_severity=true;    
    let saved_state = get_saved_state(num,get_session());
    if(saved_state)
    {

      if(saved_state.severity != null && saved_state.severity != undefined)
      {
        loading_severity = true;
        severity = saved_state.severity;
      }
      else if(saved_state.distress != null && saved_state.distress != undefined)
      {
        loading_severity = true;
        severity = saved_state.distress;
      }
      else if(saved_state.social_functioning != null && saved_state.social_functioning != undefined)
      {
        loading_severity = true;
        severity = saved_state.social_functioning;
      }
      else if(saved_state.occupational_functioning != null && saved_state.occupational_functioning != undefined)
      {
        loading_severity = true;
        severity = saved_state.occupational_functioning;
      }
      else if(saved_state.global_severity != null && saved_state.global_severity != undefined)
      {
        loading_severity = true;
        severity = saved_state.global_severity;
      }
      else if(saved_state.global_improvement != null && saved_state.global_improvement != undefined)
      {
        loading_severity = true;
        severity = saved_state.global_improvement;
      }
      else if(saved_state.global_validity != null && saved_state.global_validity != undefined)
      {
        loading_severity = true;
        severity = saved_state.global_validity;
      }
      else
        ToggleInput();
    }    
  }
  function checkSev0(event)
  {
    var btnUnlikely = document.getElementById("btnUnlikely");
    var percentInput = document.getElementById("percentageInput");
    var nextTarget = document.getElementById("sevInfoDiv");  

    if(event.relatedTarget == percentInput  || (event.relatedTarget && event.relatedTarget == btnUnlikely))
    {
      nextTarget.focus();
    }      
  }

  function checkSev4(event)
  {
    let target = document.getElementById("backwardButton");
    var nextTarget = document.getElementById("sevInfoDiv");
    if(event.relatedTarget == nextTarget)
      target.focus();
  }
  onMount( async function() { refresh_data(); })
</script>

<style lang="less">
  .container
  {
    padding: 1vw;
    position: relative;
  }
  .severityBtn:focus > .btn_content 
  {
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none;
  }
  #contextSeverityBtn:focus > .btn_content 
  {
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none;
  }
  /* Removing default outline only after we've added our custom one */
  #contextSeverityBtn:focus,
  .severityBtn:focus,
  .btn_content:focus
  {
      outline: none;
  }
  .btn_content
  {
    display: inline-block;
    width: 100%;
    height: 100%;
    pointer-events: all;
  }
  input#severity
  {
    margin: .5rem;
    overflow-y: auto;
  }
  label
  {
    font-size: 1.15vw;
    display: block;
    cursor: pointer;
    width: fit-content;
    pointer-events: none;
  }
  ul
  {
    list-style: none;
    padding: 0;
    margin: 0 auto;
  }
  li
  {
    margin-block-start: 1vw;
    position: relative;
    width: fit-content;
  }
  [type=radio]
  { 
    position: absolute;
    cursor: pointer;
    opacity: 0;
    width: 0;
    height: 0;
  }
  [type=radio] + .svg-container {cursor: pointer;}
  [type=radio]:checked + .svg-container{display: inline-block;}
  .cls-1
  {
    fill:none;
    stroke:#000;
    stroke-miterlimit:10;
    stroke-width:0.75px;
  }
  path
  {
    stroke-dasharray: 49.9541130065918;
    stroke-dashoffset: 49.9541130065918;
    animation: draw .1s ease-out forwards;
  }
  @keyframes draw 
  {
    to { stroke-dashoffset: 0; }
  }
  svg
  {
    position: relative;
    width: 1.5vw;
    height: 1.5vw;
    left: -0.5vw;
    top: .05vw;
  }
  .svg-container
  {
    position: absolute;
    display: none;
    width: 5%;
    height: 5%;
  }
  .severityBtn
  {
    background: transparent;
    border: none !important;
    font-size: 0;  
    height: 1.25vw;
    width: 100%;
    position: absolute;
    padding: 1px 1px;
    left: -0.15vw;
    top: .15vw;
    cursor: pointer;
    pointer-events: none;
  }
  #selectedDiv {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
  @media (max-width:1000px)
  {
    label
    {    
      font-size: 10px;
    }
    svg
    {
      width: 13px;
      height: 14px;
      top: 0vw;
    }
  }
</style>

<div class="container"> 
  <div id="selectedDiv"><p bind:this={ariaUpdateParapraph} aria-live="polite"></p></div>
  <ContextController divID={"ctx_select"}/>
  {#each pageFile as page}
    {#if page.id === num}
      <ul>
        {#each page.severity as s,i}
          <li>   
            <label>
              {#if i == 0 && (num < 21 || num > 28)}
                <button class="severityBtn" on:focus={(event)=>checkSev0(event)} on:blur={(event)=>checkSev0(event)} on:click={(event)=>severityBtnPressed(btns[i],i)} tabindex={$context_toggle?"-1":"0"} data-cy={'severityBtn' + i}>
                  <span class="btn_content" tabindex="-1" aria-hidden="true"></span>
                </button>
              {:else if i == (page.severity.length -1) && (num < 21 || num > 28)}
                <button class="severityBtn" on:blur={(event)=>checkSev4(event)} on:click={(event)=>severityBtnPressed(btns[i],i)} tabindex={$context_toggle?"-1":"0"}  data-cy={'severityBtn' + i}>
                  <span class="btn_content" tabindex="-1" aria-hidden="true"></span>
                </button>
              {:else}
                <button class="severityBtn" on:click={(event)=>severityBtnPressed(btns[i],i)} tabindex={$context_toggle?"-1":"0"}  data-cy={'severityBtn' + i}>
                  <span class="btn_content" tabindex="-1" aria-hidden="true"></span>
                </button>
              {/if}
              <input class="severityRadio" type="radio" bind:group={severity} bind:this={btns[i]} value={i} on:click={(event)=>severityPressed(btns[i])} tabindex="-1" data-cy={'severityBtnInput' + i}>
              <div class="svg-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.09 15.96"><defs></defs><path class="cls-1" d="M9.79,12.15a9.86,9.86,0,0,1-3.56.48c-1.71-.1-4-.21-5.19-2.41A4.63,4.63,0,0,1,.38,8C.32,4.52,5.47.22,10.34.38c3.26.11,8.06,2.3,8.36,6.09.27,3.33-3,7.67-8.16,9.13"/></svg>
              </div>
              {i} {@html s}
            </label>
          </li>
        {/each}
      </ul>
    {/if}        
  {/each}
</div>
