<script context="module">
let introTutorialStarted = false;
let conversationManager;
export function setIntroTutorialStarted(value)
{
  introTutorialStarted = value;
}
export function setConversationManager(convMgr)
{
  conversationManager = convMgr;
}
</script>
<script>
  import { onMount, onDestroy } from "svelte"; 
  import { page, input_disabled_dialogue } from './stores.js';
  import { toggleTutorialAnimation } from './TutorialAnimationController.svelte';
  import { get_session, get_user_item_progress } from "./server_api.js"
  import { getTutorialCard } from './ReChat.svelte'
  import pageFile from "./introPages.json";
  export let num = 0;

  onMount( async function(){    
    let progress = await get_user_item_progress(get_session()); 
    if(progress) //has progress don't show tutorial
      introTutorialStarted = true;
    if(!introTutorialStarted)
    {          
      introTutorialStarted = true;
      await conversationManager.matchInput("start_instructions_tutorial", false);
      toggleTutorialAnimation(getTutorialCard(),$page.itemNum);
    }
    $input_disabled_dialogue = true;
  });

  onDestroy(function(){
    $input_disabled_dialogue = false;
  })

</script>

<style lang="less">
  #container
  {
    margin: 0.5vw;
    overflow-y: auto;
    height: 90%;
  }
  #title_container
  {
    font-size: 2vw;
    font-weight: bold;
    padding-bottom: .5vw;
    border-bottom: 4px solid black;
  }
  .title
  {
    margin: 0;
    font-size: 2vw;
  }
  .introSubList
  {
    padding-left: 1.25vw;
  }
  p
  {
    font-size: .95vw;
  }
  ol
  {
    padding-left: 1.5%;
  }
  ul
  {
    list-style-type: none;
  }
  ol li, ul li
  {
    padding-left: .5vw;
    margin-bottom: .5vw;  
    font-size: .95vw;
  }
  #instructionPageTutorial
  {
    border: 0.3vw solid #597f96;
    border-radius: 10px;
    position: absolute;
    display: none;
    width: 100%;
    height: 88%;
    top: 3.5%;
    bottom: 0px;
    z-index: 200;
    margin-left: -.5%;
  }

  @media (max-width:1000px)
  {
    ol li, ul li, p { font-size: 10px; }
  }

</style>

<div id="instructionPageTutorial"></div>
<div id="container">
  {#each pageFile as page}
    {#if page.id === num}
      {#each page.content as content}
        <div id="title_container">
          {#if content.title}
            <p class="title">{@html content.title}</p>
          {/if}
        </div>
        {#if content.text}
          <div class="introListDiv">
            {#if content.title === "Instructions:"}
              <p>{@html content.text[0].maintext}</p>
            {:else} 
              <ol class="introList" start={content.start}> 
                {#each content.text as text}  
                  {#if text.subText}              
                    <li class="titleHeader">{@html text.maintext}</li>    
                    {#if page.id === 1}          
                      <ol type="1" class="introSubList">
                        {#each text.subText as subText}            
                          {#if subText}
                            <li>{@html subText.text}</li>
                          {/if}            
                        {/each}
                      </ol>
                    {:else}
                      {#if content.title === "Scoring"}
                        <ul class="introSubList">
                          {#each text.subText as subText}            
                            {#if subText}
                              <li>{@html subText.text}</li>
                            {/if}            
                          {/each}
                        </ul>
                      {:else}
                        <ol type="a" class="introSubList">
                          {#each text.subText as subText}            
                            {#if subText}
                              <li>{@html subText.text}</li>
                            {/if}            
                          {/each}
                        </ol>
                      {/if}           
                    {/if}           
                  {/if}                    
                {/each}
              </ol>
            {/if} 
          </div> 
        {/if}
      {/each}
    {/if}
  {/each}
</div>