<script>
  import { onMount, onDestroy } from 'svelte'
  import { createEventDispatcher } from 'svelte';
  import { get_evaluation }  from "./evaluation_data.js"
  import { game_mode } from './stores.js';
  import evaluation_avm from './evaluation_avm.json';
  import evaluation_validate from './evaluation_validate.json';
  import evaluation_learn from './evaluation.json';
  import eval_captions_v1 from './eval_captions_v1.json';
  import eval_captions_v2 from './eval_captions_v2.json';
  import EvaluationTable_v1 from './eval_table_v1.svelte';
  import EvaluationTable_v2 from './eval_table_v2.svelte';
  import { open_transcript } from './TranscriptReview.svelte';
  import { save_transcript, set_transcript, reset_transcript } from './TranscriptStates.svelte';
  import Transcript from './Transcript.svelte';
  import TranscriptNavigation from './TranscriptNavigation.svelte';
  import TranscriptTitle from './TranscriptTitle.svelte';
  import ContextController from './ContextController.svelte';
  
  import { base_asset_path } from './stores.js'
  const dispatch = createEventDispatcher();
  
  export let user;
  export let certDate;
  export let certID;
  export let certSession;
  export let e;
  
  let eval_title="";
  let eval_title2="";
  let eval_title3="";
  let eval_title_overview="";
  let eval_title_yourratings="";
  let eval_title_yourratingsctnd="";
  let eval_title_accuracy="";
  let eval_title_administration="";
  let eval_title_errors="";
  let eval_title_evaldetails="";
  let tbl_your_scored_header="";
  let eval_back="";
  let eval_print="";
  let eval_print1="";
  let eval_print2="";
  let eval_title_bar="";
  
  let eval_overview="";
  let eval_accuracy="";
  let eval_admin="";
  let eval_errors="";
  let eval_details_text="";
  let eval_item_details=[];
  let eval_ratings=[];
  let other_considerations=[];
  
  let topbar;
  let evalMenu;
  let printEvalBtn;
  let printSummBtn;
  let printTranscriptBtn
  let topbarText;
  let doneBtn;
  let backLabel;
  
  var evaluationFile;
  if($game_mode=="learn")
    evaluationFile = evaluation_learn;
  else if($game_mode=="validate")
    evaluationFile = evaluation_validate;
  else
    evaluationFile = evaluation_avm;
  
  
  function done()
  {
      dispatch('close');
  }
  
  function get_time_string(seconds)
  {
    if(seconds<0)
        throw "Elapsed seconds can't be  0";
  
    var d = Math.floor(seconds/86400);
    var rem = seconds%86400;
    var h = Math.floor(rem/3600);
    rem = rem%3600;
    var m = Math.floor(rem/60);
    var s = rem%60;
    var r="";
    if( d > 0 )
    {
     r = r.concat( d, " day" );
     if( d > 1 )
      r = r.concat( "s" );
    }
    if( h > 0 )
    {
     r = r.concat( " ", h, " hour" );
     if( h > 1 )
      r = r.concat( "s" );
    }
    if( m > 0 )
    {
     r = r.concat( " ", m, " minute" );
     if( m > 1 )
      r = r.concat( "s" );
    }
    if( s > 0 )
    {
     r = r.concat( " ", s, " second" );
     if( s > 1 )
      r = r.concat( "s" );
    }
    if( d <=0 && h <= 0 && m <= 0 && s <=0 )
      r = r.concat( "less than a second" );
    return r;
  }
  
  
  function get_eval_summary_text_v1()
  {
    eval_title = eval_title.concat(eval_captions_v1.title, user, eval_captions_v1.Mode, "<br>", certDate, "<br>", eval_captions_v1.EvalVersion, "<br><br>", eval_captions_v1.Congrats, "<br><br>" );
    eval_back=eval_captions_v1.Back;
    eval_print=eval_captions_v1.PrintEval;
    eval_print1=eval_captions_v1.PrintSummary;
    eval_print2=eval_captions_v1.PrintTranscript;
    eval_title_bar=eval_captions_v1.Evaluation;
    eval_title_overview = eval_captions_v1.Overview;
    eval_title_yourratings = eval_captions_v1.YourRatings;
    eval_title_accuracy = eval_captions_v1.AccuracyTitle;
    eval_title_administration = eval_captions_v1.AdministrationTitle;
    eval_title_errors = eval_captions_v1.Errors;
    eval_title_evaldetails = eval_captions_v1.EvalDetails;
    
    eval_overview = eval_overview.concat("<ul class=\"a\">");
    eval_overview = eval_overview.concat("<li><b>",eval_captions_v1.InitialAScore,e.ratings[0].initial,eval_captions_v1.CorrectScore_,e.ratings[0].correct,eval_captions_v1.braket,"</li><br>");
    eval_overview = eval_overview.concat("<li>",eval_captions_v1.InitalSeverityScore,e.initial_severity_score,eval_captions_v1.CorrectScore_,e.correct_total_severity,eval_captions_v1.braket,"</li><br>");
    eval_overview = eval_overview.concat("<li>",eval_captions_v1.PtsdScore,e.ptsd_score,eval_captions_v1.of20,"</li>");
    eval_overview = eval_overview.concat("<li>",eval_captions_v1.InitialItem21Score, e.ratings[21].initial, eval_captions_v1.CorrectScore21,"</li>");
    eval_overview = eval_overview.concat("<li>",eval_captions_v1.InitialItem22Score, e.ratings[22].initial, eval_captions_v1.CorrectScore22,"</li>");
    eval_overview = eval_overview.concat("<li>",eval_captions_v1.DistressScore,e.distress_score,eval_captions_v1.of3,"</li>");
    eval_overview = eval_overview.concat("<li>",eval_captions_v1.GlobalRatigsScore,e.global_score,eval_captions_v1.of3,"</li>");
    eval_overview = eval_overview.concat("<li>",eval_captions_v1.DissociativeScore,e.dissociative_score,eval_captions_v1.of2,"</li><br>"); 
    eval_overview = eval_overview.concat("<li>",eval_captions_v1.TotalTime,get_time_string(e.course_time),"</b></li></ul><br>"); 
  
    eval_accuracy = eval_accuracy.concat(evaluationFile.Accuracy1,e.severity_range,evaluationFile.Accuracy2,e.double_severity_range,evaluationFile.Accuracy3,"<br><br>");
    eval_accuracy = eval_accuracy.concat(evaluationFile.Accuracy4,e.correct_total_severity,evaluationFile.Accuracy5,e.severity_range,evaluationFile.Accuracy6, "<br><br>");
    eval_accuracy = eval_accuracy.concat(eval_captions_v1.OnAdministration, "<br><ul class=\"a\"><li>", eval_captions_v1.SeverityResult, e.initial_severity_score); 
    var correct_score_min=e.correct_total_severity-e.severity_range;
    var correct_score_max=e.correct_total_severity+e.severity_range;
    if(e.correct_total_severity==e.initial_severity_score)
      eval_accuracy = eval_accuracy.concat(eval_captions_v1.Perfect, "</li></ul><br>");
    else if(correct_score_min<=e.initial_severity_score&&correct_score_max>=e.initial_severity_score)
      eval_accuracy = eval_accuracy.concat(eval_captions_v1.Valid,correct_score_min,"-",correct_score_max,eval_captions_v1.Fullstop,"</li></ul><br>");
    else
      eval_accuracy = eval_accuracy.concat(eval_captions_v1.Invalid,correct_score_min,"-",correct_score_max,eval_captions_v1.Fullstop,"</li></ul><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.Administration, "<br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.OnAdministration, "<br><ul class=\"a\"><li>");
    if(e.introduction)
      eval_admin = eval_admin.concat(eval_captions_v1.DeliveredVerbatim, "</li></ul><br><br>");
    else
      eval_admin = eval_admin.concat(eval_captions_v1.DeliveredNonVerbatim, "</li></ul><br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.EvenDescription,"<br><br>")
    eval_admin = eval_admin.concat(eval_captions_v1.OnAdministration, "<br><ul class=\"a\"><li>",eval_captions_v1.AcceptableDesciption, e.acceptable_desciption, eval_captions_v1.OutOf,e.total_acceptable_description, eval_captions_v1.Times, "</li></ul><br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.LastMonth,"<br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.OnAdministration, "<br><ul class=\"a\">");
    eval_admin = eval_admin.concat("<li>",eval_captions_v1.TimePeiod, e.clarified_time_PTSD, eval_captions_v1.TimePeiod2, e.clarified_time_distress, eval_captions_v1.TimePeiod3,"</li><br>");
    eval_admin = eval_admin.concat("<li>",eval_captions_v1.TimePeiodBefore, e.correct_time_PTSD, eval_captions_v1.TimePeiod2, e.correct_time_distress, eval_captions_v1.TimePeiod3,"</li></ul><br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.AccuracyOfPrompts,"<br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.OnAdministration, "<br><ul class=\"a\">")
    eval_admin = eval_admin.concat("<li>",eval_captions_v1.VerbarimPrompts, e.verbatim_prompts, eval_captions_v1.OutOf, e.items_with_prompts, eval_captions_v1.items, "</li><br>");
    eval_admin = eval_admin.concat("<li>",eval_captions_v1.NonVerbarimPrompts, e.paraphrased_prompts, eval_captions_v1.OutOf, e.items_with_prompts, eval_captions_v1.items, "</li><br>");
    eval_admin = eval_admin.concat("<li>",eval_captions_v1.OffScript, e.off_script_prompts,eval_captions_v1.OutOf, e.items_with_prompts, eval_captions_v1.items,"</li></ul><br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.TraumaRelatedness,"<br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.OnAdministration,"<br><ul class=\"a\"><li>",eval_captions_v1.TraumaRelatednessScore,e.trauma_relatedness_score,eval_captions_v1.OutOf13, "</li></ul><br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.RequiredPrompts,"<br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.OnAdministration,"<br><ul class=\"a\"><li>",eval_captions_v1.RequiredPromptsScore,e.required_prompts,eval_captions_v1.OutOf, e.items_with_prompts, eval_captions_v1.items, "</li></ul><br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.AskedInOrder,"<br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.OnAdministration,"<br><ul class=\"a\"><li>",eval_captions_v1.AskedInOrderScore,e.total_items_in_order,eval_captions_v1.OutOf24, "</li></ul><br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.ClarifyingPrompts,"<br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.OnAdministration,"<br><ul class=\"a\"><li>",eval_captions_v1.ClarifyingPromptsScore,e.clarifying_prompts,eval_captions_v1.OutOf,e.total_clarifying_prompts,eval_captions_v1.Times,"</li></ul><br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.ClarifiedFrequency,"<br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.OnAdministration,"<br><ul class=\"a\"><li>",eval_captions_v1.ClarifiedFrequencyScore,e.clarified_frequency,eval_captions_v1.OutOf,e.total_clarified_frequency, eval_captions_v1.Times, "</li></ul><br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.PTSDSubstitutes,"<br><br>");
    eval_admin = eval_admin.concat(eval_captions_v1.OnAdministration,"<br><ul class=\"a\"><li>",eval_captions_v1.PTSDSubstitutesScore,e.total_ptsd_substitutes-e.ptsd_substitutes,eval_captions_v1.OutOf,e.total_ptsd_substitutes,eval_captions_v1.Questions,"</li></ul><br><br>");
  
    eval_errors = eval_errors.concat(eval_captions_v1.YesNo,"<br><br>");
    eval_errors = eval_errors.concat(eval_captions_v1.OnAdministration,"<br><ul class=\"a\"><li>",eval_captions_v1.YesNoScore,e.yes_no,eval_captions_v1.OutOf, e.items_with_prompts, eval_captions_v1.items,"</li></ul><br><br>");
    eval_errors = eval_errors.concat(eval_captions_v1.UnnecessaryPrompts,"<br><br>");
    eval_errors = eval_errors.concat(eval_captions_v1.OnAdministration,"<br><ul class=\"a\"><li>",eval_captions_v1.UnnecessaryPromptsScore,e.unnecessary_prompts,eval_captions_v1.OutOf, e.items_with_prompts, eval_captions_v1.items, "</li></ul><br><br>");
    eval_errors = eval_errors.concat(eval_captions_v1.AssumedInfo,"<br><br>");
    eval_errors = eval_errors.concat(eval_captions_v1.OnAdministration,"<br><ul class=\"a\"><li>",eval_captions_v1.AssumedInfoScore,e.assumed_info,eval_captions_v1.Times, "</li></ul><br><br>");
  }
  
  function get_eval_summary_text_v2()
  {
    let part = $game_mode=="learn"?2:$game_mode=="validate"?3:4;
    eval_title = eval_title.concat(eval_captions_v2.title, part, "<br>", eval_captions_v2.evaluationFor, user);
    eval_title2 = eval_title2.concat( certDate, "<br>", eval_captions_v2.EvalVersion );
    eval_title3 = eval_title3.concat( eval_captions_v2.Congrats, part, "!<br><br>" );
    eval_back=eval_captions_v2.Back;
    eval_print=eval_captions_v2.PrintEval;
    eval_print1=eval_captions_v2.PrintSummary;
    eval_print2=eval_captions_v2.PrintTranscript;
    eval_title_bar=eval_captions_v2.Evaluation;
    eval_title_overview = eval_captions_v2.Overview;
    eval_title_yourratings = eval_captions_v2.YourRatings;
    eval_title_accuracy = eval_captions_v2.AccuracyTitle;
    eval_title_administration = eval_captions_v2.AdministrationTitle;
    eval_title_errors = eval_captions_v2.Errors;
    eval_title_evaldetails = eval_captions_v2.EvalDetails;
    tbl_your_scored_header = evaluationFile.TblHeadingCol1;
  
    let player_severity_score = $game_mode=="learn"?e.initial_severity_score:e.final_severity_score;
    eval_overview += "<ul class=\"a\">";
    eval_overview += "<li>"+evaluationFile.OverviewStart+player_severity_score+eval_captions_v2.OverviewText1+e.correct_total_severity+eval_captions_v2.Fullstop+"<br><br>";
    let correct_score_min=e.correct_total_severity-e.severity_range;
    let correct_score_max=e.correct_total_severity+e.severity_range;
    if(e.correct_total_severity==player_severity_score)
      eval_overview += "<ul class=\"b\"><li>"+evaluationFile.Perfect+ "</li></ul><br>";
    else if(correct_score_min<=player_severity_score&&correct_score_max>=player_severity_score)
      eval_overview += "<ul class=\"b\"><li>"+evaluationFile.Valid+"</li></ul><br>";
    else
      eval_overview += "<ul class=\"b\"><li>"+evaluationFile.Invalid+"</li></ul><br>";
    
    let prompts_percent = e.required_prompts_used/e.required_prompts_total;
    eval_overview = eval_overview.concat("<li>",eval_captions_v2.Overview2,Math.round(prompts_percent*100),eval_captions_v2.OverviewText2,"<br><br>");
    for(let i in eval_captions_v2.promptsRequired)
      if(prompts_percent<eval_captions_v2.promptsRequired[i].range)
      {
        eval_overview = eval_overview.concat("<ul class=\"b\"><li>",eval_captions_v2.promptsRequired[i].text,"</li></ul><br>");
        break;
      }
    eval_overview = eval_overview.concat("<li>",eval_captions_v2.Overview3,e.total_items_in_order,eval_captions_v2.ofThe,e.items_with_prompts, eval_captions_v2.itemsWithPrompts,"<br><br>");
    for(let i in eval_captions_v2.promptsInOrder)
      if(e.total_items_in_order<eval_captions_v2.promptsInOrder[i].range)
      {
        eval_overview = eval_overview.concat("<ul class=\"b\"><li>",eval_captions_v2.promptsInOrder[i].text,"</li></ul><br>");
        break;
      }
    for(let i in eval_captions_v2.scoresLookup)
      if(e.score_reveal>eval_captions_v2.scoresLookup[i].range)
      {
        eval_overview += "<li>"+eval_captions_v2.Overview4+e.score_reveal+eval_captions_v2.of+e.score_reveal_total+eval_captions_v2.OverviewText4+"<br><br>"+"<ul class=\"b\"><li>"+eval_captions_v2.scoresLookup[i].text+"</li></ul><br>";
        break;
      }
    eval_overview = eval_overview.concat("<li>",eval_captions_v2.Overview5,"<br><br>");
    eval_overview = eval_overview.concat("<ul class=\"b\"><li>",get_time_string(e.course_time),"</li></ul></li></ul><br>");
  
    eval_accuracy = eval_accuracy.concat(evaluationFile.Accuracy1,e.severity_range,evaluationFile.Accuracy2,e.double_severity_range,evaluationFile.Accuracy3,"<br><br>");
    eval_accuracy = eval_accuracy.concat(evaluationFile.Accuracy4,e.correct_total_severity,evaluationFile.Accuracy5,e.severity_range,evaluationFile.Accuracy6, "<br><br>");
    eval_admin = eval_admin.concat(eval_captions_v2.Administration, "<br><br>");
    eval_admin = eval_admin.concat(evaluationFile.EventDescription,"<br><br>")
    eval_admin = eval_admin.concat(eval_captions_v2.LastMonth,"<br><br>");
    eval_admin = eval_admin.concat(eval_captions_v2.AccuracyOfPrompts,"<br><br>");
    eval_admin = eval_admin.concat(eval_captions_v2.TraumaRelatedness,"<br><br>");
    eval_admin = eval_admin.concat(eval_captions_v2.RequiredPrompts,"<br><br>");
    eval_admin = eval_admin.concat(eval_captions_v2.AskedInOrder,"<br><br>");
    eval_admin = eval_admin.concat(eval_captions_v2.ClarifyingPrompts,"<br><br>");
    eval_admin = eval_admin.concat(eval_captions_v2.ClarifiedFrequency,"<br><br>");
    eval_admin = eval_admin.concat(eval_captions_v2.PTSDSubstitutes,"<br><br>");
  
    eval_errors = eval_errors.concat(eval_captions_v2.YesNo,"<br><br>");
    eval_errors = eval_errors.concat(eval_captions_v2.UnnecessaryPrompts,"<br><br>");
    eval_errors = eval_errors.concat(eval_captions_v2.AssumedInfo,"<br><br>");
  }
  function item_has_tag(itemNum,tag)
  {
    if(e.tags!=null)
      if(e.tags[itemNum])
        for(let i in e.tags[itemNum])
        {
          if(e.tags[itemNum][i][tag]&&e.tags[itemNum][i][tag]==true)
            return true; 
        }
    return false;
  }
  function item_has_multiple_tags(itemNum, ccTagCollection, e_f)
  {  
    let isInitial = false;
    let isPastMonth = false;
    let isPastMonthNotStart = false;
    let isIndexReminder = false;
    let multiTagSet = false;
  
    let searchArray = [];
    let textArray = [];
  
    //Checking through all the tags for the given item to find the extra tags
    for(let item in e.tags[itemNum])
    {
      if(Object.keys(e.tags[itemNum][item]).includes("initial"))
      {            
        isInitial = true;      
      }
      if(Object.keys(e.tags[itemNum][item]).includes("past_month"))
      {            
        isPastMonth = true;      
      }
      if(Object.keys(e.tags[itemNum][item]).includes("past_month_not_start"))
      {            
        isPastMonthNotStart = true;      
      }
      if(Object.keys(e.tags[itemNum][item]).includes("index_reminder"))
      {            
        isIndexReminder = true;      
      }    
    }  
  
    //Setting up the search array based on extra tags
    for(let tag in ccTagCollection)
    {
      if(ccTagCollection[tag] == "off_script" && !multiTagSet)
      {
        multiTagSet = true;
        //Special case that only applies to Criterion A                                      
        if(isIndexReminder && itemNum == 0)
        {
          searchArray.push(["index_reminder","off_script"]);        
        }   
        else if(isInitial)
        {
          if(isPastMonth)
            searchArray.push(["initial","off_script","past_month"]);
          else
            searchArray.push(["initial","off_script"]);
        }  
        else 
          searchArray.push(["off_script"]);    
      }
      else if(ccTagCollection[tag] == "paraphrased" && !multiTagSet)
      {
        multiTagSet = true;
        if(isInitial)
        {
          if(isPastMonth)
            searchArray.push(["initial","paraphrased","past_month"]);
          else if(isPastMonthNotStart)
            searchArray.push(["initial","paraphrased","past_month_not_start"]);
          else
            searchArray.push([ccTagCollection[tag]]);
        }
        else
          searchArray.push([ccTagCollection[tag]]);
      }
      else
        searchArray.push([ccTagCollection[tag]]);
    }
    //Now we search through the evaluation file and see if our searchArray fits the evaluation file tag array
    let arrayComp = (compArr, targetArr) => targetArr.every(i => compArr.includes(i));
    for(let i in searchArray)
    {    
      for(let j in e_f.cc_tags)
      {
        
        if(typeof(e_f.cc_tags[j].tag) != 'string')
        {
          if(arrayComp(searchArray[i], e_f.cc_tags[j].tag))
          {
            textArray.push(e_f.cc_tags[j].text);
          } 
        }
        else if(searchArray[i] == e_f.cc_tags[j].tag[0])
        {
          textArray.push(e_f.cc_tags[j].text);
        }
      }
    }   
    return textArray;
  }
  
  function get_eval_details_text_v1()
  {
    var correct_trauma_scores = 0;
    for (let i in evaluationFile.criterions)
    {
      var criterion_header = "";
      if(evaluationFile.criterions[i].prefix==true)
        criterion_header = criterion_header.concat("<u>",eval_captions_v1.stringCRITERION,"<b>",evaluationFile.criterions[i].criterion_title, "</b></u><br><br>");
  
      for (let j in evaluationFile.criterions[i].criterions)
      {
        var itemNum = evaluationFile.criterions[i].criterions[j].itemNum;
        var item_eval_details="";
        item_eval_details = item_eval_details.concat(criterion_header);
        criterion_header="";
        item_eval_details = item_eval_details.concat("<b>",eval_captions_v1.stringITEM, evaluationFile.criterions[i].criterions[j].title,"</b>");
      
        item_eval_details = item_eval_details.concat("<ul class=\"a\"><li>",eval_captions_v1.AccuracyDetails,"</li>");
        item_eval_details = item_eval_details.concat("<ul class=\"c\"><li>",eval_captions_v1.InitalRating,e.ratings[itemNum].initial,eval_captions_v1.CorrectRatingWas,e.ratings[itemNum].correct,"</li>");
        if(itemNum==0)
        {
          for(let cAf in eval_captions_v1.criterionAfields)
            item_eval_details = item_eval_details.concat("<li>",eval_captions_v1.criterionAfields[cAf],e.A_attempts[cAf],eval_captions_v1.IncorrectAttempts,"</li>");
        }
        else if(itemNum==21||itemNum==22)
          item_eval_details = item_eval_details.concat("<li>",eval_captions_v1.YouMade,e.ratings[itemNum].attempts,eval_captions_v1.Attempts1,"</li>");
        else
          item_eval_details = item_eval_details.concat("<li>",eval_captions_v1.YouMade,e.ratings[itemNum].attempts,eval_captions_v1.Attempts,"</li>");
        item_eval_details = item_eval_details.concat("</ul><br>");//c
      
        item_eval_details = item_eval_details.concat("<li>",eval_captions_v1.Explanation,"</li>");
        for (let k in evaluationFile.criterions[i].criterions[j].items)
        {
          if(evaluationFile.criterions[i].criterions[j].items[k].explanation!=null)
          {
            item_eval_details = item_eval_details.concat(evaluationFile.criterions[i].criterions[j].items[k].title,"<br><br>");
            item_eval_details = item_eval_details.concat("<ul class=\"b\"><li>",evaluationFile.criterions[i].criterions[j].items[k].explanation,"</li></ul><br>");
          }
        }
        
        var cc_tags ="";      
        for(let cct in evaluationFile.cc_tags)
        {
          if(item_has_tag(itemNum,evaluationFile.cc_tags[cct].tag))
            cc_tags = cc_tags.concat("<li>",evaluationFile.cc_tags[cct].text,"</li><br>");
        }
        if(cc_tags!="")
        {
          item_eval_details = item_eval_details.concat("<li>",eval_captions_v1.CoachFeedback,"</li>");
          item_eval_details = item_eval_details.concat("<ul class=\"c\">",cc_tags,"</ul><br>");
        }
      
        if(evaluationFile.criterions[i].criterions[j].considerations)
        {
          var considerations ="";
          for (let k in evaluationFile.criterions[i].criterions[j].considerations)
          {
            var tag_condition_met;
            if(evaluationFile.criterions[i].criterions[j].considerations[k].not_used==null || evaluationFile.criterions[i].criterions[j].considerations[k].not_used==false)
              tag_condition_met=false;
            else
              tag_condition_met=true;
            for (let l in evaluationFile.criterions[i].criterions[j].considerations[k].tag)
            {
              var t = item_has_tag(itemNum,evaluationFile.criterions[i].criterions[j].considerations[k].tag[l]);
              if(t)
              {
                tag_condition_met=!tag_condition_met;
                break;
              }
            }
            if(tag_condition_met)
            {
              considerations = considerations.concat("<li>",evaluationFile.criterions[i].criterions[j].considerations[k].text,"</li>");
            }
          }
          if(considerations!="")
          {
            item_eval_details = item_eval_details.concat("<li><b>",eval_captions_v1.OtherConsiderations,"</b></li>");
            item_eval_details = item_eval_details.concat("<ul class=\"c\">",considerations,"</ul><br>");
          }
        }
  
       
        item_eval_details = item_eval_details.concat(eval_captions_v1.AdministrationDetails);
      
        var item_admin_details=String("<ul><ul class=\"c\"><li>"+eval_captions_v1.TimeSpentOnItem+get_time_string(e.ratings[itemNum].time)+"</li>");
        if(itemNum==21||itemNum==22||itemNum==23||itemNum==24)
          item_admin_details = item_admin_details.concat(admin_details_no_out_of_order(itemNum));
        else if(itemNum==26||itemNum==27||itemNum==28)
          item_admin_details = item_admin_details.concat(admin_details_only_unnecessary(itemNum));
        else
          item_admin_details = item_admin_details.concat(admin_details_standard(itemNum));
  
        eval_item_details[itemNum]={"eval":item_eval_details,"admin":item_admin_details};
        
      }  
    }
  }
  
  function get_eval_details_text_v2()
  {
    var correct_trauma_scores = 0;
    for (let i in evaluationFile.criterions)
    {
      var criterion_header = "";
      if(evaluationFile.criterions[i].prefix==true)
        criterion_header = criterion_header.concat("<u>",eval_captions_v2.stringCRITERION,"<b>",evaluationFile.criterions[i].criterion_title, "</b></u><br><br>");
  
      for (let j in evaluationFile.criterions[i].criterions)
      {
        var itemNum = evaluationFile.criterions[i].criterions[j].itemNum;
        var item_eval_details="";
        item_eval_details = item_eval_details.concat(criterion_header);
        criterion_header="";
        item_eval_details = item_eval_details.concat("<b>",eval_captions_v2.stringITEM, evaluationFile.criterions[i].criterions[j].title,"</b>");
        item_eval_details = item_eval_details.concat("<ul class=\"b\"><li>",eval_captions_v2.Explanation,"</li>");
        item_eval_details = item_eval_details.concat("<ul class=\"b\">");
        for (let k in evaluationFile.criterions[i].criterions[j].items)
        {
          if(evaluationFile.criterions[i].criterions[j].items[k].explanation!=null)
          {
            item_eval_details = item_eval_details.concat(evaluationFile.criterions[i].criterions[j].items[k].title,"<br><br>");
            item_eval_details = item_eval_details.concat("<li>",evaluationFile.criterions[i].criterions[j].items[k].explanation,"</li><br>");
          }
        }
        item_eval_details = item_eval_details.concat("</ul>");
        
        other_considerations[itemNum]=[];
        var ccTagCollection=[];
        for(let cct in evaluationFile.cc_tags)
        {
          if(item_has_tag(itemNum,evaluationFile.cc_tags[cct].tag))
          {
            ccTagCollection.push(evaluationFile.cc_tags[cct].tag[0]);
          }          
        }      
        if(ccTagCollection.length > 0)
        {    
          let tagText = item_has_multiple_tags(itemNum, ccTagCollection, evaluationFile);
          if(tagText.length > 0)
          {
            for(let i in tagText)
            {
              other_considerations[itemNum].push(tagText[i]);           
            }            
          }          
        }
        if(evaluationFile.criterions[i].criterions[j].considerations)
        {
          for (let k in evaluationFile.criterions[i].criterions[j].considerations)
          {
            var tag_condition_met;
            if(evaluationFile.criterions[i].criterions[j].considerations[k].not_used==null || evaluationFile.criterions[i].criterions[j].considerations[k].not_used==false)
              tag_condition_met=false;
            else
              tag_condition_met=true;          
  
            for (let l in evaluationFile.criterions[i].criterions[j].considerations[k].tag)
            {            
              var t = item_has_tag(itemNum,evaluationFile.criterions[i].criterions[j].considerations[k].tag[l]);          
              if(t)
              {
                tag_condition_met=!tag_condition_met;
                break;
              }
            }
            if(tag_condition_met)
              other_considerations[itemNum].push(evaluationFile.criterions[i].criterions[j].considerations[k].text);
          }
        }
        if(evaluationFile.criterions[i].criterions[j].requiredPrompts)
        {
          for (let k in evaluationFile.criterions[i].criterions[j].requiredPrompts)
          {
            var tag_condition_met = false; 
  
            for (let l in evaluationFile.criterions[i].criterions[j].requiredPrompts[k].tag)
            {                        
              var t = item_has_tag(itemNum,evaluationFile.criterions[i].criterions[j].requiredPrompts[k].tag[l]);          
              if(t)
              {
                tag_condition_met=!tag_condition_met;
                break;
              }
            }
            if(!tag_condition_met)
              other_considerations[itemNum].push(evaluationFile.criterions[i].criterions[j].requiredPrompts[k].text);
          }
        }
        eval_item_details[itemNum]={"eval":item_eval_details,"admin":""};
  
      }  
    }
  }
  function admin_details_standard(i)
  {
    var text = String("<li>"+eval_captions_v1.Thoroughness+"</li>");
    if(e.admin_details[i])
    {
      text = text.concat("<ul class=\"a\"><li>",eval_captions_v1.AllRequiredPromptsAsked,e.admin_details[i].required_prompts,"</li>");
      text = text.concat("<li>",eval_captions_v1.AllClarifyingPromptsAsked,e.admin_details[i].clarifying_prompts,"</li></ul><br>"); //a
      text = text.concat("<li>",eval_captions_v1.AccuracyToScript,"</li>");
      text = text.concat("<ul class=\"a\"><li>",eval_captions_v1.EntryPrompt,e.admin_details[i].entry_prompt,"</li>");
      text = text.concat("<li>",eval_captions_v1.OtherPromptsVerbatim,e.admin_details[i].other_verbatim,"</li>");
      text = text.concat("<li>",eval_captions_v1.OtherPromptsParaphrased,e.admin_details[i].other_paraphrased,"</li><br>");
      text = text.concat("<li>",eval_captions_v1.OtherPromptsOffscript,e.admin_details[i].off_script,"</li>");
      text = text.concat("<li>",eval_captions_v1.UnnecessaryQs,e.admin_details[i].unnecessary,"</li>");
      text = text.concat("<li>",eval_captions_v1.OutOfSequence,e.admin_details[i].in_order,"</li></ul>");
      text = text.concat("</ul></ul>");//c,a
      text = text.concat("</ul>");
    }
    return text;
  }
  
  function admin_details_no_out_of_order(i)
  {
    var text = String("<li>"+eval_captions_v1.Thoroughness+"</li>");
    if(e.admin_details[i])
    {
      text = text.concat("<ul class=\"a\"><li>",eval_captions_v1.AllRequiredPromptsAsked,e.admin_details[i].required_prompts,"</li>");
      text = text.concat("<li>",eval_captions_v1.AllClarifyingPromptsAsked,e.admin_details[i].clarifying_prompts,"</li></ul><br>"); //a
      text = text.concat("<li>",eval_captions_v1.AccuracyToScript,"</li>");
      text = text.concat("<ul class=\"a\"><li>",eval_captions_v1.EntryPrompt,e.admin_details[i].entry_prompt,"</li>");
      text = text.concat("<li>",eval_captions_v1.OtherPromptsVerbatim,e.admin_details[i].other_verbatim,"</li>");
      text = text.concat("<li>",eval_captions_v1.OtherPromptsParaphrased,e.admin_details[i].other_paraphrased,"</li><br>");
      text = text.concat("<li>",eval_captions_v1.OtherPromptsOffscript,e.admin_details[i].off_script,"</li>");
      text = text.concat("<li>",eval_captions_v1.UnnecessaryQs,e.admin_details[i].unnecessary,"</li></ul>");
      text = text.concat("</ul></ul>");//c,a
      text = text.concat("</ul>");
    }
    return text;
  }
  
  function admin_details_only_unnecessary(i)
  {
    var text = String("<li>"+eval_captions_v1.Thoroughness+"</li>");
    if(e.admin_details[i])
    {
      text = text.concat("<ul class=\"a\"><li>",eval_captions_v1.UnnecessaryQs,e.admin_details[i].unnecessary,"</li></ul>");//а
      text = text.concat("</ul>");//c
      text = text.concat("</ul>");
    }
    return text;
  }
  
  function get_eval_ratings_text_v1()
  {
    for (let i in evaluationFile.criterions)
      for (let j in evaluationFile.criterions[i].criterions)
      {
        let ind = evaluationFile.criterions[i].criterions[j].itemNum;
        eval_ratings[evaluationFile.criterions[i].criterions[j].itemNum]={"title":evaluationFile.criterions[i].criterions[j].title,"initial":e.ratings[ind].initial,"correct":e.ratings[ind].correct};
      }
  }
  
  function get_eval_ratings_text_v2()
  {
    let score_id;
    let correct_id;
    if($game_mode=="learn")
    {
      score_id="initial";
      correct_id="scored_correct";
    }
    else
    {
      score_id="final";
      correct_id="final_scored_correct";
    }
    for (let i in evaluationFile.criterions)
      for (let j in evaluationFile.criterions[i].criterions)
      {
        let ind = evaluationFile.criterions[i].criterions[j].itemNum;
        let cypressDataPrimary = ind + "_primary";
        let mark = e.ratings[ind][correct_id]?"<span data-cy=\"" + cypressDataPrimary + "\" style=\"color: #00ff00;\"> &#10003</span>":"<span data-cy=\"" + cypressDataPrimary + "\" style=\"color: #ff0000; margin-left: 5%; font-size: 1.2vw;\">X</span>";
        let o={"title":evaluationFile.criterions[i].criterions[j].title,"score":e.ratings[ind][score_id]+mark,"correct":e.ratings[ind].correct};
        let a=[];
        mark = e.admin_details[ind].required_prompts_asked===e.admin_details[ind].num_required_prompts?"<span style=\"color: #00ff00;\"> &#10003</span>":"<span style=\"color: #ff0000; margin-left: 5%; font-size: 1.2vw;\">X</span>";
        a.push({"title":eval_captions_v2.RequiredPromptsAsked,"score":e.admin_details[ind].required_prompts_asked+mark,"correct":e.admin_details[ind].num_required_prompts});
        for(let k in e.ratings[ind].other_scoring_items)
        {
          let cypressDataOther = ind + "_" + e.ratings[ind].other_scoring_items[k].caption;
          mark = e.ratings[ind].other_scoring_items[k][correct_id]?"<span data-cy=\"" + cypressDataOther + "\" style=\"color: #00ff00;\"> &#10003</span>":"<span data-cy=\"" + cypressDataOther + "\" style=\"color: #ff0000; margin-left: 5%; font-size: 1.2vw;\">X</span>";
          a.push({"title":e.ratings[ind].other_scoring_items[k].caption,"score":e.ratings[ind].other_scoring_items[k][score_id]+mark,"correct":e.ratings[ind].other_scoring_items[k].correct});
        }
        
        if(other_considerations[ind].length>0)
        {
          let o_c=eval_captions_v2.OtherConsiderations;
          o_c = o_c.concat("<ul class=\"e\">");
          for(let k in other_considerations[ind])
            o_c = o_c.concat("<li>",other_considerations[ind][k],"</li>");
          o_c.concat("</ul>");
          a.push({"title":o_c,"score":"","correct":""});
        }
        eval_ratings[ind]={"item":o,"details":a};
      }
  }
  
   function collapse_item_admin_details()
   {
     var elemens = document.getElementsByClassName("content");
     var arrows = document.getElementsByClassName("arrowImg");
  
     for (let i = 0; i < elemens.length; i++)
       elemens[i].style.maxHeight = "0vw";
     for (let i = 0; i < arrows.length; i++)
       arrows[i].style.transform = "rotate(90deg)";
   }
  function collapse_sub_tables()
   {
    var arrows = document.getElementsByClassName("evalRatingArrow");
    var ratings = document.getElementsByClassName("tbodyTables");
    for(let tables of ratings)
      tables.style.display = "none";
    for (let i = 0; i < arrows.length; i++)
      arrows[i].style.transform = "rotate(90deg)";
   }
   function collaps_details()
   {
    if(e.version==1)
      collapse_item_admin_details();
    else
      collapse_sub_tables();
   }
  
  function scrollCollapse(event)
  {
    if(window.scrollY != 0)
    {
      topbar.style.height = "5vw";
      doneBtn.style.width = "3%";
      doneBtn.style.left = "8vw";
      doneBtn.style.top = "1vw";
      backLabel.style.marginTop = "-.5vw";
      backLabel.style.fontSize = ".6vw";
      topbarText.style.right = "45%";
      topbarText.style.fontSize = "2vw";
      printEvalBtn.style.top = "2.35vw";
      printSummBtn.style.top = "2.35vw";
      if(certID != -1)
        printTranscriptBtn.style.top = "4.65vw";
    }
    else
    {
      topbar.style.height = "10vw";
      doneBtn.style.width = "6%";
      doneBtn.style.left = "4vw";
      doneBtn.style.top = "1.5vw";
      backLabel.style.marginTop = "-.3vw";
      backLabel.style.fontSize = "1.2vw";
      topbarText.style.right = "40%";
      topbarText.style.fontSize = "3vw";  
      printEvalBtn.style.top = "4vw";
      printSummBtn.style.top = "4vw";
      if(certID != -1)
        printTranscriptBtn.style.top = "8vw";
    }
  }
  
  function fill_in_evaluation_text_v1()
  {
    get_eval_details_text_v1();
    get_eval_summary_text_v1();
    get_eval_ratings_text_v1();
  }
  
  function fill_in_evaluation_text_v2()
  {
    get_eval_details_text_v2();
    get_eval_summary_text_v2();
    get_eval_ratings_text_v2();
  }
  
  onMount( async function()
  {
    window.addEventListener("afterprint",collaps_details);
    window.addEventListener("scroll", scrollCollapse);
    if(e.version === 1)
      fill_in_evaluation_text_v1();
    else if(e.version === 2)
      fill_in_evaluation_text_v2();
  })
  
  onDestroy(()=>{
    window.removeEventListener("afterprint",collaps_details);
    window.removeEventListener("scroll", scrollCollapse);
  })
  
  function printSummaryOnly()
  {
    var element = document.getElementById('body_details');
    element.style.display = "none";
    window.print();
    element.style.display = "initial";
  }
  
  function toggle_collapsible()
  {
    var element = this.nextElementSibling;
    var arrow = this.previousElementSibling;
    if (element.hasAttribute("aria-hidden"))
    {
      element.removeAttribute("aria-hidden");
      arrow.style.transform = "rotate(180deg)";
      element.style.maxHeight = "50vw";
    }    
    else
    {
      arrow.style.transform = "rotate(90deg)";
      element.style.maxHeight = "0vw";
      setTimeout(function(){
        element.setAttribute("aria-hidden","true");
              }, 250);
    }        
  }
  
  function print_full_eval_v1()
  {
    var elemens = document.getElementsByClassName("content");
    var arrows = document.getElementsByClassName("arrowImg");
    for (let i = 0; i < elemens.length; i++)
      elemens[i].style.maxHeight = "50vw";
    for (let i = 0; i < arrows.length; i++)
      arrows[i].style.transform = "rotate(180deg)";
    window.print();
  }
  function print_full_eval_v2()
  {
    var elemens = document.getElementsByClassName("tbodyTables");
    var arrows = document.getElementsByClassName("evalRatingArrow");
    for(let e of elemens)
      e.style.display = "table-row-group";
    for (let i = 0; i < arrows.length; i++)
      arrows[i].style.transform = "rotate(180deg)";
    window.print();
  }
  function print_full_eval()
  {
    if(e.version==1)
      print_full_eval_v1();
    else
      print_full_eval_v2();
  }
  
  async function printTranscript()
  {  
    let transcriptContainerPrint = document.getElementById("transcriptContainerPrint");
    let transcriptContainer = document.getElementById("transcriptContainer");      
    let evalTranscript = document.getElementById("evalTranscript");
    let evalBody = document.getElementById("evalBody");
    transcriptContainer.innerHTML = "";
  
    await set_transcript(30, certSession);
  
    evalBody.style.display = "none";
    evalTranscript.style.display = "block";
    transcriptContainerPrint.innerHTML = transcriptContainer.innerHTML;  
    evalMenu.style.height = "auto";
    evalMenu.style.maxHeight = "none";
  
    window.print();
  
    evalMenu.style.height = "56.25vw";
    evalMenu.style.maxHeight = "100vh";
    transcriptContainerPrint.innerHTML = "";
    evalTranscript.style.display = "none";
    evalBody.style.display = "block";
  
  }
  </script>
  
  <style lang="less">
  .viewportRatio(@x, @y)
  {
    width: 100vw;
    height: @y * 100vw / @x;
    //max-width: @x / @y * 100vh;
    max-height: 100vh;
  }
  .done-button {
      width: 6%;
      left: 4vw;
      position: relative;
      top: 1.5vw;
  }
  .doneIcon
  {
      position: absolute;
      left: 10%;
      width: 80%;
      height: 80%;
      fill: #e2eaf3;
  }
  
  .topBar
  {
      height: 10vw;
      width: 100%;
      position: fixed;
      z-index: 1;
      background: #ededed;    
  }
  
  .topBarText
  {
      position: absolute;
      top: 31%;
      margin-block-end: 0;
      margin-block-start: 0;
      right: 40%;
      color:  #023b5a;
      font-size: 3vw;
  }
  
  .body
  {
    position: absolute;
    display: block;
    top: 10vw;
    height: 89vw;
    width: 96%;
    margin-left:2%;
    margin-right:2%;
    margin-bottom:2%;
  }
  
  .bodyText1
  {
    font-size: 2vw;
    text-align: center;
    color: black;
    font-weight: bold;
  }
  
  .bodyText2Heading,.bodyText3Heading
  
  {
    font-size: 2vw;
    font-weight: bold;
    text-align: left;
    color: black;
    margin-left:15%;
    margin-right:15%;
    margin-block-end: 0;
  }
  .bodyText3Heading
  {
    font-size: 1.8vw;  
  }
  
  .bodyText2
  {
    font-size: 1.5vw;
    text-align: left;
    color: black;
    margin-left:15%;
    margin-right:15%;
    margin-block-end: 0;
  }
  
  :global(ul.a)
  {
    margin-block-end: -1vw;
    list-style-type: disc;
  }
  :global(ul.b)
  {
    list-style-type: none;
  }
  :global(ul.c)
  {
    list-style-type: square;
  }
  
  :global(ul.d)
  {
    list-style-type: decimal;
    margin-left: 5%;
  }
  :global(ul.db)
  {
    list-style-type: decimal;  
  }
  :global(ul.e)
  {
    list-style-type: circle;
    margin: 1vw 0px 0px 5vw;
    display: table-caption;
    width: 68vw;
    overflow-wrap: anywhere;
    padding: 0;
  }
  :global(ul.e li)
  {
    margin-bottom: 1%;
  }
  
  #adminDetailArrow
  {
    margin-left: 9.5vw;
    fill: #E2E2E2;
    bottom: 1vw;
    width: 2vw;
    height: 2vw;
    position: relative;
    transition: all .075s linear;
    transform: rotate(90deg);
  }
  
  .done-button-circle {
      width: 100%;
      height: 0;
      position: relative;
      padding-bottom: 100%;
      border-radius: 50%;
      overflow: hidden;
      background: #023b5a;
      box-shadow: 0 0 3px grey;
  }
  .done-button-circle:hover {
    background:#30588e;
    cursor:pointer;
  }
  .done-button label {
      display: block;
      float: left;
      width: 100%;
      padding-top: 65%;
      padding-bottom: 50%;
      line-height: 1.2vw;
      margin-top: -0.3vw;
      text-align: center;
      color: #e2eaf3;
      font-family: Verdana;
      font-size: 1.2vw;
      font-weight: bold;
      text-decoration: none;
  }
  .backIcon
  {
      position: absolute;
      left: 10%;
      width: 80%;
      height: 80%;
      fill: #e2eaf3;
  }
  .backBtn
  {
      background: transparent;
      border: none !important;
      font-size: 0;
      width: 7vw;
      height: 7vw;
      position: absolute;
      padding: 1px 0px;
      border-radius: 50%;
      cursor: pointer;
      right: 0vw;
      bottom: 0vw;
  }
  .done-button-circle:focus-within {
      box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
      outline: none; // removes default focus outline
    }
  
  button
  {
      position: absolute;
      width: 5vw;
      border-radius: 3px;
      height: 2vw;
      border: none;
      bottom: 1.5vw;
      right: 3vw;
      background: #7092a6;
      color: white;
      font-family: 'Lato', sans-serif;
      font-weight: bold;
      text-align: center;
      font-size: 1vw;
  }
  
  @media all and (max-aspect-ratio: ~"3/2")
  {
    .evaluationMenu, #evaluationTranscript {
      .viewportRatio(4,3);
      top:0;bottom:0; /* vertical center */
      left:0;right:0; /* horizontal center */
      display: flex;
    }
  }
  
  @media all and (min-aspect-ratio: ~"3/2")
  {
    .evaluationMenu, #evaluationTranscript {
      .viewportRatio(16,9);
      top:0;bottom:0; /* vertical center */
      left:0;right:0; /* horizontal center */
      display: flex;
    }
  }
  @media print 
  { 
    .topBar { display:none; }
    .body {position: static;}
  }
  
  .btn_content
  {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  
  
  .printBtn:hover > .printSVG
  {
    fill: #7092a6;
  }
  
  .printBtnDiv
  {
    position: absolute;
    right: 17vw;
    top: 4vw;
  }
  
  .printBtnDiv2
  {
    position: absolute;
    right: 0vw;
    top: 4vw;
  }
  .printBtnDiv3
  {
    position: absolute;
    right: 0vw;
    top: 8vw;
  }
  
  .printBtn:hover {
      background: white;
      border: 2px solid #7092a6;
      color: #7092a6;
  }
  .printBtn
  {
    cursor: pointer;
    width: 16vw;
    height: 2vw;
    border-radius: 8px;
    bottom: 0vw;
    background: #023b5a;
    color: white;
    font-family: 'Lato', sans-serif;
    text-align: center;
    margin: 0   ;
    right: 2vw;
    text-indent: 15%;
    font-size: 1vw;
    padding: .35vw;
  }
  
  .printSVG
  {
    position: absolute;
    width: 1.5vw;
    height: 1.5vw;
    top: 0vw;
    left: .5vw;
    fill: white;
    padding: .25vw;
  }
  .printBtn:focus
  {    
      box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
      outline: none; // removes default focus outline
  }
  
  .collapsible
  {
    position: relative;
    height: 2vw;
    bottom: 1vw;
    background: transparent;
    cursor: pointer;
    width: 13em;
    text-align: left;
    color: black;
    outline: none;
    font-size: 1.5vw;
    margin-left:1%;
    margin-right:13%;
  }
  
  .collapsible:focus > .btn_content
  {
    box-shadow: inset 0 0 0 0px #fff, 0 0 0 2px #023b5a;
    outline: none; // removes default focus outline
  }
  
  .collapsible:focus,
  .btn_content:focus
  {
    outline: none;
  }
  .btn_content:hover { box-shadow: 0 0 0 2px #023b5a; }
  
  .content {  
    overflow: hidden;
    max-height: 0vw;
    font-size: 1.5vw;
    margin-left:10%;
    margin-right:10%;
    transition: .25s linear;
  }
  
  ul {
      display: block;
      list-style-type: disc;
      margin-before: 1em;
      margin-after: 1em;
      margin-start: 0;
      margin-end: 0;
      padding-start: 40px;
      font-size: 1.5vw;
  }
  
  #evalTranscript
    {
      background-color: #d3d3d3;
      display: none;
      flex-direction: column;
      position: relative;
      height: auto;
      width: 34%;
      top: 20%;
      z-index: 40;
      margin: 0 auto;
      max-width: 100%;
      overflow: hidden;
    }
  
  
  @media (max-width: 550px)
  {
    .collapsible
    {
      bottom: 3vw;
      height: 4.5vw;
      width: 185px;
      margin-left: -1.5vw;
    }
    #adminDetailArrow
    {
      bottom: 4.25vw;
      margin-left: 14vw;
    }
  }
  
  @media (max-width: 650px) and (min-width: 551px)
  {
    .collapsible
    {
      bottom: 2.5vw;
      height: 4vw;
      width: 185px;
      margin-left: -1vw;
    }
    #adminDetailArrow
    {
      bottom: 3vw;
      margin-left: 13.5vw;
    }
  }
  
  @media (max-width: 800px) and (min-width: 651px)
  {
    .collapsible
    {
      bottom: 2vw;
      height: 3vw;
      width: 200px;
      margin-left: -.5vw;
    }
    #adminDetailArrow{
      margin-left: 12.5vw;
      bottom: 2.75vw;
    }
  }
  
  @media (max-width: 900px) and (min-width: 801px)
  {
    .collapsible
    {
      width: 200px;
      bottom: 1.5vw;
      height: 2.5vw;
      margin-left: -.5vw;
    }
    #adminDetailArrow{
      margin-left: 12vw;
      bottom: 1.5vw;
    }
  }
  @media (max-width:1000px)
  {
    .content
    {
      font-size: 15px;
    }
    #adminDetailArrow
    {
      width: 15px;
      height: 15px;
    }
    .bodyText2{
      font-size: 15px;
    }
    .bodyText2Heading, .bodyText1
    {
      font-size: 18px;
    }
    .bodyText3Heading{
      font-size: 16px;
    }
  }
  
    @media (min-width: 901px)
    {
      .collapsible
      {
        width: 20.5vw;
        height: 2.25vw;
        bottom: 1.25vw;
        margin-left: 0%;
      }
      #adminDetailArrow{
      margin-left: 12vw;
      bottom: 1.85vw;
      }
    }
  
  @media (max-width:1400px) and (min-width: 1251px){
    #adminDetailArrow{
      margin-left: 10.5vw;
      bottom: 1.15vw;
    }
    .collapsible
      {
        margin-left: 0%;
      }
  }
  
  @media (max-width:1900px) and (min-width: 1401px){
    #adminDetailArrow{
      margin-left: 10vw;
      bottom: 1.05vw;
    }
  }
  
  @media print
  {
    .bodyText1{font-size: 14;}
    .bodyText2{font-size: 11;}
    .bodyText2Heading{font-size: 14;}  
    .bodyText3Heading{font-size: 12;}  
  }
  
  </style>
  <div class="evaluationMenu" bind:this={evalMenu}>         
      <div class="topBar" bind:this={topbar}>
          <div class="done-button" bind:this={doneBtn}>
              <div class="done-button-circle">
                  <svg class="backIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="transform: scale(-1)"><path fill="none" d="M0 0h24v24H0z"/><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"/></svg>
                  <button class="backBtn" on:click={done} aria-label="Back" data-cy={'backBtn'}></button>
                  <label tabindex="-1" bind:this={backLabel}>{eval_back}</label>
              </div>
          </div>
        <p class="topBarText" bind:this={topbarText}>{eval_title_bar}</p>
        {#if certID != -1}
        <div class="printBtnDiv" bind:this={printEvalBtn}>    
          <button class="printBtn" on:click={print_full_eval} aria-label="Print Full Evaluation">{eval_print}
          <svg class="printSVG" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <path d="M8 2h16v4h-16v-4z"></path>
          <path d="M30 8h-28c-1.1 0-2 0.9-2 2v10c0 1.1 0.9 2 2 2h6v8h16v-8h6c1.1 0 2-0.9 2-2v-10c0-1.1-0.9-2-2-2zM4 14c-1.105 0-2-0.895-2-2s0.895-2 2-2 2 0.895 2 2-0.895 2-2 2zM22 28h-12v-10h12v10z"></path>
          </svg>
          </button>
        </div>
        <div class="printBtnDiv2" bind:this={printSummBtn}>    
          <button class="printBtn" on:click={printSummaryOnly} aria-label="Print Summary">{eval_print1}
          <svg class="printSVG" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <path d="M8 2h16v4h-16v-4z"></path>
          <path d="M30 8h-28c-1.1 0-2 0.9-2 2v10c0 1.1 0.9 2 2 2h6v8h16v-8h6c1.1 0 2-0.9 2-2v-10c0-1.1-0.9-2-2-2zM4 14c-1.105 0-2-0.895-2-2s0.895-2 2-2 2 0.895 2 2-0.895 2-2 2zM22 28h-12v-10h12v10z"></path>
          </svg>
          </button>
        </div>
        <div class="printBtnDiv3" bind:this={printTranscriptBtn}>    
          <button class="printBtn" on:click={printTranscript} aria-label="Print Interview Transcript" style="width: 21vw;">{eval_print2}
          <svg class="printSVG" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <path d="M8 2h16v4h-16v-4z"></path>
          <path d="M30 8h-28c-1.1 0-2 0.9-2 2v10c0 1.1 0.9 2 2 2h6v8h16v-8h6c1.1 0 2-0.9 2-2v-10c0-1.1-0.9-2-2-2zM4 14c-1.105 0-2-0.895-2-2s0.895-2 2-2 2 0.895 2 2-0.895 2-2 2zM22 28h-12v-10h12v10z"></path>
          </svg>
          </button>
        </div>
        {:else}
        <div class="printBtnDiv2" bind:this={printSummBtn}>    
          <button class="printBtn" on:click={printSummaryOnly} aria-label="Print Summary">{eval_print1}
          <svg class="printSVG" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <path d="M8 2h16v4h-16v-4z"></path>
          <path d="M30 8h-28c-1.1 0-2 0.9-2 2v10c0 1.1 0.9 2 2 2h6v8h16v-8h6c1.1 0 2-0.9 2-2v-10c0-1.1-0.9-2-2-2zM4 14c-1.105 0-2-0.895-2-2s0.895-2 2-2 2 0.895 2 2-0.895 2-2 2zM22 28h-12v-10h12v10z"></path>
          </svg>
          </button>
        </div>
        <div class="printBtnDiv3" bind:this={printEvalBtn}>    
          <button class="printBtn" on:click={print_full_eval} aria-label="Print Full Evaluation">{eval_print}
          <svg class="printSVG" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <path d="M8 2h16v4h-16v-4z"></path>
          <path d="M30 8h-28c-1.1 0-2 0.9-2 2v10c0 1.1 0.9 2 2 2h6v8h16v-8h6c1.1 0 2-0.9 2-2v-10c0-1.1-0.9-2-2-2zM4 14c-1.105 0-2-0.895-2-2s0.895-2 2-2 2 0.895 2 2-0.895 2-2 2zM22 28h-12v-10h12v10z"></path>
          </svg>
          </button>
        </div>
        {/if}
      </div>
      <div id="evalTranscript">      
        <Transcript transcriptMethod={"transcriptContainerPrint"}/>
      </div>   
      <div class="body" id="evalBody">      
        <div class="body_part1">
          <p class="bodyText1"> {@html eval_title} </p>
          <p class="bodyText2" style="text-align: center;color: #787878;"> {@html eval_title2} </p>
          <p class="bodyText1"> {@html eval_title3} </p>
          <p class="bodyText2Heading"> {@html eval_title_overview} </p>
          <p class="bodyText2"> {@html eval_overview} </p>
          <p class="bodyText2Heading"> {@html eval_title_yourratings} </p>
          <p class="bodyText2"> {@html eval_title_yourratingsctnd} </p>
        </div>
        {#if e.version === 1}
          <EvaluationTable_v1 eval_ratings={eval_ratings}/>
        {:else}
          <EvaluationTable_v2 eval_ratings={eval_ratings} tbl_your_scored_header={tbl_your_scored_header}/>
        {/if}
        <div class="body_part2">
          <p class="bodyText3Heading"> {@html eval_title_accuracy} </p>
          <p class="bodyText2"> {@html eval_accuracy} </p>
          <p class="bodyText3Heading"> {@html eval_title_administration} </p>
          <p class="bodyText2"> {@html eval_admin} </p>
          <p class="bodyText3Heading"> {@html eval_title_errors} </p>
          <p class="bodyText2"> {@html eval_errors} </p>
        </div>
        <div id="body_details">
          <p class="bodyText2Heading"> {@html eval_title_evaldetails} </p>
          <p class="bodyText2"> {@html eval_details_text} </p>
          {#each eval_item_details as id, i}
            <p class="bodyText2"> {@html id.eval} </p>
            {#if e.version === 1}
              <img class="arrowImg" style="--rotate:0deg" src={$base_asset_path + "blackDropArrow.png"} alt="Dropdown arrow for submit log" id="adminDetailArrow" height="15em">
              <button type="button" class="collapsible" on:click={toggle_collapsible} aria-label="Administration details" ><span class="btn_content" tabindex="-1" aria-hidden="true"></span></button>
              <div class="content" aria-hidden="true">
                <p>{@html id.admin}</p>
              </div>
            {/if}
          {/each}
        </div>
      </div>
  </div>
  