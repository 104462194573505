import Conversation from "./conversation.js"
import {logReChat} from "../ReChat.svelte"
export default class ConversationManager
{
  constructor()
  {
    this.conversation_state = {
      ongoing: "ongoing",
      ended: "ended"
    }
    this.conversations = [];
    this.partialConvo;
  }
  // lower number higher priority
  addConversation(name,conversants,priority,allowFallthrough) 
  {
    let convo = new Conversation();
    convo.startConversation(conversants);
    let convo_obj = {
      name: name,
      conversation: convo,
      priority: priority,
      allowFallthrough: allowFallthrough,
      state: this.conversation_state.ended
    }
    this.conversations.push(convo_obj);
    this.conversations.sort(function(a, b){return a.priority-b.priority})
  }


  matchInput(input,allowPartialMatch,volumeWarningMessage)
  {
    logReChat("\n### INPUT RECEIVED ###");
    logReChat("**INPUT:** (user) "+ input);
    if(volumeWarningMessage && volumeWarningMessage.length)
      logReChat(volumeWarningMessage);
    let matchRetValue = Conversation.matchReturnValues.noMatchFound;
    let convo_output = []; //cards that other conversations can overhear 
    if(this.partialConvo)
    {
      let retValue = this.partialConvo.conversation.matchInput(input,allowPartialMatch);
      matchRetValue = retValue.matchValue;
      if(retValue.matchValue != Conversation.matchReturnValues.moreInputNeeded)
      {
        if(retValue.matchValue == Conversation.matchReturnValues.matchFound)
        {
          //Check if this conversation is being started again
          if(this.partialConvo.state == this.conversation_state.ended)
          {
            this.startConversation(this.partialConvo)
          }
          // Go through the conversation and see if any end card was used
          if(retValue.card.conversationEnd)
          {
            this.endConversation(this.partialConvo);
          }
          convo_output.push({name:this.partialConvo.name, card:retValue.card, conversantIndex:retValue.conversantIndex});
        }
        this.partialConvo = null;
      }
    }
    else
    {
      for(let convo of this.conversations)
      {
        logReChat("\n#### Matching Conversation ("+ convo.name +") ####")
        let retValue = convo.conversation.matchInput(input,allowPartialMatch);
        matchRetValue = retValue.matchValue;
        if(retValue.matchValue == Conversation.matchReturnValues.matchFound)
        {
          //Check if this conversation is being started again
          if(convo.state == this.conversation_state.ended)
          {
            this.startConversation(convo)
          }
          // Go through the conversation and see if any end card was used
          if(retValue.card.conversationEnd)
          {
            this.endConversation(convo);
          }
          convo_output.push({name:convo.name, card:retValue.card, conversantIndex:retValue.conversantIndex});
          if(!convo.allowFallthrough)
            break;
        }
        else if(retValue.matchValue == Conversation.matchReturnValues.moreInputNeeded)
        {
          this.partialConvo = convo;
          logReChat("\n## Awaiting User Input ##")
          return retValue.matchValue; //convo has a partial match try to complete it
        }
        else if(retValue.matchValue == Conversation.matchReturnValues.noMatchFound)
        {
          if(convo.state == this.conversation_state.ended)
          {
            logReChat("xx Conversation not active xx");
          }
          else
          {
            logReChat("**No matches found**");
          }
        }
      }
    }

    // Cycle output of a conversation to all current conversations
    for(let output of convo_output)
    {
      let card = output.card
			if(card.noMoreInput) 
			{
				continue;
			}
      if(card.isUnparseable)
      {
        continue;
      }
      for(let convo of this.conversations)
      {
        logReChat("\n#### Matching Conversation ("+ convo.name +") ####")
        let conversantIndex = undefined;
        if(convo.name == output.name)
          conversantIndex = output.conversantIndex;
        let retValue = convo.conversation.matchOutputDialogue(card,conversantIndex,input);
        if(retValue.matchValue == Conversation.matchReturnValues.matchFound)
        {
          convo_output.push({name:convo.name, card:retValue.card, conversantIndex:retValue.conversantIndex})
          //Check if this conversation is being started again
          if(convo.state == this.conversation_state.ended)
          {
            this.startConversation(convo)
          }
          // Go through the conversation and see if any end card was used
          if(retValue.card.conversationEnd)
          {
            this.endConversation(convo);
          }
          break;
        }
        else if(retValue.matchValue == Conversation.matchReturnValues.noMatchFound)
        {
          if(convo.state == this.conversation_state.ended)
          {
            logReChat("xx Conversation not active xx");
          }
          else
          {
            logReChat("**No matches found**");
          }
        }
      }
    }
    logReChat("\n## Awaiting User Input ##")
    return matchRetValue;
  }


  addOnEndFunction(name,onEnd)
  {
    for(let convo of this.conversations)
    {
      if(convo.name == name)
      {
        convo.onEnd = onEnd;
      }
    }
  }

  addOnStartFunction(name,onStart)
  {
    for(let convo of this.conversations)
    {
      if(convo.name == name)
      {
        convo.onStart = onStart;
      }
    }
  }

  checkConversationState(name)
  {
    for(let convo of this.conversations)
    {
      if(convo.name == name)
      {
        return convo.state;
      }
    }
  }

  setConversationEnded(name)
  {
    for(let convo of this.conversations)
    {
      if(convo.name == name)
      {
        this.endConversation(convo);
      }
    }
  }
  
  setAllConversationsEnded()
  {
    for(let convo of this.conversations)
    {
      this.endConversation(convo);
    }
  }

  startConversation(convo)
  {
    convo.state = this.conversation_state.ongoing;
    if(convo.onStart)
    {
      convo.onStart();  
    }
  }

  endConversation(convo)
  {
    convo.state = this.conversation_state.ended;
    if(convo.onEnd)
      convo.onEnd();
    for(let conversant of convo.conversation.conversants)
    {
      conversant.rechat.clear_follow_ups();
    }
  }
}