<script context="module">
    import versionFile from "./version.json";
    import {getBrowser, getOS} from "./os_browser_detect"
    import {post_event_log} from "./server_api.js";
    
    let numCharacters = 0;
    let charactersFinishedLoading = 0;
    let restoreLog;
    let restoreEventLog = "";
    let reChatTextArea;
    let logStarted = false;
    let reChatLog = "";
    let allActiveCardsByCharacter = {}
    let generalHelpCard=null;
    let scoringHelpCard=null;
    let currentUICard;
    let tutorialCard;
    export function setGeneralHelpCard(card)
    {
        generalHelpCard = card;
    }
    export function setScoringHelpCard(card)
    {
        scoringHelpCard = card;
    }
    export function setCurrentUICard(card)
    {
        currentUICard = card;
    }
    export function setTutorialCard(card)
    {
        tutorialCard = card;
    }
    export function getGeneralHelpCard()
    {
        return generalHelpCard;
    }
    export function getScoringHelpCard()
    {
        return scoringHelpCard;
    }
    export function getCurrentUICard()
    {
        return currentUICard;
    }
    export function getTutorialCard()
    {
        return tutorialCard;
    }
    export function getReChatLog()
    {
        return reChatLog;
    }

    export function setCharacterActiveCards(character,cards)
    {
        allActiveCardsByCharacter[character] = cards;
    }

    export function serializeAllCards()
    {
        let serializedCards = ""
        for(let person in allActiveCardsByCharacter)
        {
            for(let card of allActiveCardsByCharacter[person])
            {
                serializedCards += "(" + person + ") (" + card.name + "),";
            }
        }
        return serializedCards;
    }

    export function setRechatTextArea(textArea)
    {
        reChatTextArea = textArea;
        updateTextArea();
    }

    function getGPUInfo()
    {
        let info = "GPU:";
        const gl = document.getElementById("canvas").getContext('webgl2');
        if(gl)
        {
            let debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
            info += "\n* Unmasked Vendor: " + gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL) + "  \n";
            info += "* Unmasked Renderer: " + gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) + "  \n";
            info += "* GL Version: " + gl.getParameter(gl.VERSION) + "  \n";
            info += "* GL Shading Version: " + gl.getParameter(gl.SHADING_LANGUAGE_VERSION) + "  \n";
            info += "* GL Vendor: " + gl.getParameter(gl.VENDOR) + "  \n";
            info += "* GL Renderer: " + gl.getParameter(gl.RENDERER) + "  \n";
        }
        return info;
    }
    function getNetworkInfo()
    {
        let info = "Network:";
        let connectionInfo = navigator.connection;
        if(connectionInfo)
        {
            info += "\n* Downlink: " + connectionInfo.downlink + " Mb/s (Chrome maxes out at 10 Mb/s)  \n";
            info += "* Round Trip Time: " + connectionInfo.rtt  + "  \n";
        }
        else
            info += "Could not gather network info.  \n";
        return info;
    }
    async function getMicInfo()
    {
        let info = "Microphone: ";
        try
        {
            let devices = await navigator.mediaDevices.enumerateDevices({ audio: true });
            let micFound = false;
            devices.forEach(function(device) { if(device.kind=="audioinput"){micFound = true; info += "\n* "+device.label;}});
            if(!micFound)
                info += "No microphone detected.";
        }
        catch(err)
        {
            info += "No microphone detected"
        }
        return info;
    }

    async function startLog()
    {
        logStarted = true;
        let preLog = "Begin log\n=========  \n";
        while(!Module['getVersion']) // module may not have loaded yet
        {
            await new Promise(resolve => setTimeout(resolve, 100));
        }
        preLog += "Version Info: " + Module['getVersion']() + " " + versionFile.changeset.substring(0,8) + "  \n";
        let osInfo = getOS();
        let gpuInfo = getGPUInfo();
        let networkInfo = getNetworkInfo();
        let micInfo = await getMicInfo();
        let browserInfo = await getBrowser();
        preLog += "Browser: " + browserInfo +"OS: " + osInfo + " \n" + gpuInfo + "\n" + networkInfo + "\n" + micInfo + "\n\n";
        preLog += "\n\n## Initialize ReChat ##  ";
        reChatLog = preLog + reChatLog;

        let course = window.c5t_game_mode=="advanced_validate"?"AVM":window.c5t_game_mode=="learn"?"ELM":"VM";
        let event_info = {
        course:course,
        version:Module['getVersion'](),
        changeset:versionFile.changeset.substring(0,8),
        browser:browserInfo,
        os:osInfo,
        gpu:gpuInfo,
        network:networkInfo,
        mic:micInfo};
        post_event_log("SESSION_START",event_info);
    }

    export function logReChat(logLine)
    {
        if(!logStarted)
            startLog();
        console.log(logLine);
        logLine += "  ";
        reChatLog += "\n" + logLine;
        updateTextArea();
    }

    export function addToRestoreLog(log)
    {
        if(log && log.length)
        {
            if(!restoreLog)
            {
                restoreLog = "\n#### Restoring saved ReChat ####  \n";
            }
            restoreLog += log;
        }
    }

    function updateTextArea()
    {
        if(reChatTextArea)
        {
            reChatTextArea.value = reChatLog;
            reChatTextArea.scrollTop = reChatTextArea.scrollHeight;
        }
    }

    function finishedLoading()
    {
        charactersFinishedLoading++;
        if(charactersFinishedLoading == numCharacters)
        {
            if(restoreLog)
            {
                logReChat(restoreLog);
                logReChat("**Finished restoring ReChat**")
                let event_info={rechat_restore_list:restoreEventLog};
                post_event_log("RECHAT_RESTORE",event_info);
                restoreEventLog = "";
            }
            logReChat("\n## Awaiting User Input ##")
            restoreLog = null;
        }
        
    }

    function logRechatError(error,cardName,tag,topic,displayError)
    {
        if(displayError!=null)
            displayError();
        let message = "\n### **RECHAT ERROR** ###\n" + "**Error:** "+ error + "\n**Card:** " + cardName + " **Tag:** " + tag + " **Topic:** " + topic + "\n************************************************";
        logReChat(message);
    }
</script>

<script>
    import { createMatchStruct, evaluateMatch } from './input_match.js';
    import { environment, page, positive_feedback_pool } from './stores.js';
    import { verbatim_cards, paraphrased_cards, clarifying_cards, patients_word_cards, clarified_frequency_cards, ptsd_symptoms_cards, initial_cards, highest_required_indices, irrelevant_cards, off_script_cards } from "./rechat_card_info.js"
    import { get_item_for_rechat } from "./item_translation_util.js";
    import { post_rechat_current_card, post_rechat_active_card, delete_rechat_active_card, get_rechat, post_rechat_tags, post_rechat_sequence } from './server_api.js';
    import { onDestroy, onMount } from "svelte";  
    import ConditionalActivation from "./ReChat/ConditionalActivation.js"
    let shortInputHandicap=4.5;
    export let matchDistance = 11.2;
    let partialMatchDistance = 13.5;
    let loadedCards = [];
    let activeCards = [];
    let currentFollowups = [];
    let currentCard;
    export let playAnimation;
    export let dialogue;
    export let cardsFilePath;
    export let person;
    export let listOfCardsToMatch = [] // combination of the list of active cards and followups;
    export let finishedMounting = false;
    export let restoreState = true;
    export let reChatError;
    let conditionalActivation;
    numCharacters++; //increment global character counter

    export function getActiveCardsNameList()
    {
        let activeCardsNameList="";
        for(let i = 0; i!=activeCards.length; ++i)
            activeCardsNameList+="(" + person + ")(" + activeCards[i].name + "),";
        return activeCardsNameList;     
    }
    export function getPerson()
    {
        return person;
    }

    export function loadRechatFromPrevState(obj)
    {
        let restoreLog = "";
        activeCards.length = 0;
        currentFollowups.length = 0;
        loadedCards.forEach(card =>{
            var activeCardFound = false;
            if(obj.activeCards)
                activeCardFound = obj.activeCards.find(function(element) {
                    return card.name === element.name;
                });
            if(activeCardFound)
            {
                card.repeats = activeCardFound.repeats;

                activeCards.push(card);
                if(card.tags)
                {
                    if(card.tags.includes("general_help"))
                        setGeneralHelpCard(card);
                    if(card.tags.includes("score_help"))
                        setScoringHelpCard(card);
                }
            }
            if(obj.currentCard && obj.currentCard.name === card.name)
            {
                currentCard = card;
            }
        });
        if(currentCard)
        {
            restoreLog += "- Currently Active Card: (" + person + ") " + currentCard.name + "  \n";
            dialogue = currentCard.dialogue;
            loadedCards.forEach(card =>{
                var followupCardFound = false;
                if(currentCard.followupCards)
                    followupCardFound = currentCard.followupCards.find(function(element) {
                        return card.name === element.name;
                    });
                if(followupCardFound)
                {
                    card.repeats = followupCardFound.repeats;
                    currentFollowups.push(card);
                }
            });
        }
        currentFollowups.forEach(card =>{
            restoreLog += "- Followup Card: (" + person + ") " + card.name + "  \n";  
        });
        activeCards.forEach(card =>{
            restoreLog += "- Active Card: (" + person + ") " + card.name + " " + card.repeats + "  \n";
            restoreEventLog += "(" + person + ") " + card.name + " " + card.repeats + ", ";   
        });
        return restoreLog;
    }

    function toggleDeactive(currentlyActiveArray,items,logDeactivation)
    {
        items.forEach( item => {
            let i;
            for(i=0;i!=currentlyActiveArray.length;++i)
            {
                if(currentlyActiveArray[i]==item)
                {
                    break;
                }
            }
            if(i!=currentlyActiveArray.length)
            {
                logDeactivation(item);
                currentlyActiveArray.splice(i,1);
                delete_rechat_active_card(item.name,person);
                if(item.tags)
                {
                    if(item.tags.includes("general_help")&&generalHelpCard&&generalHelpCard.name==item.name)
                        setGeneralHelpCard(null);
                    if(item.tags.includes("score_help")&&scoringHelpCard&&scoringHelpCard.name==item.name)
                        setScoringHelpCard(null);
                }
            }
        });
    }

    function toggleActive(currentlyActiveArray,items,logActivation)
    {
        items.forEach( item => {
            logActivation(item)
            currentlyActiveArray.push(item);
            if(item.tags)
            {
                if(item.tags.includes("general_help"))
                    setGeneralHelpCard(item);
                if(item.tags.includes("score_help"))
                    setScoringHelpCard(item);
            }
            post_rechat_active_card(item.name,item.repeats,person);
        });
        // Remove duplicates
        let s = new Set(currentlyActiveArray);
        currentlyActiveArray.length = 0;
        Array.prototype.push.apply(currentlyActiveArray,[... s]);
    }

    export function executeCard(card,input,matchDistance,activeCardsNameList)
    {
        logReChat("\n##### Executing Card: ("+person+") "+card.name +" #####");
        currentCard = card;
        dialogue = currentCard.dialogue;
        logReChat("Dialogue: \""+  dialogue + "\"");
        if(!currentCard.isUnparseable)
            post_rechat_current_card(currentCard.name,currentCard.repeats,person);
        if(playAnimation)
            playAnimation(currentCard.name,currentCard.sound);
        
        if(currentCard.tags && currentCard.tags.length != 0)
        {
            logReChat("Card Tags: " + currentCard.tags.join(", "))
            var item = get_item_for_rechat(currentCard.topic);
            if(item==undefined)
                item=0;
            else if(item=="All")
                item = $page.itemNum;
            var obj = {
                name:currentCard.name,
                item:item,
                person:person,
                tags:currentCard.tags
            }
            post_rechat_tags(obj);
        }
        else
        {
            logReChat("Card Tags: none")
        }
        if(( $page.itemNum === get_item_for_rechat(card.topic) || get_item_for_rechat(card.topic)=="All" ) &&
            currentCard.index !== null &&
            currentCard.index !== undefined)
        {
            var seqObj = {
                    name:currentCard.name,
                    person:person,
                    sequence_index:currentCard.index      
            }
            post_rechat_sequence(seqObj);
        }
    }

    function partialMatchingDeviations(partialMatch,user_input,splitTrigger,numWordsToMatch,deviation)
    {
        // add deviation to the end of trigger slice
        var partialString = splitTrigger.slice(partialMatch.startIndex,partialMatch.startIndex+numWordsToMatch+deviation);
        var partial_input = createMatchStruct(partialString.join(" "));
        var v = evaluateMatch(user_input, partial_input);
        if(partialMatch.startIndex > deviation)
        {
            // add deviation to the start of the trigger slice
            var partialString2 = splitTrigger.slice(partialMatch.startIndex-deviation,partialMatch.startIndex+numWordsToMatch);
            var partial_input2 = createMatchStruct(partialString2.join(" "));
            var v2 = evaluateMatch(user_input, partial_input2);
            if( v2 < v)
                v =v2;
        }
        return v;
    }

    function partialMatching(partialMatch,user_input,inputMatchSize,trigger)
    {                
        var ret = { finalSuccesfullMatch: false,
                    v: partialMatchDistance,
                    wordCount: 0};
        var splitTrigger = trigger.split(/[\s-!$%^&*_+|~={}\[\]:";<>?,.\/]/).filter((el => { return el != ""}));
        var endOfTrigger = false;
        
         for(var i=inputMatchSize; i!=inputMatchSize+3; ++i)
            {
                var v = partialMatchingDeviations(partialMatch,user_input,splitTrigger,inputMatchSize,(i-inputMatchSize));
                if(i+partialMatch.startIndex >= splitTrigger.length)
                    endOfTrigger = true;
                if (v < ret.v)
                {
                    ret.v = v;
                    ret.wordCount = i;
                    if(endOfTrigger)
                    {
                        ret.finalSuccesfullMatch = true;
                        return ret;
                    }
                }
            }
                
        for(var i=inputMatchSize-1; i!=inputMatchSize-4; --i)
        {
            var v = partialMatchingDeviations(partialMatch,user_input,splitTrigger,inputMatchSize,(i-inputMatchSize));
            
            if (v < ret.v)
            {
                ret.v = v;
                ret.wordCount = i;
                if(endOfTrigger)
                {
                    ret.finalSuccesfullMatch = true;
                    return ret;
                }
            }                 
        } 
        return ret;
    }

    // Returns the number of words it partial matched, 0 if there was no partial match
    export function matchInput(input,partialMatch = null)
    {
        if(!(input && input.length))
        {
            logReChat("Matching character: *("+ person +") no match*");
            return {};
        }

        var user_input = createMatchStruct(input);
        var best_value = matchDistance;
        var matchedCard;
        var matchedTrigger;
        listOfCardsToMatch = activeCards.concat(currentFollowups);
        setCharacterActiveCards(person,listOfCardsToMatch)
        var partialMatchValue = partialMatchDistance;
        var partialMatchWordCount = 0;
        var inputMatchSize = input.split(" ").length;
        if(partialMatch && partialMatch.startIndex != 0)
        {
            var card =partialMatch.card;
            for(var triggerIndex = 0; triggerIndex!=card.triggers.length; ++triggerIndex)
            {
                var trigger = card.triggers[triggerIndex];
                var ret = partialMatching(partialMatch,user_input,inputMatchSize,trigger);

                if(ret.v < partialMatchValue)
                {
                    matchedCard = card;
                    matchedTrigger = trigger;
                    partialMatchValue = ret.v;
                    partialMatchWordCount = ret.wordCount;
                }
                if(ret.finalSuccesfullMatch)
                {
                    best_value = partialMatchValue;
                    break;
                }
                else if(partialMatch.combinedInput)
                {
                    let combined_input = createMatchStruct(partialMatch.combinedInput + " " + input);
                    let model_input = createMatchStruct(trigger)
                    let v = evaluateMatch(combined_input, model_input);
                    if (v < best_value)
                    {
                        best_value = v;
                        matchedCard = card;
                        matchedTrigger = trigger;
                    }

                    break;
                }
            }
        }
        else
        {
            listOfCardsToMatch.forEach(card => {
                if(card.triggers && card.repeats != 0)
                    card.triggers.forEach(trigger => {
                        var model_input = createMatchStruct(trigger)
                        var v = evaluateMatch(user_input, model_input);
                        if (v < best_value)
                        {
                            best_value = v;
                            matchedCard = card;
                            matchedTrigger = trigger;
                        }
                        // Do partial match at least until we find a actuall match
                        if(partialMatch && best_value == matchDistance && card.longTriggerMatching)
                        {
                            var ret = partialMatching(partialMatch,user_input,inputMatchSize,trigger);
                            if(ret.v < partialMatchValue)
                            {
                                matchedCard = card;
                                matchedTrigger = trigger;
                                partialMatchValue = ret.v;
                                partialMatchWordCount = ret.wordCount;
                            }
                        }
                    });
            });
        }
        var retValue = {
            card: matchedCard,
            trigger: matchedTrigger,
            distance:best_value
        };
        if(best_value == matchDistance && partialMatchValue < partialMatchDistance && partialMatchValue != matchDistance)
        {
            logReChat("Partial matched character: **("+ person +") " + matchedCard.name + " - Distance: "+best_value+"**");
            logReChat("--Matched trigger: " + matchedTrigger);
            retValue.distance = partialMatchValue;
            retValue.partialStartIndex = partialMatchWordCount + partialMatch.startIndex;
            retValue.partialMatch = true;
            retValue.combinedInput = partialMatch.combinedInput ? partialMatch.combinedInput + " " + input : input;
        }
        else
        {
            if(!retValue.card)
            {
                activeCards.forEach(card => {
                    if(card.isUnparseable)
                    {
                        logReChat("Matching character: *("+ person +") no match*");
                        retValue.card = card;
                    }
                });
                if(!retValue.card)
                {
                    logReChat("Matching character: *("+ person +") no match*");
                }
            } 
            else
            {
                logReChat("Matching character: **("+ person +") " + retValue.card.name + " - Distance: "+best_value+"**");  
                logReChat("--Matched trigger: " + matchedTrigger);
            }        
        }
        return retValue;
    }

    function activateGroup(groups)
    {
        if(!groups)
            return;
        groups.forEach(groupName => {
            if(groupName.length != 0)
            {
                var groupCardsToActivate = []
                var i;
                for(i=0; i!= loadedCards.length; ++i)
                {
                    if(loadedCards[i].groups && loadedCards[i].groups.includes(groupName))
                    {
                        groupCardsToActivate.push(loadedCards[i]);
                    }
                }
                logReChat("Activating group: ("+ person +") "+ groupName); 
                if(groupCardsToActivate.length)
                {
                    toggleActive(activeCards,groupCardsToActivate,(card)=>{logReChat("Activating card: ("+person+") "+ card.name);});
                }
                else
                {
                    logReChat("--No cards activated!");
                }
            }
        });
    }

    function deactivateGroup(groups)
    {
        if(!groups)
            return;
        groups.forEach(groupName => {
            if(groupName.length != 0)
            {
                var groupCardsToDeactivate = []
                var i;
                for(i=0; i!= loadedCards.length; ++i)
                {
                    if(loadedCards[i].groups && loadedCards[i].groups.includes(groupName))
                    {
                        groupCardsToDeactivate.push(loadedCards[i]);
                    }
                }
                logReChat("Deactivating group: ("+ person +") "+ groupName); 
                if(groupCardsToDeactivate.length)
                {
                    toggleDeactive(activeCards,groupCardsToDeactivate,(card)=>{logReChat("Deactivating card: ("+person+") "+ card.name);});
                }
                else
                {
                    logReChat("--No cards deactivated!")
                }
            }
        });
    }

    export function runNextCard(nextCard,input,matchDistance,activeCardsNameList)
    {
        if(!nextCard)
            return;
        if(nextCard.repeats > 0)
        {
            nextCard.repeats--;
        }           
        executeCard(nextCard);

        let cardsActivatied = [];
        try{
            cardsActivatied = conditionalActivation.cardExecuted(currentCard);
        }
        catch(err)
        {
            console.log(err);
        }
        logReChat("---Activating Conditional Cards---"); 
        toggleActive(activeCards,cardsActivatied,(card)=>{logReChat("Activating card: ("+person+") "+ card.name + " - condition: " + card.conditionalActivation);});
        logReChat("---Activating Cards/Groups---"); 
        // Card toggling
        // Now activate groups since they control card activation
        // This can probably get optimised with better data structures 
        activateGroup(currentCard.activateGroups);

        //now activate cards
        if(currentCard.activateCards && currentCard.activateCards.length)
        {
            let cardsToActivate = []
            currentCard.activateCards.forEach(cardName => {
                for(let i=0; i!= loadedCards.length; ++i)
                {
                    if(loadedCards[i].name == cardName)
                    {
                        cardsToActivate.push(loadedCards[i]);
                        break;
                    }
                }
            });
            toggleActive(activeCards,cardsToActivate,(card)=>{logReChat("Activating card: ("+person+") "+ card.name);});
        }

        //now deactivate cards
        if(currentCard.deactivateCards && currentCard.deactivateCards.length)
        {
            let cardsToDeactivate = []
            currentCard.deactivateCards.forEach(cardName => {
                for(let i=0; i!= loadedCards.length; ++i)
                {
                    if(loadedCards[i].name == cardName)
                    {
                        cardsToDeactivate.push(loadedCards[i]);
                        break;
                    }
                }
            });
            toggleDeactive(activeCards,cardsToDeactivate,(card)=>{logReChat("Deactivating card: ("+person+") "+ card.name);});
        }

        //finally, deactivate whole groupss
        deactivateGroup(currentCard.deactivateGroups);

        let exclusiveCardsToDeactivate = [];
        if(currentCard.exclusive && currentCard.exclusive.length)
        {
            for(let i=0; i!= loadedCards.length; ++i)
            {
                if(loadedCards[i].exclusive == currentCard.exclusive)
                {
                    exclusiveCardsToDeactivate.push(loadedCards[i]);
                    loadedCards[i].repeats = 0;
                }
            }
        }
        toggleDeactive(activeCards,exclusiveCardsToDeactivate,(card)=>{logReChat("Deactivating card: ("+person+") "+ card.name);});
        toggleDeactive(loadedCards,exclusiveCardsToDeactivate,(card)=>{logReChat("Removing exclusive card: ("+person+") " + card.name);});

        if(!nextCard.isUnparseable)
        {
            currentFollowups = [];
            if(currentCard.followupCards && currentCard.followupCards.length)
            {
                currentCard.followupCards.forEach(cardName => {
                    for(let i=0; i!= loadedCards.length; ++i)
                    {
                        if(loadedCards[i].name == cardName)
                        {
                            logReChat("Adding Followup: ("+ person +") " + cardName);
                            currentFollowups.push(loadedCards[i]);
                            break;
                        }
                    }
                })
            }         
        }
        listOfCardsToMatch = activeCards.concat(currentFollowups);
        setCharacterActiveCards(person,listOfCardsToMatch)
        let event_info = {
            item:$page.itemNum,
            character:person,
            card:currentCard.name,
            dialogue:currentCard.dialogue?currentCard.dialogue:"",
            tags:currentCard.tags?currentCard.tags.toString():"",
            activate_cards:currentCard.activateCards?currentCard.activateCards.toString():"",
            deactivate_cards:currentCard.deactivateCards?currentCard.deactivateCards.toString():"",
            activate_groups:currentCard.activateGroups?currentCard.activateGroups.toString():"",
            deactivate_groups:currentCard.deactivateGroups?currentCard.deactivateGroups.toString():""};
        if($environment == "production")
        {
            event_info.dialogue = "USER OPTED OUT OF COLLECTION"
        }
        return event_info;
    }
 
    export function externalGroups(activatingGroups,deactivatingGroups)
    {
        if((activatingGroups && activatingGroups.length) || (deactivatingGroups && deactivatingGroups.length))
        {
            logReChat("---Activating External Groups ("+person+")---");
            activateGroup(activatingGroups);
            deactivateGroup(deactivatingGroups);
        }
        listOfCardsToMatch = activeCards.concat(currentFollowups);
        setCharacterActiveCards(person,listOfCardsToMatch)
    }

    export function followupGroupsToggle(groups,activate)
    {
        if(groups && groups.length)
        {
            logReChat("---Activating Followup Groups---");
            if(activate)
                activateGroup(groups);
            else
                deactivateGroup(groups);
        }
        listOfCardsToMatch = activeCards.concat(currentFollowups);
        setCharacterActiveCards(person,listOfCardsToMatch)
    }

    export function update_required_indices(item,index)
    {
        if(index != null && index != undefined)
        {
            if(highest_required_indices[item] == null || index == undefined)
            {
                highest_required_indices[item] = index;
            }
            else if(highest_required_indices[item] < index)
            {
                highest_required_indices[item] = index;
            }
        }
    }

    export function clear_follow_ups()
    {
        currentFollowups = [];
        listOfCardsToMatch = activeCards.concat(currentFollowups);
        setCharacterActiveCards(person,listOfCardsToMatch)
    }

    function checkItem(item,tag,name,topic)
    {
        if(item == null)
        {
            throw "Tag found that needs valid topic";
            return false;
        }
        return true;
    }

    function countTag(item,tag,name,topic,index)
    {
        if(tag === "verbatim")
        {
            if(checkItem(item,tag,name,topic))
            {
                if(!verbatim_cards[item])
                    verbatim_cards[item] = 0;
                verbatim_cards[item]++;
            }
        }
        else if(tag === "paraphrased")
        {
            if(checkItem(item,tag,name,topic))
            {
                if(!paraphrased_cards[item])
                    paraphrased_cards[item] = 0;
                paraphrased_cards[item]++;
            }
        }
        else if(tag === "clarifying")
        {
            if(checkItem(item,tag,name,topic))
            {
                if(!clarifying_cards[item])
                    clarifying_cards[item] = 0;
                clarifying_cards[item]++;
            }
        }
        else if(tag === "patients_words")
        {
            if(checkItem(item,tag,name,topic))
            {
                if(!patients_word_cards[item])
                    patients_word_cards[item] = 0;
                patients_word_cards[item]++;
            }
        }
        else if(tag === "ptsd_symptoms")
        {
            if(checkItem(item,tag,name,topic))
            {
                if(!ptsd_symptoms_cards[item])
                    ptsd_symptoms_cards[item] = 0;
                ptsd_symptoms_cards[item]++;
            }
        }
        else if(tag === "clarified_frequency")
        {
            if(checkItem(item,tag,name,topic))
            {
                if(!clarified_frequency_cards[item])
                    clarified_frequency_cards[item] = 0;
                clarified_frequency_cards[item]++;
            }
        }
        else if(tag === "initial")
        {
            if(checkItem(item,tag,name,topic))
            {
                if(!initial_cards[item])
                    initial_cards[item] = 0;
                initial_cards[item]++;
            }
        }
        else if(tag === "irrelevant")
        {
            if(checkItem(item,tag,name,topic))
            {
                if(!irrelevant_cards[item])
                    irrelevant_cards[item] = 0;
                irrelevant_cards[item]++;
            }
        }
        else if(tag === "off_script")
        {
            if(checkItem(item,tag,name,topic))
            {
                if(!off_script_cards[item])
                    off_script_cards[item] = 0;
                off_script_cards[item]++;
            }
        }
    }
    
    export async function loadReChat(noSaveData=false)
    {
        logReChat("- Loading Character: ("+ person +")");
        // json load
        const response = await fetch(cardsFilePath);
        const json = await response.json();
        loadedCards = json;
        var savedReChat;
        let restoreLog;
        if(!noSaveData && restoreState)
            savedReChat = await get_rechat(person);
        if(savedReChat && Object.entries(savedReChat).length != 0)
            restoreLog = loadRechatFromPrevState(savedReChat);
        else
        {
            activeCards.length = 0;
            currentFollowups.length = 0;
            currentCard = {};
        }
        // Set things up like active cards/groups
        loadedCards.forEach(element => { 
            try
            {
                let item = get_item_for_rechat(element.topic);
                if(item!=undefined)
                    update_required_indices(item,element.index);
                if(element.name == null || element.name == undefined || element.name=="")
                    throw "Card found without name";
                if(element.sound == null || element.sound == undefined || element.sound=="")
                    throw "Card found without sound";
                if(element.repeats == null || element.repeats == undefined)
                    throw "Card found without repeats";
                if(element.startActive && (!savedReChat || (Object.entries(savedReChat).length === 0 && savedReChat.constructor === Object)))
                {
                    activeCards.push(element);
                    post_rechat_active_card(element.name,element.repeats,person);
                }
                if(element.tags && element.tags.length != 0)
                {
                    element.tags.forEach(tag =>{
                        if(element.topic == "All")
                        {
                            for(let i=0;i<=30;i++)
                            {
                                countTag(i,tag,element.name,element.topic,element.index);
                            }
                        }
                        else
                        {
                            let item = get_item_for_rechat(element.topic);
                            countTag(item,tag,element.name,element.topic,element.index);
                        }
                    });
                }
                if(element.triggers && element.triggers.length)
                {
                    for(let i of element.triggers)
                    {
                        if(i.includes("possitiveFeedbackPool"))
                        {
                            $positive_feedback_pool.push(i);
                        }
                    }
                }
            }
            catch(err)
            {
                logRechatError(err,element.name,element.tag,element.topic,reChatError);
            }
        });
        conditionalActivation = new ConditionalActivation(loadedCards,false);
        listOfCardsToMatch = activeCards.concat(currentFollowups);
        setCharacterActiveCards(person,listOfCardsToMatch)
        logReChat("- Finished Loading Character: ("+ person +")");
        addToRestoreLog(restoreLog);
        finishedLoading();
    }
    
    onMount( async function() {
        await loadReChat();
        finishedMounting = true;
    });

    onDestroy( function(){
        numCharacters--;
    })
</script>