<script>
    import pageFile from "./pages.json";
    import { context_toggle } from "./stores";

    export let num = 0;
    let savedFocusTarget;

    function changeBlurTarget(event)
    {      
      var btnUnlikely = document.getElementById("btnUnlikely");
      var percentInput = document.getElementById("percentageInput");
      var sevTarget = document.getElementsByClassName("severityBtn");
      var nextTarget = document.getElementById("backwardButton")
      if(event.relatedTarget == nextTarget)
      {
        sevTarget[0].focus();              
      }        
      else if(event.relatedTarget == sevTarget[4])      
      {
        if(btnUnlikely)
          btnUnlikely.focus();
        else
          percentInput.focus();
      }   
    }
</script>

<style lang="less">
  .container
  {
    margin-left: 0.25em;
    background: #efefef;
    margin-bottom: 5%;
  } 
  p
  {
    font-size: 1vw;
    margin-inline-start: 0.5em;
    margin-inline-end: 0.5em;
  }
  @media (max-width:1000px){ p{ font-size: 8.5px; }}
</style>

{#if num < 21 || num > 28}
  <div class="container" tabindex={$context_toggle?"-1":"0"} id="sevInfoDiv" on:blur={(event)=>(changeBlurTarget(event))}>
    {#each pageFile as item}
      {#if item.id === num}
        {#each item.information as info}
          {#if info != "" }
            <p>{@html info}</p>
          {/if}
        {/each}
      {/if}            
    {/each}
  </div>
{/if}
