<script>
import './SummaryTableCSS.svelte';
import SummaryTableHeader from'./SummaryTableHeader.svelte';
import evaluation from "./../evaluation.json";
import { get_rating } from './../evaluation_data.js';
</script>

<table class="summary_table">
    <SummaryTableHeader text_column1={"Global ratings"} text_column2={"Past Month"}/>
    {#each evaluation.criterions[8].criterions as item}
        <tr>
            <td width="60%" align="left">{item.title}</td>                        
            <td>{get_rating(item.itemNum)}</td>
        </tr>
    {/each}
</table>

