<script context="module">
  let conversationManager;
  export function setConversationManager(convMgr)
  {
    conversationManager = convMgr;
  }
</script>
<script>
  import { onMount } from "svelte" ;
  import { context_toggle } from "./stores.js";
  import { show_tooltip, hide_tooltip } from "./Tooltip.svelte";
  import { getCurrentUICard } from './ReChat.svelte';

  export let divID;
  export let ctx_help_div_style;

  let context_help_div;
  let contextControllerLoaded = false;

  $:{
    if(contextControllerLoaded)
    {
      if($context_toggle)
        context_help_div.style.display = "block";
      else
        context_help_div.style.display = "none";
    } 
  }
  async function display_tooltip(element)
  {
    await conversationManager.matchInput(divID, false);      
    show_tooltip(element, getCurrentUICard().dialogue);    
  }

  function close_tooltip()
  {
    hide_tooltip();
  }

  onMount( async function() { contextControllerLoaded = true;})
</script>

<style lang="less">
  .context_help_div
  {
    border: .3vw solid #597f96;
    border-radius: 10px;
    position: absolute;
    width: 97.5%;
    height: 97.5%;
    top: 0px;
    bottom: 0px;
    margin-right: 0%;
    z-index: 200;
  }
  :global(#coachContext)
  {
    margin-top: .5%;
    width: 95%;
    margin-left: 1%;
  }
  :global(#lastInputContext)
  {
    height: 80%;
    width: 96%;
    border-radius: 10px;
  }
  :global(#ctx_select)
  {
    margin-left: -1vw;
    border-radius: 0px;
  }
  :global(#ctx_tutorial_skip), :global(#ctx_tutorial_cont)
  {
    width: 11.5vw;
    height: 2vw;
    top: 0%;
  }
  :global(#ctx_advance)
  {
    width: 2vw;
    height: 2vw;
    left: 96%;
    margin-top: -.5%;
    margin-left: .25%;
  }
  :global(#ctx_return)
  {
    width: 2vw;
    height: 2vw;
    left: 0%;
    margin-top: -0.5%;
    margin-left: -.25%;
  }
  :global(#ctx_navigate)
  {
    width: 80%;
    height: 2vw;
    margin-top: -0.5%;
    bottom: 0vw;
    left: 6vw;
  }
  :global(#ctx_notepad),:global(#ctx_notepad_close)
  {
    width: 7%;
    height: 80%;
    left: .5%;
  }
  :global(#ctx_speak)
  {
    width: 98%;
    height: 86%;
  }
  :global(#ctx_coach_open)
  {
    height: 95%;
    width: 95%;
    left: -1%;
    top: -1%;
    border-radius: 5px;
  }
  :global(#ctx_coach_open_validate),:global(#ctx_coach_close_validate)
  {
    border-radius: 50%;
    margin-right: -5.5%;
    width: 55%;
    height: 55%;
    top: 0%;
    right: 0%;
    margin-top: -7.5%;
    z-index: 200;
  }
  :global(#ctx_notepad_input)
  {
    width: 95.5%;
  }
  :global(#ctx_notepad_scroll)
  {
    width: 1%;
    right: 0%;
  }
  :global(#ctx_complete)
  {
    width: 10vw;
    height: 3vw;
    right: 0%;
    margin-top: -2%;
    margin-right: -.5%;
  }
  :global(#ctx_burger)
  {
    width: 90%;
    height: 95%;
    margin-left: -10%;
    margin-top: -5%;
  }
  :global(#ctx_criterion_instructions)
  {
    width: 5.5vw;
    height: 1.25vw;
    margin-top: .5vw;
  }
  :global(#ctx_check_multiple)
  {
    margin-left: -5%;    
  }
  :global(#ctx_review_bottom)
  {
    top: 90%;
		width: 4%;
		height: 5.5%;
  }
  :global(#ctx_review_top)
  {
    top: 6.5%;
  	width: 4%;
		height: 5.5%;
  }
  :global(#ctx_review_scroll)
  {
    right: 0;
    width: 1vw;
    height: 89%;
    top: 7%;
  }  
  :global(#ctx_review_close)
  {
    margin-left: -15%;
    margin-top: -15%;
  }  
  :global(#ctx_coach_expand_scoring),
  :global(#ctx_coach_general),
  :global(#ctx_coach_scoring),
  :global(#ctx_coach_review),
  :global(#ctx_coach_tutorial)
  {
    margin-top: -1%;
    z-index: 29;
  }
  :global(#ctx_reveal_intensity),
  :global(#ctx_reveal_frequency),
  :global(#ctx_reveal_severity)
  {
    margin-top: -.3vw;
    z-index: 29;
  }
</style>

<div class="context_help_div" style={ctx_help_div_style} id={divID} bind:this={context_help_div} on:mouseenter={(event)=>display_tooltip(context_help_div)} on:focus={(event)=>display_tooltip(context_help_div)} on:mouseleave={close_tooltip} on:blur={close_tooltip} tabindex="0">    
</div>