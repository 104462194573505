<script>
import Transcript, {get_top_div_txt,get_bottom_div_txt } from './Transcript.svelte';
import TranscriptNavigation from './TranscriptNavigation.svelte';
import TranscriptTitle from './TranscriptTitle.svelte';
import ContextController from './ContextController.svelte';
let contextContent;

let bottomDivItemText;
let topDivItemText;

function setBottomDivText()
{
	bottomDivItemText = get_bottom_div_txt();
}
function setTopDivText()
{
	topDivItemText = get_top_div_txt();
}
</script>
<script context="module">
import { scrollCriterion, torcLinkToggle, start_transcript_interval, stop_transcript_interval, scroll_transcript } from './Transcript.svelte';
import { get } from 'svelte/store';
import { show_TORC_links } from './stores.js';
import { disable_enable_coach_button } from './CoachButton.svelte';
export function open_transcript()
{
	if(is_transcript_open())
		return;
	var responseContainer = document.getElementById("reviewResponseContainer");	
	var dialogueContainer = document.getElementById("dialogue_div");	
	responseContainer.style.display = "flex";		
	dialogueContainer.style.display = "none";	

	start_transcript_interval();
	torcLinkToggle(get(show_TORC_links));
	scroll_transcript();
	scrollCriterion();
}

export function close_transcript()
{
	var responseContainer = document.getElementById("reviewResponseContainer");
	var dialogueContainer = document.getElementById("dialogue_div");
	if(is_transcript_open())
	{
		responseContainer.style.display = "none";
		dialogueContainer.style.display = "block";
		disable_enable_coach_button(false);
	}
		
}
export function is_transcript_open()
{	
	var transcriptContainer = document.getElementById("reviewResponseContainer").style.display;
	return transcriptContainer && transcriptContainer!="none";
}
</script>
<style lang="less">
	#reviewResponseContainer
	{
		background-color: #d3d3d3;
		display: none;
		flex-direction: column;
		position: absolute;
		height: 83%;
		width: 100%;
		top: 19.4%;
		z-index: 40;
		left: 0;
		max-width: 100%;
		overflow: hidden;
	}
</style>

<div id="reviewResponseContainer">      
	<TranscriptTitle />
	<TranscriptNavigation nav_id="topBannerContainer" nav_class="navBanner-top" nav_dir_top={true} span_label="Top Navigation Banner" arrow_class="arrow-up" context_divID={"ctx_review_top"}/>
	<TranscriptNavigation nav_id="bottomBannerContainer" nav_class="navBanner-bottom" nav_dir_top={false} span_label="Bottom Navigation Banner" arrow_class="arrow-down" context_divID={"ctx_review_bottom"}/>
    <ContextController divID={"ctx_review_scroll"}/>
	<Transcript transcriptMethod={"transcriptContainer"}/>
</div>
