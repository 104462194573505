<script>
import { base_asset_path } from './stores.js'
import eval_captions from "./eval_captions_v1.json";
export let eval_ratings;
</script>
<style lang="less">
table
{
  display: block;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  background-color: white;
  width: 80%;
  margin-bottom: 1vw;
  margin-left:10%;
  margin-right:10%;
  table-layout: fixed;
}

td, th
{
  border: 1px solid black;
  padding: .5vw;
}

tr
{
  font-size: 1.5vw;
  text-align: center;
  color: black;
}

tr:nth-child(odd) {
  background-color: #dedede;
  -webkit-print-color-adjust: exact; 
}
</style>

<div class="body_table">
  <table>
      <tr>
      <th align="left" bgcolor="#C0C0C0">{eval_captions.ItemName}</th><th bgcolor="#C0C0C0" >{eval_captions.YourInitialScore}</th><th bgcolor="#C0C0C0">{eval_captions.CorrectScore}</th>
      </tr>
        {#each eval_ratings as rating}
          <tr>
            <th width="70%" align="left" scope="col">{rating.title}</th><td>{rating.initial}</td><td>{rating.correct}</td>
          </tr>
        {/each}
  <table>
</div>





