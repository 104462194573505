<script context="module">
	import { get } from 'svelte/store';
	import { page, mac_intel, environment } from "./stores.js";
	import {get_session, get_item_history} from "./server_api"
	import {get_saved_state} from "./saved_states"
	export function is_dev_environment()
	{
		return get(environment) == "development";
	}
	export function scoring_page()
	{
		return get(page).itemNum >= 0 && get(page).itemNum <= 30;
	}
	var render_area_w=0;
	var render_area_h=0;
	export function resize_glfw()
	{
		let e = document.getElementById("render_area");
		let multiplier = get(mac_intel)?1:2;
		let new_render_area_w=e.clientWidth*window.devicePixelRatio*multiplier;
		let new_render_area_h=e.clientHeight*window.devicePixelRatio*multiplier;
		if(render_area_w!=new_render_area_w || render_area_h!=new_render_area_h)
		{
			render_area_w=new_render_area_w;
			render_area_h=new_render_area_h;
			console.log("resizing the window: ",render_area_w,render_area_h);
			Module['resize_glfw_window'](render_area_w,render_area_h);
		}
	}
	export function deactivate_tabbing(ids_to_not_tab)
	{
		ids_to_not_tab.forEach(id =>
		{
			let element = document.getElementById(id);
			if(element!=null)
			{
				element.style.pointerEvents = "none";
				element.tabIndex = -1;
			}
		});
	}
	export function activate_tabbing(ids_to_tab)
	{
		ids_to_tab.forEach(id =>
		{
			let element = document.getElementById(id);
			if(element!=null)
			{
				element.style.pointerEvents = "all";
				element.tabIndex = 0;
			}
		});
	}
</script> 