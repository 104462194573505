<script context="module">
import { coach_asset_path, patient_asset_path, base_asset_path, game_mode } from './stores.js';
import { get } from 'svelte/store';
import {add_criterion_div} from './Transcript.svelte';

let html_txt_part1={
coach:'<div class="transcriptDiv"><div class="imgWrapDiv"><span tabindex="0" class = "torc_link_div_transcript" id="', 
patient:'<div class="transcriptDiv"><div class="imgWrapDiv"><span tabindex="0" class = "torc_link_div_transcript" id="',
user:'<div class="userTranscriptDiv" id="'
}
 
let html_txt_part2={
coach:'"><img class = "torc_link_icon_transcript" src="'+get(base_asset_path)+'TORCfavicon.svg" alt="Torc Link"></span><img class="coach_transcript_img" src="'+get(coach_asset_path)+'Transcript_Portrait.png" alt="Coach"></div> <p class="trasnscriptParagraph-coach"> <span style="font-size: .65vw; color: white; background-color: #51a6dc; border-radius: .5vw; border-width: .5vw; padding: .3vw; padding-bottom: .2vw; margin-right: .5vw;">COACH TIP</span>',
patient:'"><img class = "torc_link_icon_transcript" src="'+get(base_asset_path)+'TORCfavicon.svg" alt="Torc Link"></span><img class="coach_transcript_img" src="'+get(patient_asset_path)+'Transcript_Portrait.png" alt="Patient"></div> <p class="trasnscriptParagraph-patient">',
user:'"><p class="trasnscriptParagraph-user">'
}


export function add_transcript_dialogue(page, participant, dialogue, character_dir, card_name )
{
 	var dialogueContainer = document.getElementById("" + page + "TranscriptDiv");
	
	if(!dialogueContainer)
	{
		add_criterion_div(page);
		dialogueContainer = document.getElementById("" + page + "TranscriptDiv");
	}
 	let id_str=card_name+participant+(new Date().getTime());
 	dialogueContainer.innerHTML += html_txt_part1[participant]+id_str+html_txt_part2[participant]+dialogue+'</p></div>';
	
	if(participant != "user")
	{
		var btn = document.getElementById(id_str);
 		var cmd = 'window.open('+'"https://torc.revergestudios.com/projects/c5t/characters/'+character_dir+'/cards/'+card_name+'/editor")';
 		btn.setAttribute("onclick", cmd);
	}
 
}

</script>

<style lang="less">
	:global(.criterionLabel)
	{
		transition: width 0.2s ease;
		position: relative;
		display: flex;
		left: -1%;
		width: auto;
		height: 2vw;
		z-index: 40;
		overflow: hidden;		
		border-radius: 0 25px 25px 0;
	}
	:global(.critBannerSpan)
	{
		border: none;
		display: flex;
		height: 100%;
		background-color: #ffffff;
		border-radius: 0 30px 30px 0;
		padding-right: 3vw;
		z-index: 40;
	}
	:global(.torc_link_div_transcript)
	{
		width: 3.5vw;
		height: 3.5vw;
		border-radius: 2px;
		display: none;
		position: absolute;
		cursor: pointer; 
	}
	:global(.torc_link_div_transcript:focus)
	{
		box-shadow: inset 0 0 0 0px #fff, 0 0 0 2px #023b5a;
		outline: none; // removes default focus outline
	}
	:global(.torc_link_div_transcript:hover) { box-shadow: 0 0 0 2px #023b5a; }
	:global(.torc_link_icon_transcript)
	{
		height: 1.3vw;
		width: 1.3vw;
		right: 0;
		bottom: 0;
		position: absolute;
		padding-bottom: 10%;		
	}
	:global(.coach_transcript_img)
	{
		width: 3.5vw;
		height: 3.5vw;
		display: inline-block;
	}
	:global(.imgWrapDiv)
	{
		padding-right: 2.5%;
		padding-top: 2.5%;
		padding-bottom: 2.5%;
		position: relative;
	}
	:global(.transcriptDiv)
	{
		width: 75%;
    	padding-left: 5%;
		display: flex;
		flex: .00001;
		-webkit-animation: flexGrow 500ms ease forwards;
		-o-animation: flexGrow 500ms ease forwards;
		animation: flexGrow 500ms ease forwards;
	}
	:global(.userTranscriptDiv)
	{
		width: 70%;
    	padding-left: 25%;
		text-align: right;
		margin-right: 0;
		flex-grow: 0.00001;
		-webkit-animation: flexGrow 200ms ease forwards;
		-o-animation: flexGrow 200ms ease forwards;
		animation: flexGrow 200ms ease forwards;
	}
	:global([class*="trasnscriptParagraph-"])
	{
		margin-block-start: 0;
		margin-block-end: 0;
		font-size: 0.9vw;
		padding-top: 2.5%;
    	padding-bottom: 2.5%;
		display: inline-block;
		line-height: 1vw;
	}
	:global(.trasnscriptParagraph-coach){line-height: 1vw;}
	:global(.trasnscriptParagraph-patient){line-height: 1vw;}
	:global(.trasnscriptParagraph-user){line-height: 1vw;margin-right: 0;font-style: italic;}
	:global(.transcriptCriterionItemContainer)
	{
		display: flex;
		flex-direction: column;
		margin-top: auto;		
		flex-basis: auto;
	}
	@-webkit-keyframes flexGrow {to {flex-grow: 1;}}
	@-o-keyframes flexGrow {to {flex-grow: 1;}}
	@keyframes flexGrow {to {flex-grow: 1;}}
</style>
	
