<script>
import { scoring_page } from './utils.svelte';
import { page, context_toggle } from './stores.js';
import { onMount} from 'svelte'
import { close_coach_menus, close_coach_sub_menu, is_coach_menu_open} from './CoachMenu.svelte';
import ContextController from "./ContextController.svelte";
import { close_transcript, is_transcript_open } from './TranscriptReview.svelte';
import { hide_coach_text } from './CoachText.svelte';

let notepad_button;
let notepad_svg;
let svgLineList;
$: {
        if(notepad_button)      
        {          
          for(let svgItem of svgLineList)
          {
            svgItem.style.stroke = $page.itemNum < 0 ? "gray" : "black";  
          }        
          
          notepad_button.disabled = $page.itemNum < 0;   
          notepad_svg.style.fill = $page.itemNum < 0 ? "gray":"black";
        }
          

    }

function toggle_notepad(type)
{

  if(opened)
  {    
    close_notepad(type);
  }
  else
  {      
    open_notepad(type);    
  }
}
function open_notepad(type)
{
  if(is_transcript_open())
    close_transcript();
  close_coach_menus();
  hide_coach_text();
  opened = true;
  document.getElementById("notepadBtn").blur();
  document.getElementById("notepad").style.display = "block";
  let e = document.getElementById("notepadText")
  e.tabIndex = "0";
  if(scoring_page())
    e.disabled = false;
  document.getElementById("scrollList").scrollTop = document.getElementById("scrollList").scrollHeight;
  setTimeout(function(){
      document.getElementById("notepadText").focus();
    }, 50);
}

onMount( async function()
{  
  svgLineList = document.querySelectorAll("#GRP_Notebook > line");
});
</script>

<script context="module">

let opened = false;

export function close_notepad(type)
{ 
  opened = false;
  document.getElementById("notepadBtn").blur();
  document.getElementById("notepad").style.display = "none";
  let e = document.getElementById("notepadText")
  e.disabled = true;
  e.tabIndex = "-1";  
  if(type =="keyboard")
  {
    setTimeout(function(){
      document.getElementById("notepadBtn").focus();
    }, 50);
  }  
}
</script> 


<style lang="less">
#notepadBtn
{
	background: transparent;
  border: none !important;
  border-radius: 2px;
  width: 7%;
  height: 80%;
  display: block;
  position: relative;
  left: 1%;
  top: 10%;
  cursor: pointer;
  pointer-events: none;
}

#notepadBtn:disabled > .btn_content
{
  pointer-events: none;
}
#notepadSvg
{
	width: 100%;
  height: 100%;
  fill: #000000;
  position: absolute;
  left: 10%;
  top: 0%;
  display: inline-block;
  pointer-events: none;
}
.btn_content
{
	display: inline-block;
	width: 100%;
	height: 100%;
  border-radius: 2px;
	pointer-events: all;
}
#notepadBtn:focus > .btn_content 
{
  box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
  outline: none; // removes default focus outline
}
#notepadBtn:focus,
.btn_content:focus
{
  outline: none;
}
#notepadBtn:hover { box-shadow: 0 0 0 2px #023b5a; }
</style>
{#if opened}
  <ContextController divID={"ctx_notepad_close"}/>
{:else}
  <ContextController divID={"ctx_notepad"}/>
{/if}
<button bind:this={notepad_button} id="notepadBtn" on:mousedown={(event)=>toggle_notepad("mouse")} on:keypress={(event)=>toggle_notepad("keyboard")} aria-label="Notepad toggle" tabindex={$context_toggle?"-1":"0"}><span class="btn_content" tabindex="-1" aria-hidden="true"></span><svg bind:this={notepad_svg} id="notepadSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.43 66.52"><g id="Layer_2" data-name="Layer 2"><g id="NotebookIcon"><g id="GRP_Notebook"><path d="M28.06,41.43,25,50l8.78-2.86a10.62,10.62,0,0,0-2.15-3.88A7.64,7.64,0,0,0,28.06,41.43Z"/><path d="M29.18,39.59a8.54,8.54,0,0,1,4,2.24,9.33,9.33,0,0,1,2.34,3.88L54.8,26.63,48.47,20.2Z"/><path d="M60.31,17.34,57,14.28s-1.48-1-3.17.71l-3.47,3.47,6.33,6.23S59.29,22.14,61,20.4C62.25,19.18,60.31,17.34,60.31,17.34Z"/><path d="M38,7a3,3,0,0,1,3,3V59.52a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V10A3,3,0,0,1,7,7H38m0-4H7a7,7,0,0,0-7,7V59.52a7,7,0,0,0,7,7H38a7,7,0,0,0,7-7V10a7,7,0,0,0-7-7Z"/><line x1="7" x2="7" y2="10" style="fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:4px"/><line x1="17" x2="17" y2="10" style="fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:4px"/><line x1="27" x2="27" y2="10" style="fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:4px"/><line x1="37" x2="37" y2="10" style="fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:4px"/></g></g></g></svg></button>
