<script>
  import criterionFile from "./criterions.json";
  import { onMount } from "svelte";
  import focusTrapModule from "focus-trap";
  import versionFile from "./version.json";
  import { createEventDispatcher } from 'svelte';
  import { debug_mode, page, desiredServerVersion, environment, userID, show_TORC_links, base_asset_path, common_asset_path, strict_scoring, input_disabled_dialogue, input_disabled_tutorial, context_toggle } from './stores.js';
  import { tweened } from 'svelte/motion';
  import { cubicOut } from 'svelte/easing';
  import { cubicInOut } from 'svelte/easing';
  import { get_current_version, set_aicc_completed, post_event_log } from './server_api.js';
  import {toggle_metrics} from './PerformanceMetrics.svelte';
  import ContextButton from './ContextButton.svelte';
  import ContextController from "./ContextController.svelte";
  import { scoring_page } from './utils.svelte';


  const dispatch = createEventDispatcher();
  const arrowRotation = tweened(90, {
    duration: 200,
    easing: cubicInOut
  });
  const expandheight = tweened(0, {
    duration: 200,
    easing: cubicOut
  });
 
  let menuFocus;
  let preDebugFocus;
  let debugFocus;
  let closeBtnFocus;
  let closeBtnSub;
  let menuBtnFocus;
  let submenuFocus;
  let logComments;

  let menuOverlay;
  let focusTrap;

  let logsOn = true;
  let submittingLog = false;
  export let showTestCard = false;
  export let showActiveCardsTable = false;
  export let showLogs = false;
  export let scormPort;
  let serverVersion = "";
  let clientVersion = "";
  let uid = "";
  let versionHash = "";

  const toggle = () => dispatch('toggle');
  
  $:{
    if(menuFocus)
    {
      if((scoring_page() && $input_disabled_dialogue) || $input_disabled_tutorial)
        menuFocus.disabled = true;
      else
        menuFocus.disabled = false;
    }
  }


function toggleDebug()
{
  $debug_mode = !$debug_mode;
}
function toggleStrictScoring()
{
  $strict_scoring = !$strict_scoring;
}
function navCheck(e)
{      
  console.log(e);
  console.log(menuOverlay);
  if(e.target != menuOverlay)
    closeNav(e);  
}
function openNav(e) {
  console.log(e);
  clientVersion = Module['getVersion']();
  versionHash = versionFile.changeset.substring(0,8);
  
  document.getElementById("myNav").style.right = "0px";
  // document.getElementById("myNav").style.boxShadow = "black 7px -5px 25px 4px";
  document.getElementById("closebtn").style.display = "block";
  document.getElementById("menuBtn").style.display = "none";
  document.getElementById("myNav").style.display = "block";
  setTimeout(function(){
                focusTrap.activate();
                closeBtnSub.blur();
                if(e.screenX == 0)
                  menuFocus.focus();
            }, 50); 
}
function openDebug(e){
  console.log(e);
  document.getElementById("subMenu").style.display = "block";
  setTimeout(function(){
                if(e.screenX == 0)
                  submenuFocus.focus();
            }, 50);  
}
function closeDebug(e)
{
  document.getElementById("subMenu").style.display = "none";
  setTimeout(function(){
                if(e.screenX == 0)
                  debugFocus.focus();
            }, 50);  
}
function closeNav(e) {   
  arrowRotation.set(90)
  expandheight.set(0); 
  submittingLog = false;
  document.getElementById("myNav").style.right = "-400px";
  document.getElementById("myNav").style.boxShadow = "";
  document.getElementById("closebtn").style.display = "none";
  if(document.getElementById("subMenu"))
    document.getElementById("subMenu").style.display = "none";
  document.getElementById("menuBtn").style.pointerEvents = "none";
  setTimeout(function(){
    focusTrap.deactivate();
    document.getElementById("menuBtn").style.display = "block";
    document.getElementById("myNav").style.display = "none";
    document.getElementById("menuBtn").style.pointerEvents = "all";
    if(e && e.screenX == 0)
    {      
        menuBtnFocus.focus();    
    }      
}, 200); 
}

function backToMainMenu(e)
{
  closeNav(e);
  dispatch('backToStart');
}

function toggleTestCard()
{
  showTestCard = !showTestCard;
  show_hide_testAndLogsDiv();
}

function toggleActiveCardsTable()
{
  showActiveCardsTable = !showActiveCardsTable;
  show_hide_testAndLogsDiv();
}

function toggleShowLogs()
{
  showLogs = !showLogs;
  show_hide_testAndLogsDiv();
}

function toggleTORCLinks()
{
  $show_TORC_links = !$show_TORC_links;
}


function restartInterview()
{
  dispatch('restart');
  dispatch('clear');
}

function courseCompleted()
{
  if(scormPort)
    scormPort.postMessage({func:"setStatus",param:"completed"});
  else
    set_aicc_completed();
}
function show_hide_testAndLogsDiv()
{
  document.getElementById('testAndLogs').style.display = (showTestCard||showActiveCardsTable||showLogs)?"block":"none";
}
function logReChatDropDown()
{
  if(submittingLog)
  {
    arrowRotation.set(90)
    expandheight.set(0);
    document.getElementById("submitBtn").style.display = "none";
    document.getElementById("sendLogBtn").style.pointerEvents = "none";
    setTimeout(function(){                
                document.getElementById("sendLogBtn").style.pointerEvents = "all";
                submittingLog = false;
                document.getElementById("sendLogBtn").focus();
            }, 200);     
  }
  else
  {
    arrowRotation.set(180);
    expandheight.set(5);
    submittingLog = true;

    setTimeout(function(){                
                document.getElementById("submitBtn").style.display = "block";
                logComments.focus();
            }, 5);      
  }
}

function mailFeedback()
{
  dispatch('feedback');
}

function mailLog()
{
  dispatch('log', {
    comment: logComments.value
  });
  let event_info = {report:logComments.value};
  post_event_log("USER_REPORT",event_info);
  document.getElementById("textareaLog").value = "";
  logReChatDropDown();
}

function backToStart(e)
{
  closeNav(e);
  dispatch('backToStart');
}

function evaluateFocus(e)
{
  setTimeout(function(){
     if(document.activeElement === debugFocus)
     {
       if(document.getElementById("subMenu"))
        document.getElementById("subMenu").style.display = "block";
     }
     else if(document.activeElement === preDebugFocus || document.activeElement === closeBtnFocus)
     {
       if(document.getElementById("subMenu"))
        document.getElementById("subMenu").style.display = "none";
     }
}, 5);    
}


onMount( async function() {
  var versionCheck = await get_current_version();
  if($desiredServerVersion == versionCheck)
  {
    serverVersion = versionCheck;
  }
  else
  {
    serverVersion = versionCheck + "(Desired: " + $desiredServerVersion + ")";
    document.getElementById("overlayVersion").style.marginLeft = "22.5%";
  }
  focusTrap = focusTrapModule('#myNav', {
  onDeactivate: function (e) {
    closeNav(e);
  },
  clickOutsideDeactivates: true,
  });  
});

</script>

<style lang="less">
.menuBar {
  position: relative;
  width: 100%;
  height: 2vw;
  min-height: 2vw;
  border-bottom-style: dotted;
  border-bottom: 2px dotted black;
  display: flex;
}
#critButtonDiv
{
  width: 97%;
  display: block;
}
.criterionDiv-Content {
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
  background: #efefef;
  padding-bottom: 0.125em;
}
.menuOverlay a {
  font-size: 1.25vw;
  color: #000000;
  display: block;
}
.criterionContainer:hover {
  cursor: pointer;
  outline: 0.5px solid #000000;
}
.critContainerH:hover {
  cursor: pointer;
  outline: 0.5px solid #000000;
}

.CriterionBtn:focus > .btn_content  {
    box-shadow: inset 0 0 0 0px #fff, 0 0 0 2px #023b5a;
    outline: none; // removes default focus outline
}
.optionsBtn:focus > .btn_content  {
    box-shadow: inset 0 0 0 0px #fff, 0 0 0 2px #023b5a;
    outline: none; // removes default focus outline
}
.optionsBtn:focus > .menuFunction  {
    outline: none; // removes default focus outline
    text-decoration: underline;
}
#debugFocus:focus > .optionSubMenu
{
  display: block;
}

.menuCloseBtn:focus > .closebtn {
  outline: none;
  text-decoration: underline;
}

.optionsBtn .menuFunction:hover {
  text-decoration: underline;
  cursor: pointer;
}
.optionsBtn:focus,
.CriterionBtn:focus,
.menuCloseBtn:focus,
.closebtn:focus,
.menuFunction:focus,
.btn_content:focus
{
  outline: none;
}

.mainMenu {
  position: absolute;
  z-index: 150;
  right: 0.1vw;
  cursor: pointer;
  font-size: 2vw;
  pointer-events: none;
}
.menuBtnCtnr
{
    position: relative;
    width: 2vw;
    height: 1.5vw;
    left: 0%;
    top: 10%;
    margin: 0;
    padding: 0;
    z-index: 150;
    background-size: contain;
}
.menuBtn
{
    display: block;
    transition: filter 0.2s;
    background-color: rgba(0, 0, 0, 0);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: none;
    position: relative;
    z-index: 150;
    width: 2vw;
    height: 1.5vw;
    left: 0%;
    top: 10%;
    margin: 0;
    padding: 0;
    cursor: pointer;
    pointer-events: none;
    border-radius: 2px;
}
.menuBtn:focus  {
    box-shadow: inset 0 0 0 0px #fff, 0 0 0 2px #023b5a;
    outline: none; // removes default focus outline
}
.menuBtn:hover { box-shadow: 0 0 0 2px #023b5a; }

.menuCloseBtn
{
  border: none;
  background: transparent !important;
  position: absolute;
  z-index: 150;
  right: 1vw;
  top: .75vw;
  cursor: pointer;
  width: 2vw;
  height: 2vw;
  margin: 0;
  padding: 0;
  pointer-events: all;
}

.btn_content
{
  display: inline-block;
  width: 100%;
  height: 100%;
  pointer-events: all;
}
.CriterionBtn
{
  background: transparent;
  border: none !important;
  font-size: 0;
  width: 6vw;
  height: 1.25vw;
  top: .75vw;
  position: absolute;
  padding: 1px 0px;
  cursor: pointer;
  pointer-events: all;
}
.criterionSpan {
  position: absolute;
  display: inline-block;
  transition: transform 0.3s ease-in-out;
  transform-origin: 50% 0;
  transform-style: preserve-3d;
  font-weight: bold;
  font-size: 1vw;
  left: 0vw;
  top: 0.15vw;
  margin-inline-start: 0.5vw;
  z-index: 1;
}
.criterionSpanDI
{
  position: absolute;
  display: inline-block;
  transition: transform 0.3s ease-in-out;
  transform-origin: 50% 0;
  transform-style: preserve-3d;
  font-weight: bold;
  font-size: 1vw;
  left: 0vw;
  top: 0.15vw;
  margin-inline-start: 0.5vw;
  width: 11vw;
  z-index: 1;
}
.criterionCollapse {
  display: inline-block;
  z-index: 199;
  transform: rotateX(90deg);
  transition: transform 0.3s ease-in-out;
  transform-origin: top;
  transform-style: preserve-3d;
  font-size: 1.15vw;
  margin-inline-start: 0.75vw;
  margin-block-start: 1.65vw;
  position: absolute;
  top: -0.25vw;
}
.critCollapseH
{
  position: relative;
  display: block;
  font-weight: bold;
  font-size: 1vw;
  top: 0.15vw;
  z-index: 1;
  text-align: center;
}
.criterionDiv {
  max-height: 0%;
  width: 100%;
  position: absolute;
  z-index: 100;
  top: 2vw;
  left: 0vw;
  overflow: hidden;
  transition: max-height 0.35s ease-in-out;
  transform-origin: top;
  box-shadow: -1px 3px 4px #888;
}
.criterionTitle {
  border-bottom: 2px solid black;
}
.pTitle {
  font-size: 2vw;
  font-weight: bold;
  margin-inline-start: 0.25vw;
  margin-inline-end: 0.25vw;
  margin-block-start: 0;
  margin-block-end: 0;
}
.subText {
  font-size: 1vw;
  margin-inline-start: 0.25em;
  margin-inline-end: 0.25em;
}
.criterionContainer {
  position: absolute;
  width: 6vw;
  height: 1.25vw;
  top: .75vw;
  transition: transform 0.5s;
  padding: 0em;
  background: #efefef;
  pointer-events: none;
  overflow: hidden;
}
.critContainerH {
  position: absolute;
  width: 7vw;
  height: 1.25vw;
  top: .75vw;
  transition: transform 0.5s;
  padding: 0em;
  background: #efefef;
  pointer-events: none;
  align-content: center;
  text-align: center;
}
.critContainerS {
  position: absolute;
  width: 5vw;
  height: 1.25vw;
  top: .75vw;
  transition: transform 0.5s;
  padding: 0em;
  background: #efefef;
  pointer-events: none;
  align-content: center;
  text-align: center;
}
.menuOverlay {
  height: auto;
  width: 15%;
  position: fixed;
  z-index: 199;
  top: 0;
  right: -15vw;
  transition: right 0.3s ease-in;
  /* overflow: hidden; */
  background-color: #023b5a;
  transition: 0.5s;
  display: none;
}
.menuOverlay-content {
  position: relative;
    list-style-type: none;
    margin-block-start: 0;
    width: 100%;
    text-align: left;
    padding-inline-start: 0
}
.menuOverlay .closebtn {
  position: absolute;
  top: -.5vw;
  right: 7%;
  font-size: 3.5vw;
  text-decoration: none;
  color: #E2E2E2;
  padding: 0;
}
.menuOverlay .closebtn:hover {
  text-decoration: underline;
}
.menuOverlay-version {
  bottom: 0.5em;
  margin-left: 35%;
  position: absolute;
  font-size: 0.6vw;
  text-align: center;
  word-break: break-word;
}
.menuOverlay-version p {
  margin-block-start: 0.25vw;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: #E2E2E2;
}
.menuFunction {
  font-size: 1.25vw;
  color: #E2E2E2;
  display: inline-block;
  margin-left: 1vw;
  cursor: pointer;
}
.menuFunction:hover {
  text-decoration: underline;
  cursor: pointer;
}


.menuTitle-Div {
  border-bottom: 1px solid #E2E2E2;
  height: 12.5%;
  margin-top: 4vw;
}
.menuTitle {
    color: #E2E2E2;
    margin-inline-start: 1vw;
    top: .5vw;
    font-size: 2vw;
    position: absolute;
}

.menuListItem
{
    display: block;
    border-bottom: 1px solid #E2E2E2;
    position: relative;
}

.menuVersion
{
    display: block;
    border-bottom: 1px solid #E2E2E2;
    position: relative;
    padding-top: .25vw;
    height: 4vw;
}
.menuIcons
{
  margin-inline-start: 5%;
  fill: #E2E2E2;
  width: 1.5vw;
  height: 1.5vw;
  position: absolute;
  padding-top: .75vw;
  padding-bottom: .75vw;
}
.optionSubMenu
{
  display: none;
  position: absolute;
  z-index: 1;
  background-color: #023b5a;
  top: 0vw;
  left: -14.5vw;
  width: 100%;
}

#menuOptions:hover .optionSubMenu 
{
  display:block;
}

#debugFocus:focus .optionSubMenu 
{
  display:block;
}

#rightCoach
{
  font-size: 1vw;
}

a.nostyle
{
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}
#critSpanDI
{
  width: 11vw;
}
#DIButton
{
  width: 11vw;
}

#critContainerDI
{
  width: 11vw;
}
.optionsBtn
{
  background: transparent;
  border: none !important;
  font-size: 0;
  width: 100%;
  height: 100%;
  top: 0vw;
  position: relative;
  cursor: pointer;
  pointer-events: all;
  padding-top: .75vw;
  padding-bottom: .75vw;
}
.optionsBtn:disabled{ pointer-events: none;background: gray;}


.expanding
{
  height: var(--exp);
  margin: auto;
}
textarea
{         
    font-family: 'Lato', sans-serif;
    overflow-y: auto;
    position: relative;
    font-size: 0.9vw;
  //  background-color: #f8f8f800;
    border-radius: 8px;
    width: 95%;
    height: 70%;
    resize: none;
    outline: none !important;
    border-width: 2px;
    margin: auto;
    display: block;
}
.submitLog
{
  width: 3vw;
  height: 1.5vw;
  float: right;
  margin-top: .1vw;
  margin-right: 0.25vw;
  display: block;
  background: #e2e2e2;
  background-image: linear-gradient(0deg, #e2e2e2 0%, #e2e2e2 30%);
  color: #023b5a;
  font-family: Lato;
  font-size: .6vw;
  font-weight: 700;
  letter-spacing: 1px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
.submitLog:hover,
.submitLog:focus {
background: white;
border: 2px solid #7092a6;
color: #2e4a5a;
}

#logArrow
{
  margin-left: 3%;
  fill: #E2E2E2;
  top: .5vw;
  width: 2vw;
  height: 2vw;
  position: absolute;
  transform: rotate(var(--rotate));
}
#textareaLog
{
  font-size: .8vw;
}
@media(max-width:750px)
{
  #textareaLog
  {
    height: 40%;
  }
}

@media(max-width:1350px)
{
  #textareaLog
  {
    height: 60%;
  }
}
</style>


<div id="myNav" class="menuOverlay" bind:this={menuOverlay}>
  <div class="menuTitle-Div">
  <label class="menuTitle"></label>
  </div>
  <button class="menuCloseBtn" onmouseup="this.blur();" on:click|stopPropagation={closeNav} bind:this={closeBtnFocus} on:keypress|stopPropagation={evaluateFocus} aria-label="Menu close"><span class="closebtn" data-cy="closeBtn" id="closebtn" bind:this={closeBtnSub} aria-hidden="true" tabindex="-1">&times;</span></button>  
  <div class="menuOverlay-content">   
    <div class="menuListItem">
    <!-- <span class="btn_content" tabindex="-1" aria-label="Back to main menu"></span> -->
      <svg class="menuIcons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="transform: scale(-1)"><path fill="none" d="M0 0h24v24H0z"/><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"/></svg>      
      <button class="optionsBtn" onmouseup="this.blur();" on:click={backToMainMenu} bind:this={menuFocus} aria-label="Back to main menu"><label class="menuFunction" aria-hidden="true">Main Menu</label></button>
    </div> 
    {#if $environment == "development"}
      <div class="menuListItem">
        <div>
          <div>
          <img style="--rotate:{$arrowRotation}deg" src={$base_asset_path+"whiteDropArrow.png"} alt="Dropdown arrow for submit log" id="logArrow" height="15em">
          <button class="optionsBtn" onmouseup="this.blur();" id="sendLogBtn" on:click={logReChatDropDown} aria-label="Submit Report"><label class="menuFunction" aria-hidden="true">Submit Report</label></button>
          </div>
        {#if submittingLog} 
            <div class="expanding" style="--exp:{$expandheight}vw">
              <textarea bind:this={logComments} id="textareaLog" placeholder="The log for the current session is automatically saved. Enter any additional comments here as often as desired."/>
              <button class="submitLog" onmouseup="this.blur();" id="submitBtn" on:click={mailLog}>Ok</button>
            </div>
          {/if}
        </div>
      </div>
      <div class="menuListItem">
        <svg class="menuIcons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/><path d="M0 0h24v24H0z" fill="none"/></svg>      
        <button class="optionsBtn" onmouseup="this.blur();" on:click={mailFeedback} aria-label="Mail feedback"><label class="menuFunction" aria-hidden="true">Mail Feedback</label></button>
      </div> 
    {/if}
    <div class="menuListItem">
      <a class="nostyle" href ={$base_asset_path +"CAPS-5.pdf"} download="CAPS-5.pdf" tabindex="-1">
      <svg class="menuIcons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>              
      <button class="optionsBtn" onmouseup="this.blur();" aria-label="Download CAPS-5 P D F" on:blur|stopPropagation={evaluateFocus} bind:this={preDebugFocus}><label class="menuFunction" aria-hidden="true">CAPS-5 PDF</label></button>
      </a>
    </div>   
    <div class="menuListItem">
      <a class="nostyle" href ={$common_asset_path + "CAPS-5_Guidance.pdf"} download="CAPS-5_Guidance.pdf" tabindex="-1">
      <svg class="menuIcons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>              
      <button class="optionsBtn" onmouseup="this.blur();" aria-label="Download Guidance P D F" on:blur|stopPropagation={evaluateFocus}><label class="menuFunction" aria-hidden="true">Guidance PDF</label></button>
      </a>
    </div> 
    {#if $environment == "development"}
    <div class="menuListItem" id="menuOptions">
      <svg class="menuIcons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M12.09 2.91C10.08.9 7.07.49 4.65 1.67L8.28 5.3c.39.39.39 1.02 0 1.41L6.69 8.3c-.39.4-1.02.4-1.41 0L1.65 4.67C.48 7.1.89 10.09 2.9 12.1c1.86 1.86 4.58 2.35 6.89 1.48l7.96 7.96c1.03 1.03 2.69 1.03 3.71 0 1.03-1.03 1.03-2.69 0-3.71L13.54 9.9c.92-2.34.44-5.1-1.45-6.99z"/></svg>      
      <button class="optionsBtn" id="debugFocus" data-cy="debugFocusBtn" onmouseup="this.blur();" on:click|stopPropagation={openDebug} on:blur|stopPropagation={evaluateFocus} bind:this={debugFocus} aria-label="Debug"><label class="menuFunction" aria-hidden="true">Debug</label></button>
      <div class="optionSubMenu" id="subMenu">
        <div class="menuListItem">
          <svg class="menuIcons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 5h10v2h2V3c0-1.1-.9-1.99-2-1.99L7 1c-1.1 0-2 .9-2 2v4h2V5zm8.41 11.59L20 12l-4.59-4.59L14 8.83 17.17 12 14 15.17l1.41 1.42zM10 15.17L6.83 12 10 8.83 8.59 7.41 4 12l4.59 4.59L10 15.17zM17 19H7v-2H5v4c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-4h-2v2z"/></svg>          
          <button class="optionsBtn" onmouseup="this.blur();" on:click|stopPropagation={toggleTORCLinks} aria-label="Toggle TORC Links"><label class="menuFunction" aria-hidden="true">Toggle TORC Links</button>
        </div>
      <!--  <div class="menuListItem">
          <svg class="menuIcons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"/><path d="M0 0h24v24H0z" fill="none"/></svg>                  
          <button class="optionsBtn" onmouseup="this.blur();" on:click|stopPropagation={restartInterview} bind:this={submenuFocus} aria-label="Reset ReChat"><label class="menuFunction" aria-hidden="true">Reset ReChat</label></button>
        </div> -->
        <div class="menuListItem">
          <svg class="menuIcons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M19.77 5.03l1.4 1.4L8.43 19.17l-5.6-5.6 1.4-1.4 4.2 4.2L19.77 5.03m0-2.83L8.43 13.54l-4.2-4.2L0 13.57 8.43 22 24 6.43 19.77 2.2z"/></svg>          
          <button class="optionsBtn" onmouseup="this.blur();" on:click|stopPropagation={courseCompleted} aria-label="Course complete debug"><label class="menuFunction" aria-hidden="true">Course Completed</label></button>
        </div>
        <div class="menuListItem">
          <svg class="menuIcons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 5h10v2h2V3c0-1.1-.9-1.99-2-1.99L7 1c-1.1 0-2 .9-2 2v4h2V5zm8.41 11.59L20 12l-4.59-4.59L14 8.83 17.17 12 14 15.17l1.41 1.42zM10 15.17L6.83 12 10 8.83 8.59 7.41 4 12l4.59 4.59L10 15.17zM17 19H7v-2H5v4c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-4h-2v2z"/></svg>          
          <button class="optionsBtn" onmouseup="this.blur();" on:click|stopPropagation={toggle_metrics} aria-label="Toggle Metrics"><label class="menuFunction" aria-hidden="true">Toggle Metrics</label></button>
        </div>
        <div class="menuListItem">
          <svg class="menuIcons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 5h10v2h2V3c0-1.1-.9-1.99-2-1.99L7 1c-1.1 0-2 .9-2 2v4h2V5zm8.41 11.59L20 12l-4.59-4.59L14 8.83 17.17 12 14 15.17l1.41 1.42zM10 15.17L6.83 12 10 8.83 8.59 7.41 4 12l4.59 4.59L10 15.17zM17 19H7v-2H5v4c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-4h-2v2z"/></svg>          
          <button class="optionsBtn" onmouseup="this.blur();" on:click|stopPropagation={toggleTestCard} aria-label="Toggle test card"><label class="menuFunction" aria-hidden="true">Toggle Test Card</label></button>
        </div>
        <div class="menuListItem">
          <svg class="menuIcons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 5h10v2h2V3c0-1.1-.9-1.99-2-1.99L7 1c-1.1 0-2 .9-2 2v4h2V5zm8.41 11.59L20 12l-4.59-4.59L14 8.83 17.17 12 14 15.17l1.41 1.42zM10 15.17L6.83 12 10 8.83 8.59 7.41 4 12l4.59 4.59L10 15.17zM17 19H7v-2H5v4c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-4h-2v2z"/></svg>          
          <button class="optionsBtn" onmouseup="this.blur();" on:click|stopPropagation={toggleActiveCardsTable} aria-label="Toggle active cards"><label class="menuFunction" aria-hidden="true">Toggle Active Cards</label></button>
        </div>
        <div class="menuListItem">
          <svg class="menuIcons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 5h10v2h2V3c0-1.1-.9-1.99-2-1.99L7 1c-1.1 0-2 .9-2 2v4h2V5zm8.41 11.59L20 12l-4.59-4.59L14 8.83 17.17 12 14 15.17l1.41 1.42zM10 15.17L6.83 12 10 8.83 8.59 7.41 4 12l4.59 4.59L10 15.17zM17 19H7v-2H5v4c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-4h-2v2z"/></svg>          
          <button class="optionsBtn" on:click|stopPropagation={toggleShowLogs} aria-label="Toggle log visibility"><label class="menuFunction" aria-hidden="true">Toggle Log Visibility</label></button>
        </div>
        <div class="menuListItem">
          <svg class="menuIcons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
          <button class="optionsBtn" onmouseup="this.blur();" on:click|stopPropagation={toggleStrictScoring} on:blur|stopPropagation={evaluateFocus} aria-label="Toggle strict scoring"><label class="menuFunction" aria-hidden="true">Toggle Strict Scoring</label></button>
        </div>
        <div class="menuListItem">
          <svg class="menuIcons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
          <button class="optionsBtn" onmouseup="this.blur();" on:click|stopPropagation={toggleDebug} on:blur|stopPropagation={evaluateFocus} data-cy="debugModeBtn" aria-label="Toggle debug mode"><label class="menuFunction" aria-hidden="true">Toggle Debug Mode</label></button>
        </div>
      </div>
    </div>
    {/if}
  </div>
  <div class="menuVersion">
    <div class="menuOverlay-version" id="overlayVersion">
    <p id="serverVersionDebug">Server version: {serverVersion}</p>
    <p>Client version: {clientVersion}</p>
    {#if $environment=="development" || $debug_mode}
      <p>User ID: {$userID}</p>
    {/if}
    <p>{versionHash}</p>
  </div>
</div>
</div>
<div id="critDiv" class="criterionDiv">      
  {#if $page.itemNum >= 0 && $page.itemNum <= 30} 
    <div class="criterionDiv-Content" id="critContent">
      {#each criterionFile as jsonfile}
        {#if jsonfile.criterion === $page.criterion}
          {#if $page.criterion == "GL"}
          <div class="criterionTitle">
            <p class="pTitle">{jsonfile.titleName}</p>
          </div> 
          {:else}
            <div class="criterionTitle">
            <p class="pTitle">{jsonfile.titleName}</p>
            </div> 
            <p class="subText">{jsonfile.titleText}</p>
            {#each jsonfile.subText as text}
              <p class="subText">{text}</p>
            {/each}
          {/if}
        {/if}
      {/each}
    </div>
  {:else}
    <div id="critContent"></div>
  {/if}
</div>
<div id="menuBar" class = "menuBar">
  <div id="critButtonDiv">
    {#if $page.criterion === "GL"}
    <ContextController divID={"ctx_criterion_instructions"} ctx_help_div_style={"width: 6vw;"}/>
    <div class="critContainerH">      
      <span id="critCollapse" class="critCollapseH" > Global Ratings </span>  
      <span id="critSpan" class="criterionSpan" data-cy="CriterionName"> </span>
    </div>
    {:else if $page.criterion ==="DI"}
    
      <ContextController divID={"ctx_criterion_instructions"} ctx_help_div_style={"width: 10.5vw;"}/>
      <button class="CriterionBtn" onmouseup="this.blur();" id="DIButton" on:click|stopPropagation={toggle} aria-label="Open Criterion Info" tabindex={$context_toggle?"-1":"0"}><span class="btn_content" tabindex="-1" aria-hidden="true"></span></button>
      <div class="criterionContainer" id="critContainerDI">
            <span id="critCollapse" class="criterionCollapse" >Collapse</span>
            <span id="critSpan" class="criterionSpanDI">Dissociative symptoms</span> 
      </div>  

    {:else if $page.criterion ==="Summary"}
    
    <ContextController divID={"ctx_criterion_instructions"} ctx_help_div_style={"width: 4vw;"}/>
    <button class="CriterionBtn" onmouseup="this.blur();" aria-label="Open Criterion Info" tabindex={$context_toggle?"-1":"0"}><span class="btn_content" tabindex="-1" aria-hidden="true" ></span></button>
    <div class="critContainerS">
      <span id="critCollapse" class="critCollapseH" > Summary </span>  
      <span id="critSpan" class="criterionSpan"> </span>
    </div>  
    
    {:else if $page.itemNum >= 0 && $page.itemNum <= 30}
      <ContextController divID={"ctx_criterion_instructions"} />
      <button class="CriterionBtn" onmouseup="this.blur();" on:click|stopPropagation={toggle} aria-label="Open Criterion Info" tabindex={$context_toggle?"-1":"0"}><span class="btn_content" tabindex="-1" aria-hidden="true"></span></button>
      <div class="criterionContainer">  
        <!-- <span id="critCollapse" class="criterionCollapse" >Collapse</span>
        <span id="critSpan" class="criterionSpan">Criterion A</span>     -->    
      {#if $page.criterion === "A"}
        <span id="critCollapse" class="criterionCollapse" >Collapse</span>
        <span id="critSpan" class="criterionSpan" data-cy="critSpanCritA">Criterion A</span>
      {:else if $page.criterion ==="B"}
        <span id="critCollapse" class="criterionCollapse" >Collapse</span>
        <span id="critSpan" class="criterionSpan">Criterion B</span>    
      {:else if $page.criterion ==="C" && $page.itemNum != 10}
        <span id="critCollapse" class="criterionCollapse" >Collapse</span>
        <span id="critSpan" class="criterionSpan">Criterion C</span>    
      {:else if $page.criterion ==="D" || $page.itemNum === 10}
        <span id="critCollapse" class="criterionCollapse" >Collapse</span>
        <span id="critSpan" class="criterionSpan">Criterion D</span>    
      {:else if $page.criterion ==="E"}
        <span id="critCollapse" class="criterionCollapse" >Collapse</span>
        <span id="critSpan" class="criterionSpan">Criterion E</span>
      {:else if $page.criterion ==="F"}
        <span id="critCollapse" class="criterionCollapse" >Collapse</span>
        <span id="critSpan" class="criterionSpan">Criterion F</span>    
      {:else if $page.criterion ==="G"}
        <span id="critCollapse" class="criterionCollapse" >Collapse</span>
        <span id="critSpan" class="criterionSpan">Criterion G</span>   
      {/if}

      </div>
    {:else}
      <span id="critCollapse" />
      <span id="critSpan" />
    {/if}
  </div>
  <ContextButton/>
 
  <div class="menuBtnCtnr">
    <ContextController divID={"ctx_burger"}  />
    <button class="menuBtn" onmouseup="this.blur();" id="menuBtn" data-cy="openMenuBtn" on:click={openNav} bind:this={menuBtnFocus} style={"background-image: url("+ $base_asset_path +"burgerMenu.png);"} tabindex={$context_toggle?"-1":"0"}><span class="btn_content" tabindex="-1" aria-label="Menu"></span></button>
  </div>
</div>