<script>
	import { input_disabled_dialogue, input_disabled_all, page, game_mode, progress_level } from './stores.js';	
	import { hide_coach_text } from './CoachText.svelte';
	import { disable_enable_coach_button } from './CoachButton.svelte';
	import { open_transcript } from './TranscriptReview.svelte';
	import { toggleTutorialAnimation } from './TutorialAnimationController.svelte';
	import { scoring_page } from './utils.svelte';
	import { getTutorialCard,getGeneralHelpCard,getScoringHelpCard } from './ReChat.svelte'
	import CoachMenuBtn from "./CoachMenuBtn.svelte";
	import ContextController from "./ContextController.svelte";
	import scoring_help_info_json from "./scoring_help_info.json";
	export let incoming_match_input;
	export let clear_last_input;
	let coachOpen = $game_mode == "learn" ? "ctx_coach_open" : "ctx_coach_open_validate";
	let coachClose = $game_mode == "learn" ? "ctx_coach_close" : "ctx_coach_close_validate";
	let scoring_help_info = scoring_help_info_json.scoring_help_info;		

	function disable_enable_button(name,disable)
	{
		var button = document.getElementById(name);
		if(button!=undefined)
		{
			button.disabled = disable;
			let nodes = button.getElementsByTagName('*');
			for(let i = 0; i < nodes.length; i++)
			{
				let name=nodes[i].id;
				if(name=="btn_menu_txt_ctnr"||name=="btn_drop_content")
					nodes[i].style.pointerEvents = disable?"none":"all";
			}
		}			
	}
	function disable_border_color_hack_for_item_scoring_help(disable)
	{
		var button = document.getElementById("reveal_scores");
		if(button!=undefined)
			button.style.borderColor = disable?"#565656":"#597f96";
	}
	function disable_enable_sub_menu_buttons(disable)
	{
		if(scoring_page())
		{
			for(let i=0; i < scoring_help_info[$page.itemNum].length; i++)
			{
				let button = document.getElementById(scoring_help_info[$page.itemNum][i].id);
				if(button && button!=undefined)
					button.disabled = disable;
			}
		}
	}

	function adjust_sub_menu_arrow(disable)
	{
		let e = document.getElementById("arrow_img");
		if(e)
			e.style.display=disable?"none":"block";
		e = document.getElementById("split_line");
		if(e)
			e.style.borderLeftColor=disable?"#565656":"white";
		e = document.getElementById("arrow_img_gray");
		if(e)
			e.style.display=disable?"block":"none";
	}

	$:{
		let scoring_reveal_not_available = $page.itemNum >= 0?$progress_level > $page.itemNum:false; 
		disable_enable_button("interview_help",$input_disabled_dialogue || (!$input_disabled_dialogue && getGeneralHelpCard() == null));
		disable_enable_button("item_scoring_help",$input_disabled_dialogue || (!$input_disabled_dialogue && getScoringHelpCard() == null));
		disable_enable_button("reveal_scores",$input_disabled_dialogue || scoring_reveal_not_available );
		disable_border_color_hack_for_item_scoring_help($input_disabled_dialogue || scoring_reveal_not_available );
		disable_enable_button("review_responses",$input_disabled_dialogue);
		disable_enable_button("replay_tutorial",$input_disabled_dialogue);
		adjust_sub_menu_arrow($input_disabled_dialogue || scoring_reveal_not_available );
		disable_enable_sub_menu_buttons($input_disabled_dialogue);
	}

    function interview_help()
    {
				let general_help_card = getGeneralHelpCard();
        if(general_help_card && general_help_card!=null)
            if(general_help_card.data!="")
                incoming_match_input(general_help_card.data,false);

    }
    function item_scoring_help()
    {
			let score_help_card = getScoringHelpCard();
        if(score_help_card && score_help_card!=null)
            if(score_help_card.data!="")
                incoming_match_input(score_help_card.data,false);
    }
	function item_scoring_help_toggle()
	{
		let display = document.getElementById("coach_submenu_ctnr").style.display;
		if( !display || display == "none" )
			open_sub_menu();
		else
			close_coach_sub_menu();
	}
	function scoring_help(ind)
	{
		var card_trigger=scoring_help_info[$page.itemNum][ind].card;
		incoming_match_input(card_trigger,false);
	}
	function review_responses()
	{
		open_transcript();
		clear_last_input();
		close_coach_menus();
		hide_coach_text();
		$input_disabled_all = false;
		disable_enable_coach_button(true);
	}
	async function replay_tutorial()
	{
		if(is_coach_menu_open())
			document.getElementById(coachClose).style.pointerEvents = "none";
		else
			document.getElementById(coachOpen).style.pointerEvents = "none";
		conversationManager.setConversationEnded("tutorial");
		await incoming_match_input("start_criterion_a_tutorial",false);
		toggleTutorialAnimation(getTutorialCard(), $page.itemNum);
		close_coach_menus();
	}
	function open_sub_menu()
	{		
		document.getElementById("coach_submenu_ctnr").style.display = "flex";
		let btn_item_scoring_help = document.getElementById("reveal_scores");
		if(btn_item_scoring_help!=null&&scoring_page())
		{
			btn_item_scoring_help.style.borderColor = "#b3c5cf";
			btn_item_scoring_help.style.borderRadius = "5px 5px 0px 0px";
			let last_button_ind = scoring_help_info[$page.itemNum].length-1;
			let id = scoring_help_info[$page.itemNum][last_button_ind].id;
			document.getElementById(id).style.borderRadius= "0px 0px 5px 5px";		
			document.getElementById("arrow_img").style.transform = "rotate(180deg)";
			document.getElementById("arrow_img_gray").style.transform = "rotate(90deg)";
		}
	}
</script>
<script context="module">
	import { get } from 'svelte/store';
	let conversationManager;
	let scoring_help_info = scoring_help_info_json.scoring_help_info;		
	export function close_coach_sub_menu()
	{
		document.getElementById("coach_submenu_ctnr").style.display = "none";
		var btn_item_scoring_help = document.getElementById("reveal_scores");
		if(btn_item_scoring_help!=null&&scoring_page())
		{
			btn_item_scoring_help.style.borderColor = "#597f96";
			btn_item_scoring_help.style.borderRadius = "5px";
			document.getElementById("arrow_img").style.transform = "rotate(90deg)";
			document.getElementById("arrow_img_gray").style.transform = "rotate(0deg)";
			let item_num = get(page).itemNum;
			let last_button_ind = scoring_help_info[item_num].length-1;
			let id = scoring_help_info[item_num][last_button_ind].id;
			document.getElementById(id).style.borderRadius= "0px 0px 0px 0px";
		}
	}
	export function is_coach_submenu_open()
	{
		var d = document.getElementById("coach_submenu_ctnr");
		return d && d.style.display!="none";
	}
	export function is_coach_menu_open()
	{
		var d = document.getElementById("coach_menu_ctnr");
		return d && d.style.display!="none";
	}
	export function open_coach_menu()
	{
		var coach_menu = document.getElementById("coach_menu_ctnr");
		coach_menu.style.display = "flex";
	}
	export function hide_coach_menu()
	{
		var coach_menu = document.getElementById("coach_menu_ctnr");
		coach_menu.style.display = "none";
	}
	export function close_coach_menus()
	{
		if(is_coach_submenu_open())
			close_coach_sub_menu();
		hide_coach_menu();
	}
	export function setConversationManager(convMgr)
	{
		conversationManager = convMgr;
	}
</script>
<style lang="less">
	#coach_menu_ctnr
	{
		flex-grow: 1;
		margin: 0 0;
		width: 100%;
		display: none;
		flex-direction:column;
		justify-content: flex-start;
		align-items: center;
		z-index: 21;
	}
	#coach_submenu_ctnr
	{
		width: 100%;
		display: none;
		flex-direction:column;
		justify-content: center;
		align-items: center;
	}
	.dummy_div
	{
		width: 100%;
		height: 35%;
	}
</style>

<div id="coach_menu_ctnr">
	{#if $game_mode == 'learn'}
		<CoachMenuBtn btn_class={"btn"} btn_ctnr_class={"menu_btn_ctnr"} btn_text={"Interview Help"} btn_id={"interview_help"} on_click={interview_help} area_lbl={"Interview Help"} divID={"ctx_coach_general"} />
		<CoachMenuBtn btn_class={"btn"} btn_ctnr_class={"menu_btn_ctnr"} btn_text={"Item Scoring Help"} btn_id={"item_scoring_help"} on_click={item_scoring_help} area_lbl={"Item Scoring Help"} divID={"ctx_coach_scoring"} />
		{#if $page.itemNum >= 0 && $page.itemNum <= 30}
			<CoachMenuBtn btn_class={"btn"} btn_ctnr_class={"menu_btn_ctnr"} btn_text={"Reveal Scores"} btn_id={"reveal_scores"} on_click={item_scoring_help_toggle} area_lbl={"Reveal Scores"} divID={"ctx_coach_expand_scoring"} dropdown={true}/>
			<div id="coach_submenu_ctnr">
				{#each scoring_help_info[$page.itemNum] as info,i}
					<CoachMenuBtn btn_class={"sub_menu_btn"} btn_ctnr_class={"sub_menu_btn_ctnr"} btn_text={info.caption} btn_id={info.id} on_click={scoring_help} component={i} area_lbl={info.area_lbl} divID={info.context_id} />
				{/each}
			</div>
		{/if}
	{:else}
		<div class="dummy_div"/>
	{/if}
	<CoachMenuBtn btn_class={"btn"} btn_ctnr_class={"menu_btn_ctnr"} btn_text={"Review Responses"} btn_id={"review_responses"} on_click={review_responses} area_lbl={"Review Responses"} divID={"ctx_coach_review"} />
	<CoachMenuBtn btn_class={"btn"} btn_ctnr_class={"menu_btn_ctnr"} btn_text={"Replay Tutorial"} btn_id={"replay_tutorial"} on_click={replay_tutorial} area_lbl={"Replay Tutorial"} divID={"ctx_coach_tutorial"} />
</div>              
