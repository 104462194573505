<script>
import './SummaryTableCSS.svelte';
export let alignment="center";
export let bgcolor="white";
export let text1;
export let text2="";
export let text3="";
export let text4;
export let text5="";
export let text6;
</script>

<tr class="summary_table">
    <th bgcolor={bgcolor} width="60%" align="right" scope="row" style="font-weight: normal;"><b>{text1} {text2}</b></th>
    <td width="15%" align={alignment}>{text3} {text4}</td>
    <td width="25%" align={alignment}>{text5} {text6}</td>
</tr>
       

