<script>
import { onMount } from 'svelte'
import App from './App.svelte';
import { getReChatLog } from './ReChat.svelte'
import { scoring_page, resize_glfw, is_dev_environment } from './utils.svelte';
import SpeechTest from './SpeechTest.svelte';
import Certificate from './CertificateMenu.svelte';
import { set_save_state } from "./saved_states.js";
import {getBrowser} from "./os_browser_detect.js";
import {resetTutorialAnimation} from "./TutorialAnimationController.svelte";
import { ServerError, start_aicc_connection, set_random_id, set_scorm_user, get_items_completed, get_addtl_state_data, get_current_version, get_session, increment_session, checkHeartbeat, get_force_restart, make_blob, get_base_url, post_rechat_log} from './server_api.js';
import { page, desiredServerVersion, base_asset_path, game_mode, common_asset_path } from "./stores.js";
import { post_user_item_progress_viewed, post_user_item_progress_viewing, set_user_id, get_certificates, proccess_item_results } from './server_api.js';
import RecordFamiliarityDialogue from './components/RecordFamiliarityDialogue.svelte';
import RestartTrainingDialogue from './components/RestartTrainingDialogue.svelte';
import MessageBox from "./components/MessageBox.svelte";
import { reset_transcript, set_transcript } from './TranscriptStates.svelte';
export let user = "Test User";
if(!is_dev_environment())
    user = "Guest";
const SERVER_ERROR_MESSAGE = "SERVER NOT RESPONDING - TRY RELOADING PAGE";
let isLoggedIn = true;
let isRunningApp = false;
let isRunningSpeech = false;
let isViewingCertificate = false;
let menuMountFinished = false;
let promptTraining = "START TRAINING";
let trainingStarted = false;
let courseComplete = false;
let isChrome;
getBrowser().then((value)=>{isChrome=value.toLowerCase().includes("chrome")});
let forceEvaluation = false;
let serverVersionElement;
let reChatElement;
let newAppSession;
let channel = new MessageChannel();
let scormPort;
let scormMessageChannelConnected = false;
let scormUserInfoReceived = false;
let scormUser;
let scormId;
let scormSource;
let initialStart = true;
let restartTrainingDialogue = false;
let focusTrap;

let main_menu_btn_ids=["trainingStartBtn","trainingRestartBtn","testSpeechBtn","evaluationBtn","downloadCAPSBtn","downloadCAPSGuidanceBtn"];


//Custom Error Object for authentication
function AuthenticationError(errCode,message,fileName,lineNumber)
{
  var instance = new Error(message, fileName, lineNumber);
  instance.name = 'AuthenticationError';
  instance.errCode = errCode;
  Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
  if (Error.captureStackTrace) {
    Error.captureStackTrace(instance, AuthenticationError);
  }
  return instance;
}

AuthenticationError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: Error,
    enumerable: false,
    writable: true,
    configurable: true
  }
});

if (Object.setPrototypeOf){
  Object.setPrototypeOf(AuthenticationError, Error);
} else {
  AuthenticationError.__proto__ = Error;
}



// Handle messages received on port1
function onMessage(e) {
    if(e.data == "sync-ack")
    {
        scormMessageChannelConnected = true;
        scormPort.postMessage("ack")
    }
    else if(e.data.status == "success")
    {
        if(e.data.callback == "getUserInfo")
        {
            scormUser = e.data.user_name;
            scormId = e.data.user_id;
            scormSource = e.data.scorm_source;
            if(scormUser && scormId && scormSource)
                scormUserInfoReceived = true;
        }
    }
    else if(e.data.status == "error")
    {
        console.log(e.data.errorData);
    }
}
let recordFamiliarityResolve;
async function recordFamiliarity()
{
    let recordFamiliarityPromise = new Promise((resolve,reject)=>{
        recordFamiliarityResolve = resolve;
    });
    await recordFamiliarityPromise;
    recordFamiliarityResolve = null;
}

let startingNewSession = false;
async function newSession()
{
    if(!startingNewSession)
    {
        startingNewSession = true;
        resetTutorialAnimation();
        restartTrainingDialogue = false;
        await recordFamiliarity();
        await increment_session();
        await newAppSession();
        await toggleTraining();   
        startingNewSession = false; 
    }
}


function showEvaluation()
{        
    document.getElementById('em_border').style.display = 'none';
    document.getElementById('testAndLogs').style.display = 'none';
    document.getElementById('mainMenuToggle').style.display = 'none';
    isViewingCertificate = true;
    forceEvaluation = true;
    isRunningApp = !isRunningApp   
    trainingStarted = false;
    console.log("isViewingCertificate " + isViewingCertificate + ": forcingEvaluation " + forceEvaluation)

    checkCompletion();
    document.getElementById(main_menu_btn_ids[1]).disabled = false;
}
async function toggleTraining()
{    
    try
    {
        let force_restart = await get_force_restart();
        if(force_restart && !initialStart)
        {
            courseComplete = "force_restart";
            restartTrainingDialogue = true;
            return;
        }
        else if(force_restart)
        {
            initialStart =false;
            newSession();
            return;
        }
    }
    catch(err)
    {
        console.log(err)
        serverErrorOnLaunchHideButtons();
        return;
    }
    if(initialStart)
    {
        await recordFamiliarity();
        initialStart = false;
    }
    if(isRunningApp)
    {        
        document.getElementById('em_border').style.display = 'none';
        document.getElementById('testAndLogs').style.display = 'none';
        document.getElementById('mainMenuToggle').style.display = 'block';
        document.getElementById(main_menu_btn_ids[1]).disabled = false;
        document.getElementById(main_menu_btn_ids[0]).disabled = false;        
    }
    else
    {
        if(!await checkHeartbeat())
        {
            setServerErrorMessage(SERVER_ERROR_MESSAGE);
            return; 
        }
        document.getElementById('em_border').style.display = 'flex';                
        document.getElementById('mainMenuToggle').style.display = 'none';
    }
    isRunningApp = !isRunningApp    

    checkCompletion();

    if(!trainingStarted) {        
        document.getElementById("coach_menu_ctnr").style.display = "none";
        console.log("Resume Training");
        promptTraining = "RESUME TRAINING";
        trainingStarted = true;
        document.getElementById(main_menu_btn_ids[1]).disabled = false;         
    }
    reset_transcript();
    set_transcript($page.itemNum, get_session());
    resize_glfw();
}
async function checkCompletion()
{
    var certificates = await get_certificates();
    if (!certificates) {
        document.getElementById(main_menu_btn_ids[3]).disabled = true;
    } else {
        document.getElementById(main_menu_btn_ids[3]).disabled = false;
    }
    courseComplete = false;
    console.log("Session id: " + get_session());
    for(var i in certificates)
    {        
        for(var k in certificates[i])
        {
            console.log(certificates[i][k]);
            if(certificates[i][k].session == get_session())
            {
                if(!trainingStarted)
                {
                    courseComplete = true;
                    promptTraining ="COURSE COMPLETED";
                    document.getElementById(main_menu_btn_ids[0]).disabled = true;
                    document.getElementById(main_menu_btn_ids[1]).disabled = false;  
                }
            }            
        }
    }
}

function showRestartTrainingDialogue()
{
    restartTrainingDialogue = true;        
    document.getElementById(main_menu_btn_ids[1]).disabled = true;
    document.getElementById(main_menu_btn_ids[0]).disabled = true;
}

function cancelRestart()
{
    restartTrainingDialogue = false;
    document.getElementById(main_menu_btn_ids[1]).disabled = false;
    if(!courseComplete)
        document.getElementById(main_menu_btn_ids[0]).disabled = false;
}

async function toggleSpeech()
{
    if(!await checkHeartbeat())
    {
        setServerErrorMessage(SERVER_ERROR_MESSAGE);
    }
    isRunningSpeech = !isRunningSpeech;    
    if(isRunningSpeech)
    {
        document.getElementById('mainMenuToggle').style.display = 'none';
    }    
    else
    {
        document.getElementById('mainMenuToggle').style.display = 'block';
        document.getElementById('mainMenuButtons').style.display = 'block';
    }
}

async function toggleCertificate()
{
    if(!await checkHeartbeat())
    {
        setServerErrorMessage(SERVER_ERROR_MESSAGE);
        return; 
    }
    isViewingCertificate = !isViewingCertificate;
    if(isViewingCertificate)
    {
        document.getElementById('mainMenuToggle').style.display = 'none';
    }
    else
    {
        document.getElementById('mainMenuToggle').style.display = 'block';
        document.getElementById('mainMenuButtons').style.display = 'block';
    }
}

async function displayServerVersion() {
        var currServerVersion = await get_current_version();
        if($desiredServerVersion == currServerVersion)
        {
            serverVersionElement.innerHTML = "Server: " +  currServerVersion;
        }
        else
        {
            serverVersionElement.innerHTML = "Server: " +  currServerVersion + "*";
        }                
    }


function setServerErrorMessage(message)
{
    serverVersionElement.innerHTML = "<font color='red'>"+message+"</font>";
}

function hideMenuButtons()
{
    main_menu_btn_ids.forEach(id =>
    {
        let element = document.getElementById(id);
        if(element!=null)
            element.style.visibility = "hidden";
    });
}

function showMenuButtons()
{
    main_menu_btn_ids.forEach(id =>
    {
        let element = document.getElementById(id);
        if(element!=null)
            element.style.visibility = "visible";
    });
}

function reChatError()
{
    hideMenuButtons();
    let message = is_dev_environment()? "ReChat Data Error.  Consult Browser Console for deatails.":"Course Data Error.  Contact Support."
    reChatElement.innerHTML = message;
    reChatElement.style.display = "block";
}

function serverErrorOnLaunchHideButtons()
{
    hideMenuButtons();
    setServerErrorMessage(SERVER_ERROR_MESSAGE); 
}


async function mounted()
{
    while(!menuMountFinished)
    {
      await new Promise(resolve => setTimeout(resolve, 100));    
    }
    return true;
}

async function scormMessageConnectionMade()
{
    scormPort = channel.port1;
    scormPort.onmessage = onMessage;
    window.parent.postMessage("sync","*",[channel.port2]);
    if(scormMessageChannelConnected)
        return true;
    for(let i=0; i!=3; ++i)
    {
        await new Promise(resolve => setTimeout(resolve, 1000));  
        if(scormMessageChannelConnected)
            break;
    }
    if(!scormMessageChannelConnected)
        scormPort = null;
    return scormMessageChannelConnected;
}

async function scormUserInfo()
{
    scormPort.postMessage({func:"getUserInfo"});
    for(let i=0; i!=3; ++i)
    {
        await new Promise(resolve => setTimeout(resolve, 1000));  
        if(scormUserInfoReceived)
            break;
    }
    return scormUserInfoReceived;
}


onMount( async function() {    
    try
    {   
        hideMenuButtons();
        await displayServerVersion();
        try
        {
            var url = new URL(document.URL);
            var url_params = new URLSearchParams(url.search);
            var aicc_sid_param = url.search.match(/aicc_sid/i);
            var aicc_url_param = url.search.match(/aicc_url/i);
            var aicc = {
                aicc_sid:url_params.get(aicc_sid_param),
                aicc_url:url_params.get(aicc_url_param)
            };
            if(aicc.aicc_sid && aicc.aicc_url)
            {
                let user_info = await start_aicc_connection(aicc);
                user = user_info.user_name.first + " " + user_info.user_name.last;
            }
            else if(await scormMessageConnectionMade())
            {
                let userInfoReceived = await scormUserInfo();
                if(!userInfoReceived)
                {
                    throw "Could not recieve user info from Scorm";
                }
                let last_name;
                let first_name;
                // if there is a comma then its "Last, First" otherwise the format probably is "First Last"
                if(scormUser.search(',') != -1)
                {
                    let user_split = scormUser.split(",");
                    last_name = user_split[0].trim();
                    first_name = user_split.length > 1 ? user_split[1].trim(): "";
                }
                else
                {
                    let user_split = scormUser.split(" ");
                    first_name = user_split[0];
                    last_name = user_split.length > 1 ? user_split[1]: "";
                }
                user = first_name + " " + last_name;
                await set_scorm_user(first_name,last_name,scormId,scormSource);
            }
            else
            {
                console.log("SCORM not found; AICC query args not found, not connecting to AICC server");

                var uid_url_param = url.search.match(/uid/i);
                if(uid_url_param)
                {
                    await set_user_id(url_params.get(uid_url_param));
                }
                else
                {
                    try
                    {
                        await set_random_id();
                        console.log("Setting random id for development")
                    }
                    catch(err)
                    {
                        if(err instanceof ServerError)
                            if(err.errCode == 501)
                                throw "Auth Error";
                        throw err;
                    }
                }
            }
        }
        catch(err)
        {
            if(err instanceof ServerError)
                throw err;
            throw new AuthenticationError(1,err);
        }
        var saved_criteria = await get_items_completed();
        if(saved_criteria)
        {
            promptTraining = "RESUME TRAINING";
            document.getElementById(main_menu_btn_ids[1]).disabled = false; 
            Object.keys(saved_criteria).forEach(function(item){
                Object.keys(saved_criteria[item]).forEach(function(session){
                    set_save_state(item,session,saved_criteria[item][session]);
                });
            });
            initialStart = false;
        }
        var addtl_data = await get_addtl_state_data();
        if(addtl_data)
            Object.keys(addtl_data).forEach(function(item){
                    Object.keys(addtl_data[item]).forEach(function(session){
                    set_save_state(item,session,{addtl_data:addtl_data[item][session]});
                });
            });
        menuMountFinished = true;
        await checkCompletion();
        showMenuButtons();
    }
    catch(err)
    {
        if(err instanceof ServerError)
        {
            console.log("Server error when mounting Main Menu")
            serverErrorOnLaunchHideButtons();
        }
        else if(err instanceof AuthenticationError)
        {
            setServerErrorMessage("C5T could not reach host LMS"); 
            hideMenuButtons();
        }
        console.log(err);
    }
});

window.addEventListener('beforeunload',async function (e) {
    if(isRunningApp)
    {
        post_user_item_progress_viewed($page.itemNum);
        if(scoring_page() && $game_mode != "learn") //b/c scores can be changed at any time need to be sure to proccess results when someone leaves not just when they go to the next item
            proccess_item_results()
    }
    if(is_dev_environment())
    {
        let obj  = {};
        obj.log = getReChatLog();
        obj.comment = 'auto submit';
        let blob = make_blob(obj);
        let added = navigator.sendBeacon(get_base_url()+"/submit_log/rechat",blob);
        if(!added)
            await post_rechat_log(obj.log,"auto commit")
    }
    // the absence of a returnValue property on the event will guarantee the browser unload happens
    delete e['returnValue'];
});

function get_course_completed_ind()
{
    return courseComplete?1:0;
}

</script>

<style lang="less">
 *:focus {
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none; // removes default focus outline
 }

.mainMenu
{
    display: flex;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
}
.mainMenuBackground
{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: -1vw;
    height: 14%;
    width: 102%;
    z-index: -1;
}
#logoBackground
{
    width: 40%;
    flex-shrink: initial;
    position: absolute;
    top: 26vw;
    left: 12vw;
}

#waveBackground
{
    width: 100%;
    height: auto;
}
/* .ocean {
  width:100%;
  position:absolute;
  bottom:0;
  left:0;
}
.wave {
  background: url(wave.svg) repeat-x; 
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 20s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8
}
.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 15s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 15s ease -1.25s infinite;
  opacity: 0.5;
}
@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,5px,0);
  }
} */

.micSVG
{
    position: absolute;
    width: 4.5vw;
    height: 3.3vw;
    top: 16vw;
    left: 0.5vw;
    fill: white;
    padding: 0.25vw;
}
.micSVG:focus
{    
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none; // removes default focus outline
}
.startRestartBtn:hover > .micSVG
{
    fill: #7092a6;
}
.documentSVG
{
    position: absolute;
    width: 1.2vw;
    height: 1.1vw;
    left: 4vw;
    fill: white;
}
.evaluationSVG
{
    position: absolute;
    width: 1.2vw;
    height: 1.1vw;
    left: 4vw;
    fill: white;
}
.documentSVG:focus
{
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none; // removes default focus outline
}
.evaluationSVG:focus
{
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none; // removes default focus outline
}
.downloadBtn:hover > .evaluationSVG,
.downloadBtn:focus > .evaluationSVG
{
    fill: #7092a6;
}
.downloadBtn:hover > .documentSVG,
.downloadBtn:focus > .documentSVG
{
    fill: #7092a6;
}

.mainMenuParagraphs
{
    position: relative;
    width: 50%;
    left: 0;
    top: 2vw;
}

.userParagraph
{
    position: absolute;
    width: 100%;
    text-transform: uppercase;
    top: 7vw;
    left: 6vw;
    font-size: 1.5vw;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #7092a6;
    margin-block-end: 0;
}

.capsParagraph
{    
    position: absolute;
    top: 11vw;
    left: 6vw;
    font-size: 4.5vw;
    font-weight: 1000;
    letter-spacing: 1px;
    color: #023b5a;
    margin-block-start: 0;
}

.chromeSupportParagraph
{
    position: absolute;
    width: 100%;
    text-transform: uppercase;
    top: 20vw;
    left: 6vw;
    font-size: 1.5vw;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: red;
    margin-block-end: 0;
}

#versionParagraph,#serverVersionParagraph,#reChatErrorParagraph
{
    position: absolute;
    display: none;
    top: 19.9vw;
    left: 33vw;
    font-size: 1vw;
    font-weight: 1000;
    letter-spacing: 1px;
    color: #023b5a;
    margin-block-start: 0;
}
#serverVersionParagraph {top: 17.9vw;}
#reChatErrorParagraph{top: 21.9vw;color: red}

.mainMenuOptions
{
    position: absolute;
    width: 30%;
    left: 58vw;
    top: 5vw;
}

#mainMenuButtons
{
    display: none;
}

.startRestartBtn
{
    width: 30vw;
    height: 6vw;
    display: block;
    margin-block-end: 1.5vw;
    background: #023b5a;
    color: white;
    letter-spacing: 0.5px;
    font-family: Lato;
    font-size: 2vw;
    font-weight: 900;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

#trainingRestartBtn:disabled,
#trainingStartBtn:disabled,
#downloadCAPSBtn:disabled,
#downloadCAPSGuidanceBtn:disabled,
#evaluationBtn:disabled,
.menuOptionBtns:disabled
{
    background: #A1A1A1;
    color: #363636;
    cursor: default;
}

#downloadCAPSBtn:disabled > .documentSVG,
#downloadCAPSGuidanceBtn:disabled .documentSVG,
#evaluationBtn:disabled > .evaluationSVG
{
    fill: #363636;
}


#trainingStartBtn:hover:enabled,
#trainingStartBtn:focus, 
#trainingRestartBtn:hover:enabled,
#trainingRestartBtn:focus,
#testSpeechBtn:hover:enabled,
#testSpeechBtn:focus,
.menuOptionBtns:hover:enabled,
.menuOptionBtns:focus {
    background: white;
    border: 2px solid #7092a6;
    color: #7092a6;
}

.menuOptionBtns
{    
    cursor: pointer;
    width: 23vw;
    height: 2vw;
    display: block;
    margin-block-end: 1vw;
    margin-inline-start: 3.5vw;
    border-radius: 8px;
    bottom: 0vw;
    background: #023b5a;
    color: white;
    font-family: 'Lato', sans-serif;
    text-align: center;
    letter-spacing: 1px;
    right: 2vw;
    font-size: 1vw;
    padding: .35vw;
}
a.nounderline 
{
    text-decoration: none;
}

.imgScale
{
    width: 30vw;
    margin: 15% 35%;
    position: absolute;
    border: 1px solid #4c89ab;
    border-radius: 5px;
    box-shadow: 0 0 10px #023b5a;
    display: none;
}
#errorImg
{
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: none;
}
#disabledImg
{
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: none;
}

#testSpeechBtn
{
    font-size: 1.7vw;
}

@media(max-width:800px)
{
    #versionParagraph
    {
        top: 19.8vw;
        left: 34vw;
    }
}
@media (min-width:2000px)
  {
    @media (max-height: 700px) 
    {      
        .mainMenuBackground
        {
            height: 10%;
        }
        #logoBackground
        {
            top: 20vw;
            left: 9vw;
            width: 30%;
        }

        .mainMenuParagraphs
        {
            top: -5vw;
        }
        .capsParagraph
        {
            font-size: 3vw;   
        }
        #versionParagraph
        {
            top: 16.6vw;
        }
    }
  }
  @media (min-width:2500px)
  {
      @media (max-height: 700px) 
    {   
        .mainMenuBackground
        {
            height: 12%;
        }
        #logoBackground
        {
            top: 19vw;
            left: 9vw;
        }
        .capsParagraph
        {
            font-size: 3vw;   
        }
        .mainMenuOptions
        {
            top: 1vw;
        }
    }
  }

</style>
<div class="mainMenu" id="mainMenuToggle">                      
    <div class="imgScale" id="imgScaleID">
        <img src={$base_asset_path+"ErrMsg_WebGL2NoSupport.png"} alt="WebGL2 Disabled error message" id="errorImg">
        <img src={$base_asset_path+"ErrMsg_WebGL2Disabled.png"} alt="WebGL2 Disabled error message" id="disabledImg">
    </div>
    <div class="mainMenuParagraphs" id="paragraphsID">
        <p class="userParagraph">Welcome, {user}!</p>
        <p class="capsParagraph"> CAPS-5 TRAINING SIMULATOR</p>
        <p id="versionParagraph"></p>
        <p bind:this={serverVersionElement} id="serverVersionParagraph"></p>
        <p bind:this={reChatElement} id="reChatErrorParagraph"></p>
        {#if !isChrome}
        <p class="chromeSupportParagraph">Only the Chrome browser is supported</p>
        {/if}
        <img src={$base_asset_path+"ptsd-logo.jpg"} alt="National Center for PTSD, Post Traumatic Stress Disorder" id="logoBackground">
    </div>
    <div class="mainMenuBackground">                
        <img src={$base_asset_path+"main-wave.jpg"} alt="" id="waveBackground">
    </div>
    {#if restartTrainingDialogue}
        <RestartTrainingDialogue course_complete={courseComplete} confirm_restart={newSession} cancel_restart={cancelRestart} main_menu_btn_ids={main_menu_btn_ids} addtnl_class_to_handle="nounderline" btn_to_blur_id={main_menu_btn_ids[1]}/>
    {/if}
    {#if recordFamiliarityResolve}
        <RecordFamiliarityDialogue bind:resolvePromise={recordFamiliarityResolve} main_menu_btn_ids={main_menu_btn_ids} addtnl_class_to_handle="nounderline" btn_to_blur_id={main_menu_btn_ids[0]} forNewSession={startingNewSession}/>
    {/if}
    <div class="mainMenuOptions" id="mainMenuButtons">                                
        <button id={main_menu_btn_ids[0]} class="startRestartBtn" on:click={toggleTraining} data-cy={main_menu_btn_ids[0]} >{promptTraining}</button>
        <button id={main_menu_btn_ids[1]} class="startRestartBtn" on:click={showRestartTrainingDialogue} disabled  data-cy={main_menu_btn_ids[1]} >RESTART TRAINING</button>
        <button id={main_menu_btn_ids[2]} class="startRestartBtn" on:click={toggleSpeech} data-cy={main_menu_btn_ids[2]} >
            <svg class="micSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 165.93 225.19"><g id="Layer_2" data-name="Layer 2"><g id="Layer_3" data-name="Layer 3"><path d="M83,142.22a35.4,35.4,0,0,0,35.44-35.55l.12-71.11a35.56,35.56,0,1,0-71.11,0v71.11A35.5,35.5,0,0,0,83,142.22Zm62.82-35.55c0,35.55-30.11,60.44-62.82,60.44s-62.81-24.89-62.81-60.44H0c0,40.41,32.24,73.83,71.11,79.64v38.88h23.7V186.31c38.88-5.69,71.12-39.11,71.12-79.64Z"/></g></g></svg>
            VERIFY SPEECH<br />RECOGNITION SETUP
        </button>
        <!-- <button class="menuOptionBtns">Set Options</button> -->
        
        <a class="nounderline" href ={$common_asset_path + "CAPS-5_Guidance.pdf"} download="CAPS-5_Guidance.pdf" tabindex="-1">
            <button id={main_menu_btn_ids[5]} class="menuOptionBtns downloadBtn">
                <svg class="documentSVG" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.75 69.33">
                    <g id="a6c85ed6-bb3b-4ef5-a7bb-0f68d3d90891" data-name="Layer 2">
                      <g id="b5d23604-5e67-4f11-8bc2-53eeff81f3d4" data-name="Icon_Document">
                        <path class="bc2a8cec-8e9c-4555-a407-32b13e7c9cdb" d="M48.57,0H8.17A8.17,8.17,0,0,0,0,8.17v53a8.17,8.17,0,0,0,8.17,8.17h40.4a8.17,8.17,0,0,0,8.18-8.17v-53A8.17,8.17,0,0,0,48.57,0ZM8.12,10.45h18.4v6.36H8.12Zm40.74,49.4H7.58v-7H48.86Zm0-13.81H7.58V39H48.86Zm0-13.32H7.58v-7H48.86Z"/>
                      </g>
                    </g>
                  </svg>
                DOWNLOAD GUIDANCE PDF
            </button>
        </a>             
        <a class="nounderline" href ={$base_asset_path +"CAPS-5.pdf"} download="CAPS-5.pdf" tabindex="-1">
            <button id={main_menu_btn_ids[4]} class="menuOptionBtns downloadBtn">
                <svg class="documentSVG" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.75 69.33">
                    <g id="a6c85ed6-bb3b-4ef5-a7bb-0f68d3d90891" data-name="Layer 2">
                      <g id="b5d23604-5e67-4f11-8bc2-53eeff81f3d4" data-name="Icon_Document">
                        <path class="bc2a8cec-8e9c-4555-a407-32b13e7c9cdb" d="M48.57,0H8.17A8.17,8.17,0,0,0,0,8.17v53a8.17,8.17,0,0,0,8.17,8.17h40.4a8.17,8.17,0,0,0,8.18-8.17v-53A8.17,8.17,0,0,0,48.57,0ZM8.12,10.45h18.4v6.36H8.12Zm40.74,49.4H7.58v-7H48.86Zm0-13.81H7.58V39H48.86Zm0-13.32H7.58v-7H48.86Z"/>
                      </g>
                    </g>
                  </svg>
                DOWNLOAD THE CAPS-5 PDF
            </button>
        </a>  
        <button id={main_menu_btn_ids[3]} class="menuOptionBtns downloadBtn" on:click={toggleCertificate} data-cy={main_menu_btn_ids[3]}>
            <svg class="evaluationSVG" xmlns="http://www.w3.org/2000/svg" id="uuid-2ad5ab95-c1ef-4359-8b7d-f7812d5c8b32" viewBox="0 0 23.86 29.92">
                <g id="uuid-2d45ecfc-0544-4c3d-8ddc-b89d832d4bf0">
                  <g>
                    <path class="uuid-46ccf0e7-577f-4d59-9b9b-98103ef6af23" d="M11.93,16.59c-2.36,0-4.28,1.92-4.28,4.28s1.92,4.28,4.28,4.28,4.28-1.92,4.28-4.28-1.92-4.28-4.28-4.28Z"/>
                    <path class="uuid-46ccf0e7-577f-4d59-9b9b-98103ef6af23" d="M11.93,11.83c-4.99,0-9.04,4.05-9.04,9.04s4.05,9.04,9.04,9.04,9.04-4.05,9.04-9.04-4.05-9.04-9.04-9.04Zm0,15.47c-3.57,0-6.46-2.89-6.46-6.46s2.89-6.46,6.46-6.46,6.46,2.89,6.46,6.46-2.89,6.46-6.46,6.46Z"/>
                    <path class="uuid-46ccf0e7-577f-4d59-9b9b-98103ef6af23" d="M5.47,12.01V0H0V8.13l4.52,4.65c.3-.28,.62-.53,.95-.77Z"/>
                    <path class="uuid-46ccf0e7-577f-4d59-9b9b-98103ef6af23" d="M15.58,10.52V0h-7.3V10.52c1.14-.41,2.37-.64,3.65-.64s2.51,.23,3.65,.64Z"/>
                    <path class="uuid-46ccf0e7-577f-4d59-9b9b-98103ef6af23" d="M18.39,0V12.01c.33,.24,.65,.5,.95,.77l4.52-4.65V0h-5.47Z"/>
                  </g>
                </g>
            </svg>
            REVIEW EVALUATION
        </button>
         
    </div>
</div>
<MessageBox bind:focusTrap={focusTrap}/>
<App on:backToStart={toggleTraining} bind:isRunningApp={isRunningApp} menuMounted={mounted} on:showEval={showEvaluation} bind:newSession={newAppSession} bind:scormPort={scormPort} bind:focusTrap={focusTrap} reChatError={reChatError}/>
{#if isLoggedIn}
    {#if isRunningSpeech}
        <SpeechTest on:speechTest={toggleSpeech} bind:focusTrap/>
    {:else if isViewingCertificate}
        <Certificate bind:user={user} on:certificate={toggleCertificate} bind:showCertificate={forceEvaluation} on:completeCheck={checkCompletion}/>
    {/if}

{:else}
<div class="mainMenu">
</div>
{/if}
