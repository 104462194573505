<script context="module">
  let mic_permanently_disabled = false;
  let firstInstance = true;
</script>
<script>
    import { onMount } from "svelte";
    import focusTrapModule from "focus-trap";
    import { input_disabled_dialogue, input_disabled_all } from './stores.js';
    import {logReChat} from "./ReChat.svelte"
    import {post_event_log} from "./server_api.js";
    export let continuous = false;
    export let transcript = '';
    export let focusTrap;
    export let listening = false;
    export let micBtn;
    let recognition;
    const network_error_text="WARNING: Google speech recognition services have failed, probably due to a network issue.  Voice input will be disabled for the rest of this session.  Please use your keyboard to communicate with the patient.";
    const ping_alert="WARNING: Slow network detected.  Voice input could be compromised.  We advise that you use your keyboard to communicate with the patient.";

  $:{
    if(continuous)
      listening=true;
  }
    function display_network_error_message_and_disable_mic()
    {
      open_messagebox(network_error_text);
      mic_permanently_disabled = true;
      if(micBtn)
        micBtn.disabled = true;
    }
    function display_ping_message()
    {
      open_messagebox(ping_alert);
    }
    function open_messagebox(text)
    {
      let e = document.getElementById("modal-wrapper");
      e.style.display = "block";
      e = document.getElementById("recordParagraph");
      e.textContent = text;
      e = document.getElementById("MessageBoxOK");
      focusTrap = focusTrapModule('#modal-wrapper', {
      initialFocus: e,
      escapeDeactivates: false
      });
      focusTrap.activate();
    }
    function onClickHandler()
    {
      if(!continuous)
      {
        if(!listening)
        {
          listening=true;
          recognition.start();
        }
      }            
    }
    $:{
      if(micBtn)
      {
        if(mic_permanently_disabled)
        {
          micBtn.disabled = true;
        }
        else
        {
          micBtn.disabled = $input_disabled_dialogue || $input_disabled_all;
          if(micBtn.disabled == true)
            listening = false;
        }
      }
    }

    export function abortFurtherRecognition()
    {
        recognition.abort();
        transcript = '';
        console.log("Recognition aborted");
    }

    export function stopFurtherRecognition()
    {
        recognition.stop();
        transcript = '';
        console.log("Recognition stopped");
    }
    function collectEventResults(event){
        let finalTranscript = '';
        let interimTranscript = '';
        for (let i = event.resultIndex, len = event.results.length; i < len; i++) {
            let incomingTranscript = event.results[i][0].transcript;
            if (event.results[i].isFinal) {
                finalTranscript += incomingTranscript;
                console.log("Final Transcript: " + finalTranscript);
            }
            else {
                interimTranscript += incomingTranscript;
            }
        }
        if(interimTranscript.length)
        {
            transcript = interimTranscript + "...";
        }
        else if (finalTranscript)
        {
            transcript = finalTranscript;
            listening = false || continuous;
        }
    }
    function errorMessage(error,message)
    {
      let errorMessage = "\n### **ERROR** ###  \n";
      errorMessage += "**Type: Recognition Error**  \n"
      errorMessage += "**Error:** " + error + "  \n";
      errorMessage += "**Message:** " + message + "  \n";
      errorMessage += "*****************  ";    
      return errorMessage;  
    }
    onMount( function() {
        window.SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
        recognition = new window.SpeechRecognition();
        recognition.interimResults = true;
        recognition.maxAlternatives = 1;
        recognition.continuous = true;
        $input_disabled_dialogue = false;
        recognition.onend = () => {
          listening=false;
        }
        recognition.onerror = (error) => {
          console.log(error);
          if(error.error == "network")
            display_network_error_message_and_disable_mic();
          logReChat(errorMessage(error.error,error.message));

          let event_info = {error_type:error.error,error_message:error.message};
          post_event_log("RECOGNITION_ERROR",event_info);
        }
        if(firstInstance)
        {
          firstInstance = false;
          recognition.start();
          setTimeout(function(){
            recognition.abort();
            recognition.onresult = (event) => {collectEventResults(event);};
          }, 550);
        }
        else
        {
          recognition.onresult = (event) => {collectEventResults(event);};
        }
        // 0.5mb is where reportedly the voice recognition starts to suffer(slow 3g)
        // if we want to test for ping use navigator.connection.rtt
        if(navigator.connection.downlink<0.5)
          display_ping_message();
    });
</script>

<slot name="micBtn" micBtnClick={onClickHandler}/>





