<script>
import { show_TORC_links, base_asset_path } from './stores.js';
export let character_dir;
export let card_name;
export let characterSpanClass;
export let characterImgClass;
function openTorc()
{
	var link = "https://torc.revergestudios.com/projects/c5t/characters/"+character_dir+"/cards/"+card_name+"/editor";
	window.open(link);
}
</script>

<style lang="less">
	.torc_link_div, .torc_link_div_patient
	{
		height: 1.3vw;
		width: 1.3vw;
		border-radius: 2px;
		cursor: pointer;		
	}
	.torc_link_icon, .torc_link_icon_patient
	{
		height: 1.3vw;
		width: 1.3vw;
		vertical-align:bottom;
	}
	.torc_link_div:focus 
	{
		box-shadow: inset 0 0 0 0px #fff, 0 0 0 2px white;
		outline: none; // removes default focus outline
	}
	.torc_link_div:hover { box-shadow: 0 0 0 2px white; }
	.torc_link_div_patient:focus
	{
		box-shadow: inset 1px 1px 1px 1px #fff, 0.5px 1px 1px 2px white;
		outline: none;
	}
	.torc_link_div_patient:hover
	{
		box-shadow: inset 1px 1px 1px 1px #fff, 0.5px 1px 1px 2px white;
	}
	.torc_link_icon_patient
	{		
		margin-left: 4px;
	}
</style>

{#if $show_TORC_links===true}
<span class ={characterSpanClass} on:click={openTorc} tabindex="0">
	<img class ={characterImgClass} src={$base_asset_path + "TORCfavicon.svg"} alt="Torc Link">
</span>
{/if}
