<script>
import ContextController from './ContextController.svelte';
import TorcLink from './TorcLink.svelte';
import './btn_class.svelte';
export let dialogue; 
export let skipTutorial; 
export let continueTutorial;
export let card_name;
export let coachDir;
</script>
<script context="module">
	export function show_coach_buttons()
	{
        document.getElementById("coachButtonContainer").style.display = "flex";
        document.getElementById("continueButtonDiv").style.display = "flex";
		document.getElementById("skipButtonDiv").style.display = "flex";
	}
	export function hide_coach_text()
	{
        var coach_text = document.getElementById("coachTextArea");
        coach_text.style.display = "none";
	}
	export function show_coach_text()
	{
		var coach_text = document.getElementById("coachTextArea");
	    coach_text.style.display = "inline-block";
	}
	export function hide_coach_buttons()
	{
		var coach_skip_btn = document.getElementById("skipButtonDiv");
		coach_skip_btn.style.display = "none";
		var coach_continue_btn = document.getElementById("continueButtonDiv");
		coach_continue_btn.style.display = "none";
		var coach_menu = document.getElementById("coach_menu_ctnr");
		coach_menu.style.display = "none";

	}
</script>
<style lang="less">
	#coachTextArea
	{
		width: 95%;
		display: none;
		top: 0vw;
		left: 0vw;
		color: rgba(255, 255, 157);
		background-color: rgba(0, 0, 0, 0.6);
		text-align: left;
		padding: 2%;
		padding-right: 3%;
		margin: 0 0;
		border-radius: 10px;
		position: relative;
		z-index: 2;
	}
	#coachText
	{
		margin-block-start: 0;
		margin-block-end: 0;
		font-weight: 600;
		font-size: 1.2vw;
	}
	#fakeImg
	{
		height: 7vw;
		width: 7vw;
		float: right;
		border-radius: 5px;
		padding: 0vw;
		margin: -2% -3% 2% 2%;
		z-index: 1;
		display: block;
	}
	#coachButtonContainer
	{
		margin: 0 auto;
		width: 100%;
		display: none;
		justify-content: center;
		align-items: center;
		padding: 2%;
	}
	.coachButtonDiv
	{
		display: flex;
		position: relative;
		width: 12vw;
		height: 2.5vw;
		margin: 0px 5% 0% 5%;
		font-size: .85vw;
		font-weight: 900;
	}
</style>
<div id="coachTextArea">
	<div id="fakeImg"></div>   
	<p id="coachText" data-cy="coachText">
		<TorcLink character_dir={coachDir} card_name={card_name} characterImgClass={"torc_link_icon"} characterSpanClass={"torc_link_div"}/> {dialogue}
	</p> 
	<div id="coachButtonContainer">
		<div id="skipButtonDiv" class="coachButtonDiv">
			<ContextController divID={"ctx_tutorial_skip"}/>
			<button class="btn" id="skipButton" on:click={()=>{skipTutorial(card_name)}} data-cy='skipButton'>Skip</button>
		</div>
		<div id="continueButtonDiv" class="coachButtonDiv">
			<ContextController divID={"ctx_tutorial_cont"}/>
			<button class="btn" id="continueButton" on:click={()=>{continueTutorial(card_name)}}>Continue</button>
		</div>
	</div>              
</div>
