<script>
  import { onMount } from 'svelte'
  import { post_familiarity,get_session } from '../server_api.js';
  import { activate_tabbing,deactivate_tabbing } from '../utils.svelte';
  export let resolvePromise;
  export let main_menu_btn_ids;
  export let addtnl_class_to_handle;
  export let btn_to_blur_id;
  export let forNewSession=false;
  let btn_to_focus_id = "notFamiliarBtn";
  let addtnl_class_elements=[];
  async function submitFamiliarity(score)
  {
    let familiaritySession = get_session();
    if(forNewSession)
    {
      familiaritySession += 1;
    }
    await post_familiarity(familiaritySession,score)//familiarity for the session that is about to start
    resolvePromise();
    activate_tabbing(main_menu_btn_ids);
    for (var i=0, len=addtnl_class_elements.length; i<len; ++i)
      if(addtnl_class_elements[i]["element"]!=null)
        addtnl_class_elements[i]["element"].setAttribute("href",addtnl_class_elements[i]["href"]);
  }
  onMount( async function()
  {
    let element = document.getElementById(btn_to_blur_id);
      if(element!=null)
        element.blur();
    element = document.getElementById(btn_to_focus_id);
      if(element!=null)
        element.focus();
    deactivate_tabbing(main_menu_btn_ids);
    let elements = document.getElementsByClassName(addtnl_class_to_handle);
    for (var i=0, len=elements.length; i<len; ++i)
    {
      elements[i].style.cursor = "unset";
      addtnl_class_elements.push({"element":elements[i],"href":elements[i].getAttribute("href")});
      elements[i].removeAttribute("href");
    }
  });
</script>


<style>
  #recordDiv
  {
    display: block;
    background: white;
    z-index: 1;
    border: 3px solid #7092a6;
    border-radius: 8px;
    position: fixed;
    width: 25vw;
    height: 14vw;
    top: 16vw;
    left: 40vw;
  }

  #recordParagraph
  {
    position: relative;
    font-size: 1vw;
    color: #7092a6;
    font-weight: bold;
    text-align: center;
  }
  #btnDiv
  {
    position: relative;
    padding: 0.15vw 1.5vw .5vw 1.5vw;
  }
  .recordConfirm
  {
    width: 100%;
    height: 2vw;
    display: block;
    background: #7092a6;
    background-image: linear-gradient(0deg, #557C93 0%, #7092a6 30%);
    color: white;
    font-family: Lato;
    font-size: 1vw;
    font-weight: 700;
    letter-spacing: 1px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: .5vw;
    text-align: center;
  }
  .recordConfirm:focus,
  .recordConfirm:hover{
    background: white;
    border: 2px solid #7092a6;
    color: #7092a6;
  }
  .recordConfirm:focus
  {
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none;
  }
</style>
<div id="recordDiv">
  <p id="recordParagraph">To begin, please rate your current familiarity with the CAPS-5:</p>
  <div id="btnDiv">
    <button class="recordConfirm" id={btn_to_focus_id} on:click={()=>{submitFamiliarity(1)}} aria-label="Not very familiar" data-cy="notFamiliarButton">Not very familiar</button>
    <button class="recordConfirm" on:click={()=>{submitFamiliarity(2)}} aria-label="Moderately familiar">Moderately familiar</button>
    <button class="recordConfirm" on:click={()=>{submitFamiliarity(3)}} aria-label="Very familiar">Very familiar</button>
  </div>
</div>