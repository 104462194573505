<script context="module">
  import {logReChat} from "./ReChat.svelte"
  
  export function addCommentToLog(event)
  {
    let comment = "### *Comments* ###  \n" + event.detail.comment;
    logReChat(comment);
  }
</script>

<script>
  import { onMount, tick } from 'svelte'
  import { ServerError, post_certificate, delete_rechat_state, get_rechat_sequence, proccess_item_results, get_user_item_progress, post_rechat_log, get_session, post_eval_totals, set_current_page, get_current_page} from './server_api.js'
  import { verbatim_cards, paraphrased_cards, highest_required_indices, initial_cards, irrelevant_cards, off_script_cards} from "./rechat_card_info.js"
  import { get_criteria_for_item } from './item_translation_util.js'
  import { createEventDispatcher } from 'svelte';  
  import ConversationManager from "./ConversationManager/conversationManager.js";
  import { debug_mode, page, environment, positive_feedback_pool, CAPS5_cert_id, input_disabled_all, input_disabled_dialogue, input_disabled_tutorial, coach_asset_path, patient_asset_path, base_asset_path, game_mode, incorrectScoreValues, strict_scoring } from './stores.js';
  import { matchDistanceForUI, matchDistanceForCoach, matchDistanceForPatient, matchDistanceForTutorial } from './match_distance_store.js';
  import CardSelection from './CardSelection.svelte';
  import ReChat, { setRechatTextArea,getTutorialCard } from './ReChat.svelte';
  import Menu from './ContentMenu.svelte';
  import AudioAnimationManager from './AudioAnimationManager.svelte';
  import Navigation, {fwd_arrow_animation} from './ContentNavigation.svelte';
  import CriterionA from './CriterionA/CriterionA.svelte';
  import CriterionB from './CriterionB.svelte';
  import IntroPages, {setConversationManager as introPagesSetConversationManager} from './IntroPage.svelte';
  import InputFeedComponent from './InputFeedComponent.svelte';
  import { get_programmable_item_name_from_item_num } from './item_translation_util.js'
  import Summary from './Summary/Summary.svelte';
  import evaluation_learn from "./evaluation.json";
  import evaluation_validate from "./evaluation_validate.json";
  import evaluation_avm from "./evaluation_avm.json";
  import Notepad, { update_history, clear_notepad_item_entry, set_notepad_item_entry, enable_notepad_text_entry, save_notepad_item_entry } from "./Notepad.svelte";
  import { close_notepad } from './InputFeedNotepad.svelte';
  import CoachButton, { disable_enable_coach_button, show_coach_alert, hide_coach_alert} from './CoachButton.svelte';
  import CoachMenu, {close_coach_menus,is_coach_menu_open,setConversationManager as coachMenuSetConversationManager} from './CoachMenu.svelte';
  import CoachText, { show_coach_buttons, hide_coach_buttons, show_coach_text, hide_coach_text } from './CoachText.svelte';
  import TranscriptReview, {close_transcript} from './TranscriptReview.svelte';
  import { scroll_transcript } from './Transcript.svelte';
  import { add_transcript_dialogue } from './TranscriptAddDialogue.svelte';
  import { save_transcript, set_transcript, reset_transcript } from './TranscriptStates.svelte';
  import { toggleTutorialAnimation, resetTutorialAnimation } from './TutorialAnimationController.svelte';
  import PerformanceMetrics from './PerformanceMetrics.svelte';
  import Tooltip from './Tooltip.svelte';
  import {setConversationManager as contextControllerSetConversationManager} from "./ContextController.svelte";
  import TorcLink from './TorcLink.svelte';
  import { scoring_page, resize_glfw } from './utils.svelte';
  import { setIntroTutorialStarted } from "./IntroPage.svelte";
  import MainSpeechInputArea from "./components/SpeechInputAreas/MainSpeechInputArea.svelte";

  let patientDir;
  let coachDir;
  let patientAnimationFilePath;
  let patientCardPath;
  let coachCardPath;
  let uiCardPath;
  let tutorialCardPath;
  const dispatch = createEventDispatcher();

  let displayCrit = true;
  let displayHistory = false;
  let showTestCard;
  let showActiveCardsTable;
  let showLogs;
  let menuMountFinished = false;
  export let isRunningApp;
  export let menuMounted;
  let results;
  let activeCardsPatient;
  let activeCardsCoach;
  let activeCardsUI;
  let activeCardsTutorial;
  let activeCardsTable;
  let emstext;
  let lastInput;
  let currentInput; 
  export let scormPort;
  let is_scorm_session = false;
  let patientRechatFinishedMounting;
  let coachRechatFinishedMounting;
  let uiRechatFinishedMounting=false;
  let allConversationsAdded=false;
  let patient_event;
  let click_coach;
  let openCoach;
  let closeCoach;
  let evaluation;
  let reChatTextArea;
  let cancel_speech;
  export let focusTrap;
  export let reChatError;

  let conversationManager = new ConversationManager();
  coachMenuSetConversationManager(conversationManager);
  introPagesSetConversationManager(conversationManager);
  contextControllerSetConversationManager(conversationManager);

  $:{
    if(reChatTextArea)
      setRechatTextArea(reChatTextArea);
  }

  if($game_mode == "learn")
  {
    evaluation=evaluation_learn;
    openCoach = "ctx_coach_open";
    closeCoach = "ctx_coach_close";
    patient_event = "IED attack";
  }
  else if($game_mode == "validate")
  {
    evaluation=evaluation_validate;
    openCoach = "ctx_coach_open_validate";
    closeCoach = "ctx_coach_close_validate";

    patient_event = "sexual assault";
  }
  else
  {
    evaluation=evaluation_avm;
    openCoach = "ctx_coach_open_validate";
    closeCoach = "ctx_coach_close_validate";

    patient_event = "the fire";
  }
  let lastViewedPage;
  function updateValidateRechatToLatestItem()
  {
    if($game_mode != "learn" && lastViewedPage!=null && lastViewedPage != $page.itemNum)
      {
        let nextItem = get_programmable_item_name_from_item_num( $page.itemNum);
        let lastIten = get_programmable_item_name_from_item_num(lastViewedPage);
        if(nextItem === "A")
          nextItem = "CRITERION_"+nextItem;
        if(lastIten === "A")
          lastIten = "CRITERION_"+lastIten;
        if(lastIten)
          incomingMatchInput("deactivate_all_"+lastIten,false);
        if(nextItem)
          incomingMatchInput("activate_all_"+nextItem,false);
      }    
  }
  $: {
    if(patientRechatFinishedMounting && coachRechatFinishedMounting && uiRechatFinishedMounting)
    {
      updateValidateRechatToLatestItem()
      post_eval_totals(verbatim_cards,paraphrased_cards,highest_required_indices,initial_cards,irrelevant_cards,off_script_cards);
    }
  }
  
  $: {
    if(emstext)
    {
      // doing this instead of using svelte's {if} on the element in order to save the text since i don't think its stored elsewhere
      emstext.style.display = showLogs ? 'block' : 'none';
    }
  }

  function morphIntoTableReadyData(activeCards)
  {
    let finalGroups = [];
    if(activeCards && activeCards.length)
    {
      for(let i = 0; i!=activeCards.length; ++i)
      {
        let foundGroupIndex = -1;
        for(let k = 0; k!=finalGroups.length; ++k)
        {
          //no really how this should be done but this is a rarely used debug feature, so unless tom asks for something better it is what it is
          if(JSON.stringify(finalGroups[k][0].groups) === JSON.stringify(activeCards[i].groups))
          {
            foundGroupIndex = k;
          }
        }
        if(foundGroupIndex == -1)
        {
          finalGroups.push([activeCards[i]]);
        }
        else
        {
          finalGroups[foundGroupIndex].push(activeCards[i]);
        }
      }
      //sort the sub groups by card name
      for(let i = 0; i!=finalGroups.length; ++i)
      {
        finalGroups[i].sort((a,b)=>{
          return a.name.toString().localeCompare(b.name)
        });
      }
      
      //sort the groups
      finalGroups.sort((a,b)=>{
        if(!a[0].groups)
          return 0;
        return JSON.stringify(a[0].groups).localeCompare(JSON.stringify(b[0].groups))
      });
      
    }
    return finalGroups;
  }

  function addToTable(cards,col)
  {
    let currentTableRowIndex = 1;
    for(let i = 0; i!=cards.length; ++i)
    {
      for(let j = 0; j!=cards[i].length; ++j)
      {
        let row = activeCardsTable.rows[currentTableRowIndex];
        if(!row)
        {
          row = activeCardsTable.insertRow(-1);
        }
        let cell;
        for(let k = 0; k!=col+1; ++k)
        {
          if(!row.cells[k])
          {
            cell = row.insertCell(k);
          }
        }
        cell.innerHTML = "(" +cards[i][j].groups + ") " + cards[i][j].name;
        currentTableRowIndex++;
      }
    }  
  }

  $: {
    if(activeCardsTable && showActiveCardsTable && $environment=="development")
    {
      let finalPatientGroups = morphIntoTableReadyData(activeCardsPatient);
      let finalCoachGroups = morphIntoTableReadyData(activeCardsCoach);
      let finalUIGroups = morphIntoTableReadyData(activeCardsUI);
      let finalTutorialGroups = morphIntoTableReadyData(activeCardsTutorial);
      while(activeCardsTable.rows.length > 0) {
        activeCardsTable.deleteRow(0);
      }
      let row = activeCardsTable.insertRow(0);
      row.insertCell(0).innerHTML = "Patient";
      row.insertCell(1).innerHTML = "Coach";
      row.insertCell(2).innerHTML = "UI";
      row.insertCell(3).innerHTML = "Tutorial";
      addToTable(finalPatientGroups,0);
      addToTable(finalCoachGroups,1);
      addToTable(finalUIGroups,2);
      addToTable(finalTutorialGroups,3);
    }
  }
  
  window.addEventListener('keydown', function(event){
    event.stopImmediatePropagation();
}, true);

window.addEventListener('keyup', function(event){
    event.stopImmediatePropagation();
}, true);


  let currentPatientCardName='';
  let currentCoachCardName='';
  let patientDialogue;
  let coachDialogue;
  let uiDialogue;
  let displayCoachDialogue = '';
  let displayDialogue = '';
  let reChatExecuteCard;
  let loadReChatPatient;
  let loadReChatCoach;
  let loadReChatUI;
  let loadReChatTutorial;
  async function loadReChat(noSaveData)
  {
    conversationManager.setAllConversationsEnded();
    await loadReChatPatient(noSaveData);
    await loadReChatCoach(noSaveData);
    await loadReChatUI(noSaveData);
    await loadReChatTutorial(noSaveData);
  }
  
  let debugExecuteCard = (card) => { 
    console.log("Executing arbitrary card for debugging: " + card.name);
    reChatExecuteCard(card);
  }

  
  async function preSubmitPage(itemNum,directNav)
  {
    if(itemNum > 0)
      await save_notepad_item_entry($page.itemNum);
    var lastPageNum = $page.itemNum;
    var result = await onChangePage(itemNum,directNav);
    var item = get_programmable_item_name_from_item_num(lastPageNum);
    if(item === "A")
      item = "CRITERION_"+item;
    let newItem = false;
    let totalProgress = await get_user_item_progress(get_session());
    if(totalProgress == null)
    {
      newItem = true
    }
    else if(result.success && lastPageNum+1 == itemNum) //only when scoring an item 
    {
      if(!directNav && (!totalProgress[itemNum] || !totalProgress[itemNum].viewed))
      {
        newItem = true
        if(item)
          incomingMatchInput("ITEM_CORRECTLY_SCORED_"+item,false);
      }
    }
    if($game_mode != "learn" && (!newItem || result.outsideNav) && (lastPageNum != itemNum))
    {
      let nextItem = get_programmable_item_name_from_item_num(itemNum);
      if(result.outsideNav)
        nextItem = get_programmable_item_name_from_item_num($page.itemNum);
      if(nextItem === "A")
        nextItem = "CRITERION_"+nextItem;
      if(item)
        incomingMatchInput("deactivate_all_"+item,false);
      if(nextItem)
        incomingMatchInput("activate_all_"+nextItem,false);
    }
    return result.success;
  }

  async function checkAnswers(itemNum)
  {
    if(!$debug_mode && scoring_page())
    {
      let progress = await get_user_item_progress(get_session()); 
      if(!progress)
        return false;
      var length = Object.keys(progress).length;
      let results;
      $incorrectScoreValues = [];
      if($strict_scoring)
      {
        results = await proccess_item_results();
        var keys = Object.keys(results);
        for(let i =0; i!=keys.length; ++i)
        {
          if(keys[i] != "blanks" && results[keys[i]] == false)
          {
            $incorrectScoreValues = $incorrectScoreValues.concat([keys[i]])
          }
        }
        if($incorrectScoreValues.length)
          return false;
      }

      if($game_mode != "learn")
        results = await proccess_item_results(); //validate should always be updating results

      if($page.itemNum < itemNum && length == itemNum) // if we are navigating forward
      {
        console.log("Checking answers");
        var prompts_used = await get_rechat_sequence("patient",get_session());
        if(!results)
          results = await proccess_item_results();
        if(!results)
        {
          if($game_mode == "learn")
            incomingMatchInput("incorrectItemSubmission",false);
          return false;
        }
        var keys = Object.keys(results);
        for(let i =0; i!=keys.length; ++i)
        {
          if(keys[i]=="blanks")
            continue;
          if(!results[keys[i]])
          {
            if($game_mode == "learn")
              incomingMatchInput("incorrectItemSubmission",false);
            return false;
          }
        }//for
        if($game_mode == "learn")
        {
          if( (!prompts_used || (prompts_used && !prompts_used[$page.itemNum])) && !($page.itemNum >= 26 && $page.itemNum <= 28))
            incomingMatchInput("noPromptsGeneralAlert",false);
          else
          {
            // select random possitive feedback
            incomingMatchInput($positive_feedback_pool[Math.floor(Math.random()*$positive_feedback_pool.length)],false);
          }
        }
      }
    }
    return true;
  }
  async function onChangePage(itemNum,directNav)
  {
    let totalProgress = await get_user_item_progress(get_session());
    // if you navigate back to the instructions then click the forward button it should put you back to the latest item if you have any progress already
    if($page.itemNum == -1 && $page.itemNum < itemNum && totalProgress && Object.keys(totalProgress).length && !directNav) // maybe we can bring this into contentNavigation.svelte later...
    {
      await updateProgress(0);
      await modifyCriterion(itemNum);
      console.log("Page changing");
      return {outsideNav:true,success:false}; // return false since normal navigation isn't actually changing the page since updateProgress is...
    }
    var correct = await checkAnswers(itemNum);
    if((!correct && $game_mode=='learn') || (!correct && $strict_scoring))
      return {success:false};
    hide_coach_text();
    hide_coach_buttons();
    close_coach_menus();
    await modifyCriterion(itemNum);
    return {success:true};
  }

  async function postSubmitPage(navigatingForward)
  {
    if(scoring_page())
    {
      if(navigatingForward)
      {
        let progress = await get_user_item_progress(get_session());
        let currentItemProgress;
        if(progress)
          currentItemProgress = progress[$page.itemNum]
        if(currentItemProgress == null || currentItemProgress == undefined || !currentItemProgress.passed || $game_mode!="learn")
        {
          enable_notepad_text_entry(true);
          update_history(patient_event,$page.itemNum, evaluation);        
          set_notepad_item_entry($page.itemNum);          
        }
      }
      else
      {
        if($game_mode!="learn")
        {
          enable_notepad_text_entry(true);   
          update_history(patient_event,$page.itemNum, evaluation);        
          set_notepad_item_entry($page.itemNum);    
        }
        else
          enable_notepad_text_entry(false);
      }
        
    }
    set_current_page($page.itemNum);
  }
    
  function handleResetReChatOnClick()
  {
    console.log("Reseting ReChat");
    delete_rechat_state();
    loadReChat(true);
  }

  let addAudioAnimation;
  let reChatPlayAnimation = (animationName,sound) => {
    currentPatientCardName = animationName;
    var dialogue = patientDialogue;
    addAudioAnimation("patient",animationName,sound,()=>{ 
      clearSubtitles();
      displayDialogue = dialogue;
      if(scoring_page())
      {
        add_transcript_dialogue($page.itemNum, "patient", dialogue, patientDir, currentPatientCardName);
        scroll_transcript();
        save_transcript($page.itemNum, "patient", dialogue, patientDir, currentPatientCardName);
      }
    });
  }

  let userTranscriptInput = (input, allowPartialMatch, volumeWarningMessage) => {
    if(scoring_page())
    {
      add_transcript_dialogue($page.itemNum, "user", input);
      scroll_transcript();
      save_transcript($page.itemNum, "user", input);
    } 
    close_transcript();
    return incomingMatchInput(input,allowPartialMatch,volumeWarningMessage);
  }
  let incomingMatchInput = (input,allowPartialMatch,volumeWarningMessage) => {
    return conversationManager.matchInput(input,allowPartialMatch,volumeWarningMessage);
  }
  
  function playCoachAnimation(animation,sound)
  {
    currentCoachCardName = animation;
    var dialogue = coachDialogue;
    addAudioAnimation("coach",animation,sound,()=>{      
      displayCoachDialogue = dialogue;
      show_coach_text(); 
      if($input_disabled_all != true && dialogue != "How can I help?" && scoring_page())
      {
        add_transcript_dialogue($page.itemNum, "coach", dialogue, coachDir, currentCoachCardName);
        scroll_transcript();
        save_transcript($page.itemNum, "coach", dialogue, coachDir, currentCoachCardName);
      }      
    });
  }

  function clear_last_input()
  {
    lastInput = '';
  }
  function clear_current_input()
  {
    currentInput = '';
  }

  function activateAlert(card)
  {
    if(card)
      show_coach_alert();
    else
      hide_coach_alert();
    alertCard=card;
  }

  let tutorialMatchDistance = $matchDistanceForTutorial;
  let uiMatchDistance = $matchDistanceForUI;
  let coachMatchDistance = $matchDistanceForCoach;
  let patientMatchDistance = $matchDistanceForPatient;
  let patientRechat;
  let coachRechat;
  let uiRechat;
  let tutorialRechat;
  let alertCard;
  let skipTrack;

  function createConversations() 
  {
      let coach = {person:"coach",rechat:coachRechat};
      let patient = {person:"patient",rechat:patientRechat};
      let ui = {person:"UI",rechat:uiRechat};
      let tutorial = {person:"tutorial",rechat:tutorialRechat};
      conversationManager.addConversation("main",[coach,patient,ui],10,true);
      conversationManager.addConversation("tutorial",[tutorial],5,true);
      conversationManager.addOnStartFunction("tutorial",() => { console.log("started!") 
      $input_disabled_all = true;
      $input_disabled_tutorial = true;
      show_coach_buttons();
      document.getElementById("skipButton").innerHTML = "Skip";
      disable_enable_coach_button(true);
      });
      conversationManager.addOnEndFunction("tutorial",() => { console.log("ended!");
      document.getElementById("skipButton").innerHTML = "Done";
      document.getElementById("continueButtonDiv").style.display = "none";
      });   
      allConversationsAdded = true; 
  }

  $:{
      if(coachRechat && patientRechat && uiRechat && tutorialRechat)
      {
        createConversations();
      }
   }
   
  function close_coach()
  {
      if(is_coach_menu_open())
      {
        click_coach();
      }
  }
  
async function modifyCriterion(itemNum)
{
  var hasViewedBefore = false;
  var progress = await get_user_item_progress(get_session());
  if(progress && progress[itemNum])
    hasViewedBefore = progress[itemNum].viewed;
  if((itemNum === 0 || itemNum === 1 || itemNum === 6 || itemNum === 8 || itemNum === 15 || itemNum === 21 || itemNum === 23 || itemNum === 29) && !hasViewedBefore)
  {     
    displayCriterion();
  }
  else
  {
    closeCriterion();
  }
}

async function toggleCriterion()
{
  if($page.criterion != "GL")
  {
    if(displayCrit)  
      closeCriterion();
    else 
      displayCriterion();    
  }  
  else
    closeCriterion();
  close_coach();
}

function displayCriterion()
{
  setTimeout(function(){
    document.getElementById("critDiv").style.maxHeight = "100%"
    document.getElementById("critSpan").style.transform = "rotateX(90deg) translateY(-.25vw)";
    document.getElementById("critCollapse").style.transform = "rotateX(0deg) translateY(-100%)";  
    displayCrit = true;
    document.getElementById("critContent").style.display = "block";
    let critLabel = document.getElementsByClassName("CriterionBtn")[0];
    if(critLabel)
      critLabel.setAttribute("aria-label", "Collapse Criterion Info");
  }, 150); 
}

function closeCriterion()
{
  document.getElementById("critDiv").style.maxHeight = "0%";
  document.getElementById("critSpan").style.transform = "";
  document.getElementById("critCollapse").style.transform = "";  
  displayCrit = false;
  setTimeout(function(){
    document.getElementById("critContent").style.display = "none";
    let critLabel = document.getElementsByClassName("CriterionBtn")[0];
    if(critLabel)
      critLabel.setAttribute("aria-label", "Open Criterion Info");
}, 250); 
}

function mailCommentsTo()
{
  window.open(getMailtoUrl("f5c172c7.alphaogroup.com@amer.teams.ms", "C5T Feedback (Type Below)"),"Feedback");
}

function getMailtoUrl(to, subject, body) {
    var args = [];
    if (typeof subject !== 'undefined') {
        args.push('subject=' + encodeURIComponent(subject));
    }
    if (typeof body !== 'undefined') {
        args.push('body=' + encodeURIComponent(body))
    }

    var url = 'mailto:' + encodeURIComponent(to);
    if (args.length > 0) {
        url += '?' + args.join('&');
    }
    return url;
}

function clearSubtitles()
{
  hide_coach_text();
  hide_coach_buttons();
  displayDialogue = "";
}

let dialogue_box;
$: {
  if(dialogue_box)
  {
    if(displayDialogue && displayDialogue.length)
      dialogue_box.hidden = false;
    else
      dialogue_box.hidden = true;
  }
}

async function courseComplete()
{
  await post_certificate($CAPS5_cert_id,is_scorm_session);
  if(is_scorm_session)
  {
    scormPort.postMessage({func:"setStatus",param:"completed"});
  }
}

function backToStart()
{
  cancel_speech();
  dispatch('backToStart');
}

function showEvaluation()
{
  dispatch('showEval');
}
let cancelAnim;
async function continueTutorial(name)
{
  toggleTutorialAnimation(getTutorialCard(),$page.itemNum);
  cancelAnim(name);
  conversationManager.matchInput("tutorial_next", false);
  toggleTutorialAnimation(getTutorialCard(),$page.itemNum);
}

function skipTutorial(name)
{
  toggleTutorialAnimation(getTutorialCard(),$page.itemNum);
  cancelAnim(name);
  conversationManager.setConversationEnded("tutorial");
  disable_enable_coach_button(false);
  hide_coach_text();
  hide_coach_buttons();
  $input_disabled_all = false;
  $input_disabled_tutorial = false;
  if(String(getTutorialCard().name).includes("instructions"))
  {
    $input_disabled_dialogue = true;
    fwd_arrow_animation(true);
  }
}

export async function newSession()
{
  await updateProgress(-3);
  await loadReChat();
  await reset_transcript();
  alertCard = null;
  close_transcript();
  if($game_mode == "learn")
    hide_coach_alert();
  console.log("session");
  console.log(get_session());
  clear_notepad_item_entry();
  close_notepad("mouse"); 
  update_history(patient_event,$page.itemNum, evaluation);
  clearSubtitles();
  clear_current_input();
  clear_last_input();
  show_coach_buttons();
  resetTutorialAnimation(getTutorialCard());
  setIntroTutorialStarted(false);
}

function updateProgressLearn(progress)
{
  let progressItem = 0;
  var keys = Object.keys(progress);
  for(let i =0; i!=keys.length; ++i)
  {
    if(progress[keys[i]].viewed && !progress[keys[i]].passed)
    {
      progressItem = keys[i];
      break;
    }
    progressItem = keys[i];
  }
  let itemNum = parseInt(progressItem);
  if(progress[progressItem].passed)
  {
    itemNum = itemNum + 1; // set to the next item if this item has passed
  }
  return itemNum;
}

function updateProgressValidate(progress)
{
  let progressItem = 0;
  var keys = Object.keys(progress);
  for(let i =0; i!=keys.length; ++i)
  {
    if(!progress[keys[i]].viewed)
      break;
    progressItem = keys[i];
  }
  let itemNum = parseInt(progressItem);
  return itemNum;
}

async function updateProgress(defaultPage)
{
  let itemNum = defaultPage;
  let progress = await get_user_item_progress(get_session());
  if(progress)
  {
    if($game_mode == "learn")
      itemNum = updateProgressLearn(progress);
    else if ($game_mode == "validate" || $game_mode == "advanced_validate")
      itemNum = updateProgressValidate(progress);
  }
  $page.itemNum = itemNum;
  $page.criterion = get_criteria_for_item($page.itemNum).criteria;
  postSubmitPage(true);
}

onMount( async function()
{  
  try
  {
    await menuMounted();
    menuMountFinished = true;
    patientDir = "anthony_learn";   
    patientAnimationFilePath = $patient_asset_path+'anthony_learn.anim.json'
    patientCardPath = $patient_asset_path+"anthony_learn.rechat.json";
    uiCardPath = $base_asset_path+"ui_learn/ui_learn.rechat.json";
    tutorialCardPath = $base_asset_path+"tutorial_learn/tutorial_learn.rechat.json";
    coachCardPath = $coach_asset_path+"coach_learn.rechat.json";
    coachDir = "coach_learn";
    if($game_mode == "validate")
    {
      patientDir = "kathy_validate";
      patientAnimationFilePath = $patient_asset_path+'kathy_validate.anim.json';
      patientCardPath= $patient_asset_path+"kathy_validate.rechat.json";
      coachDir = "coach_validate";
      coachCardPath = $coach_asset_path+"coach_validate.rechat.json";
      uiCardPath = $base_asset_path+"ui_validate/ui_validate.rechat.json";
      tutorialCardPath = $base_asset_path+"tutorial_validate/tutorial_validate.rechat.json";
    }
    else if($game_mode == "advanced_validate")
    {
      patientDir = "robert_avm";
      patientAnimationFilePath = $patient_asset_path+'robert_avm.anim.json';
      patientCardPath= $patient_asset_path+"robert_avm.rechat.json";
      coachDir = "coach_avm";
      coachCardPath = $coach_asset_path+"coach_avm.rechat.json";
      uiCardPath = $base_asset_path+"ui_avm/ui_avm.rechat.json";
      tutorialCardPath = $base_asset_path+"tutorial_avm/tutorial_avm.rechat.json";
    }
    console.log(patientCardPath);
    lastViewedPage = await get_current_page();
    await updateProgress(-3);
    update_history(patient_event,$page.itemNum, evaluation);
    if(scoring_page()&&!$input_disabled_tutorial)
      disable_enable_coach_button(false);
    await set_transcript($page.itemNum, get_session());
    is_scorm_session = scormPort != undefined && scormPort != null;
  }
  catch(err)
  {
    if(err instanceof ServerError)
    {
      console.log("Server error when mounting App")
      console.log(err);
      isRunningApp = false;
    }
  }
});

</script>

<style lang="less">

  p
  {
    //font-family: Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif; 
    font-size: 18px;
  }
  #control_area{
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: .5%;
  }
  .emscripten_border
  {
    display: none;
    overflow: hidden;
  }
  #testAndLogs
  {
    display: none;
  }
  .dialogue_box
  {
    position: absolute;
    z-index: 20;
    text-align: center;
    padding: 3%;
    bottom: 7%;
    width: 94%;
    min-height: 10%;
    max-height: 36%;
    background-color: rgba(0, 0, 0, 0.4);
    overflow: hidden;
}
.dialogue_textarea
{
    font-family: Helvetica, sans-serif;
    width: 100%;
    font-size: 1.2vw;
    font-weight: 700;
    color: white;
    text-shadow: 1px 1px 3px #000000;
    /* -webkit-text-stroke-color: black;
    -webkit-text-stroke-width: 0.01em; */
    text-align: left;
    margin: auto;
    background: transparent;
    border-width: 0px;
    resize: none;
}
  .h-centered {
  margin-left: auto;
  margin-right: auto;
  display:block;
}
.inline {
 display: inline-block;
}
.viewportRatio(@x, @y)
{
  width: 100vw;
  height: @y * 100vw / @x;
  //max-width: @x / @y * 100vh;
  max-height: 100vh;
}
div#status
{
    position: absolute;
    top: 2.25vw;
    left: 46.5vw;
}

.tech_area
{
  width: 34%;
  position: relative;
}

.render_area{
  width: 100%;
  height: 83%;
  position: relative;
  overflow: hidden;
  margin-bottom: -.1%;
}
.control_area{
  width:65%;
  background: white;
  .inline();
}

.emscripten {
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    
    position: relative;
    display: block;
    text-align: center;
    //font-family: Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif;
  }

.progressSVG
{
    top: -.5vw;
    left: 37%;
    position: absolute;
    width: 13vw;
    height: 1vw;
}

  textarea.emscripten {
    clear: both;
    width: 98%;
    //font-family: Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif;
    .h-centered();

  }

  div.emscripten {
    text-align: center;
  }

  @media all and (max-aspect-ratio: ~"3/2")
  {
    div.emscripten_border {
      .viewportRatio(4,3);
      top:0;bottom:0; /* vertical center */
      left:0;right:0; /* horizontal center */
    }
  }

  @media all and (min-aspect-ratio: ~"3/2")
  {
    div.emscripten_border {
      .viewportRatio(16,9);
      top:0;bottom:0; /* vertical center */
      left:0;right:0; /* horizontal center */
    }
  }


  /* the canvas *must not* have any border or padding, or mouse coords will be wrong */
  canvas.emscripten {
    border: 0px none;
    background-color: black;
    width: 100%;
  }

  .spinner {
    text-align: center;        
  }

  .spinner>div {
    width: .75vw;
    height: .75vw;
    background-color: #003c5e;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
      -webkit-transform: scale(0)
    }

    40% {
      -webkit-transform: scale(1.0)
    }
  }

  @keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }

  #reChatLog
  {
    clear: both;
    width: 98%;
    .h-centered();
  }
  #coachContainer {
    width: 97%;
    height: 98.5%;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    position: absolute;
    padding: 1.5% 1.5% 0 1.5%;
  }
  .loadingDiv
  {
    position: absolute;
    top: 20vw;
    left: 25vw;
    width: 50%;
    text-align: center;
  }
</style>

<svelte:window on:resize={resize_glfw}/>

<div class="emscripten_border" id="em_border" on:click={close_coach}>
  <Tooltip/>
  <div class="tech_area">
    <div class="render_area" id="render_area">
      <canvas class="emscripten" id="canvas" oncontextmenu="event.preventDefault()" tabindex=-1 aria-label="Rendered image of subject"></canvas>
      <div id="coachContainer">              
        {#if menuMountFinished === true}
          <ReChat bind:this={coachRechat} bind:dialogue={coachDialogue} person="coach" bind:finishedMounting={coachRechatFinishedMounting} bind:matchDistance={coachMatchDistance} bind:listOfCardsToMatch={activeCardsCoach} bind:cardsFilePath={coachCardPath}  playAnimation={playCoachAnimation} bind:loadReChat={loadReChatCoach} setAlert={activateAlert} reChatError={reChatError}/>
        {/if}
        <CoachText dialogue={displayCoachDialogue} coachDir={coachDir} skipTutorial={skipTutorial} continueTutorial={continueTutorial} card_name={currentCoachCardName}/>
        {#if (($game_mode == 'learn') || $input_disabled_tutorial || ($page.itemNum >= 0 && $page.itemNum <= 30)) }
          <CoachButton bind:click_coach={click_coach} bind:alert_card={alertCard} incoming_match_input={incomingMatchInput} skipTrack={skipTrack} dialogue={coachDialogue} coach_card_name={currentCoachCardName} coach_dir={coachDir}/>
        {/if}
        <CoachMenu incoming_match_input={incomingMatchInput} clear_last_input={clear_last_input} />
      </div>
      <TranscriptReview/>
      <PerformanceMetrics/>
      <div class="dialogue_box" bind:this={dialogue_box} id="dialogue_div">
        <p class="dialogue_textarea" readonly data-cy='dialogue_output'><TorcLink bind:character_dir={patientDir} card_name={currentPatientCardName} characterImgClass={"torc_link_icon_patient"} characterSpanClass={"torc_link_div_patient"}/> {displayDialogue}</p>
      </div>  
      <Notepad/>
    </div>      
    {#if menuMountFinished === true}
      <ReChat bind:this={patientRechat} bind:cardsFilePath={patientCardPath} person="patient" bind:finishedMounting={patientRechatFinishedMounting} bind:matchDistance={patientMatchDistance} bind:listOfCardsToMatch={activeCardsPatient} bind:dialogue={patientDialogue} bind:executeCard={reChatExecuteCard} bind:loadReChat={loadReChatPatient} bind:playAnimation={reChatPlayAnimation} setAlert={activateAlert} reChatError={reChatError}/>
      <MainSpeechInputArea bind:matchInput={userTranscriptInput} bind:lastInput={lastInput} bind:currentTextInput={currentInput} bind:focusTrap={focusTrap} bind:abort_speech_recognition={cancel_speech}/>
      <AudioAnimationManager bind:add={addAudioAnimation} bind:cancelAnim={cancelAnim} bind:skipTrack={skipTrack} animationFilePath={patientAnimationFilePath}/>
    {/if}
  </div>
  <div class="control_area" id="control_area" on:click={closeCriterion} data-cy='close_Criterion'>      
    <Menu on:toggle={toggleCriterion} on:restart={handleResetReChatOnClick} 
      on:clear={clearSubtitles} on:log={addCommentToLog} on:feedback={mailCommentsTo} on:backToStart={backToStart} bind:showTestCard bind:showActiveCardsTable bind:showLogs bind:scormPort={scormPort} />
    {#if menuMountFinished === true && isRunningApp === true }
      {#if $page.itemNum < 0}
        {#if $page.itemNum % 2 === 0}
          <IntroPages num={$page.itemNum}/> 
        {:else}
          <IntroPages num={$page.itemNum}/> 
        {/if}
      {:else if $page.criterion === "A"}
        <CriterionA num={$page.itemNum} bind:initCoach={incomingMatchInput}/>
      {:else if $page.criterion === "B" || $page.criterion === "C" || $page.criterion === "D"  || $page.criterion === "E" || $page.criterion === "DI" || $page.criterion === "F" ||  $page.criterion === "G" || $page.criterion === "GL"}
        {#if $page.itemNum % 2 === 0}
          <CriterionB num={$page.itemNum}/> 
        {:else}
          <CriterionB num={$page.itemNum}/>
        {/if}
      {:else if $page.criterion === "Summary"}
          <Summary/>
      {/if}         
      <Navigation prePageChangedFunc={preSubmitPage} postPageChangeFunc={postSubmitPage} on:showEval={showEvaluation} courseCompleted={courseComplete} />		      
    {/if} 
  </div>
</div>		      
<div id="testAndLogs">   
  {#if showTestCard}  
    <CardSelection bind:executeCard={debugExecuteCard}/>
  {/if}
  <!-- <button on:click={handleResetReChatOnClick}>Reset ReChat</button> -->
  <hr>
  {#if showActiveCardsTable && $environment == "development"}
    <table id="cardsTable" bind:this={activeCardsTable}/>
  {/if}
  {#if showLogs && $environment == "development"}
    <textarea bind:this={reChatTextArea} id="reChatLog" rows="8"></textarea>
  {/if}
  {#if $environment == "development"}
    <textarea bind:this={emstext} class="emscripten" id="output" rows="8"  ></textarea>
  {/if}
</div>
<div class="loadingDiv">
  <div class="emscripten">
    <svg id="progressContainer" class="progressSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 334.5 24"><defs><style>.cls-1,.cls-2{fill:none;stroke-miterlimit:10;}.cls-1{stroke:#7092a6;}.cls-2{stroke:#023b5a;stroke-linecap:round;stroke-width:15px;}</style></defs><title>Asset 1</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path id="container" class="cls-1" d="M12,23.5a11.5,11.5,0,0,1,0-23H322.5a11.5,11.5,0,0,1,0,23Z"/><g id="stroke"><line id="progress" class="cls-2" x1="12" y1="12" x2="322.5" y2="12"/></g></g></g></svg>
      <!-- <progress value="0" max="100" id="progress"></progress> -->
  </div>
  <figure style="overflow:visible;" id="spinner">
    <div class="spinner" id="spinnerElement">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
    <center style="margin-top:0.5em; font-weight:bold; color: #003D5E; font-size: .85vw;" id="spinnerText"></center>
  </figure>  
  <div class="emscripten" id="status"></div>      
</div>  
{#if menuMountFinished === true}
  <ReChat bind:this={uiRechat} person="UI" bind:dialogue={uiDialogue} bind:finishedMounting={uiRechatFinishedMounting} bind:matchDistance={uiMatchDistance} bind:listOfCardsToMatch={activeCardsUI} bind:cardsFilePath={uiCardPath} bind:loadReChat={loadReChatUI} setAlert={activateAlert} reChatError={reChatError}/>
  <ReChat bind:this={tutorialRechat} person="tutorial" bind:matchDistance={tutorialMatchDistance} bind:listOfCardsToMatch={activeCardsTutorial} bind:cardsFilePath={tutorialCardPath} bind:loadReChat={loadReChatTutorial} setAlert={activateAlert} reChatError={reChatError}/>
{/if}