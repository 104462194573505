 <script>
    import { onMount } from "svelte";
    import {patient_asset_path,game_mode} from './stores.js'
    let selectedCard;
    let selectedGroup = "all";
    export let executeCard;
    let cards = [];
    let groups = [];
    let selectCardElement;
    $: {
      if(selectedCard && selectedCard.groups && !selectedCard.groups.includes(selectedGroup))
      { 
        let i;
        console.log("Group: " + selectedGroup);
        for(i=0; i!=cards.length; ++i)
        {
          if(cards[i].groups && cards[i].groups.includes(selectedGroup))
          {
            selectedCard = cards[i];
            break;
          }
        }
      }
    }
    function testCard()
    {
      executeCard(selectedCard,"",0,"");
    }

    async function reload()
    {
        const response = await fetch($patient_asset_path +($game_mode == "learn"?"anthony_learn.rechat.json":$game_mode == "validate"?"kathy_validate.rechat.json":"robert_avm.rechat.json"));
        const json = await response.json();
        cards = json;
        // Remove empty json object that might be in the data
        var lastElement = cards[cards.length-1];
        if(Object.entries(lastElement).length === 0 && lastElement.constructor === Object)
        {
            cards.pop();
        }
        cards.sort((a,b)=> { return a.name.localeCompare(b.name);})
        let prevCardFound = false;
        let groupsSet = new Set();
        cards.forEach(card =>{
          for(let i in card.groups)
          {
            groupsSet.add(card.groups[i]); 
          }
          if(selectedCard != null && selectedCard != undefined && selectedCard.name === card.name) 
          {
            prevCardFound = true;
            selectedCard = card;
          }
        });
        groups = Array.from(groupsSet);
        groups.sort((a,b)=> {return a.localeCompare(b);})

        if(cards.length && !prevCardFound)
        {
          selectedCard = cards[0];
        }
        console.log("loading test cards complete")
    }
    
    onMount( async function() {
      await reload();
    });
</script>

<style lang="less">
	input[type=button]{
		font: inherit;
		padding: 0.15rem 0.5rem;
		color: black;
		cursor: pointer;
  }

</style>

<div style="float: left">
<select bind:value={selectedGroup}>
          <option value="all">all</option>   
          {#each groups as group}
            <option value={group}>{group}</option>            
          {/each}
        </select>
<select bind:value={selectedCard}>
          {#each cards as card}
            {#if (card.groups && card.groups.includes(selectedGroup)) || selectedGroup == 'all' }
              <option value={card}>{card.name}</option> 
            {/if}
          {/each}
        </select>
</div>
<div>
<input type="button" style="overflow:hidden" value="Test Card"
        on:click={testCard}>
<input type="button" style="overflow:hidden" value="Reload!"
        on:click={reload}>
</div>


