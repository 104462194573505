<script>
let notepadTextEle;
let history='';

import { game_mode, page } from './stores.js';
import ContextController from "./ContextController.svelte";

  $:{
      if(notepadTextEle)
        document.getElementById("notepadText").disabled = ($page.itemNum < 0 || $page.itemNum > 30) ? true : false;
  }

  function on_text_change(event)
  {
    clearTimeout(timer);
    timer = setTimeout(()=>{
      save_notepad_item_entry($page.itemNum);
      timer=null;
      console.log("Notepad Timeout: Text submitted");
      },1500);
  }

</script>
<script context="module">
import { get } from 'svelte/store';
import { post_addtl_state_data, get_session, get_user_item_progress } from './server_api.js';
import { get_saved_state } from "./saved_states.js";

let timer=null;

export async function save_notepad_item_entry(itemNum)
{
  clearTimeout(timer);
  let progress = await get_user_item_progress(get_session());
  if(progress)
  {
    let length = Object.keys(progress).length 
    if(length == itemNum+1 || get(game_mode) != "learn")    
      await post_addtl_state_data({notepad_text:document.getElementById("notepadText").value},itemNum);
  }
}

export function enable_notepad_text_entry(enable)
{
  document.getElementById("notepadText").disabled = !enable;  
}

export function set_notepad_item_entry(itemNum)
{
  var state = get_saved_state(itemNum,get_session());
  document.getElementById("notepadText").value = (state && state.addtl_data && state.addtl_data.notepad_text)?state.addtl_data.notepad_text:'';
}

export function clear_notepad_item_entry()
{
  document.getElementById("notepadText").value = '';
}

export function update_history(event, pageNum, evaluation)
{
  history = "<b>Background</b><br>Trauma: "+event+"<br><br>";
  document.getElementById("historyNotepad").innerHTML = history;
  if(pageNum<0)
  {      
    document.getElementById("historyNotepad").innerHTML = history;
    document.getElementById("scrollList").scrollTop = document.getElementById("scrollList").scrollHeight;
    return;
  }
  history=history.concat(evaluation.criterions[0].criterion_title.bold());    
  for(var i=1; i<evaluation.criterions.length; i++)
    for(var k in evaluation.criterions[i].criterions)            
      if(evaluation.criterions[i].criterions[k].itemNum <= pageNum)
      {          
        var titleMatch = evaluation.criterions[i].criterions[k].title.bold();
        var state = get_saved_state(evaluation.criterions[i].criterions[k].itemNum-1,get_session());
        let item_notepad_text = "";
        if(state && state.addtl_data && state.addtl_data.notepad_text)
          item_notepad_text = state.addtl_data.notepad_text;
        history = history.concat("<br>", item_notepad_text == ""?"No notes.":item_notepad_text,"<br><br>", titleMatch);                          
      }
      else
        break;
  document.getElementById("historyNotepad").innerHTML = history;
  document.getElementById("scrollList").scrollTop = document.getElementById("scrollList").scrollHeight;
}
</script>
<style lang="less">
  #rel
  {
    position: relative;
  }
  #notepad
  {
    position: absolute;
    top: -22vw;
    display: none;
    z-index: 30;
    width: 100%;
    border-radius: 5px;
    transition: 0.1s ease;
  }
  ul
  {
    padding: 0vw;
    margin: 0vw;
    overflow-y: auto;
    overflow-wrap: break-word;
    height: 22vw;
    background-image: url(/assets/line.png);
    background-size: 100% 30px;
    background-repeat: repeat-y, repeat;
  }
  #notepadText {
    font-size: .85vw;
    font-family: 'Kalam', cursive;
    line-height: 30px;
    color: rgb(0, 0, 0);
    margin: 0;
    display: block;
    width: 100%;
    height: 10vw;
    box-sizing: border-box;
    border: none;
    resize: none;
    padding: 1.5em;
    padding-left: 5vw;
    padding-top: 0.1em;
    padding-bottom: 0vw;
    background: transparent;
    overflow: hidden;
    outline: none;
  }
	#historyNotepad
	{
		font-size: .75vw;
    font-family: 'Kalam', cursive;
    line-height: 1.5vw;
		color:#757575;
		margin: 0;
		display: block;
		width: 100%;
		height: auto;
		box-sizing: border-box;
		border-bottom: 0;
		resize: none;
		padding: 1.5em;
		padding-left: 5vw;
		padding-top: 0.1em;  
		padding-bottom: 0em;
		outline: none;
	}
	.notepadList
	{
		display: block;
		background: white;
	}

  @media (max-width:1500px)
  {
      #historyNotepad
      {
        font-size: .7em;
        line-height: 1em;
      }
      #notepadText
      {
        font-size: .8em;
      }
  }

</style>

<div id="rel">
	<div id="notepad">
		<div class="notepadList">
			<ContextController divID={"ctx_notepad_input"}/>
			<ul id="scrollList">
				<ContextController divID={"ctx_notepad_scroll"}/>
				<li>
					<p id="historyNotepad" aria-label="Notepad history area" tabindex="-1"></p> </li>
				<li id="textAreaListItem">                
					<textarea id="notepadText" bind:this={notepadTextEle} on:input={on_text_change} maxlength="10000" aria-label="Notepad text area" tabindex="-1"></textarea>
				</li>            
			</ul>
		</div>
	</div>  
</div>