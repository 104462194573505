import {readable} from 'svelte/store';

export const matchDistanceForTutorial = readable(11.2);

export const matchDistanceForUI = readable(11.2);

export const matchDistanceForCoach = readable(10.2);

export const matchDistanceForPatient = readable(11.2);

export const matchDistanceForSpeechTest = readable(11.2);