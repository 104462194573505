<script context="module">
import {svg_show_elements,svg_reset_all_anms,svg_run_anms} from "./svg_helper.js"
var controlling_elements=["GRP_In","GRP_Loop","GRP_Out"];
var svg_id="ewoz9vvb9k6d1";

export function alert_in()
{
  	svg_show_elements(svg_id,controlling_elements,false);
	svg_show_elements(svg_id,["GRP_In"],true);
	svg_reset_all_anms(svg_id);
	svg_run_anms(svg_id,["GRP_In","In_exc"],true,false,);
}

export function alert_loop()
{
  	svg_show_elements(svg_id,controlling_elements,false);
	svg_show_elements(svg_id,["GRP_Loop"],true);
	svg_reset_all_anms(svg_id);
	svg_run_anms(svg_id,["Loop_exc_holder","Loop_exc"],true,false,);
}

export function alert_out()
{
	svg_show_elements(svg_id,controlling_elements,false);
	svg_show_elements(svg_id,["GRP_Out"],true);
	svg_reset_all_anms(svg_id);
	svg_run_anms(svg_id,["Out_bubbleholder_ts","Out_bubble_exc_holder"],true,false,);
}
</script>
<style lang="less">
#alert_svg_container
	{
		display: none;
		width: 2.5vw;
		height: 2.5vw;
		position: absolute;
		left: 4.8vw;
		top: 5.7vw;
		z-index: 5;
	}
</style>
<div id="alert_svg_container">
		<svg
			id="ewoz9vvb9k6d1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 60.590000 54.850000"
			shape-rendering="geometricPrecision"
			text-rendering="geometricPrecision">
			<style>
				#Out_bubbleholder_ts {
				animation: Out_bubbleholder_ts__ts 1500ms linear infinite normal forwards;
				}
				@keyframes Out_bubbleholder_ts__ts {
				0% {
					transform: translate(0px, 0px) scale(1, 1);
				}
				20% {
					transform: translate(0px, 0px) scale(1, 1);
					animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
				}
				26.666667% {
					transform: translate(0px, 0px) scale(0, 0);
				}
				100% {
					transform: translate(0px, 0px) scale(0, 0);
				}
				}
				#Out_bubble_exc_holder {
				animation: Out_bubble_exc_holder__ts 1500ms linear infinite normal forwards;
				}
				@keyframes Out_bubble_exc_holder__ts {
				0% {
					transform: translate(35.385087px, 41.910036px) scale(1, 1);
					animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
				}
				6.666667% {
					transform: translate(35.385087px, 41.910036px) scale(1.1, 1.1);
					animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
				}
				20% {
					transform: translate(35.385087px, 41.910036px) scale(0, 0);
				}
				100% {
					transform: translate(35.385087px, 41.910036px) scale(0, 0);
				}
				}
				#Loop_exc_holder {
				animation: Loop_exc_holder__tr 1500ms linear infinite normal forwards;
				}
				@keyframes Loop_exc_holder__tr {
				0% {
					transform: translate(35.385087px, 28.770018px) rotate(0deg);
				}
				60% {
					transform: translate(35.385087px, 28.770018px) rotate(0deg);
					animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
				}
				66.666667% {
					transform: translate(35.385087px, 28.770018px) rotate(-15deg);
				}
				73.333333% {
					transform: translate(35.385087px, 28.770018px) rotate(15deg);
				}
				80% {
					transform: translate(35.385087px, 28.770018px) rotate(-15deg);
				}
				86.666667% {
					transform: translate(35.385087px, 28.770018px) rotate(15deg);
				}
				93.333333% {
					transform: translate(35.385087px, 28.770018px) rotate(-15deg);
				}
				100% {
					transform: translate(35.385087px, 28.770018px) rotate(0deg);
				}
				}
				#Loop_exc {
				animation: Loop_exc__ts 1500ms linear infinite normal forwards;
				}
				@keyframes Loop_exc__ts {
				0% {
					transform: scale(1, 1);
				}
				60% {
					transform: scale(1, 1);
					animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
				}
				80% {
					transform: scale(1.5, 1.5);
					animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
				}
				100% {
					transform: scale(1, 1);
				}
				}
				#GRP_In {
				animation: GRP_In__ts 1500ms linear infinite normal forwards;
				}
				@keyframes GRP_In__ts {
				0% {
					transform: translate(0px, 0px) scale(0, 0);
					animation-timing-function: cubic-bezier(0, 0, 0.495, 1.01);
				}
				13.333333% {
					transform: translate(0px, 0px) scale(1, 1);
				}
				100% {
					transform: translate(0px, 0px) scale(1, 1);
				}
				}
				#In_exc {
				animation: In_exc__ts 1500ms linear infinite normal forwards;
				}
				@keyframes In_exc__ts {
				0% {
					transform: translate(35.385087px, 41.910036px) scale(0, 0);
				}
				13.333333% {
					transform: translate(35.385087px, 41.910036px) scale(0, 0);
					animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
				}
				26.666667% {
					transform: translate(35.385087px, 41.910036px) scale(1.1, 1.1);
					animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
				}
				40% {
					transform: translate(35.385087px, 41.910036px) scale(1, 1);
				}
				100% {
					transform: translate(35.385087px, 41.910036px) scale(1, 1);
				}
				}
			</style>
			<g id="GRP_Out" display="none">
				<g id="Out_bubbleholder_ts" transform="translate(0,0) scale(1,1)">
				<g id="Out_bubbleholder" display="block" transform="translate(0,0)">
					<path
					id="Out_bubble_bg_path" display="block"
					d="M35.080000,53.880000C21.555876,53.863470,10.596530,42.904124,10.580000,29.380000C10.571659,25.973901,11.280355,22.604187,12.660000,19.490000C13.370000,17.870000,11.490000,15.240000,6.710000,9.170000C4.550000,6.440000,1.360000,2.400000,1,1L1.100000,1C2.900000,1,7.500000,2.910000,11.560000,4.590000C16.160000,6.500000,20.130000,8.150000,22.140000,8.150000C22.535095,8.165170,22.927511,8.079114,23.280000,7.900000C32.251159,2.998324,43.322849,4.182187,51.054790,10.869878C58.786731,17.557569,61.553470,28.343162,57.995519,37.926956C54.437567,47.510749,45.302898,53.877984,35.080000,53.900000Z"
					fill="rgb(73,135,172)"
					stroke="none"
					stroke-width="1" />
					<path
					id="Out_bubble_outline_path" display="block"
					d="M2.840000,2.340000C5,3,8.350000,4.350000,11.170000,5.520000C16.620000,7.780000,20.080000,9.150000,22.170000,9.150000C22.732677,9.166426,23.290247,9.039080,23.790000,8.780000C33.863390,3.284791,46.433835,5.886346,53.498612,14.928438C60.563390,23.970530,60.047169,36.796975,52.278590,45.242074C44.510011,53.687172,31.771154,55.270113,22.171958,48.983148C12.572761,42.696183,8.933061,30.386157,13.570000,19.890000C14.520000,17.760000,12.760000,15.230000,7.490000,8.560000C6,6.670000,4,4.090000,2.840000,2.340000M1.100000,0C0.759853,-0.039540,0.418748,0.065695,0.160000,0.290000C-1.650000,2.290000,12.850000,16.580000,11.750000,19.080000C6.727563,30.507262,10.715478,43.890575,21.173518,50.705016C31.631558,57.519457,45.485331,55.761758,53.910240,46.551540C62.335150,37.341323,62.854491,23.386151,55.137454,13.575211C47.420417,3.764271,33.735609,0.981724,22.800000,7C22.591880,7.089311,22.366238,7.130337,22.140000,7.120000C18.460000,7.150000,5,0,1.100000,0Z"
					fill="rgb(255,255,255)"
					stroke="none"
					stroke-width="1" />
				</g>
				</g>
				<g
				id="Out_bubble_exc_holder" display="block"
				transform="translate(35.385087,41.910036) scale(1,1)">
				<path
					id="Out_bubble_exc_path" display="block"
					d="M32.650000,39.210000C32.650860,38.844121,32.718643,38.481487,32.850000,38.140000C32.982621,37.816757,33.180006,37.524084,33.430000,37.280000C33.684824,37.038495,33.979063,36.842335,34.300000,36.700000C34.642645,36.559518,35.009679,36.488150,35.380000,36.490000C35.743848,36.488131,36.104347,36.559550,36.440000,36.700000C36.765262,36.839889,37.063192,37.036252,37.320000,37.280000C37.566711,37.526806,37.763625,37.818781,37.900000,38.140000C38.048278,38.477063,38.123266,38.841778,38.120000,39.210000C38.124504,39.578341,38.049462,39.943316,37.900000,40.280000C37.763611,40.598261,37.566620,40.886955,37.320000,41.130000C37.065475,41.374096,36.766832,41.567536,36.440000,41.700000C36.104347,41.840450,35.743848,41.911869,35.380000,41.910000C35.009679,41.911850,34.642645,41.840482,34.300000,41.700000C33.977492,41.564974,33.682582,41.371757,33.430000,41.130000C33.180260,40.889576,32.982811,40.600211,32.850000,40.280000C32.716625,39.939088,32.648771,39.576072,32.650000,39.210000ZM37.520000,15.630000L37.520000,25.920000C37.522568,26.992055,37.469162,28.063514,37.360000,29.130000C37.250000,30.180000,37.120000,31.300000,36.950000,32.470000L33.950000,32.470000C33.790000,31.300000,33.650000,30.180000,33.540000,29.130000C33.430838,28.063514,33.377432,26.992055,33.380000,25.920000L33.380000,15.630000Z"
					transform="translate(-35.385087,-41.910036)"
					fill="rgb(255,255,255)"
					stroke="none"
					stroke-width="1" />
				</g>
			</g>
			<g id="GRP_Loop" display="block">
				<g id="Loop_bubble_holder">
				<path
					id="Loop_bubble_bg_path" display="block"
					d="M35.080000,53.880000C21.555876,53.863470,10.596530,42.904124,10.580000,29.380000C10.571659,25.973901,11.280355,22.604187,12.660000,19.490000C13.370000,17.870000,11.490000,15.240000,6.710000,9.170000C4.550000,6.440000,1.360000,2.400000,1,1L1.100000,1C2.900000,1,7.500000,2.910000,11.560000,4.590000C16.160000,6.500000,20.130000,8.150000,22.140000,8.150000C22.535095,8.165170,22.927511,8.079114,23.280000,7.900000C32.251159,2.998324,43.322849,4.182187,51.054790,10.869878C58.786731,17.557569,61.553470,28.343162,57.995519,37.926956C54.437567,47.510749,45.302898,53.877984,35.080000,53.900000Z"
					fill="rgb(73,135,172)"
					stroke="none"
					stroke-width="1" />
				<path
					id="Loop_bubble_outline_path"
					d="M2.840000,2.340000C5,3,8.350000,4.350000,11.170000,5.520000C16.620000,7.780000,20.080000,9.150000,22.170000,9.150000C22.732677,9.166426,23.290247,9.039080,23.790000,8.780000C33.863390,3.284791,46.433835,5.886346,53.498612,14.928438C60.563390,23.970530,60.047169,36.796975,52.278590,45.242074C44.510011,53.687172,31.771154,55.270113,22.171958,48.983148C12.572761,42.696183,8.933061,30.386157,13.570000,19.890000C14.520000,17.760000,12.760000,15.230000,7.490000,8.560000C6,6.670000,4,4.090000,2.840000,2.340000M1.100000,0C0.759853,-0.039540,0.418748,0.065695,0.160000,0.290000C-1.650000,2.290000,12.850000,16.580000,11.750000,19.080000C6.727563,30.507262,10.715478,43.890575,21.173518,50.705016C31.631558,57.519457,45.485331,55.761758,53.910240,46.551540C62.335150,37.341323,62.854491,23.386151,55.137454,13.575211C47.420417,3.764271,33.735609,0.981724,22.800000,7C22.591880,7.089311,22.366238,7.130337,22.140000,7.120000C18.460000,7.150000,5,0,1.100000,0Z"
					fill="rgb(255,255,255)"
					stroke="none"
					stroke-width="1" />
				</g>
				<g
				id="Loop_exc_holder"
				transform="translate(35.385087,28.770018) rotate(0)">
				<g id="Loop_exc" transform="scale(1,1)">
					<path
					id="Loop_exc_path"
					d="M32.650000,39.210000C32.650860,38.844121,32.718643,38.481487,32.850000,38.140000C32.982621,37.816757,33.180006,37.524084,33.430000,37.280000C33.684824,37.038495,33.979063,36.842335,34.300000,36.700000C34.642645,36.559518,35.009679,36.488150,35.380000,36.490000C35.743848,36.488131,36.104347,36.559550,36.440000,36.700000C36.765262,36.839889,37.063192,37.036252,37.320000,37.280000C37.566711,37.526806,37.763625,37.818781,37.900000,38.140000C38.048278,38.477063,38.123266,38.841778,38.120000,39.210000C38.124504,39.578341,38.049462,39.943316,37.900000,40.280000C37.763611,40.598261,37.566620,40.886955,37.320000,41.130000C37.065475,41.374096,36.766832,41.567536,36.440000,41.700000C36.104347,41.840450,35.743848,41.911869,35.380000,41.910000C35.009679,41.911850,34.642645,41.840482,34.300000,41.700000C33.977492,41.564974,33.682582,41.371757,33.430000,41.130000C33.180260,40.889576,32.982811,40.600211,32.850000,40.280000C32.716625,39.939088,32.648771,39.576072,32.650000,39.210000ZM37.520000,15.630000L37.520000,25.920000C37.522568,26.992055,37.469162,28.063514,37.360000,29.130000C37.250000,30.180000,37.120000,31.300000,36.950000,32.470000L33.950000,32.470000C33.790000,31.300000,33.650000,30.180000,33.540000,29.130000C33.430838,28.063514,33.377432,26.992055,33.380000,25.920000L33.380000,15.630000Z"
					transform="translate(-35.385087,-28.770018)"
					fill="rgb(255,255,255)"
					stroke="none"
					stroke-width="1" />
				</g>
				</g>
			</g>
			<g id="GRP_In" display="none" transform="translate(0,0) scale(0,0)">
				<g id="In_holder" display="block" transform="translate(0,0)">
				<g id="In_bubble_holder">
					<path
					id="In_bubble_bg_path" display="block"
					d="M35.080000,53.880000C21.555876,53.863470,10.596530,42.904124,10.580000,29.380000C10.571659,25.973901,11.280355,22.604187,12.660000,19.490000C13.370000,17.870000,11.490000,15.240000,6.710000,9.170000C4.550000,6.440000,1.360000,2.400000,1,1L1.100000,1C2.900000,1,7.500000,2.910000,11.560000,4.590000C16.160000,6.500000,20.130000,8.150000,22.140000,8.150000C22.535095,8.165170,22.927511,8.079114,23.280000,7.900000C32.251159,2.998324,43.322849,4.182187,51.054790,10.869878C58.786731,17.557569,61.553470,28.343162,57.995519,37.926956C54.437567,47.510749,45.302898,53.877984,35.080000,53.900000Z"
					fill="rgb(73,135,172)"
					stroke="none"
					stroke-width="1" />
					<path
					id="In_bubble_outline_path" display="block"
					d="M2.840000,2.340000C5,3,8.350000,4.350000,11.170000,5.520000C16.620000,7.780000,20.080000,9.150000,22.170000,9.150000C22.732677,9.166426,23.290247,9.039080,23.790000,8.780000C33.863390,3.284791,46.433835,5.886346,53.498612,14.928438C60.563390,23.970530,60.047169,36.796975,52.278590,45.242074C44.510011,53.687172,31.771154,55.270113,22.171958,48.983148C12.572761,42.696183,8.933061,30.386157,13.570000,19.890000C14.520000,17.760000,12.760000,15.230000,7.490000,8.560000C6,6.670000,4,4.090000,2.840000,2.340000M1.100000,0C0.759853,-0.039540,0.418748,0.065695,0.160000,0.290000C-1.650000,2.290000,12.850000,16.580000,11.750000,19.080000C6.727563,30.507262,10.715478,43.890575,21.173518,50.705016C31.631558,57.519457,45.485331,55.761758,53.910240,46.551540C62.335150,37.341323,62.854491,23.386151,55.137454,13.575211C47.420417,3.764271,33.735609,0.981724,22.800000,7C22.591880,7.089311,22.366238,7.130337,22.140000,7.120000C18.460000,7.150000,5,0,1.100000,0Z"
					fill="rgb(255,255,255)"
					stroke="none"
					stroke-width="1" />
				</g>
				<g
					id="In_exc" display="block"
					transform="translate(35.385087,41.910036) scale(0,0)">
					<path
					id="In_exc_path"
					d="M32.650000,39.210000C32.650860,38.844121,32.718643,38.481487,32.850000,38.140000C32.982621,37.816757,33.180006,37.524084,33.430000,37.280000C33.684824,37.038495,33.979063,36.842335,34.300000,36.700000C34.642645,36.559518,35.009679,36.488150,35.380000,36.490000C35.743848,36.488131,36.104347,36.559550,36.440000,36.700000C36.765262,36.839889,37.063192,37.036252,37.320000,37.280000C37.566711,37.526806,37.763625,37.818781,37.900000,38.140000C38.048278,38.477063,38.123266,38.841778,38.120000,39.210000C38.124504,39.578341,38.049462,39.943316,37.900000,40.280000C37.763611,40.598261,37.566620,40.886955,37.320000,41.130000C37.065475,41.374096,36.766832,41.567536,36.440000,41.700000C36.104347,41.840450,35.743848,41.911869,35.380000,41.910000C35.009679,41.911850,34.642645,41.840482,34.300000,41.700000C33.977492,41.564974,33.682582,41.371757,33.430000,41.130000C33.180260,40.889576,32.982811,40.600211,32.850000,40.280000C32.716625,39.939088,32.648771,39.576072,32.650000,39.210000ZM37.520000,15.630000L37.520000,25.920000C37.522568,26.992055,37.469162,28.063514,37.360000,29.130000C37.250000,30.180000,37.120000,31.300000,36.950000,32.470000L33.950000,32.470000C33.790000,31.300000,33.650000,30.180000,33.540000,29.130000C33.430838,28.063514,33.377432,26.992055,33.380000,25.920000L33.380000,15.630000Z"
					transform="translate(-35.385087,-41.910036)"
					fill="rgb(255,255,255)"
					stroke="none"
					stroke-width="1" />
				</g>
				</g>
			</g>
			</svg>
			</div>