<script>
import './SummaryTableCSS.svelte';
import SummaryTableRow from'./SummaryTableRow.svelte';
import evaluation from "./../evaluation.json";
import { get_rating } from './../evaluation_data.js';

export let cmp;

</script>
<table class="summary_table">                
    <tr>
        <th colspan="3" bgcolor="#C0C0C0" align="left"><b>{evaluation.criterions[0].criterion_title}</b></th>
    </tr>
    {#each evaluation.criterions[0].criterions as item}
        <SummaryTableRow text1={item.title} rating={get_rating(0)} cmp={cmp} col3={false}/>
    {/each}
</table>
