<script>
import { get_evaluation,get_rating }  from "./../evaluation_data.js"
import { get_session } from "./../server_api.js"
import { page, CAPS5_cert_id } from './../stores.js';
import ContextController from './../ContextController.svelte';
import Title from './../ContentTitle.svelte';
import SummaryTable0 from './SummaryTable0.svelte';
import SummaryTable1 from './SummaryTable1.svelte';
import SummaryTable2 from './SummaryTable2.svelte';
import SummaryTable3 from './SummaryTable3.svelte';
import SummaryTable4 from './SummaryTable4.svelte';
import SummaryTable5 from './SummaryTable5.svelte';

var allow_page_change=false;
var summary;

$: {
    if(allow_page_change)
    {
        if($page.itemNum == 31)
        {
            document.getElementById("summaryPage1").style.display = "block";
            document.getElementById("summaryPage2").style.display = "none";
            document.getElementById("summaryPage3").style.display = "none";      
        }
        else if($page.itemNum == 32)
        {
            document.getElementById("summaryPage1").style.display = "none";
            document.getElementById("summaryPage2").style.display = "block";
            document.getElementById("summaryPage3").style.display = "none";      
        }
        else if($page.itemNum == 33)
        {
            document.getElementById("summaryPage1").style.display = "none";
            document.getElementById("summaryPage2").style.display = "none";
            document.getElementById("summaryPage3").style.display = "block";       
        }
    }
}

function cmp2(r)
{
    return r>=2;
}
function cmpYES(r)
{
    return r=="YES";
}
function cmp31(r)
{
    return get_rating(0)=="YES" && get_rating(22)=="YES" && summary[0].Sx >= 1 && summary[1].Sx >= 1 && summary[2].Sx >= 2 && summary[3].Sx >= 2 && summary[4].Sx >= 1;
}
function cmp32(r)
{
    return get_rating(29)!= null && get_rating(30) != null && summary[5].Sx != 0;
}
function cmp33(r)
{
    return get_rating(21)=="YES";
}
async function get_summary()
{
    let e = await get_evaluation($CAPS5_cert_id, get_session(),true);
    summary = e.summary;
}
</script>

<style lang="less">
#summaryContent
{
    height: 90%;
    overflow: auto;
    margin-bottom: 1%;
}
.summaryDiv
{
    position: relative;
    width: 100%;
    max-height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
}
.summaryTables
{
    position: relative;
    display: block;
    top: 35%;
    margin: 0 auto;
    text-align: center;
    border-radius: 2%;
    background: white;
    width: 100%;
    height: 25%;
}
#summaryPage1{display:block;}
#summaryPage2{display:none;}
#summaryPage3{display:none;}

</style>
<div id="summaryContent">
    <Title num={$page.itemNum}/>
    <div class="summaryDiv">
    <ContextController divID={"ctx_summary"}/>
    {#await get_summary() then e}
        <div class="summaryTables" id ="summaryPage1">
            <SummaryTable0 cmp={cmpYES}/> 
            <SummaryTable1 on:allow_page_change={e=>allow_page_change=true} id={"B"} ind={1} criterion_sx_title={"Sx(Sev≥2)?"} total_severity={"B Sev = "} criterion_severity={summary[0].Sev} total_sx={"#B Sx ="} criterion_sx={summary[0].Sx} cmp={cmp2}/> 
            <SummaryTable1 id={"C"} ind={2} criterion_sx_title={"Sx(Sev≥2)?"} total_severity={"C Sev = "} criterion_severity={summary[1].Sev} total_sx={"#C Sx ="} criterion_sx={summary[1].Sx} cmp={cmp2}/> 
            <SummaryTable1 id={"D"} ind={3} criterion_sx_title={"Sx(Sev≥2)?"} total_severity={"D Sev = "} criterion_severity={summary[2].Sev} total_sx={"#D Sx ="} criterion_sx={summary[2].Sx} cmp={cmp2}/>
        </div>         
        <div class="summaryTables" id ="summaryPage2">
            <SummaryTable1 id={"E"} ind={4} criterion_sx_title={"Sx(Sev≥2)?"} total_severity={"E Sev = "} criterion_severity={summary[3].Sev} total_sx={"#E Sx ="} criterion_sx={summary[3].Sx} cmp={cmp2}/> 
            <SummaryTable2 criterion_severity={summary[6].Sev} criterion_sx={summary[6].Sx}/> 
            <SummaryTable3 cmp={cmpYES} /> 
            <SummaryTable1 id={"G"} ind={7} criterion_sx_title={"Cx(Sev≥2)?"} total_severity={"G Sev = "} criterion_severity={summary[4].Sev} total_sx={"#G Cx ="} criterion_sx={summary[4].Sx} cmp={cmp2}/>
        </div>               
        <div class="summaryTables" id ="summaryPage3">
            <SummaryTable4/> 
            <SummaryTable1 id={"Dissociative"} ind={9} criterion_sx_title={"Sx(Sev≥2)?"} total_severity={"Diss Sev = "} criterion_severity={summary[5].Sev} total_sx={"#Diss Sx ="} criterion_sx={summary[5].Sx} cmp={cmp2}/> 
            <SummaryTable5 cmp31={cmp31} cmp32={cmp32} cmp33={cmp33}/> 
        </div>
    {/await}
    </div>
</div>
