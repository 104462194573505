<script context="module">
import {svg_show_elements,svg_reset_all_anms,svg_run_anms} from "./svg_helper.js"
var controlling_elements=["GRP_mic","GRP_hover","GRP_listening","GRP_success","GRP_restart","GRP_miccancel","GRP_restarthover"];
var svg_id="e3ix9ha1nk2e1";

export function mic_reset()
{
  	svg_show_elements(svg_id,controlling_elements,false);
	svg_show_elements(svg_id,["GRP_mic"],true);
	svg_reset_all_anms(svg_id);
}

export function mic_error()
{
  	svg_show_elements(svg_id,controlling_elements,false);
	svg_show_elements(svg_id,["GRP_miccancel"],true);
	svg_reset_all_anms(svg_id);
}

export function mic_listening()
{
	svg_show_elements(svg_id,controlling_elements,false);
	svg_show_elements(svg_id,["GRP_listening"],true);
	svg_reset_all_anms(svg_id);
	svg_run_anms(svg_id,["pulsering","pulsering_path","pulsecircleB","pulsecircleB_path","pulsecircleA"],true,false);
}

export function mic_hover()
{
  	svg_show_elements(svg_id,controlling_elements,false);
	svg_show_elements(svg_id,["GRP_hover"],true);
	svg_reset_all_anms(svg_id);
	svg_run_anms(svg_id,["hoveroutercircle","hoveroutercircle_path","hoverinnercircle","hoverinnercircle_path"],true,false);
}

export function mic_success()
{
  	svg_show_elements(svg_id,controlling_elements,false);
	svg_show_elements(svg_id,["GRP_success"],true);
	svg_reset_all_anms(svg_id);
	svg_run_anms(svg_id,["bloop","bluecircle","whitecircle","checkmark"],true,false);
}

export function mic_restart()
{
	svg_show_elements(svg_id,controlling_elements,false);
	svg_show_elements(svg_id,["GRP_restart"],true);
	svg_reset_all_anms(svg_id);
	svg_run_anms(svg_id,["restartarrow"],true,false);
}

export function mic_restartHover()
{
	svg_show_elements(svg_id,controlling_elements,false);
	svg_show_elements(svg_id,["GRP_restarthover"],true);
	svg_reset_all_anms(svg_id);
}
</script>