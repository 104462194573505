import sample_certificate_learn from "./sample_certificate_learn.json";
import sample_certificate_validate from "./sample_certificate_validate.json";
import sample_certificate_avm from "./sample_certificate_avm.json";
import { verbatim_cards, paraphrased_cards, clarifying_cards, patients_word_cards, clarified_frequency_cards, ptsd_symptoms_cards, initial_cards, highest_required_indices, irrelevant_cards } from "./rechat_card_info.js"
import { get_rechat_sequence,get_user_item_progress,get_rechat_tags,get_item_history,get_eval_correct_values,post_saved_evaluation,get_saved_evaluation,get_latest_eval_version } from "./server_api.js"
import { get } from 'svelte/store';
import { environment, sample_cert_id, game_mode } from "./stores.js";
import eval_captions_v1 from './eval_captions_v1.json';
import eval_captions_v2 from './eval_captions_v2.json';
import evaluation_avm from './evaluation_avm.json';
import evaluation_validate from './evaluation_validate.json';
import evaluation_learn from './evaluation.json';
import scoring_help_info_json from "./scoring_help_info.json";
let scoring_help_info = scoring_help_info_json.scoring_help_info;		


var evaluationFile;
var sample_certificate;
if(get(game_mode)=="learn")
{
  evaluationFile = evaluation_learn;
  sample_certificate = sample_certificate_learn;
}
else if(get(game_mode)=="validate")
{
  evaluationFile = evaluation_validate;
  sample_certificate = sample_certificate_validate;
}
else
{
  evaluationFile = evaluation_avm;
  sample_certificate = sample_certificate_avm;
}

var scores_history=[];
var correct_values=[];
var in_order=[];
var progress=[];
var tags;
var e={};

function score_reveal_total()
{
  let score_reveal_sum=0;
  for(let i in scoring_help_info)
    for(let j in scoring_help_info[i])
      score_reveal_sum++;
  return score_reveal_sum;
}

function calculate_overview_scores()
{
  e.distress_score=0;
  e.global_score=0;
  e.dissociative_score=0;
  e.ptsd_score=0;
  e.initial_severity_score=0;
  e.final_severity_score=0;
  e.trauma_relatedness_score=0;

  for(var i=1; i<=20; ++i)
  {
    for(let j in scores_history[i])
      if(scores_history[i][j].severity!=null)
      {
        e.initial_severity_score+=scores_history[i][j].severity;
        if(scores_history[i][j].severity_correct)
          e.ptsd_score++;
        break;
      }
    let final_ind =scores_history[i].length-1;
    if(final_ind>=0&&scores_history[i][final_ind].severity!=null)
      e.final_severity_score+=scores_history[i][final_ind].severity;
    if(correct_values[i]!=0)
      for(let j in scores_history[i])
        if(scores_history[i][j].trauma&&scores_history[i][j].trauma_correct)
          {
            e.trauma_relatedness_score++;
            break;
          }
  }
  if(scores_history[16]&&scores_history[16][0])
    if(!scores_history[16][0].trauma)
      e.trauma_relatedness_score++;

  for(let j in scores_history[23])
    if(scores_history[23][j].distress!=null)
    {
      if(scores_history[23][j].distress_correct)
        e.distress_score++;
      break;
    }
  for(let j in scores_history[24])
    if(scores_history[24][j].social_functioning!=null)
    {
      if(scores_history[24][j].social_functioning_correct)
        e.distress_score++;
      break;
    }
  for(let j in scores_history[25])
    if(scores_history[25][j].occupational_functioning!=null)
    {
      if(scores_history[25][j].occupational_functioning_correct)
        e.distress_score++;
      break;
    }

  for(let j in scores_history[26])
    if(scores_history[26][j].global_validity!=null)
    {
      if(scores_history[26][j].global_validity_correct)
        e.global_score++;
      break;
    }
  for(let j in scores_history[27])
    if(scores_history[27][j].global_severity!=null)
    {
      if(scores_history[27][j].global_severity_correct)
        e.global_score++;
      break;
    }
  for(let j in scores_history[28])
    if(scores_history[28][j].global_improvement!=null)
    {
      if(scores_history[28][j].global_improvement_correct)
        e.global_score++;
      break;
    }

  for(i=29;i<=30;i++)
  {      
    if(scores_history[i]&&scores_history[i][0])
      if(!scores_history[i][0].trauma)
        e.trauma_relatedness_score++;
    for(let j in scores_history[i])
      if(scores_history[i][j].severity!=null)
      {
        if(scores_history[i][j].severity_correct==1)
          e.dissociative_score++;
        break;
      }
  }
}

function get_rating_text(item_num,i,j,k,rating_id)
{
  if(scores_history[item_num][k]==null||scores_history[item_num][k][rating_id]==null||scores_history[item_num][k][rating_id]=="")
    return "";
  if(evaluationFile.scoring_info[item_num].other_scoring_info[i].scoring_info[j].lookup_id)
  {
    let text = evaluationFile[evaluationFile.scoring_info[item_num].other_scoring_info[i].scoring_info[j].lookup_id][scores_history[item_num][k][rating_id]-1];
    if(text && text!="")
      return " "+text;
    else 
      return "";
  }
  else
    return " "+scores_history[item_num][k][rating_id];
}

function get_correct_rating_text(item_num,i,j,rating_id)
{
  if(correct_values[item_num][rating_id]===null)
    return "";
  if(evaluationFile.scoring_info[item_num].other_scoring_info[i].scoring_info[j].lookup_id)
  {
    let text = evaluationFile[evaluationFile.scoring_info[item_num].other_scoring_info[i].scoring_info[j].lookup_id][correct_values[item_num][rating_id]-1];
    if(text)
      return " "+text;
    else 
      return "";
  }
  else
    return " "+correct_values[item_num][rating_id];
}

function fill_in_rating(item_num)
{
  if(evaluationFile.scoring_info[item_num]==null)
    return;
  let primary_rating_id=evaluationFile.scoring_info[item_num].primary_scoring_id;
  e.ratings[item_num]={"initial":evaluationFile.na,"correct":evaluationFile.na,"scored_correct":false,"time":0,"final":evaluationFile.na,"final_scored_correct":false};
  if(correct_values[item_num])
    if(evaluationFile.scoring_info[item_num].lookup_id)
      e.ratings[item_num].correct=evaluationFile[evaluationFile.scoring_info[item_num].lookup_id][correct_values[item_num].value];
    else
      e.ratings[item_num].correct=correct_values[item_num].value;
  if(progress && progress[item_num])
  {
    let item_seconds = progress[item_num].duration;
    e.ratings[item_num].time=item_seconds;
    e.course_time=e.course_time+item_seconds;
  }

  if(scores_history&&scores_history[item_num])
  {
    let initial_score_ind=0;
    for(let i in scores_history[item_num])
      if(scores_history[item_num][i][primary_rating_id]!=null)
      {
        if(evaluationFile.scoring_info[item_num].lookup_id)
          e.ratings[item_num].initial=evaluationFile[evaluationFile.scoring_info[item_num].lookup_id][scores_history[item_num][i][primary_rating_id]];
        else
          e.ratings[item_num].initial=scores_history[item_num][i][primary_rating_id];
        initial_score_ind=i;
        break;
      }
    let rating_id_correct = primary_rating_id + "_correct";
    let a = 0;
    for(let i=initial_score_ind; i<scores_history[item_num].length; i++)
      if(scores_history[item_num][i][rating_id_correct]!=1)
         a++;
    e.ratings[item_num].attempts=a;
    if(scores_history[item_num][initial_score_ind]) // if there was an initial score
      e.ratings[item_num].scored_correct=scores_history[item_num][initial_score_ind][rating_id_correct]==1?true:false;
    else //if there was no initial score then it could never be correct
      e.ratings[item_num].scored_correct=false;

    let final_score_ind = scores_history[item_num].length-1;
    if(final_score_ind>=0&&scores_history[item_num][final_score_ind]&&scores_history[item_num][final_score_ind][primary_rating_id]!=null) // if there was a score
    {
      if(evaluationFile.scoring_info[item_num].lookup_id)
        e.ratings[item_num].final=evaluationFile[evaluationFile.scoring_info[item_num].lookup_id][scores_history[item_num][final_score_ind][primary_rating_id]];
      else
        e.ratings[item_num].final=scores_history[item_num][final_score_ind][primary_rating_id];
      e.ratings[item_num].final_scored_correct=scores_history[item_num][final_score_ind][rating_id_correct]==1?true:false;
    }
  
    e.ratings[item_num].other_scoring_items=[];
    for(let i in evaluationFile.scoring_info[item_num].other_scoring_info)
    {
      let initial_score = "";
      let final_score = "";
      let correct = true;
      let final_correct = true;
      let correct_score_text = "";
      let initial_scored = false;
      let final_scored = false;
      for(let j in evaluationFile.scoring_info[item_num].other_scoring_info[i].scoring_info)
      {
        let rating_id = evaluationFile.scoring_info[item_num].other_scoring_info[i].scoring_info[j].id;
        let current_correct_score_text = get_correct_rating_text(item_num,i,j,rating_id);
        correct_score_text += current_correct_score_text;
        let rating_id_correct = rating_id + "_correct";
        let current_initial_score = get_rating_text(item_num,i,j,initial_score_ind,rating_id);
        let current_final_score = get_rating_text(item_num,i,j,final_score_ind,rating_id);
        initial_scored = initial_scored || (scores_history[item_num][initial_score_ind]!=null&&scores_history[item_num][initial_score_ind][rating_id]!=null);
        final_scored = final_scored || (scores_history[item_num][final_score_ind]!=null&&scores_history[item_num][final_score_ind][rating_id]!=null);

        if(evaluationFile.no_display_id.includes(rating_id))
        {
          initial_score += current_initial_score!=""?" ":evaluationFile.na;
          final_score += current_final_score!=""?" ":evaluationFile.na;
        }
        else
        {
          initial_score = initial_score + current_initial_score;
          final_score = final_score + current_final_score;
        }
        if(scores_history[item_num][initial_score_ind])
        {
          let current_correct = true;
          if(scores_history[item_num][initial_score_ind][rating_id_correct]!=undefined)
            current_correct = scores_history[item_num][initial_score_ind][rating_id_correct];
          correct = correct && scores_history[item_num][initial_score_ind] && current_correct;
        }
        else
        {
          if(!(current_correct_score_text==""&&current_initial_score==""))
            correct = false;
        }
        if(final_score_ind>=0)
        {
          let current_correct = true;
          if(scores_history[item_num][final_score_ind][rating_id_correct]!=undefined)
            current_correct = scores_history[item_num][final_score_ind][rating_id_correct];
          final_correct = final_correct && scores_history[item_num][final_score_ind] && current_correct;
        }
        else
          if(!(current_correct_score_text==""&&current_final_score==""))
            correct = false;
      }
      if(evaluationFile.scoring_info[item_num].other_scoring_info[i].no_yes_prefix)
      {
        let prefixes = evaluationFile[evaluationFile.scoring_info[item_num].other_scoring_info[i].no_yes_prefix];
        initial_score=(initial_scored?prefixes[initial_score==""?0:1]:"")+initial_score;
        final_score=(final_scored?prefixes[final_score==""?0:1]:"")+final_score; 
        correct_score_text=prefixes[correct_score_text==""?0:1]+correct_score_text; 
      }

      if(initial_score=="")
        initial_score=evaluationFile.na;
      if(final_score=="")
        final_score=evaluationFile.na;
      if(correct_score_text=="")
        correct_score_text=evaluationFile.na;

      e.ratings[item_num].other_scoring_items.push({"initial":initial_score,"correct":correct_score_text,"scored_correct":correct,"final":final_score,"final_scored_correct":final_correct,"caption":evaluationFile.scoring_info[item_num].other_scoring_info[i].caption});
    }
  }
}

function init_ratings()
{
  e.ratings=[];
  e.course_time=0;
  for(var i=0;i<=30;i++)
    fill_in_rating(i);
}

function sum_criterion_ratings(start, end)
{
  var s={Sev:0,Sx:0};
	if(e && e.ratings)
	{
    if(e.version==1)
      for(let i = start; i<=end; i++)
      {
        if(e.ratings[i].initial>=0)
          s.Sev+=e.ratings[i].initial;
        if(e.ratings[i].initial>=2)
          s.Sx++;
      }
    else
      for(let i = start; i<=end; i++)
      {
        if(e.ratings[i].final>=0)
          s.Sev+=e.ratings[i].final;
        if(e.ratings[i].final>=2)
          s.Sx++;
      }
  }
  return s;
}

function init_summary()
{
	e.summary=[];
//XSev - accumulates all the severety values in a criterion B[1,5], C[6,7], D[8,14], E[15,20],(1-20) G[23,25], Diss[29,30], sevTotal = BSev + CSev + DSev + ESev; sxTotal = BSx + CSx + DSx + ESx;
//XSx - counts the values in a criterion that are over certain threshould >= 2
  e.summary[0]=sum_criterion_ratings( 1, 5 );
  e.summary[1]=sum_criterion_ratings( 6, 7 );
  e.summary[2]=sum_criterion_ratings( 8, 14 );
  e.summary[3]=sum_criterion_ratings( 15, 20 );
  e.summary[4]=sum_criterion_ratings( 23, 25 );
  e.summary[5]=sum_criterion_ratings( 29, 30 );
  e.summary[6]={Sev:0,Sx:0};
	e.summary[6].Sev=e.summary[0].Sev+e.summary[1].Sev+e.summary[2].Sev+e.summary[3].Sev;
	e.summary[6].Sx=e.summary[0].Sx+e.summary[1].Sx+e.summary[2].Sx+e.summary[3].Sx;
}

function fill_in_A_attempts(rating_name)
{
  e.A_attempts=[0,0,0,0,0];
  for(let i in scores_history[0])
  {
    if(scores_history[0][i].exposure_experienced_correct==null||scores_history[0][i].exposure_witnessed_correct==null||
    scores_history[0][i].exposure_learned_correct==null||scores_history[0][i].exposure_aversive_correct==null)
      e.A_attempts[0]++;
    if(scores_history[0][i].life_threatened_self_correct==null||scores_history[0][i].life_threatened_other_correct==null)
      e.A_attempts[1]++;
    if(scores_history[0][i].serious_injury_self_correct==null||scores_history[0][i].serious_injury_other_correct==null)
      e.A_attempts[2]++;
    if(scores_history[0][i].sexual_violence_self_correct==null||scores_history[0][i].sexual_violence_other_correct==null)
      e.A_attempts[3]++;
    if(scores_history[0][i].criteria_met_correct==null)
      e.A_attempts[4]++;
  }
}

function process_in_order()
{
  if(in_order == null)
    in_order = {}
  for(var i=0;i<=30;i++)
    if(in_order[i]==null)
      in_order[i]={"valid":0,"tally":0};
  for(i=26;i<=28;i++)
    in_order[i].valid=0;
}

function calculate_correct_total_severity()
{
  e.correct_total_severity=0;
  for(var i=1;i<=20;i++)
    if(correct_values[i])
      e.correct_total_severity+=correct_values[i].value;
  e.severity_range=Math.round(e.correct_total_severity/10);
  e.double_severity_range=Math.round(e.correct_total_severity/5);
}

function calculate_tags_ratings(eval_captions)
{
  e.introduction=false;
  e.verbatim_prompts=0;
  e.paraphrased_prompts=0;
  e.unnecessary_prompts=0;
  e.off_script_prompts=0;
  e.assumed_info=0;
  e.required_prompts=0;
  e.required_prompts_total=0;
  e.required_prompts_used=0;
  e.total_items_in_order=0;
  e.clarifying_prompts=0;
  e.total_clarifying_prompts=0;
  e.total_acceptable_description=0;
  e.acceptable_desciption=0;
  e.clarified_frequency=0;
  e.total_clarified_frequency=0;
  e.ptsd_substitutes=0;
  e.yes_no=0;
  e.total_ptsd_substitutes=0;
  e.admin_details=[];
  e.score_reveal=0;
  e.score_reveal_total=score_reveal_total();
  e.items_with_prompts=0;

  for(var i=0; i<=30; i++)
  {
    var total_item_verbatim=0;
    if(verbatim_cards[i]!=null)
      total_item_verbatim=verbatim_cards[i];
    var item_verbatim_used = 0;
    var item_entry_verbatim_used=0;
    var total_item_paraphrased=0;
    if(paraphrased_cards[i]!=null)
      total_item_paraphrased=paraphrased_cards[i];
    var item_paraphrased_used = 0;
    var total_item_entry_paraphrased=0;
    if(initial_cards[i]!=null)
      total_item_entry_paraphrased=initial_cards[i]-1;
    var item_entry_paraphrased_used=0;
    var item_off_script_used = 0;
    var item_irrelevant_used=0;
    var all_required_prompts=false;
    var total_item_clarifying=0;
    if(clarifying_cards[i]!=null)
      total_item_clarifying=clarifying_cards[i];
    var item_clarifying_used=0;
    var item_yes_no=0;
    if(patients_word_cards[i]!=null)
      e.total_acceptable_description+=patients_word_cards[i];
    if(clarified_frequency_cards[i]!=null)
      e.total_clarified_frequency+=clarified_frequency_cards[i];
    if(ptsd_symptoms_cards[i]!=null)
      e.total_ptsd_substitutes+=ptsd_symptoms_cards[i];
    var item_ptsd_substitute=0;
    var item_initial_used=0;
    var item_past_month_not_start_used=0;

    if(tags)
      for(let j in tags[i])
      {
        if(i==0&&tags[0][j].introduction)
          e.introduction = true;

        if(tags[i][j].yes_no)
          item_yes_no++
        if(tags[i][j].verbatim)
        {
          item_verbatim_used++;
          if(tags[i][j].initial)
            item_entry_verbatim_used++;
        }
        if(tags[i][j].paraphrased)
        {
          item_paraphrased_used++;
          if(tags[i][j].initial)
            item_entry_paraphrased_used++;
        }
        if(tags[i][j].irrelevant)
          item_irrelevant_used++;
        if(tags[i][j].off_script)
          item_off_script_used++;
        if(tags[i][j].clarifying)
          item_clarifying_used++;
        if(tags[i][j].assuming)
          e.assumed_info++;
        if(tags[i][j].patients_words)
          e.acceptable_desciption++;
        if(tags[i][j].clarified_frequency)
          e.clarified_frequency++;
        if(tags[i][j].ptsd_symptoms)
          item_ptsd_substitute++;
        if(tags[i][j].past_month_not_start)
          item_past_month_not_start_used++;
        if(tags[i][j].initial)
          item_initial_used++;
        if(tags[i][j].score_reveal)
          e.score_reveal++;
      }

    if(i>20&&i<26)
      if(item_ptsd_substitute>0)
        e.ptsd_substitutes+=item_ptsd_substitute;
      else
        if(!(item_off_script_used||item_initial_used||item_past_month_not_start_used))
          e.ptsd_substitutes++;

    if(item_yes_no>0)
      e.yes_no++;
    if(item_irrelevant_used>0)
      e.unnecessary_prompts++;

    if(total_item_verbatim)
      if(item_verbatim_used==total_item_verbatim)
        e.verbatim_prompts++;
    if(item_paraphrased_used>0)
      e.paraphrased_prompts++;
    if(item_off_script_used>0)
      e.off_script_prompts++;
    var item_required_prompts=in_order[i].tally;
    e.required_prompts_used+=item_required_prompts;
    e.admin_details[i]={};
    e.admin_details[i].num_required_prompts=0;
    if(highest_required_indices[i] == undefined)
      all_required_prompts = true;
    else 
    {
      e.items_with_prompts++;
      if(item_required_prompts>=(highest_required_indices[i]+1))
      {
        all_required_prompts=true;
        e.required_prompts++;
        if(in_order[i].valid==1)
          e.total_items_in_order++;
      }
      e.required_prompts_total+=highest_required_indices[i]+1;
      e.admin_details[i].num_required_prompts=highest_required_indices[i]+1;
    }
    e.admin_details[i].required_prompts_asked=item_required_prompts;
    if(all_required_prompts)
      e.admin_details[i].required_prompts=eval_captions.Yes;
    else
      e.admin_details[i].required_prompts=eval_captions.No;
    e.total_clarifying_prompts+=total_item_clarifying;
    e.clarifying_prompts+=item_clarifying_used;
    if(total_item_clarifying==0)
      e.admin_details[i].clarifying_prompts=eval_captions.NA;
    else
      if(item_clarifying_used)
        e.admin_details[i].clarifying_prompts=eval_captions.Yes;
      else
        e.admin_details[i].clarifying_prompts=eval_captions.No;
    if(item_entry_verbatim_used>0)
      e.admin_details[i].entry_prompt=eval_captions.Verbatim;
    else if(item_entry_paraphrased_used>0)
      e.admin_details[i].entry_prompt=eval_captions.NotVerbatim;
    else
      e.admin_details[i].entry_prompt=eval_captions.NotUsed;

    if(total_item_verbatim-1<=0)
      e.admin_details[i].other_verbatim=eval_captions.NA;
    else
      e.admin_details[i].other_verbatim=String(item_verbatim_used-item_entry_verbatim_used)+eval_captions.of+String(total_item_verbatim-1);//all item shave 1 inital+verbatim

    if((total_item_paraphrased-total_item_entry_paraphrased)<=0)
      e.admin_details[i].other_paraphrased=eval_captions.NA;
    else
      e.admin_details[i].other_paraphrased=String(item_paraphrased_used-item_entry_paraphrased_used)+eval_captions.of+String(total_item_paraphrased-total_item_entry_paraphrased);

    e.admin_details[i].off_script=item_off_script_used;

    if(irrelevant_cards[i]&&irrelevant_cards[i]!=0)
      e.admin_details[i].unnecessary=item_irrelevant_used;
    else
      e.admin_details[i].unnecessary=eval_captions.NA;

    if(in_order[i].valid)
      e.admin_details[i].in_order=eval_captions.Yes
    else
      e.admin_details[i].in_order=eval_captions.No
  }
}

//calculate correct_time_distress and correct_time_PTSD
function calculate_PTSD_ratings(evaluationFile)
{
  e.correct_time_distress=0;
  e.correct_time_PTSD=0;
  e.clarified_time_distress=0;
  e.clarified_time_PTSD=0;
  if(tags)
    for(let i in evaluationFile.criterions)
      if(evaluationFile.criterions[i].id=="G")
      {
        for(let k in evaluationFile.criterions[i].criterions)
        {
          var itemNum = evaluationFile.criterions[i].criterions[k].itemNum;
          for(let j in tags[itemNum])
            if(tags[itemNum][j].past_month && tags[itemNum][j].past_month==true)
            {
                e.correct_time_distress++;
                break;
            }
          for(let j in tags[itemNum])
            if((tags[itemNum][j].past_month && tags[itemNum][j].past_month==true)||(tags[itemNum][j].past_month_not_start && tags[itemNum][j].past_month_not_start==true ))
            {
                e.clarified_time_distress++;
                break;
            }
        }
      }
      else
        if(evaluationFile.criterions[i].id=="B" ||evaluationFile.criterions[i].id=="C" ||evaluationFile.criterions[i].id=="D" || evaluationFile.criterions[i].id=="E")
        {
          for(let k in evaluationFile.criterions[i].criterions)
          {
            var itemNum = evaluationFile.criterions[i].criterions[k].itemNum;
            for(let j in tags[itemNum])
              if(tags[itemNum][j].past_month && tags[itemNum][j].past_month==true)
              {
                e.correct_time_PTSD++;
                break;
              }
            for(let j in tags[itemNum])
              if((tags[itemNum][j].past_month && tags[itemNum][j].past_month==true)||(tags[itemNum][j].past_month_not_start && tags[itemNum][j].past_month_not_start==true ))
              {
                e.clarified_time_PTSD++;
                break;
              }
          }
        }
}

//uses tags
function calculate_ratings(evaluationFile,eval_captions)
{
	calculate_tags_ratings(eval_captions);
	calculate_PTSD_ratings(evaluationFile);
}

function calculate_evaluation(evaluationFile,eval_captions)
{
	calculate_overview_scores();
	init_ratings();
	init_summary();
	fill_in_A_attempts();
	process_in_order();
	calculate_correct_total_severity();
	calculate_ratings(evaluationFile,eval_captions); // must be done afer process_in_order
}

async function get_data_for_evaluation(certificate_id, session)
{
	if(get(environment) === "development" && certificate_id==get(sample_cert_id))
	{
		scores_history = sample_certificate.scores_history;
    correct_values = sample_certificate.correct_values;
		in_order=sample_certificate.in_order;
		//tags = sample_certificate.prompts_considerations;
		//tags = sample_certificate.prompts;
		tags = sample_certificate.tags;
    progress = sample_certificate.progress;
    e.version = sample_certificate.version;
	}
	else
	{
    scores_history = await get_item_history(-1,session);
    correct_values = await get_eval_correct_values();
		in_order = await get_rechat_sequence("patient",session);
		tags = await get_rechat_tags(session);
		progress = await get_user_item_progress(session);
	}
}

export async function get_evaluation(certificate_id,session,forceRecalculation =false)
{
  var current_e_version = await get_latest_eval_version();
  var evaluation_data;
  if(!forceRecalculation)
     evaluation_data = await get_saved_evaluation(session);
	if(evaluation_data==null)
	{
		e.version = current_e_version;
		await get_data_for_evaluation(certificate_id,session); 
		calculate_evaluation(evaluationFile,e.version==1?eval_captions_v1:eval_captions_v2);
		e.tags=tags;
		if(certificate_id!=get(sample_cert_id))
      await post_saved_evaluation(e);
	}
	else
		e = evaluation_data.evaluation;
	return e;
}
export function get_rating(ind)
{
  if(e.version==1)
  {
    if( e && e.ratings && e.ratings[ind] && e.ratings[ind].initial!=null )
      return e.ratings[ind].initial;
    else
      return 'null';
  }
  else
  {
    if( e && e.ratings && e.ratings[ind] && e.ratings[ind].final!=null )
      return e.ratings[ind].final;
    else
      return 'null';
  }
}
export function get_summary_value(i,j)
{
	if(e && e.summary && e.summary[i][j])
		return e.summary[i][j];
  else
    return "";
}


