const criteria_item_translation =
{
  A:0,
  B1:1,
  B2:2,
  B3:3,
  B4:4,
  B5:5,
  C1:6,
  C2:7,
  D1:8,
  D2:9,
  D3:10,
  D4:11,
  D5:12,
  D6:13,
  D7:14,
  E1:15,
  E2:16,
  E3:17,
  E4:18,
  E5:19,
  E6:20,
  F21:21,
  F22:22,
  G23:23,
  G24:24,
  G25:25,
  GL26:26,
  GL27:27,
  GL28:28,
  DI29:29,
  DI30:30
};

const rechat_item_translation = {
  "A":0,
  "Item 1 (B1)":1,
  "Item 2 (B2)":2,
  "Item 3 (B3)":3,
  "Item 4 (B4)":4,
  "Item 5 (B5)":5,
  "Item 6 (C1)":6,
  "Item 7 (C2)":7,
  "Item 8 (D1)":8,
  "Item 9 (D2)":9,
  "Item 10 (D3)":10,
  "Item 11 (D4)":11,
  "Item 12 (D5)":12,
  "Item 13 (D6)":13,
  "Item 14 (D7)":14,
  "Item 15 (E1)":15,
  "Item 16 (E2)":16,
  "Item 17 (E3)":17,
  "Item 18 (E4)":18,
  "Item 19 (E5)":19,
  "Item 20 (E6)":20,
  "Item 21":21,
  "Item 22":22,
  "Item 23":23,
  "Item 24":24,
  "Item 25":25,
  "Item 26":26,
  "Item 27":27,
  "Item 28":28,
  "Item 29 (1)":29,
  "Item 30 (2)":30,
  "All":"All" 
}

const item_criteria_translation = {}
//dynamically create  item_criteria from criteria_item
Object.keys(criteria_item_translation).forEach(function(criteria){
  var integer = parseInt(criteria.replace(/\D+/g, ''));
  var criteriaString =  criteria.replace(/\d+/g, '');
  var obj  = {criteria:criteriaString};

  if(!isNaN(integer))
  {
    obj.number = integer;
  }
  item_criteria_translation[criteria_item_translation[criteria]] = obj;
});

var numItems = Object.keys(criteria_item_translation).length;

export function get_criteria_for_item(item)
{
  if(item>=numItems)
  {
    return {criteria:"Summary",number:item};
  }
  return item_criteria_translation[item] ? item_criteria_translation[item] : {criteria:null};
}

export function get_item_for_criteria(criteria)
{
  return criteria_item_translation[criteria];
}

export function get_item_for_rechat(rechat)
{
  return rechat_item_translation[rechat];
}

export function get_full_item_name_from_item_num(item)
{
  var rechat_items = Object.keys(rechat_item_translation);
  console.log(rechat_items);
  console.log(item);
  for(var i = 0; i!=rechat_items.length; ++i)
  {
    if(rechat_item_translation[rechat_items[i]] == item)
    {
      return rechat_items[i];
    }
  }
  return undefined;
}

export function get_programmable_item_name_from_item_num(item)
{
  var rechat_items = Object.keys(rechat_item_translation);
  for(var i = 0; i!=rechat_items.length; ++i)
  {
    if(rechat_item_translation[rechat_items[i]] == item)
    {
      var item = rechat_items[i];
      item = item.replace(/\(|\)/g,"");
      item = item.replace(/\s/g,"_");
      return item;
    }
  }
  return undefined;
}