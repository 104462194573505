<script>
export let class_span;
export let value;
export let text;
</script>

<style lang="less">

label
{
    font-size: .75vw;
    display: inline-block;    
}
.leftLabel
{
    margin-right: 1vw;
}

.cls-1
{
    fill:none;
    stroke:black;
    stroke-miterlimit:10;
    stroke-width:0.75px;
}

path
{
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
    animation: draw .1s ease-out forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
svg
{
    position: relative;
    width: 1.5vw;
    height: 1.5vw;
    left: -0.5vw;
    top: .05vw;
}
[type=radio]
{ 
    position: absolute;
    cursor: pointer;
    opacity: 0;
    width: 0;
    height: 0;
}

[type=radio]:checked + .svg-container{
  display: inline-block;
}
.svg-container
{
    position: absolute;
    display: none;
    width: 5%;
    height: 5%;
}
#svg_id
{
    left: -0.25vw;
    top: -2.65vw;
    width: 3vw;
    height: 6vw;
}
@media all and (max-aspect-ratio: ~"3/2")
{ 
  label
  {
      font-size: 7px;
  }

}
</style>
<span class="{class_span}">
    <label>
        <input type="radio" name="severity" value={value}>               
        <div class="svg-container">
            <svg id="svg_id" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.85 21.57"><defs></defs><title>minimal</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_2-2" data-name="Layer 2-2"><path class="cls-1" d="M22,16.48a37.27,37.27,0,0,1-8.18.61c-3.92-.15-9.11-.32-11.91-3.34a4.83,4.83,0,0,1-1.5-3C.27,6,12.12.11,23.3.38c7.49.19,18.49,3.23,19.14,8.41C43,13.35,35.61,19.25,23.66,21.2"/></g></g></g></svg>
        </div>               
        {text}
    </label>          
</span>
