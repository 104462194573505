<script>
  function close_modal()
  {
    let page = document.getElementById("evaluation-modal");
    let modal = document.getElementById("modalDiv");
    page.style.display = "none";
    modal.style.display = "none";
  }
</script>
  
  
<style>
  #evaluation-modal
  {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    z-index: 1250;
    background-color: grey;
    opacity: 0.5;
  }
  #modalDiv
  {
    display: block;
    background: white;
    z-index: 1300;
    border: 3px solid #7092a6;
    border-radius: 8px;
    position: fixed;
    width: 25vw;
    height: 10vw;
    top: 16vw;
    left: 40vw;
  }
  #modalParagraph
  {
    position: relative;
    font-size: 1vw;
    color: #7092a6;
    font-weight: bold;
    text-align: center;
  }
  #modalBtnDiv
  {
    position: relative;
    padding: 0.15vw 1.5vw 1.5vw 1.5vw;
  }
  .recordConfirm
  {
    width: 8vw;
    margin-right: auto;
    margin-left: auto;
    height: 2vw;
    display: block;
    background: #7092a6;
    background-image: linear-gradient(0deg, #557C93 0%, #7092a6 30%);
    color: white;
    font-family: Lato;
    font-size: 1vw;
    font-weight: 700;
    letter-spacing: 1px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: .5vw;
    text-align: center;
  }
  .recordConfirm:focus,
  .recordConfirm:hover{
    background: white;
    border: 2px solid #7092a6;
    color: #7092a6;
  }
  .recordConfirm:focus
  {
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none;
  }
</style>

<div>
  <div id="evaluation-modal"></div>
  <div id="modalDiv">
    <p id="modalParagraph">
      You will not be able to view your evaluation again after leaving this screen. Please use the Print buttons at the top of your screen to preserve your evaluation by printing it or saving to a PDF.
    </p>
    <div id="modalBtnDiv">
      <button class="recordConfirm" on:click={close_modal} aria-label="Ok" data-cy="OkButton">OK</button>
    </div>
  </div>
</div>