export let verbatim_cards = {};

export let paraphrased_cards = {};

export let clarifying_cards = {};

export let patients_word_cards = {};

export let clarified_frequency_cards = {};

export let ptsd_symptoms_cards = {};

export const initial_cards = {};

export const irrelevant_cards = {};

export let highest_required_indices = {};

export const off_script_cards = {};