import deepmerge from 'deepmerge'
let saved_states = {};

export function set_save_state(item,session,obj,options={})
{
    if(item == null || item == undefined)
        throw "No criteria specified for saving";
    if(saved_states[item] && saved_states[item][session])
        saved_states[item][session] = deepmerge(saved_states[item][session],obj,options);
    else
    {
        if(!saved_states[item])
        saved_states[item] = {};
        if(!saved_states[item][session])
        saved_states[item][session] = obj;
    }
}

export function get_saved_state(item,session)
{
    if(saved_states[item])
    {
        return saved_states[item][session];
    }
    else
        return undefined;
}





