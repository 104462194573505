<script context="module">
import { post_addtl_state_data, get_session } from './server_api.js';
import { get_saved_state } from "./saved_states.js";
import { add_transcript_dialogue } from './TranscriptAddDialogue.svelte';
import {scroll_transcript} from './Transcript.svelte';

export async function save_transcript(page,character,char_dialogue, character_dir, card_name )
{
	if( page < 0 || page > 30)
		return;
  	var timestamp = (new Date().getTime())/1000; // unix time is in seconds
	var state = get_saved_state(page,get_session());
	let trans;
	if(state && state.addtl_data && state.addtl_data.transcript)
	{
		trans = state.addtl_data.transcript; // [...dialogues]
		trans.push({character:character,char_dialogue:char_dialogue, character_dir:character_dir, card_name:card_name, timestamp: timestamp});
	}
	else
	{
		trans = [{character:character,char_dialogue:char_dialogue, character_dir:character_dir, card_name:card_name, timestamp: timestamp}];
	}
	await post_addtl_state_data({transcript:trans},page,{ arrayMerge: (dest,source,opts)=>{ return source;}});   
}

export async function reset_transcript()
{
	var transcriptContainer = document.getElementById("transcriptContainer");
	transcriptContainer.innerHTML = "";
}
export async function set_transcript(page, session)
{
	if( page < 0 || page > 30)
		return;
	for(let i = 0; i<=page; i++)
	{
		let state = get_saved_state(i,session);
		if(state && state.addtl_data && state.addtl_data.transcript)
		{
			console.log(state.addtl_data.transcript);
			for(let transObj of state.addtl_data.transcript)
			{
				add_transcript_dialogue(i, transObj.character, transObj.char_dialogue, transObj.character_dir, transObj.card_name );
				scroll_transcript();	
			}
		}
	}
}
</script>
<style lang="less">
	
</style>
	
