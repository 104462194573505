<script>
  import Evaluation_v1v2 from "./Evaluation_v1v2.svelte";
  import Evaluation_v3 from "./Evaluation_v3.svelte";
  import { get_evaluation }  from "./evaluation_data.js"
  import { createEventDispatcher } from "svelte";
  import { onMount } from "svelte";

  export let user;
  export let certDate;
  export let certID;
  export let certSession;

  const dispatch = createEventDispatcher();

  function closeCert() {
    console.log('closing from Evaluation!')
    dispatch('close')
  }

  let e = {};
  let onMountFinished = false;

  onMount(async function () {
    // The different e.versions correspond to different evaluation data formats
    // In the previous iteration, evaluations handled version 1 and version 2 data differently
    // The versions appended to the file names for Evaluation_v#.svelte indicate updates to the front end stylings
    // In Evaluation v3, the evaluation still uses version 2 data, so:
    // Version 1: Use Evaluation_v1v2
    // Version 2: Use Evaluation_v3
    e = await get_evaluation(certID, certSession)
    onMountFinished = true;
  })
</script>

{#if e.version === 1 && onMountFinished}
  <Evaluation_v1v2 user={user} certDate={certDate} certID={certID} certSession={certSession} e={e} on:close={closeCert} />
{:else if onMountFinished}
  <Evaluation_v3 user={user} certDate={certDate} certID={certID} certSession={certSession} e={e} on:close={closeCert} />
{/if}
