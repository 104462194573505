
export function svg_show_elements(svg_id,element_ids,show)
{
	let state = show?"block":"none";
	let svg_doc=document.getElementById(svg_id);
	if(svg_doc!=null)
		element_ids.forEach(id => {
			let element=svg_doc.getElementById(id);
			if(element!=null)
				element.style.display=state;	
		});
}

export function svg_reset_all_anms(svg_id)
{
	var svg_doc=document.getElementById(svg_id);
	if(svg_doc!=null)
	{
		var svg_style=svg_doc.getElementsByTagName("style");
		for(let i = 0; i < svg_style[0].sheet.rules.length; i++)
		{
			let style = svg_style[0].sheet.rules[i].style;
			if(style && style.animationName!="")
			{
				let element=svg_doc.getElementById(svg_style[0].sheet.rules[i].selectorText.substring(1));
				if(element!=null)
				{
					let newone = element.cloneNode(true);
					element.parentNode.replaceChild(newone, element);
					newone.style.animationPlayState = "paused";
				}
			}
		}
	}
}

export function svg_run_anms(svg_id,anm_names,run,reset)
{
	var svg_doc=document.getElementById(svg_id);
	if(svg_doc)
	{
		var svg_style=svg_doc.getElementsByTagName("style");
		if(svg_style!=null)
			for(let i = 0; i < svg_style[0].sheet.rules.length; i++)
			{
				let text = svg_style[0].sheet.rules[i].selectorText;
				if(text && anm_names.includes(text.substring(1)))
				{
					let element=svg_doc.getElementById(text.substring(1));
					if(element)
					{
						element.style.animationPlayState = run?"running":"paused";
						if(reset)
						{		
							let newone = element.cloneNode(true);
							element.parentNode.replaceChild(newone, element);
						}
					}
				}
			}
	}
}


