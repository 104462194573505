
let shortInputHandicap=4.5;
let closeWeightPhrase =0.25;
let closeWeightWords = 1.5;
let closeMinWeight = 10;
let closeMaxWeight = 1;
let closeLengthWeight = .6;
let minWeight = 1;
let weightPhrase = .3;
let weightWord = .85;
let maxWeight = 1;
let lengthWeight = .4;


export function createMatchStruct(value = '')
{
    var handicap = 0 ;
    var originalWordCount = value.split(' ').length;
    var modified = value.toLowerCase();
    modified = modified.replace(/[^0-9a-zÀ-ÿ\s]/gi,"");
    if(originalWordCount <= 3)
    {
        handicap = shortInputHandicap;
    }
    var splitInput = modified.split(' ');
    var len = 0;
    splitInput.forEach(element => {
        len += element.length;
    });
    var returnValue = {
        handicap: handicap,
        endLength: len,
        originalNumWords: originalWordCount,
        inputArray: splitInput
    }
    return returnValue;
}
function lavenshtein(a_array = [], b_array = [])
{
    console.assert(a_array.length>=0);
    console.assert(b_array.length>=0);
    var v = [Array.apply(null, Array(b_array.length+1)).map(function (x, i) { return i; }),Array.apply(null, Array(b_array.length+1)).map(function (x,i) { return 0})];
    var q = 0;
    var i,a,b;
    for(i=0,a=0,b=0; i!=a_array.length; ++i,++a,q=+!q)
    {
        var v0j_array = v[q];
        var v1j_array = v[+!q];
        v1j_array[0] = i+1;
        var bi_array = b_array;
        var j,bi,v0j,v1j;
        for(j=0, bi=0, v0j=0, v1j=0; j != b_array.length; j++, ++v0j, ++v1j, ++bi)
        {
            v1j_array[v1j+1] = Math.min(v1j_array[v1j]+1, v0j_array[v0j+1]+1, v0j_array[v0j] + (a_array[a]!=bi_array[bi]));
        }
    };
    var d = v[q][v[q].length-1];
    console.assert(d>=Math.abs(a_array.length-b_array.length));
    console.assert(d<=Math.max(a_array.length,b_array.length));
    return d;

}
function findTotalDistance(outer_input, inner_input)
{
    var total = 0;
    outer_input.inputArray.forEach( outer_element => {
        var best_dist = outer_input.endLength;
        var i;
        for(i=0;i!=inner_input.inputArray.length; ++i)
        {   
            var inner_element = inner_input.inputArray[i];
            var dist = lavenshtein(outer_element,inner_element); //lavenshtein stuff here
            if(dist<best_dist)
                best_dist = dist;
             if(dist==0)
                 break;
        }
        total += best_dist;
    });
    return total;
}

function valueWords(user_input, model_input)
{
    var total = 0;
    if(model_input.inputArray.length < user_input.inputArray.length)
    {
       total = findTotalDistance(model_input,user_input);
    }
    else
    {
        total = findTotalDistance(user_input,model_input);
    }
    return total;
}

function valueLength(user_input, model_input)
{
    return Math.abs(model_input.endLength - user_input.endLength);
}

export function evaluateMatch(user_input, model_input)
{
    if(user_input.inputArray.length == 1)
    {
        if(model_input.inputArray.length>1)
        {
            return Number.MAX_VALUE;
        }
        else if( valueWords(user_input,model_input) > 0)
        {
            return Number.MAX_VALUE;
        }
    }
    if(model_input.inputArray.length == 1)
    {
        if(user_input.inputArray.length>1)
        {
            return Number.MAX_VALUE;
        }
        else if( valueWords(user_input,model_input) > 0)
        {
            return Number.MAX_VALUE;
        }
    }
    if( Math.abs(user_input.originalNumWords - model_input.originalNumWords)<=2 && (user_input.originalNumWords<5||model_input.originalNumWords<=5))
    {
        var vp = lavenshtein(model_input.inputArray,user_input.inputArray)*closeWeightPhrase;
        var vw = valueWords(user_input,model_input)*closeWeightWords;
        return Math.min(vp,vw)*closeMinWeight+Math.max(vp,vw)*closeMaxWeight+valueLength(user_input,model_input)*closeLengthWeight+Math.max(user_input.handicap,model_input.handicap);
    }
    else
    {
        var vp = lavenshtein(model_input.inputArray,user_input.inputArray)*weightPhrase;
        var vw = valueWords(user_input,model_input)*weightWord;
        return Math.min(vp,vw)*minWeight+Math.max(vp,vw)*maxWeight+valueLength(user_input,model_input)*lengthWeight+Math.max(user_input.handicap,model_input.handicap);
    }
}
