<script>
    import { onMount, onDestroy } from 'svelte'
    import Title from './ContentTitle.svelte';
    import Content from './Content.svelte';
    import ContentSymptoms from './ContentSymptomCount.svelte';
    import Severity from './Severity.svelte';
    import { item_page_finished_mount } from './stores.js';
    import {add_criterion_div} from './Transcript.svelte';
    import { post_user_item_progress_viewed, post_user_item_progress_viewing, get_user_item_progress, get_session } from './server_api.js';
    export let num = 0;

    onMount( async function(){ 
      $item_page_finished_mount = false;
      await post_user_item_progress_viewing(num);  
      $item_page_finished_mount = true;
    });
    onDestroy( async function(){ post_user_item_progress_viewed(num);});
</script>

<style lang="less">
	#interactiveContent
	{
    display: flex;
    justify-content: space-between;
  }
  #contentContainer
  {
    height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 1%;
  }
</style>
<div id="contentContainer">
  <Title num={num}/>
  <div id="interactiveContent" data-cy='interactiveContent'>
      <Content num={num}/>
      {#if num === 21 || num === 22}
          <ContentSymptoms num={num}/>
      {:else}
          <Severity num={num}/>
      {/if}
  </div>  
</div>