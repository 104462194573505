<script>
  import IconCaretToggle from "./IconCaretToggle.svelte";
  import { patient_asset_path } from './stores.js';	

  export let rating = {};
  export let hideAllItems = true;

  let hidden = true;

  function showEval() {
    hidden = !hidden;
  }

  function showAll(hide) {
    hidden = hide;
  }

  $: showAll(hideAllItems)
</script>

<style>
  .explanation-text {
    margin-left: 5px;
  }
  .explanation-sub-title {
    font-weight: bold;
  }
  .explanation-sub-text {
    font-size: 13px;
  }
  .column2CriterionA {
    min-width: auto;
    flex-grow: 0;
  }
  .column4CriterionA {
    max-width: 250px;
    min-width: 130px;
    flex-grow: 0;
  }
  .lastColumnCriterionA {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 170px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
</style>

<div class="peritemdiv">
  <div class="peritemheaderdiv">
    <IconCaretToggle on:click={showEval} {hidden} />
    <div class="peritemtitle">&nbsp;{rating.item.title}</div>
  </div>
  <div class="grid">
    <div class="topsection">
      <div class="column firstcolumn">
        <div class="cell"></div>

        <div class="cell">
          <div class="celltitle">Your Scoring</div>
        </div>
        <div class="cell">
          <div class="celltitle">Correct Score</div>
        </div>
      </div>

      <div class="column column2" class:column2CriterionA={rating.short_name === 'Criterion A'}>
        <div class="cell toprow">
          <div class="celltitle columnheader">{rating.item.header}</div>
        </div>
        <div class="cell intcell">
          <div class="cellbody">{rating.item.score}</div>
          {#if rating.item.mark}
            <img src="{$patient_asset_path+"Icon_GreenCheck-2.svg"}" loading="lazy" alt="" class="greencheck">
          {:else}
            <img src="{$patient_asset_path+"Icon_RedX-2.svg"}" loading="lazy" alt="" class="redx">
          {/if}
        </div>
        <div class="cell intcell">
          <div class="cellbody">{rating.item.correct}</div>
        </div>
      </div>

      {#each rating.details as details}
        {#if details.title === "Required Prompts"}
          <div class="column lastcolumn" class:lastColumnCriterionA={rating.short_name === 'Criterion A'}>
            <div class="cell toprow">
              <div class="celltitle">{details.title}</div>
            </div>
            <div class="cell intcell">
              <div class="cellbody">{details.score}</div>
              {#if details.mark}
                <img src="{$patient_asset_path+"Icon_GreenCheck-2.svg"}" loading="lazy" alt="" class="greencheck">
              {:else}
                <img src="{$patient_asset_path+"Icon_RedX-2.svg"}" loading="lazy" alt="" class="redx">
              {/if}
            </div>
            <div class="cell intcell">
              <div class="cellbody">{details.correct}</div>
            </div>
          </div>
        {:else if details.title === "Trauma Relatedness"}
          <div class="column traumarelatedness">
            <div class="cell toprow">
              <div class="celltitle trauma">{details.title}</div>
            </div>
            <div class="cell">
              <div class="cellbody">{details.score}</div>
              {#if details.mark}
                <img src="{$patient_asset_path+"Icon_GreenCheck-2.svg"}" loading="lazy" alt="" class="greencheck">
              {:else}
                <img src="{$patient_asset_path+"Icon_RedX-2.svg"}" loading="lazy" alt="" class="redx">
              {/if}
            </div>
            <div class="cell">
              <div class="cellbody">{details.correct}</div>
            </div>
          </div>
        {:else}
          <div class="column column4" class:column4CriterionA={rating.short_name === 'Criterion A'}>
            <div class="cell toprow">
              <div class="celltitle columnheader">{details.title}</div>
            </div>
            <div class="cell">
              <div class="cellbody">{details.score}</div>
              {#if details.mark}
                <img src="{$patient_asset_path+"Icon_GreenCheck-2.svg"}" loading="lazy" alt="" class="greencheck">
              {:else}
                <img src="{$patient_asset_path+"Icon_RedX-2.svg"}" loading="lazy" alt="" class="redx">
              {/if}
            </div>
            <div class="cell">
              <div class="cellbody">{details.correct}</div>
            </div>
          </div>
        {/if}
      {/each}

      <!-- top section end div -->
    </div> 

    <div class="bottomsection" class:hidden="{hidden}" >
      <div class="feedbackdiv">
        <div class="peritemtitlediv">
          <img src="{$patient_asset_path+"coach_1coach.png"}" loading="lazy" width="24" alt="" class="coachimage">
          <h1 class="feedbacktitle">{rating.administration_feedback.title}</h1>
        </div>
        {#if rating.administration_feedback.feedback}
          <ul role="list" class="list">
            {#each rating.administration_feedback.feedback as feedback}
              <li class="list-item">{feedback}</li>
            {/each}
          </ul>
        {/if}
      </div>

      <div class="lowerdivider"></div>

      <div class="feedbackdiv">
        <div class="peritemtitlediv">
          <h1 class="feedbacktitle">{rating.short_name} Score Explanation</h1>
        </div>
        {#each rating.item_explanation as explanation}
          <div class="explanation-text">
            <div class="explanation-sub-title">{explanation.title}</div>
            {#if explanation.id === 'severity'}
              <div class="explanation-sub-text">{@html rating.severity_generic}</div>
              <br/>
            {/if}
            <div>{@html explanation.explanation}</div>
            <br/>
          </div>
        {/each}
      </div>

      <!-- bottom section end div -->
    </div>

  </div>
</div>
