<script>
    import { onMount, onDestroy } from 'svelte';
    import pageFile from "./../pages.json";
    import { page, game_mode, item_page_finished_mount, context_toggle } from './../stores.js';
    import {add_criterion_div} from './../Transcript.svelte';
    import { post_user_item_progress_viewed, post_user_item_progress_viewing, get_user_item_progress, get_session } from '../server_api.js';
    import { toggleTutorialAnimation } from './../TutorialAnimationController.svelte';
    import { getTutorialCard } from './../ReChat.svelte';
    export let num = 0;
    export let coachStart;

    let oldValue = '';
    let maxInput = [ 97, 121, 163];
    let inputIdentifier = 0;
    let event = $game_mode=='learn'? "IED attack" : $game_mode=='validate'? "sexual assault":"the fire";
    function changeInput()
    {
        const tempInput = document.getElementById('inputValue');        
        var tempValue = tempInput.value.length;
        if(tempValue > maxInput[inputIdentifier])
        {
            if(inputIdentifier>= 2)
            {
                tempInput.value = oldValue;
            }
            else
            {
                inputIdentifier += 1;
            }
        }
        if(tempValue <= 97)
        {
          inputIdentifier = 0;
        }
        if(tempValue > 97 && tempValue <= 121)
        {
          inputIdentifier = 1;
        }
        oldValue = tempInput.value;
        changeInputSize();
    }
    function changeInputSize()
    {
      const tempInput = document.getElementById('inputValue');
      if(inputIdentifier == 0)
      {
        tempInput.style.fontSize = "1.25vw";
      }
      else if(inputIdentifier == 1)
      {
        tempInput.style.fontSize = "1vw";
      }
      else
      {
        tempInput.style.fontSize = ".75vw";
      }
    }

    onMount( async function()
    {  
      $item_page_finished_mount = false;
      console.log("Sending coach start");
      let progress = await get_user_item_progress(get_session())      
      if(!progress || (progress && progress[$page.itemNum] && !progress[$page.itemNum].viewed))
      {
        //add_criterion_div(num);
        await coachStart("start_criterion_a_tutorial",false);
        toggleTutorialAnimation(getTutorialCard(), $page.itemNum);
        if($game_mode=="learn")
          await coachStart("ITEM_A_START",false);       
      }
      await post_user_item_progress_viewing(num);
      $item_page_finished_mount = true;
    });

    onDestroy( async function()
    {
        post_user_item_progress_viewed(num);
    });
</script>

<style lang="less">
  *:focus {
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #023b5a;
    outline: none; // removes default focus outline
  }
  *:active:focus
  {
    box-shadow: none;
    outline: none; // removes default focus outline
  }
.contentBox
  {
    width: 100%;
    position: relative;
    left: 0;
    padding-bottom: 1%;
    overflow-y: auto;
  } 
  p
  {
    font-size: 1vw;
  }
  .indexEvent
  {
    margin-inline-end: 0;
    margin-block-start: 2%;
    margin-bottom: 0;
  }
  .contentText
  {
    margin-inline-end: 0.5em;
    font-weight: bold;
    line-height: 1.5;
  }

  .eventInput
  {
    font-family: 'Kalam', cursive;
    border: 0;
    outline: 0; 
    border-bottom: 1px solid black;
    width: 84%;
  }
  input
{
  font-size: 1.25vw;
}
@media (max-width:1000px){
    p
  {
      
      font-size: 8.5px;
  }
}
@media (max-width:850px){
    .eventInput
  {
    width: 80%;
  }
}
@media (max-width:700px){
    .eventInput
  {
    width: 80%;
  }
}
@media (max-width:600px){
    .eventInput
  {
    width: 70%;
  }
}
</style>


<div class="contentBox" id="contentBox">
        {#each pageFile as jsonfile}
            {#if jsonfile.id === num}
              {#each jsonfile.content as content, index}
                <p class="contentText" id="contentText{index}">{@html content}</p>
              {/each}                    
              <p class="indexEvent"><i>Index event (specify)</i>: <input type="text" onmouseup="this.blur();" class="eventInput" id="inputValue" aria-label="event readonly text area" on:input={changeInput} bind:value={event} readonly tabindex={$context_toggle?"-1":"0"}></p>
            {/if}            
        {/each}
</div>