import { set_save_state, get_saved_state } from "./saved_states.js"
import { get } from 'svelte/store';
import {getBrowser, getOS} from "./os_browser_detect.js";
import { page, dirtyServerConnection, environment, local_server, userID, game_mode, errorConnectionMessage } from "./stores.js";
let base_url_original;
if(get(environment) == "development")
    if(get(local_server))
        base_url_original = "http://localhost/api";
    else
        base_url_original = "https://demo.c5t.alphaogroup.com/api"
if(get(environment) == "production")
    base_url_original = "https://c5t.alphaogroup.com/api"

let base_url = base_url_original + "/" + get(game_mode);
let items_completed = "/item";
let version_link = "/version";
let addtl_state_data = "/addtl_state_data";
let aicc_connect = "/aicc/connect";
let aicc_completed = "/aicc/completed";
let development_token = "/development/token";
let rechat = "/rechat";
let current_card = "/current_card";
let active_cards = "/active_cards";
let evaluation = "/evaluation";
let certificate = "/certificate";
let user_item_progress = "/user_item_progress";
let submit_log = "/submit_log";
let user = "/user";
let scorm_session = "/scorm_session"

let id;
let session;
let aicc = { aicc_sid:null, aicc_url:null};
let token;
let source;
let failedEvaluationGet = false;

export function get_base_url()
{
    return base_url;
}

export function make_blob(obj)
{
    obj.id = id;
    return new Blob([JSON.stringify(obj)], {type : 'application/json; charset=UTF-8',"Authorization":token}); 
}

//Custom Error Object for the server
export function ServerError(errCode,message,fileName,lineNumber)
{
  var instance = new Error(message, fileName, lineNumber);
  instance.name = 'ServerError';
  instance.errCode = errCode;
  Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
  if (Error.captureStackTrace) {
    Error.captureStackTrace(instance, ServerError);
  }
  return instance;
}

ServerError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: Error,
    enumerable: false,
    writable: true,
    configurable: true
  }
});

if (Object.setPrototypeOf){
  Object.setPrototypeOf(ServerError, Error);
} else {
  ServerError.__proto__ = Error;
}

async function fetch_wrapper(url,message,onAsyncCompleteion=null)
{
    let res;
    try{
        res = await fetch(url,message);
    }
    catch(err)
    {
        dirtyServerConnection.set(true);
        errorConnectionMessage.set("FAILED TO FETCH REQUEST, CHECK YOUR INTERENT CONNECTIVITY")
        throw new ServerError(-1,"FAILED TO FETCH REQUEST");
    }
    errorConnectionMessage.set("");
    if(res.status > 400)
    {
        dirtyServerConnection.set(true);
        throw new ServerError(res.status,res.statusText);
    }
    dirtyServerConnection.set(false);
    if(onAsyncCompleteion)
        onAsyncCompleteion(res);
    return res;
}

// should eventually get id from server authentication step
export async function set_random_id()
{
    let random_id_min=1; 
    let random_id_max_max=100000000;
    id =Math.floor(Math.random() * (+random_id_max_max - +random_id_min)) + +random_id_min;
    userID.set(id);
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8"
        },
        body:JSON.stringify({id:id,os:getOS(),browser:await getBrowser()}),
        method:"POST"
    };
    const response = await fetch_wrapper(base_url+development_token,message);
    try{
        const response_json = await response.json();
        token = response_json.token;
        session = response_json.session;
        source = response_json.source;
    }
    catch(err)
    {
        console.log("Failed to recieve token");
    }
    console.log("id = " + id);
}

export async function set_user_id(uid)
{
    userID.set(uid);
    id = uid    
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8"
        },
        body:JSON.stringify({id:id,os:getOS(),browser:await getBrowser()}),
        method:"POST"
    };
    try{
        const response = await fetch_wrapper(base_url+development_token,message);
        const response_json = await response.json();
        token = response_json.token;
        session = response_json.session;
        source = response_json.source;
    }
    catch(err)
    {
        console.log("Failed to recieve token");
    }
    console.log("id = " + id);
}

export async function start_aicc_connection(obj)
{
    if(!obj.aicc_sid)
    {
        throw "No aicc_sid found"
    }
    if(!obj.aicc_url)
    {
        throw "No aicc_url found"
    }
    aicc.aicc_sid = obj.aicc_sid;
    aicc.aicc_url = obj.aicc_url;
    obj.os = getOS();
    obj.browser = await getBrowser();
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8"
        },
        body:JSON.stringify(obj),
        method:"POST"
    };
    const response = await fetch_wrapper(base_url+aicc_connect,message);
    try
    {
        let user_info = await response.json();
        id = user_info.id;
        token = user_info.token;
        session = user_info.session;
        source = user_info.source;
        return user_info;
    }
    catch(error)
    {
        console.log(error);
        return null;
    }
}

export async function set_scorm_user(first_name,last_name,scorm_id,scorm_source){
    let obj = {
        first_name: first_name,
        last_name: last_name,
        scorm_id: scorm_id,
        scorm_source: scorm_source,
        os: getOS(),
        browser: await getBrowser()
    };
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8"
        },
        body:JSON.stringify(obj),
        method:"POST"
    };
    const response = await fetch_wrapper(base_url+scorm_session,message);
    try
    {
        let user_info = await response.json();
        id = user_info.id;
        token = user_info.token;
        session = user_info.session;
        source = user_info.source;
        return user_info;
    }
    catch(error)
    {
        console.log(error);
        return null;
    }
}

export async function set_aicc_completed()
{
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(aicc),
        method:"POST"
    };

    await fetch_wrapper(base_url+aicc_completed,message);
}

export function get_session()
{
    return session;
}

export function get_source()
{
    return source;
}

export async function post_item_completed(obj)
{
    if(!obj.id)
    {
        if(id)
        {
            obj.id = id;
        }
        else
        {
            throw "No ID found"
        }
    }
    if(!obj.session)
    {
        if(session)
        {
            obj.session = session;
        }
        else
        {
            throw "No session found"
        }
    }
    if(obj.item == null || obj.item == undefined)
    {
        if(get(page).itemNum != null && get(page).itemNum!= undefined) 
        {
            obj.item = get(page).itemNum;
        }
        else
        {
            throw "No item number found"
        }
    }
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"POST"
    };
    await fetch_wrapper(base_url+items_completed,message,res=>{
            if(res.status == 200)
            {
                set_save_state(obj.item,session,obj);
            }
        });
}

export async function get_items_completed()
{
    if(!id)
        throw "No ID found";
    let obj = {
        id:id
    };
    let url = new URL(base_url+items_completed),
        params = obj;
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const message = {
        headers: {
            "Authorization":token
        },
        method:"GET"
    };
    const res = await fetch_wrapper(url,message);
    try{
        if(res.status === 204)
        {
            console.log(res.statusText);
            console.log("No items found");
            return null;
        }
        const info = await res.json();
        return info;
    }
    catch(err)
    {
        console.log(err);
        console.log("Failed to recieve items");
        return null;
    }
}

export async function get_item_history(item,session)
{
    let url = new URL(base_url+items_completed+"/history");
    url.searchParams.append("id", id);
    url.searchParams.append("item", item);
    url.searchParams.append("session", session);
    const message = {
        headers: {
            "Authorization":token
        },
        method:"GET"
    };
    const res = await fetch_wrapper(url,message);
    try
    {
        const results = await res.json();
        return results;
    }
    catch(err)
    {
        console.log("Failed to recieve results");
        return null;
    }
}


export async function proccess_item_results()
{
    let url = new URL(base_url+items_completed+"/results");
    url.searchParams.append("id", id);
    url.searchParams.append("item", get(page).itemNum);
    url.searchParams.append("session", session);
    const message = {
        headers: {
            "Authorization":token
        },
        method:"GET"
    };
    const res = await fetch_wrapper(url,message);
    try
    {
        const results = await res.json();
        return results;
    }
    catch(err)
    {
        console.log(err);
        console.log("Failed to recieve results");
        return null;
    }
}

export async function get_current_version()
{
    let url = new URL(base_url+version_link);
    const message = {
        headers: {
            "Authorization":token
        },
        method:"GET"
    };
    const res = await fetch_wrapper(url,message);
    try
    {
        const results = await res.json();
        return results.version.major + "." + results.version.minor + "." + results.version.patch;
    }
    catch(err)
    {
        console.log("Failed to recieve results");
        return null;
    }
}

export async function post_rechat_current_card(name,repeats,person)
{
    if(!id)
        throw "No ID found";
    let obj = {
        id:id,
        name:name,
        repeats:repeats,
        person:person,
        session:session
    };
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"POST"
    };

    fetch_wrapper(base_url+rechat+current_card,message);
}

export async function post_rechat_active_card(name,repeats,person)
{
    if(!id)
        throw "No ID found";
    let obj = {
        id:id,
        name:name,
        repeats:repeats,
        person:person,
        session:session
    };
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"POST"
    };
    fetch_wrapper(base_url+rechat+active_cards,message);
}

export async function delete_rechat_active_card(name,person)
{
    if(!id)
        throw "No ID found";
    let obj = {
        id:id,
        name:name,
        person:person,
        session:session
    };
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"DELETE"
    };
    fetch_wrapper(base_url+rechat+active_cards,message);
}


export async function get_rechat(person)
{
    let url = new URL(base_url+rechat);
    url.searchParams.append("id", id);
    url.searchParams.append("person",person);
    url.searchParams.append("session",session);
    const message = {
        headers: {
            "Authorization":token
        },
        method:"GET"
    };
    const res = await fetch_wrapper(url,message);
    try
    {
        const info = await res.json();
        return info;
    }
    catch(err)
    {
        console.log("Failed to recieve rechat");
        return null;
    }
}

export async function delete_rechat_state()
{
    if(!id)
        throw "No ID found";
    let obj = {
        id:id
    };
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"DELETE"
    };
    await fetch_wrapper(base_url+rechat,message);
}

// requires an object with an id (provided if set through the api), cretieria, and save (as a json string)
export async function post_addtl_state_data(data,itemNum,options={})
{
    if(!id)
        throw "No ID found";
    set_save_state(itemNum,session,{addtl_data:data},options);
    let saved = get_saved_state(itemNum,session);
    let addtl_data;
    if(saved)
        addtl_data = saved.addtl_data;
    if(!addtl_data)
        throw "No additional data to save";
    let obj = {
        id:id,
        item:itemNum,
        data:addtl_data,
        session:session
    }
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"POST"
    };

    fetch_wrapper(base_url+addtl_state_data,message);
}

export async function get_addtl_state_data()
{
    if(!id)
        throw "No ID found";
    let url = new URL(base_url+addtl_state_data);
    url.searchParams.append("id", id);
    url.searchParams.append("session",session);
    const message = {
        headers: {
            "Authorization":token
        },
        method:"GET"
    };
    const res = await fetch_wrapper(url,message);
    try
    {
        const obj = await res.json();
        return obj;
    }
    catch(err)
    {
        console.log("Failed to any recieve addtl state data");
        return null;
    }
}

export async function get_eval_correct_values()
{
    let url = new URL(base_url+evaluation+"/correct_values");
    const message = {
        headers: {
            "Authorization":token
        },
        method:"GET"
    };
    const res = await fetch_wrapper(url,message);
    try
    {
        const obj = await res.json();
        return obj;
    }
    catch(err)
    {
        console.log("Failed to recieve evaluation");
        return null;
    }
}


export async function get_latest_eval_version()
{
    let url = new URL(base_url+evaluation+"/version");
    const message = {
        headers: {
            "Authorization":token
        },
        method:"GET"
    };
    const res = await fetch_wrapper(url,message);
    try
    {
        const results = await res.json();
        return results.version;
    }
    catch(err)
    {
        console.log("Failed to recieve results");
        return null;
    }
}

export async function post_certificate(certificate_id, is_scorm_session)
{
    if(!id)
        throw "No ID found";
    let obj = {
        id:id,
        cid:certificate_id,
        session:session,
        aicc_sid:aicc.aicc_sid,
        aicc_url:aicc.aicc_url,
        is_scorm: is_scorm_session
    };
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"POST"
    };
    await fetch_wrapper(base_url+certificate,message);
}

export async function get_certificates()
{
    let url = new URL(base_url+certificate);
    url.searchParams.append("id", id);
    const message = {
        headers: {
            "Authorization":token
        },
        method:"GET"
    };
    const res = await fetch_wrapper(url,message);
    try
    {
        const obj = await res.json();
        return obj;
    }
    catch(err)
    {
        console.log("Failed to recieve certificates");
        return null;
    }
}

export async function post_user_item_progress_passed(passed)
{
    if(!id)
        throw "No ID found";
    let obj = {
        id:id,
        item:get(page).itemNum,
        passed:passed,
        session:session
    };
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"POST"
    };
    await fetch_wrapper(base_url+user_item_progress+"/passed",message);
}

export async function post_user_item_progress_viewing(item)
{
    if(!id)
        throw "No ID found";
    let obj = {
        id:id,
        item:item,
        session:session
    };
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"POST"
    };

    await fetch_wrapper(base_url+user_item_progress+"/viewing",message);

}

export async function post_user_item_progress_viewed(item)
{
    if(!id)
        throw "No ID found";
    let obj = {
        id:id,
        item:item,
        session:session
    };
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"POST"
    };

    await fetch_wrapper(base_url+user_item_progress+"/viewed",message);

}

export async function get_user_item_progress(session)
{
    let url = new URL(base_url+user_item_progress);
    url.searchParams.append("id", id);
    url.searchParams.append("session", session);
    const message = {
        headers: {
            "Authorization":token
        },
        method:"GET"
    };
    try
    {
        const res = await fetch_wrapper(url,message);
        if(res.status === 204)
        {
            console.log(res.statusText);
            console.log("No items found");
            return null;
        }
        const obj = await res.json();
        return obj;
    }
    catch(err)
    {
        console.log(err);
        console.log("Failed to recieve user progress");
        return null;
    }
}

// obj must have name (card name) and an array of tags
//
export async function post_rechat_tags(obj)
{
    if(!id)
        throw "No ID found";
    obj.id = id;
    obj.session = session;
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"POST"
    };

    await fetch_wrapper(base_url+rechat+"/tags",message);

}

export async function get_rechat_tags(session)
{
    let url = new URL(base_url+rechat+"/tags");
    url.searchParams.append("id", id);
    url.searchParams.append("session", session);
    const message = {
        headers: {
            "Authorization":token
        },
        method:"GET"
    };
    const res = await fetch_wrapper(url,message);
    try
    {
        if(res.status === 204)
        {
            console.log(res.statusText);
            console.log("No items found");
            return null;
        }
        const obj = await res.json();
        return obj;
    }
    catch(err)
    {
        console.log(err);
        console.log("Failed to recieve user progress");
        return null;
    }
}

export async function post_rechat_sequence(obj)
{
    if(!id)
        throw "No ID found";
    obj.id = id;
    obj.item = get(page).itemNum;
    obj.session = session;
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"POST"
    };

    await fetch_wrapper(base_url+rechat+"/sequence",message);

}

export async function get_rechat_sequence(person,session)
{
    let url = new URL(base_url+rechat+"/sequence");
    url.searchParams.append("id", id);
    url.searchParams.append("person", person);
    url.searchParams.append("session", session);
    const message = {
        headers: {
            "Authorization":token
        },
        method:"GET"
    };
    const res = await fetch_wrapper(url,message);
    try
    {
        if(res.status === 204)
        {
            console.log(res.statusText);
            console.log("No items found");
            return null;
        }
        const obj = await res.json();
        return obj;
    }
    catch(err)
    {
        console.log("Failed to recieve rechat sequence");
        return null;
    }
}

export async function post_rechat_log(log,comment)
{
    let obj = {};
    if(!id)
        throw "No ID found";
    obj.id = id;
    obj.log = log;
    obj.comment = comment;
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"POST"
    };
    await fetch_wrapper(base_url+submit_log+"/rechat",message);
}

export async function post_event_log(event_name,event_info)
{
    let obj = {};
    if(!id)
        throw "No ID found";
    obj.id = id;
    obj.session = session;
    obj.event_name= event_name;
    obj.event_info= event_info;
    obj.timestamp = (new Date().getTime())/1000;
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"POST"
    };
    await fetch_wrapper(base_url+submit_log+"/event",message);
}

export async function increment_session()
{
    let obj = {};
    if(!id)
        throw "No ID found";
    obj.id = id;
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"PUT"
    };
    let res = await fetch_wrapper(base_url+user+"/increment_session",message);
    try
    {
        if(res.status === 204)
        {
            console.log(res.statusText);
            console.log("Session was not updated");
        }
        const obj = await res.json();
        session = obj.session;
    }
    catch(err)
    {
        console.log("Failed update session");
        return null;
    }
}

export async function get_current_page()
{
    if(!id)
        throw "No ID found";
    let url = new URL(base_url+user+"/current_page");
    url.searchParams.append("id", id);
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        method:"GET"
    };
    let res = await fetch_wrapper(url,message);
    try
    {
        if(res.status === 204)
        {
            console.log(res.statusText);
            console.log("Current Page was not recieved");
        }
        const obj = await res.json();
        return obj.current_page;
    }
    catch(err)
    {
        console.log("Failed to get user's current page");
        return null;
    }
}

export async function set_current_page(page)
{
    let obj = {};
    if(!id)
        throw "No ID found";
    obj.id = id;
    obj.page = page;
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"PUT"
    };
    let res = await fetch_wrapper(base_url+user+"/current_page",message);
    try
    {
        if(res.status === 204)
        {
            console.log(res.statusText);
            console.log("Current Page was not updated");
        }
        const obj = await res.json();
        return obj.current_page;
    }
    catch(err)
    {
        console.log("Failed to update current page");
        return null;
    }
}

export async function checkHeartbeat()
{
    let url = new URL(base_url);
    const message = {
        headers: {
            "Authorization":token
        },
        method:"GET"
    };
    try
    {
        const res = await fetch_wrapper(url,message);
        return true
    }
    catch(err)
    {
        console.log("Failed to recieve heartbeat");
        return false;
    }  
}

export async function get_saved_evaluation(sessionIn)
{
    let url = new URL(base_url+evaluation+"/save");
    url.searchParams.append("id", id);
    url.searchParams.append("session", sessionIn);
    const message = {
        headers: {
            "Authorization":token
        },
        method:"GET"
    };
    const res = await fetch_wrapper(url,message);
    try
    {
        if(res.status === 204)
        {
            console.log(res.statusText);
            console.log("No items found");
            return null;
        }
        const obj = await res.json();
        return obj;
    }
    catch(err)
    {
        console.log("Failed to recieve rechat sequence");
        return null;
    }
}

export async function post_saved_evaluation(e)
{
    var obj = {
        id:id,
        session:session,
        evaluation:e
    };
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"POST"
    };

    await fetch_wrapper(base_url+evaluation+"/save",message);
}

export async function post_eval_totals(other_prompts_verbatim_total,other_prompts_paraphrased_total,required_ask_total,initial_total,irrelevant_total,off_script_total)
{
    var obj = {
        other_prompts_verbatim_total:other_prompts_verbatim_total,
        other_prompts_paraphrased_total:other_prompts_paraphrased_total,
        required_ask_total:required_ask_total,
        initial_total:initial_total,
        irrelevant_total:irrelevant_total,
        off_script_total:off_script_total
    };
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"POST"
    };

    await fetch_wrapper(base_url+evaluation+"/prompt_totals",message);
}

export async function post_familiarity(session,familiarity)
{
    var obj = {
        id:id,
        session:session,
        familiarity:familiarity
    };
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        body:JSON.stringify(obj),
        method:"POST"
    };

    await fetch_wrapper(base_url+"/familiarity",message);
}

export async function get_force_restart()
{
    if(!id)
        throw "No ID found";
    let url = new URL(base_url+user+"/force_restart");
    url.searchParams.append("id", id);
    const message = {
        headers: {
            "content-type":"application/json; charset=UTF-8",
            "Authorization":token
        },
        method:"GET"
    };
    let res = await fetch_wrapper(url,message);
    try
    {
        if(res.status === 204)
        {
            console.log(res.statusText);
            console.log("Force restart was not recieved");
            throw "Can't force restart";
        }
        const obj = await res.json();
        return obj.force_restart;
    }
    catch(err)
    {
        console.log("Failed to get force restart");
        throw "Can't force restart";
    }
}